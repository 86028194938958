import LanguageReducer from './LanguageReducer';
import AuthReducer from './AuthReducer';
import { combineReducers } from "redux";
import ChatReducer from './ChatReducer'
import NotificationReducer from './NotificationReducer'
import CompanyReducer from './CompanyReducer';

export default combineReducers({
    lang: LanguageReducer,
    auth: AuthReducer,
    chat: ChatReducer,
    notif: NotificationReducer,
    company:CompanyReducer
});