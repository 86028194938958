import React from "react";
import "./userForm.css";
import {MDBContainer,MDBRow,MDBCol,MDBInput,MDBBtn,MDBIcon} from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import {hasValue} from '../../validations/validations'
import {message, Select,TreeSelect,Radio } from 'antd';
import {NavLink} from 'react-router-dom';
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import {ChangeCompany,ChangeBranch} from '../../redux/actions/CompanyAction'
import moment from 'moment'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

const {Option} = Select
const {TreeNode} = TreeSelect

class UserForm extends React.Component {
  state = {
    fullname: this.props.location.state?this.props.location.state.data.fullname:"",
    password: this.props.location.state?this.props.location.state.data.password:"",
    phone: this.props.location.state?this.props.location.state.data.phone:"",
    anotherPhone: this.props.location.state?this.props.location.state.data.anotherPhone:"",
    address: this.props.location.state?this.props.location.state.data.address:"",
    notes: this.props.location.state?this.props.location.state.data.notes:"",
    cardNum: this.props.location.state?this.props.location.state.data.cardNum:"",
    
    city:this.props.location.state?this.props.location.state.data.city.id:[],
    profileImg:this.props.location.state?this.props.location.state.data.img !=""?this.props.location.state.data.img :null:null,
    cardImg:this.props.location.state?this.props.location.state.data.cardImg.length > 0?this.props.location.state.data.cardImg:null:null,
    cardImgViews:this.props.location.state?this.props.location.state.data.cardImg:[],
    profileImgType:this.props.location.state?'url':'data',
    cardImgType:this.props.location.state?'url':'data',
    User:this.props.location.state?this.props.location.state.data:[],
    requestType:this.props.location.state?"put":"post",
    cities:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,

   
    showPartners:this.props.location.state?this.props.location.state.data.pagesPermission.includes('PARTNERS') ?true:false:false,
    showCategories:this.props.location.state?this.props.location.state.data.pagesPermission.includes('CATEGORIES') ?true:false:false,
    salesMen:this.props.location.state?this.props.location.state.data.pagesPermission.includes('SALESMEN') ?true:false:false,
    showReports:this.props.location.state?this.props.location.state.data.pagesPermission.includes('REPORTS') ?true:false:false,
    showStock:this.props.location.state?this.props.location.state.data.pagesPermission.includes('STOCK') ?true:false:false,
    showClients:this.props.location.state?this.props.location.state.data.pagesPermission.includes('CLIENTS') ?true:false:false,
    showBranch:this.props.location.state?this.props.location.state.data.pagesPermission.includes('BRANCHES') ?true:false:false,
    showCompany:this.props.location.state?this.props.location.state.data.pagesPermission.includes('COMPANIES') ?true:false:false,
    showStatistic:this.props.location.state?this.props.location.state.data.pagesPermission.includes('STATISTIC') ?true:false:false,
    showAds:this.props.location.state?this.props.location.state.data.pagesPermission.includes('ADS') ?true:false:false,
    showOrders:this.props.location.state?this.props.location.state.data.pagesPermission.includes('ORDERS') ?true:false:false,
    showSuppliers:this.props.location.state?this.props.location.state.data.pagesPermission.includes('SUPPLIERS') ?true:false:false,
    showPermissions:this.props.location.state?this.props.location.state.data.pagesPermission.includes('PERMISSIONS') ?true:false:false,
    showExpenses:this.props.location.state?this.props.location.state.data.pagesPermission.includes('EXPENSES') ?true:false:false,
    showSettings:this.props.location.state?this.props.location.state.data.pagesPermission.includes('SETTINGS') ?true:false:false,
    showReciepts:this.props.location.state?this.props.location.state.data.pagesPermission.includes('RECIEPTS') ?true:false:false,
    showCities:this.props.location.state?this.props.location.state.data.pagesPermission.includes('CITIES') ?true:false:false,
    showCollectibles:this.props.location.state?this.props.location.state.data.pagesPermission.includes('COLLECTIBLES') ?true:false:false,
    showProblems:this.props.location.state?this.props.location.state.data.pagesPermission.includes('PROBLEMS') ?true:false:false,
    showCalculation:this.props.location.state?this.props.location.state.data.pagesPermission.includes('CALCULATIONS') ?true:false:false,
    addBranch:this.props.location.state?this.props.location.state.data.company?this.props.location.state.data.company.addBranch:true:false,
    companyBuyType:this.props.location.state?this.props.location.state.data.company?this.props.location.state.data.company.buyType != "CASH"?true:false:true:false,
    photoIndex: 0,
    isOpen1: false,
    isOpen2: false,

  };

  constructor(props){
    super(props)
    console.log("isr   ",this.props.location)
    if(this.props.currentUser.user.type == "COMPANY"){
      if(this.props.currentUser.user.addBranch == true){
        this.setState({addBranch:true})
      }else{
        this.setState({addBranch:false})
      }
      if(this.props.currentUser.user.buyType != "CASH"){
        this.setState({companyBuyType:true})
      }else{
        this.setState({companyBuyType:false})
      }
    }
    this.getCities()
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    window.scrollTo(0,0)
  }
  
  getCities = () => {
    axios.get(`${BASE_END_POINT}cities`)
    .then(response=>{
      console.log(response.data)
      this.setState({cities:response.data})
    })
    .catch(error=>{
      console.log("ALL cities ERROR")
      console.log(error.response)
    })
  }
  componentDidMount(){
    this.getCities()
    if(this.props.currentUser.user.type == "COMPANY"){
      if(this.props.currentUser.user.addBranch == true){
        this.setState({addBranch:true})
      }else{
        this.setState({addBranch:false})
      }
      if(this.props.currentUser.user.buyType != "CASH"){
        this.setState({companyBuyType:true})
      }else{
        this.setState({companyBuyType:false})
      }
    }
  }
  addUser = () => {
    const {fullname,password,phone,anotherPhone,profileImg,
      showPartners,showCategories,salesMen,showReports,showStock,showClients,showBranch,
      showCompany,showStatistic,showAds,showOrders,showSuppliers,showPermissions,
      showExpenses,showSettings,showReciepts,showCities,showCollectibles,showProblems,
      showCalculation,cardNum,address,notes,city,cardImg} = this.state
   console.log(city)
    if(hasValue(fullname)&&hasValue(password)&&hasValue(phone)&&hasValue(cardNum)
      &&city!=[]){
        let l = message.loading('Wait..', 2.5)
        var data = new FormData()
        data.append('fullname',fullname)
        data.append('cardNum',cardNum)
        data.append('city',city)
        data.append('type','SUB-ADMIN')
        data.append('password',password); 
        data.append('phone',phone); 
        ///// 
        if(this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN" ){
          if(this.props.company == false){
            data.append('company',this.props.currentUser.user.company[0].id); 
          }else{
            data.append('company',this.props.company); 
          }
        } else{
          data.append('company',this.props.currentUser.user.id);
          data.append('gineralSubAdmin',false)
        }
        if(cardImg != null){
          for(var i=0 ; i<= cardImg.length-1 ; i++){
             data.append('cardImg',cardImg[i]); 
          }
        }
        let pages = [];
        if(showPartners == true){
          pages.push('PARTNERS')
        }
        if(showCategories == true){
          pages.push('CATEGORIES')
        }
        if(salesMen == true){
          pages.push('SALESMEN')
        }
        if(showReports == true){
          pages.push('REPORTS')
        }
        if(showStock == true){
          pages.push('STOCK')
        }
        if(showClients == true){
          pages.push('CLIENTS')
        }
        if(showBranch == true){
          pages.push('BRANCHES')
        }
        if(showCompany == true){
          pages.push('COMPANIES')
        }
        if(showStatistic == true){
          pages.push('STATISTIC')
        }
        if(showAds == true){
          pages.push('ADS')
        }
        if(showOrders == true){
          pages.push('ORDERS')
        }
        if(showSuppliers == true){
          pages.push('SUPPLIERS')
        }
        if(showPermissions == true){
          pages.push('PERMISSIONS')
        }
        if(showExpenses == true){
          pages.push('EXPENSES')
        }
        if(showSettings == true){
          pages.push('SETTINGS')
        }
        if(showReciepts == true){
          pages.push('RECIEPTS')
        }
        if(showCities == true){
          pages.push('CITIES')
        }
        if(showCollectibles == true){
          pages.push('COLLECTIBLES')
        }
        if(showProblems == true){
          pages.push('PROBLEMS')
        }
        if(showCalculation == true){
          pages.push('CALCULATIONS')
        }
     console.log(pages)
     data.append('pagesPermission', JSON.stringify(pages))

       
        
        if(profileImg != null){
          data.append('img',profileImg); 
        }
         
        if(anotherPhone !==""  && anotherPhone != undefined){
          data.append('anotherPhone',anotherPhone); 
        }
        if(notes !==""  && notes != undefined){
          data.append('notes',notes); 
        }
        if(address !==""  && address != undefined){
          data.append('address',address); 
        }
        console.log(Array.from(data))
        
        axios.post(`${BASE_END_POINT}addUser`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.push('/users')
        })
        .catch(error=>{
            console.log(error.response)
            if(error.response.data.errors[0].msg =="cardNum duplicated"){
              l.then(() => message.error(allStrings.duplicatedCardNum, 2.5))
            }
            if(error.response.data.errors[0].msg =="phone duplicated"){
              l.then(() => message.error(allStrings.duplicatedPhone, 2.5))
            }
        })
    }
  }
  updateUser = () => {
    const {fullname,password,phone,anotherPhone,profileImg,
      showPartners,showCategories,salesMen,showReports,showStock,showClients,showBranch,
      showCompany,showStatistic,showAds,showOrders,showSuppliers,showPermissions,
      showExpenses,showSettings,showReciepts,showCities,showCollectibles,showProblems,
      showCalculation,cardNum,address,notes,city,cardImg,cardImgType}  = this.state
    if(hasValue(fullname)&&hasValue(phone)&&hasValue(cardNum)
      ){
        let l = message.loading('Wait..', 2.5)
        var data = new FormData()
        data.append('fullname',fullname)
        if(cardImg != null){
          //if(cardImgType == 'data'){
            for(var i=0 ; i<= cardImg.length-1 ; i++){
              data.append('cardImg',cardImg[i]); 
            }
          //}
        }
        data.append('cardNum',cardNum)
        data.append('city',city)
        data.append('type','SUB-ADMIN')
        if(password !="" && password != undefined){
          data.append('password',password); 
        }
        
        data.append('phone',phone); 
         /////
         let pages = [];
          if(showPartners == true){
            pages.push('PARTNERS')
          }
          if(showCategories == true){
            pages.push('CATEGORIES')
          }
          if(salesMen == true){
            pages.push('SALESMEN')
          }
          if(showReports == true){
            pages.push('REPORTS')
          }
          if(showStock == true){
            pages.push('STOCK')
          }
          if(showClients == true){
            pages.push('CLIENTS')
          }
          if(showBranch == true){
            pages.push('BRANCHES')
          }
          if(showCompany == true){
            pages.push('COMPANIES')
          }
          if(showStatistic == true){
            pages.push('STATISTIC')
          }
          if(showAds == true){
            pages.push('ADS')
          }
          if(showOrders == true){
            pages.push('ORDERS')
          }
          if(showSuppliers == true){
            pages.push('SUPPLIERS')
          }
          if(showPermissions == true){
            pages.push('PERMISSIONS')
          }
          if(showExpenses == true){
            pages.push('EXPENSES')
          }
          if(showSettings == true){
            pages.push('SETTINGS')
          }
          if(showReciepts == true){
            pages.push('RECIEPTS')
          }
          if(showCities == true){
            pages.push('CITIES')
          }
          if(showCollectibles == true){
            pages.push('COLLECTIBLES')
          }
          if(showProblems == true){
            pages.push('PROBLEMS')
          }
          if(showCalculation == true){
            pages.push('CALCULATIONS')
          }
       
          console.log(pages)
          data.append('pagesPermission', JSON.stringify(pages))
          if(profileImg != null){
            //if(this.state.profileImgType == 'data'){
              if(profileImg != null){
                data.append('img',profileImg); 
              }
            //}
          }
        
         
        if(anotherPhone !==""  && anotherPhone != undefined){
          data.append('anotherPhone',anotherPhone); 
        }
        if(notes !==""  && notes != undefined ){
          data.append('notes',notes); 
        }
        if(address !==""  && address != undefined){
          data.append('address',address); 
        }
       
        
        console.log(Array.from(data))
        
        axios.put(`${BASE_END_POINT}user/${this.state.User.id}/updateInfo`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.push('/users')
        })
        .catch(error=>{
            console.log(error.response)
            if(error.response.data.errors[0].msg =="cardNum duplicated"){
              l.then(() => message.error(allStrings.duplicatedCardNum, 2.5))
            }
            if(error.response.data.errors[0].msg =="phone duplicated"){
              l.then(() => message.error(allStrings.duplicatedPhone, 2.5))
            }
        })
    }
  }
  
  fileHandlerProfileImg = (event) => {
    this.setState({
      profileImg: event.target.files[0],
      profileImgType:'data'
    })
    
  }
  removeProfileImg = () => {
    this.setState({
      profileImg:null
    });
  }

  
  //card img
  fileHandlerCardImg = (event) => {
    let imgs =[];
    for(var i=0 ; i<= event.target.files.length-1 ; i++){
      imgs.push(URL.createObjectURL(event.target.files[i]))
    }
    console.log(imgs)
    this.setState({
      cardImg: event.target.files,
      cardImgViews:imgs,
      cardImgType:'data'
    })
    
  }
  removeCardImg = (url) => {
    let arr = this.state.cardImgViews;
    let arr2 =  Array.from(this.state.cardImg);
    console.log(arr2)
    var index = arr.indexOf(url);
    if (index !== -1) arr.splice(index, 1);
    if (index !== -1) arr2.splice(index, 1);
    console.log(arr2)
    this.setState({
      cardImgViews:arr,
      cardImg:arr2,
    });
    if(arr2.length == 0){
      this.setState({
        cardImg:null,
      });
    }
  };
  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  UserSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.addUser()
  };
  updateSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.updateUser()

  };
  
  onChangeDate = (date, dateString) => {
    console.log(date, dateString)
    this.setState({start:dateString})
  }
  onChange = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      contractType: e.target.value,
    });
  };
  showRecieptsOn =()=>{
    this.setState({
      showReciepts: true,
    })
  }
  showRecieptsOff =()=>{
    this.setState({
      showReciepts: false,
    })
  }
  partnersOn =()=>{
    this.setState({
      showPartners: true,
    })
  }
  partnersOff =()=>{
    this.setState({
      showPartners: false,
    })
  }
  showCollectiblesOn =()=>{
    this.setState({
      showCollectibles: true,
    })
  }
  showCollectiblesOff =()=>{
    this.setState({
      showCollectibles: false,
    })
  }
  showOrdersOn =()=>{
    this.setState({
      showOrders: true,
    })
  }
  showOrdersOff =()=>{
    this.setState({
      showOrders: false,
    })
  }
  showSuppliersOn =()=>{
    this.setState({
      showSuppliers: true,
    })
  }
  showSuppliersOff =()=>{
    this.setState({
      showSuppliers: false,
    })
  }
  showPermissionsOn =()=>{
    this.setState({
      showPermissions: true,
    })
  }
  showPermissionsOff =()=>{
    this.setState({
      showPermissions: false,
    })
  }
  categoriesOn =()=>{
    this.setState({
      showCategories: true,
    })
  }
  categoriesOff =()=>{
    this.setState({
      showCategories: false,
    })
  }
  salesMenOn =()=>{
    this.setState({
      salesMen: true,
    })
  }
  salesMenOff =()=>{
    this.setState({
      salesMen: false,
    })
  }
  statisticOn =()=>{
    this.setState({
      showStatistic: true,
    })
  }
  statisticOff =()=>{
    this.setState({
      showStatistic: false,
    })
  }
  adsOn =()=>{
    this.setState({
      showAds: true,
    })
  }
  adsOff =()=>{
    this.setState({
      showAds: false,
    })
  }
  reportsOn =()=>{
    this.setState({
      showReports: true,
    })
  }
  reportsOff =()=>{
    this.setState({
      showReports: false,
    })
  }
  stockOn =()=>{
    this.setState({
      showStock: true,
    })
  }
  stockOff =()=>{
    this.setState({
      showStock: false,
    })
  }
  branchOn =()=>{
    this.setState({
      showBranch: true,
    })
  }
  branchOff =()=>{
    this.setState({
      showBranch: false,
    })
  }
  clientsOn =()=>{
    this.setState({
      showClients: true,
    })
  }
  clientsOff =()=>{
    this.setState({
      showClients: false,
    })
  }
  companyOn =()=>{
    this.setState({
      showCompany: true,
    })
  }
  companyOff =()=>{
    this.setState({
      showCompany: false,
    })
  }

  showCalculationOn =()=>{
    this.setState({
      showCalculation: true,
    })
  }
  
  showCalculationOff =()=>{
    this.setState({
      showCalculation: false,
    })
  }
  showRecieptsOff =()=>{
    this.setState({
      showReciepts: false,
    })
  }
  showRecieptsOn =()=>{
    this.setState({
      showReciepts: true,
    })
  }
  showCitiesOff =()=>{
    this.setState({
      showCities: false,
    })
  }
  showCitiesOn =()=>{
    this.setState({
      showCities: true,
    })
  }
  showProblemsOff =()=>{
    this.setState({
      showProblems: false,
    })
  }
  showProblemsOn =()=>{
    this.setState({
      showProblems: true,
    })
  }
  showSettingsOn =()=>{
    this.setState({
      showSettings: true,
    })
  }
  showSettingsOff =()=>{
    this.setState({
      showSettings: false,
    })
  }
  showExpensesOn =()=>{
    this.setState({
      showExpenses: true,
    })
  }
  showExpensesOff =()=>{
    this.setState({
      showExpenses: false,
    })
  }

  render() {
    let {cardImg,profileImg, photoIndex, isOpen1,isOpen2} = this.state;
     profileImg = [profileImg]
     console.log("company buy type",this.state.companyBuyType)
    return (
      
      <div className="UserForm">
        <MDBContainer>
          <MDBRow>
            <div className="top" style={{width:'100%',textAlign:this.state.requestType==="post" ?'center':'right'}}>
              <NavLink to="/users">
                <MDBIcon icon="times" className="closeIcon"/>
              </NavLink>
              {this.state.requestType==="post" ?
              <span className="stockTitle">{allStrings.completeData}</span>
              :
              <span>
              <span className="newTitle" style={{margin: '1rem 0rem 1rem .5rem'}}>{this.state.User.lastSeen?moment().calendar(this.state.User.lastSeen):moment().calendar()}</span>
                <span className="newTitle">
                  {allStrings.lastSeen}
                </span>
              </span>
              }
              
            </div>
          </MDBRow>
          <form
              className="needs-validation"
              onSubmit={this.state.requestType==="post"?this.UserSubmitHandler:this.updateSubmitHandler}
              noValidate
              style={{marginLeft:'2rem'}}
          >
          <MDBRow>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="6">
                  <MDBRow>
                    <MDBCol md="12">
                      <label  htmlFor="defaultFormRegisterNameEx2"
                      style={{
                        float:'right',
                        fontWeight: '800',
                        color: '#43425D',
                        fontSize: '18px',
                        marginBottom: '2rem',
                        marginRight:'1rem'
                      }}>
                      {allStrings.permissions}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.partnersOff}
                            style={{background:this.state.showPartners?'transparent':"mediumseagreen"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.showPartners?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.partnersOn}
                             style={{background:this.state.showPartners?"mediumseagreen":'transparent'}}>
                                <span style={{
                                  color:this.state.showPartners?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.partners}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.showSettingsOff}
                            style={{background:this.state.showSettings?'transparent':"mediumseagreen"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.showSettings?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.showSettingsOn}
                             style={{background:this.state.showSettings?"mediumseagreen":'transparent'}}>
                                <span style={{
                                  color:this.state.showSettings?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.settings}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.reportsOff}
                            style={{background:this.state.showReports?'transparent':"mediumseagreen"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.showReports?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.reportsOn}
                             style={{background:this.state.showReports?"mediumseagreen":'transparent'}}>
                                <span style={{
                                  color:this.state.showReports?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.reports}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.stockOff}
                            style={{background:this.state.showStock==false?'mediumseagreen':"transparent"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.showStock?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.stockOn}
                             style={{background:this.state.showStock==true?'mediumseagreen':"transparent"}}>
                                <span style={{
                                  color:this.state.showStock?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.stock}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.showCalculationOff}
                            style={{background:this.state.showCalculation?'transparent':"mediumseagreen"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.showCalculation?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.showCalculationOn}
                             style={{background:this.state.showCalculation?"mediumseagreen":'transparent'}}>
                                <span style={{
                                  color:this.state.showCalculation?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.calculations}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.clientsOff}
                            style={{background:this.state.showClients==false?'mediumseagreen':"transparent"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.showClients?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.clientsOn}
                             style={{background:this.state.showClients==true?'mediumseagreen':"transparent"}}>
                                <span style={{
                                  color:this.state.showClients?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.clients}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  {this.props.currentUser.user.type == "ADMIN" &&
                  
                  <MDBRow>
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.companyOff}
                            style={{background:this.state.showCompany?'transparent':"mediumseagreen"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.showCompany?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.companyOn}
                             style={{background:this.state.showCompany?"mediumseagreen":'transparent'}}>
                                <span style={{
                                  color:this.state.showCompany?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.companies}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.adsOff}
                            style={{background:this.state.showAds==false?'mediumseagreen':"transparent"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.showAds?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.adsOn}
                             style={{background:this.state.showAds==true?'mediumseagreen':"transparent"}}>
                                <span style={{
                                  color:this.state.showAds?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.ads}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  }
                  {this.props.currentUser.user.type == "ADMIN" &&
                   <MDBRow>
                   <MDBCol md="6">
                     <MDBRow>
                       <MDBCol md="6">
                         <div className="checking">
                           <div className="checkingLeft" onClick={this.statisticOff}
                           style={{background:this.state.showStatistic?'transparent':"mediumseagreen"}}>
                              <span style={{
                                padding: '7px 25px',
                                display: 'inline-block',
                                color:this.state.showStatistic?'crimson':'#fff',
                                fontWeight: '600'
                              }}>{allStrings.no}</span>
                           </div>
                           <div className="checkingRight" onClick={this.statisticOn}
                            style={{background:this.state.showStatistic?"mediumseagreen":'transparent'}}>
                               <span style={{
                                 color:this.state.showStatistic?'#fff':'crimson',
                                 padding:' 6px 15px',
                                 display: 'inline-block',
                                 fontWeight: '600'}}>{allStrings.yes}</span>
                           </div>
                         </div>
                       </MDBCol>
                       <MDBCol md="6">
                         <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                           {allStrings.statistic}
                         </label>
                       </MDBCol>
                     </MDBRow>
                   </MDBCol>
                  
                    <MDBCol md="6">
                     <MDBRow>
                       <MDBCol md="6">
                         <div className="checking">
                           <div className="checkingLeft" onClick={this.categoriesOff}
                           style={{background:this.state.showCategories==false?'mediumseagreen':"transparent"}}>
                              <span style={{
                                padding: '7px 25px',
                                display: 'inline-block',
                                color:this.state.showCategories?'crimson':'#fff',
                                fontWeight: '600'
                              }}>{allStrings.no}</span>
                           </div>
                           <div className="checkingRight" onClick={this.categoriesOn}
                            style={{background:this.state.showCategories==true?'mediumseagreen':"transparent"}}>
                               <span style={{
                                 color:this.state.showCategories?'#fff':'crimson',
                                 padding:' 6px 15px',
                                 display: 'inline-block',
                                 fontWeight: '600'}}>{allStrings.yes}</span>
                           </div>
                         </div>
                       </MDBCol>
                       <MDBCol md="6">
                         <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                           {allStrings.categories}
                         </label>
                       </MDBCol>
                     </MDBRow>
                   </MDBCol>
                 
                   </MDBRow>
                  }
                 
                  <MDBRow>
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.showOrdersOff}
                            style={{background:this.state.showOrders?'transparent':"mediumseagreen"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.showOrders?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.showOrdersOn}
                             style={{background:this.state.showOrders?"mediumseagreen":'transparent'}}>
                                <span style={{
                                  color:this.state.showOrders?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.orders}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.showSuppliersOff}
                            style={{background:this.state.showSuppliers==false?'mediumseagreen':"transparent"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.showSuppliers?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.showSuppliersOn}
                             style={{background:this.state.showSuppliers==true?'mediumseagreen':"transparent"}}>
                                <span style={{
                                  color:this.state.showSuppliers?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.suppliers}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.showPermissionsOff}
                            style={{background:this.state.showPermissions?'transparent':"mediumseagreen"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.showPermissions?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.showPermissionsOn}
                             style={{background:this.state.showPermissions?"mediumseagreen":'transparent'}}>
                                <span style={{
                                  color:this.state.showPermissions?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.usersAndPermission}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.showExpensesOff}
                            style={{background:this.state.showExpenses?'transparent':"mediumseagreen"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.showExpenses?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.showExpensesOn}
                             style={{background:this.state.showExpenses?"mediumseagreen":'transparent'}}>
                                <span style={{
                                  color:this.state.showExpenses?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.expenses}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  {this.state.companyBuyType == true &&
                  <MDBRow>
                    
                  <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.salesMenOff}
                            style={{background:this.state.salesMen==false?'mediumseagreen':"transparent"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.salesMen?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.salesMenOn}
                             style={{background:this.state.salesMen==true?'mediumseagreen':"transparent"}}>
                                <span style={{
                                  color:this.state.salesMen?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.salesMen}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.showRecieptsOff}
                            style={{background:this.state.showReciepts?'transparent':"mediumseagreen"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.showReciepts?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.showRecieptsOn}
                             style={{background:this.state.showReciepts?"mediumseagreen":'transparent'}}>
                                <span style={{
                                  color:this.state.showReciepts?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.receipts}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  }
                  <MDBRow>
                  {this.state.addBranch == true &&
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.branchOff}
                            style={{background:this.state.showBranch?'transparent':"mediumseagreen"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.showBranch?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.branchOn}
                             style={{background:this.state.showBranch?"mediumseagreen":'transparent'}}>
                                <span style={{
                                  color:this.state.showBranch?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.branches}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    }
                     {this.state.companyBuyType == true &&
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.showCollectiblesOff}
                            style={{background:this.state.showCollectibles?'transparent':"mediumseagreen"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.showCollectibles?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.showCollectiblesOn}
                             style={{background:this.state.showCollectibles?"mediumseagreen":'transparent'}}>
                                <span style={{
                                  color:this.state.showCollectibles?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.collectibles}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    }
                  </MDBRow>
                  {this.props.currentUser.user.type == "ADMIN" &&
                  <MDBRow>
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.showProblemsOff}
                            style={{background:this.state.showProblems?'transparent':"mediumseagreen"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.showProblems?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.showProblemsOn}
                             style={{background:this.state.showProblems?"mediumseagreen":'transparent'}}>
                                <span style={{
                                  color:this.state.showProblems?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.problemsAndSuggestions}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.showCitiesOff}
                            style={{background:this.state.showCities?'transparent':"mediumseagreen"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.showCities?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.showCitiesOn}
                             style={{background:this.state.showCities?"mediumseagreen":'transparent'}}>
                                <span style={{
                                  color:this.state.showCities?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.cities}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    
                  </MDBRow>
                  }
                  <br></br>
                  <MDBRow>
                    <MDBBtn type="submit" 
                    style={{ margin: '1rem',width:'150px',padding:'9px',height:'35px' }}
                        className="btn z-depth-1a "
                        color="primary"
                      >
                        {this.state.requestType=="post"?allStrings.add:allStrings.edit}
                    </MDBBtn>
                  </MDBRow>
                </MDBCol>
                <MDBCol md="6" style={{borderLeft:'1px solid'}}>
                  <MDBRow>
                    <MDBCol md="12">
                      <label  htmlFor="defaultFormRegisterNameEx2"
                      style={{
                        float:'right',
                        fontWeight: '800',
                        color: '#43425D',
                        fontSize: '18px',
                        marginBottom: '2rem',
                        marginRight:'4rem'
                      }}>
                      {allStrings.user}
                      </label>
                    </MDBCol>
                  </MDBRow>
    
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                      <input name="fullname" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx5" required
                        className="form-control" placeholder={allStrings.enterName} 
                        style={{direction:this.props.isRTL?'rtl':'ltr',marginBottom: '10px'}} defaultValue={this.state.fullname} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx5" className="grey-text formLabel">
                      {allStrings.name}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                      <input name="password" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx5" 
                        className="form-control" placeholder={allStrings.enterPassword} 
                        style={{direction:this.props.isRTL?'rtl':'ltr',marginBottom: '10px'}} defaultValue={this.state.password} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx5" className="grey-text formLabel">
                      {allStrings.password}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                    {this.state.profileImg == null&&
                      <div className="smallUploadContainer">
                        <img alt=""  name="img" className="smallUploderImg" src={require('../../assets/images/Path 343.png')}></img>
                        <input 
                          onChange={this.fileHandlerProfileImg}
                          type="file"
                          id="defaultFormRegisterNameEx6"
                          required className="smallUploader" />
                        <MDBBtn color="primary" size="sm" className="smallUploaderBtn">{allStrings.chooseFile}</MDBBtn>
                        <p style={{fontSize: '14px',marginTop: '3px 15px',float:'right',paddingTop: '4px'}}  className="grey-text">
                          {allStrings.dropHere}
                        </p>
                      </div>
                    }
                      <div className="previewRaduis">
                      {this.state.profileImg!= null&&
                      <MDBRow>
                        
                        <MDBCol md = "3">
                        <div style={{paddingTop:'5px',cursor:'pointer'}}  onClick={() => this.setState({ isOpen2: true })}>
                          <span style={{
                            fontSize: '17px',
                            color: '#478DFF',
                            fontWeight: '600',
                            margin: '5px'
                          }}>{allStrings.showD}</span>
                          <img alt=""  className="showImge" width="20px" src={require('../../assets/images/icons8_external_link_480px_2.png')}></img>
                        </div>
                        </MDBCol>
                        <MDBCol md="9" style={{paddingRight:'30px'}}  >
                          {this.state.profileImg!= null&&
                            <div style={{display:"inline-block",float:'right',marginBottom:'1rem'}}>
                                <img alt=""    src={this.state.profileImgType=="data"?URL.createObjectURL(this.state.profileImg):this.state.profileImg} />
                                <MDBIcon icon="times" className="mr-2 removeImg" onClick={()=> this.removeProfileImg()}></MDBIcon>
                            </div>
                          }
                        </MDBCol>
                      </MDBRow>
                      }
                      {isOpen2 && (
                      <Lightbox
                        mainSrc={profileImg[photoIndex]}
                        nextSrc={profileImg[(photoIndex + 1) % profileImg.length]}
                        prevSrc={profileImg[(photoIndex + profileImg.length - 1) % profileImg.length]}
                        onCloseRequest={() => this.setState({ isOpen2: false,photoIndex:0  })}
                        onMovePrevRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + profileImg.length - 1) % profileImg.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + 1) % profileImg.length,
                          })
                        }
                      />
                    )}
                    </div>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx7" className="grey-text formLabel">
                      {allStrings.profileImg}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                    {this.state.cardImg == null&&
                      <div className="smallUploadContainer">
                        <img alt=""  name="img" className="smallUploderImg" src={require('../../assets/images/Path 343.png')}></img>
                        <input 
                          multiple
                          onChange={this.fileHandlerCardImg}
                          type="file"
                          id="defaultFormRegisterNameE03"
                           className="smallUploader" />
                        <MDBBtn color="primary" size="sm" className="smallUploaderBtn">{allStrings.chooseFile}</MDBBtn>
                        <p style={{fontSize: '14px',marginTop: '3px 15px',float:'right',paddingTop: '4px'}}  className="grey-text">
                          {allStrings.dropHere}
                        </p>
                      </div>
                    }
                     
                    {this.state.cardImg!= null&&
                      <div className="previewRaduis">
                      <MDBRow>
                        <MDBCol md = "3">
                        <div style={{paddingTop:'5px',cursor:'pointer'}}  onClick={() => this.setState({ isOpen1: true })}>
                          <span style={{
                            fontSize: '17px',
                            color: '#478DFF',
                            fontWeight: '600',
                            margin: '5px'
                          }}>{allStrings.showD}</span>
                          <img alt=""  className="showImge" width="20px" src={require('../../assets/images/icons8_external_link_480px_2.png')}></img>
                        </div>
                        </MDBCol>
                        <MDBCol md="9" style={{paddingRight:'30px'}}  >
                         {this.state.cardImgViews.map((val) => (
                          <div style={{display:"inline-block",float:'right',marginBottom:'1rem'}}>
                              <img alt=""    src={this.state.cardImgType=="data"?val:val} />
                              <MDBIcon icon="times" className="mr-2 removeImg" onClick={()=> this.removeCardImg(val)}></MDBIcon>
                          </div>
                          ))}
                        </MDBCol>
                      </MDBRow>
                    {isOpen1 && (
                      <Lightbox
                        mainSrc={cardImg[photoIndex]}
                        nextSrc={cardImg[(photoIndex + 1) % cardImg.length]}
                        prevSrc={cardImg[(photoIndex + cardImg.length - 1) % cardImg.length]}
                        onCloseRequest={() => this.setState({ isOpen1: false,photoIndex:0  })}
                        onMovePrevRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + cardImg.length - 1) % cardImg.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + 1) % cardImg.length,
                          })
                        }
                      />
                    )}
                    </div>
                    }

                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx003" className="grey-text formLabel">
                      {allStrings.cardImg}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="cardNum" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx12" 
                        className="form-control" placeholder={allStrings.enterCardNum} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.cardNum} required/>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                      {allStrings.cardNum}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="address" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx13" 
                        className="form-control" placeholder={allStrings.enterAddress} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.address} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx13" className="grey-text formLabel">
                      {allStrings.address}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <TreeSelect
                        value= {this.state.city}
                        showSearch={false} 
                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left'}} 
                        treeCheckable = {false}         
                        treeNodeFilterProp="title"                      
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={allStrings.chooseCity}
                        onChange={(value) => {
                          console.log(value)
                          this.setState({city:value});
                        }} 
                      
                      >
                      {/*<TreeNode style={{background:"antiquewhite",color:'#fff !important'}} value="newUser" title={allStrings.newUser} key={allStrings.newUser} />*/}
                      {this.state.cities.map(val=>(
                          <TreeNode value={val.id} title={this.props.isRTL?val.cityName_ar:val.cityName_en} key={val.id} />
                      ))}
                      </TreeSelect>
                      
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx14" className="grey-text formLabel">
                      {allStrings.city}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="phone" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx15" 
                        className="form-control" placeholder={allStrings.enterPhone} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.phone} required />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx15" className="grey-text formLabel">
                      {allStrings.phone}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="anotherPhone" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx16" 
                        className="form-control" placeholder={allStrings.EnterAnotherPhone} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.anotherPhone} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx16" className="grey-text formLabel">
                      {allStrings.anotherPhone}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <textarea name="notes" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx17" 
                        className="form-control" placeholder={allStrings.enterNote} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.notes} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx17" className="grey-text formLabel">
                      {allStrings.note}
                      </label>
                    </MDBCol>
                  </MDBRow>
                 
                </MDBCol>
              
              </MDBRow>
            </MDBContainer>
          </MDBRow>
          
          </form>
        </MDBContainer>
      </div>
    );
  }
}


const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
}
  
  export default  withRouter(
    connect(mapToStateProps,mapDispatchToProps)(UserForm)
  );
  
