/* eslint-disable eqeqeq */
import React from 'react';
import './client.css'
import { MDBContainer, MDBRow, MDBBtn, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import { ChangeLanguage } from '../../redux/actions/LanguageAction'
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { BASE_END_POINT } from '../../urls'
import axios from 'axios'
import { setUser } from '../../redux/actions/AuthActions'
import { Popconfirm, message, Select, Input, ConfigProvider, DatePicker } from 'antd';
import Pagination from '@material-ui/lab/Pagination';
import LoadCard from '../../component/load card/load card'
import { hasValue } from "../../validations/validations";
import { SearchOutlined } from '@ant-design/icons';
import { Redirect, NavLink } from 'react-router-dom'
import ReactToPrint from 'react-to-print';
import { ChangeCompany, ChangeBranch } from '../../redux/actions/CompanyAction'
import moment from 'moment'
import locale_ar from 'antd/lib/locale/ar_EG';
import locale_en from 'antd/lib/locale/en_GB';
import 'moment/locale/ar';
const { Option } = Select;
//const {TreeNode} = TreeSelect
let printFromDate, printToDate
class ComponentToPrint extends React.Component {

  render() {

    console.log(this.props)
    return (
      <div>
        {this.props.printType === "TYPE1" ?
          <form class="col s12">
            <div className='tabelDev'>
              <div class="row">
                <table class="table table-borderless">
                  <thead class="thead-light">
                    <tr>
                      <th>الاسم</th>
                      <th>الهاتف</th>
                      <th>هاتف اخر</th>
                      <th>رقم البطاقه</th>
                      <th>عدد الطلبات</th>

                    </tr>
                    <tr>
                      <th>Items</th>
                      <th>Phone</th>
                      <th>Phone2</th>
                      <th>Card Num</th>
                      <th>Orders Count</th>

                    </tr>
                  </thead>

                  <tbody>
                    {this.props.data.map(client => (
                      <tr>
                        <td>{client.user.fullname ? client.user.fullname : ''}</td>
                        <td>{client.user.phone ? client.user.phone : ''}</td>
                        <td>{client.user.anotherPhone ? client.user.anotherPhone : ''}</td>
                        <td>{client.user.cardNum ? client.user.cardNum : ''}</td>
                        <td>{client.ordersCount ? client.ordersCount : 0}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </form>
          :
          <MDBContainer>
            <br></br>
            <br></br>
            <MDBRow style={{ textAlign: 'center' }}>
              <MDBCol size="4" style={{ textAlign: 'center' }}>
                <img alt="" style={{ width: '70px', height: '70px', borderRadius: '50%' }} src={require('../../assets/images/icons8_pdf.png')}></img>
              </MDBCol>
              <MDBCol size="4">
                <p style={{ fontWeight: '500', fontSize: '24px' }}>

                  <span>طباعه العملاء  </span>
                  {this.props.reportType === "DURATION" &&
                    <span>
                      <span> من </span>
                      <span style={{ color: 'red', textDecoration: 'underline' }}>{this.props.printFromDate}</span>
                      <span> الى </span>
                      <span style={{ color: 'red', textDecoration: 'underline' }}>{this.props.printToDate}</span>
                    </span>
                  }
                </p>
              </MDBCol>
              <MDBCol size="4"></MDBCol>
            </MDBRow>
            <br></br>
            <MDBRow style={{ textAlign: 'right' }}>
              <MDBCol size="4">
                <p style={{ fontWeight: '500' }}>
                  <span> عدد الصفح من  / </span>
                  <span style={{ color: 'red', textDecoration: 'underline' }}>1</span>
                  <span> الى </span>
                  <span style={{ color: 'red', textDecoration: 'underline' }}>{Math.ceil(this.props.instalmentOrdersCount / 40) + 1}</span>
                </p>
              </MDBCol>
              <MDBCol size="4">
                <p style={{ fontWeight: '500' }}>
                  <span > عدد العملاء / </span>
                  <span style={{ color: 'red', textDecoration: 'underline' }}>{this.props.instalmentClientsCount}</span>
                </p>
              </MDBCol>
              <MDBCol size="4">
                <p style={{ fontWeight: '500' }}>
                  <span style={{ float: 'right' }}> / اسم الشركه </span>
                  <span style={{ color: 'red', textDecoration: 'underline' }}>{this.props.theCompany.fullname ? this.props.theCompany.fullname : ""}</span>
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow style={{ textAlign: 'right' }}>
              <MDBCol size="4">
                <p style={{ fontWeight: '500' }}>
                  <span> تاريخ الطباعه / </span>
                  <span style={{ color: 'red', textDecoration: 'underline' }}>{moment().format('YYYY/MM/DD')} </span>
                </p>
              </MDBCol>
              <MDBCol size="4">
                <p style={{ fontWeight: '500' }}>
                  <span> اجمالى المتبقى / </span>
                  <span style={{ color: 'red', textDecoration: 'underline' }}>{this.props.totalRemain}</span>
                </p>
              </MDBCol>
              <MDBCol size="4">
                <p style={{ fontWeight: '500' }}>
                  <span> اجمالى المدفوعات / </span>
                  <span style={{ color: 'red', textDecoration: 'underline' }}>{this.props.totalPaid}</span>
                </p>
              </MDBCol>

            </MDBRow>
            <MDBRow>
              <MDBCol md="12" sm="12">
                <div className='tabelDev'>
                  <div class="row">
                    <table class="table table-bordered table-sm">
                      <thead>
                        <tr class="thead-light" >
                          <th>تاريخ الشراء</th>
                          <th>مندوب البيع</th>
                          <th>القسط رقم </th>
                          <th>عدد الاقساط</th>
                          <th>المتبقى </th>
                          <th>المدفوع</th>
                          <th>المقدم </th>
                          <th>سعر الشراء</th>
                          <th>نوع المنتج </th>
                          <th>رقم التليفون </th>
                          <th>مكان العمل </th>
                          <th>اسم العميل </th>
                          <th>رقم </th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.props.data.map(val => (
                          <tr>

                            <td style={{ padding: '8px' }}>{moment(val.startDate).format('YYYY/MM/DD')}</td>
                            <th scope="row">

                              {val.multiBuySalesMen.length > 0 ?
                                val.multiBuySalesMen.map((v, i) => (

                                  <p style={{ textAlign: 'right' }}>{v.buySalesMan ? v.buySalesMan.fullname : ""}</p>
                                ))
                                :
                                <p style={{ textAlign: 'right' }}>{val.buySalesMan ? val.buySalesMan.fullname : ""}</p>
                              }

                            </th>
                            <th scope="row">{val.monthCount ? val.monthCount > val.paidMonth ? val.paidMonth + 1 : val.paidMonth : ""}</th>
                            <th scope="row">{val.premuimsDesc ? val.premuimsDesc : ""}</th>
                            <th scope="row">{val.costRemaining ? val.costRemaining : 0}</th>
                            <th scope="row">{val.costPaid ? val.costPaid : 0}</th>
                            <th scope="row">{val.firstPaid ? val.firstPaid : 0}</th>
                            <th scope="row">{val.total ? val.total : 0}</th>
                            <td style={{ padding: '8px' }}>
                              {val.multiProducts.length > 0 && val.processType == "MULTI" &&
                                val.multiProducts.map((v, i) => (
                                  <p style={{ textAlign: 'right' }}>{v.product ? this.props.isRTL ? v.product.name_en : v.product.name_ar : ""}</p>
                                ))
                              }
                              {val.processType != "MULTI" &&
                                <p style={{ textAlign: 'right' }}>{val.product ? this.props.isRTL ? val.product.name_en : val.product.name_ar : ""}</p>
                              }

                            </td>
                            <th scope="row">{val.client ? val.client.phone : ""}</th>
                            <th scope="row">{val.client ? val.client.jobArea ? val.client.jobArea : "" : ""}</th>
                            <th scope="row">{val.client ? val.client.fullname ? val.client.fullname : "" : ""}</th>
                            <th scope="row">
                              {val.client.clientCompanyIds.find(e => e.company === val.company.id) ? val.client.clientCompanyIds.find(e => e.company === val.company.id).value : ""}
                            </th>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>

          </MDBContainer>
        }
      </div>
    );
  }
}
class Clients extends React.Component {

  state = {
    Clients: [],
    loading: true,
    refresh: false,
    page: 1,
    printFrom: this.props.isRTL ? moment().startOf('month').format("YYYY/MM/DD") : moment().startOf('month').locale('en').format("YYYY/MM/DD"),
    printTo: this.props.isRTL ? moment().endOf('month').format("YYYY/MM/DD") : moment().endOf('month').locale('en').format("YYYY/MM/DD"),
    print: false,
    pages: 1,
    selectedClient: null,
    salesMen: [],
    filterType: null,
    value: '',
    clientsCount: '',
    ordersCount: '',
    clientAdd: [],
    orders: [],
    instalmentClientsCount: 0,
    instalmentOrdersCount: 0,
    totalRemain: 0,
    totalPaid: 0,
    theCompany: [],
    printType: 'TYPE1',
    modal: false,
    enablePrint: false,
    reportType: 'ALL',
    company: null
  }
  constructor(props) {
    super(props)
    console.log("current user   ", this.props.currentUser.user.type)
    this.getClients(1, false)
    this.getCompanyData()
    //this.getClientWithoutPagenation()
    //this.getOrdersWithoutPagenation()
    this.getDuration()
    // this.getClientAdditionWithoutPagenation()
    //this.getInstalmentOrdersWithoutPagenation()
    if (this.props.isRTL) {
      allStrings.setLanguage('ar')
    } else {
      allStrings.setLanguage('en')
    }
    window.scrollTo(0, 0)
  }
  getDuration = () => {
    //first day
    let date = new Date()
    let newDate = date.toISOString().slice(0, 8)
    let firstDay = newDate + '01';
    //get days in month
    function daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }

    let DaysInCurrentMonth = daysInMonth(date.getMonth() + 1, date.getFullYear()); //number of day in month
    //last day
    let lastDay = newDate + DaysInCurrentMonth;
    console.log(firstDay)
    console.log(lastDay)
    if (this.props.isRTL) {
      this.setState({ printFromDate: moment(firstDay).format("YYYY/MM/DD"), to: moment(lastDay).format("YYYY/MM/DD") })
    } else {
      this.setState({ printFromDate: moment(firstDay).locale('en').format("YYYY/MM/DD"), printToDate: moment(lastDay).locale('en').format("YYYY/MM/DD") })


    }

    printFromDate = firstDay
    printToDate = lastDay

  }
  getClientAdditionWithoutPagenation = () => {
    let url = ``;
    if (this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN") {
      if (this.props.company === false) {
        url = `${BASE_END_POINT}withoutPagenation/additionGet?type=CLIENT`
      } else {
        url = `${BASE_END_POINT}withoutPagenation/additionGet?type=CLIENT&company=${this.props.company}`
      }
    } else {
      url = `${BASE_END_POINT}withoutPagenation/additionGet?type=CLIENT&company=${this.props.currentUser.user.id}`
    }
    axios.get(url)
      .then(response => {
        console.log(response.data.users)
        this.setState({ enablePrint: true, clientAdd: response.data.users })
      })
      .catch(error => {
        console.log("ALL salesMen ERROR")
        console.log(error.response)
      })
  }
  getClientWithoutPagenation = () => {
    let url = ``;
    if (this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN") {
      if (this.props.company === false) {
        url = `${BASE_END_POINT}withoutPagenation/get?type=CLIENT`
      } else {
        url = `${BASE_END_POINT}withoutPagenation/get?type=CLIENT&company=${this.props.company}`
      }
    } else {
      url = `${BASE_END_POINT}withoutPagenation/get?type=CLIENT&company=${this.props.currentUser.user.id}`
    }
    axios.get(url)
      .then(response => {
        console.log(response.data.users)
        this.setState({ enablePrint: true, clients: response.data.users })
      })
      .catch(error => {
        console.log("ALL salesMen ERROR")
        console.log(error.response)
      })
  }
  getOrdersWithoutPagenation = () => {
    let url = ``;
    if (this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN") {

      if (this.props.company === false) {
        url = `${BASE_END_POINT}orders/withoutPagenation/get?status=DELIVERED`
      } else {
        url = `${BASE_END_POINT}orders/withoutPagenation/get?status=DELIVERED&company=${this.props.company}`
      }
    } else {
      url = `${BASE_END_POINT}orders/withoutPagenation/get?status=DELIVERED&company=${this.props.currentUser.user.id}`
    }
    axios.get(url)
      .then(response => {
        console.log(response.data.users)
        this.setState({ ordersCount: response.data.ordersCount })
      })
      .catch(error => {
        console.log("ALL salesMen ERROR")
        console.log(error.response)
      })
  }
  getInstalmentOrdersWithoutPagenation = () => {
    let url = ``;
    if (this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN") {
      if (this.props.company === false) {
        url = `${BASE_END_POINT}orders/withoutPagenation/get?status=DELIVERED&paymentSystem=installment`
        if (this.state.reportType === "DURATION") {
          url = `${BASE_END_POINT}orders/withoutPagenation/get?status=DELIVERED&paymentSystem=installment&start=${printFromDate}&end=${printToDate}`
        }

      } else {
        url = `${BASE_END_POINT}orders/withoutPagenation/get?status=DELIVERED&company=${this.props.company}&paymentSystem=installment`
        if (this.state.reportType === "DURATION") {
          url = `${BASE_END_POINT}orders/withoutPagenation/get?status=DELIVERED&company=${this.props.company}&paymentSystem=installment&start=${printFromDate}&end=${printToDate}`
        }
      }
    } else {
      url = `${BASE_END_POINT}orders/withoutPagenation/get?status=DELIVERED&company=${this.props.currentUser.user.id}&paymentSystem=installment`
      if (this.state.reportType === "DURATION") {
        url = `${BASE_END_POINT}orders/withoutPagenation/get?status=DELIVERED&company=${this.props.currentUser.user.id}&paymentSystem=installment&start=${printFromDate}&end=${printToDate}`
      }
    }
    axios.get(url)
      .then(response => {
        console.log("orders", response.data)
        this.setState({ enablePrint: true, orders: response.data.orders, instalmentOrdersCount: response.data.ordersCount, instalmentClientsCount: response.data.clientsCount, totalRemain: response.data.totalRemain, totalPaid: response.data.totalPaid })
      })
      .catch(error => {
        console.log("ALL orders ERROR")
        console.log(error.response)
      })
  }
  getSalesMen = () => {
    let url = ``;
    if (this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN") {
      if (this.props.company === false) {
        url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN`
      } else {
        url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&company=${this.props.company}`
      }
    } else {
      url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&company=${this.props.currentUser.user.id}`
    }
    axios.get(url)
      .then(response => {
        console.log(response.data.users)
        this.setState({ salesMen: response.data.users })
      })
      .catch(error => {
        console.log("ALL salesMen ERROR")
        console.log(error.response)
      })
  }
  componentDidMount() {


    let company = this.props.currentUser.user.id
    if (this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN") {
      if (this.props.company == false) {
        company = this.props.currentUser.user.company[0].id
      } else {
        company = this.props.company
      }
    } else {
      company = this.props.currentUser.user.id
    }
    this.setState({ company: company })
  }
  getCompanyData = () => {
    let url = ``;
    if (this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN") {
      if (this.props.company !== false) {

        url = `${BASE_END_POINT}${this.props.company}/findById`
      }
    } else {
      url = `${BASE_END_POINT}${this.props.currentUser.user.id}/findById`
    }
    console.log("url", url)
    axios.get(url, {
      headers: {
        'Authorization': `Bearer ${this.props.currentUser.token}`
      }
    })
      .then(response => {
        console.log(response.data)
        this.setState({ theCompany: response.data })
      })
      .catch(error => {
        console.log("ALL salesMen ERROR")
        console.log(error.response)
      })
  }

  getClients = (page, refresh, filterType, value) => {
    this.setState({ loading: true })
    let url = ``;

    console.log(filterType)
    console.log(value)
    if (filterType != null && value !== '') {
      if (filterType === "cardNum") {
        if (this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN") {
          if (this.props.company === false) {
            url = `${BASE_END_POINT}find?type=CLIENT&page=${page}&cardNum=${value}`
          } else {
            url = `${BASE_END_POINT}find?type=CLIENT&page=${page}&cardNum=${value}&company=${this.props.company}`
          }
        } else {
          url = `${BASE_END_POINT}find?type=CLIENT&page=${page}&company=${this.props.currentUser.user.id}&cardNum=${value}`
        }
      }
      if (filterType === "id") {
        if (this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN") {
          if (this.props.company === false) {
            url = `${BASE_END_POINT}find?type=CLIENT&page=${page}&id=${value}`
          } else {
            url = `${BASE_END_POINT}find?type=CLIENT&page=${page}&clientCompanyId=${value}&company=${this.props.company}`
          }
        } else {
          url = `${BASE_END_POINT}find?type=CLIENT&page=${page}&company=${this.props.currentUser.user.id}&clientCompanyId=${value}`
        }
      }
      if (filterType === "name") {
        if (this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN") {
          if (this.props.company === false) {
            url = `${BASE_END_POINT}find?type=CLIENT&page=${page}&name=${value}`
          } else {
            url = `${BASE_END_POINT}find?type=CLIENT&page=${page}&name=${value}&company=${this.props.company}`
          }
        } else {
          url = `${BASE_END_POINT}find?type=CLIENT&page=${page}&company=${this.props.currentUser.user.id}&name=${value}`
        }
      }
      if (filterType === "salesMan") {
        if (value !== "all") {
          if (this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN") {

            if (this.props.company === false) {
              url = `${BASE_END_POINT}find?type=CLIENT&page=${page}&salesMan=${value}`
            } else {
              url = `${BASE_END_POINT}find?type=CLIENT&page=${page}&salesMan=${value}&company=${this.props.company}`
            }
          } else {
            url = `${BASE_END_POINT}find?type=CLIENT&page=${page}&company=${this.props.currentUser.user.id}&salesMan=${value}`
          }
        } else {
          if (this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN") {

            if (this.props.company === false) {
              url = `${BASE_END_POINT}find?type=CLIENT&page=${page}`
            } else {
              url = `${BASE_END_POINT}find?type=CLIENT&page=${page}&company=${this.props.company}`
            }
          } else {
            url = `${BASE_END_POINT}find?type=CLIENT&page=${page}&company=${this.props.currentUser.user.id}`
          }
        }

      }
    } else {
      if (this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN") {
        if (this.props.company === false) {
          url = `${BASE_END_POINT}find?type=CLIENT&page=${page}`
        } else {
          url = `${BASE_END_POINT}find?type=CLIENT&page=${page}&company=${this.props.company}`
        }
      } else {
        url = `${BASE_END_POINT}find?type=CLIENT&page=${page}&company=${this.props.currentUser.user.id}`
      }
    }
    console.log("url", url)
    axios.get(url, {
      headers: {
        'Authorization': `Bearer ${this.props.currentUser.token}`
      }
    })
      .then(response => {
        console.log("Clients   ", response.data)
        this.setState({
          loading: false,
          refresh: false,
          Clients: response.data.data,
          clientsCount: response.data.totalCount,
          page: response.data.page,
          pages: response.data.pageCount,
        })
        if (!this.state.print) {
          this.state.print = true



        }

      })
      .catch(error => {
        console.log("error   ", error.response)
        console.log("error2   ", error)
        this.setState({ loading: false, })
      })
    this.getOrdersWithoutPagenation()

  }
  selectClientId = (e) => {
    e.preventDefault();
    let id = e.target.id.substring(6)
    console.log(id)
    this.setState({ selectedClient: id })
  }
  deleteClient = (id) => {
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}${id}/delete`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`
      },
    })
      .then(response => {
        console.log("Client DELETED")
        l.then(() => message.success(allStrings.done, 2))
        this.getClients(this.state.page)
      })
      .catch(error => {
        console.log(error.response)
        l.then(() => message.error('Error', 2.5))
      })
  }
  OKBUTTON = (e) => {
    this.deleteClient(this.state.selectedClient)
  }

  getDataHandel = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.setState({ enablePrint: false })
    if (this.state.printType === "TYPE1") {
      this.getClientAdditionWithoutPagenation()
    } else {
      this.getInstalmentOrdersWithoutPagenation()
    }
  }

  onChangePrintFrom = (date, dateString) => {
    console.log(dateString)
    if (this.props.isRTL) {
      this.setState({ printFrom: moment(dateString).format("YYYY/MM/DD") })
    } else {
      this.setState({ printFrom: moment(dateString).locale('en').format("YYYY/MM/DD") })
    }
    printFromDate = moment(dateString).locale('en').format("YYYY-MM-DD")
  }
  onChangePrintTo = (date, dateString) => {
    if (this.props.isRTL) {
      this.setState({ printTo: moment(dateString).format("YYYY/MM/DD") })
    } else {
      this.setState({ printTo: moment(dateString).locale('en').format("YYYY/MM/DD") })

    }
    printToDate = moment(dateString).locale('en').format("YYYY-MM-DD")



  }
  toggle = () => {
    this.getClientWithoutPagenation()
    this.getClientAdditionWithoutPagenation()
    this.getInstalmentOrdersWithoutPagenation()
    this.getSalesMen()
    this.setState({
      modal: !this.state.modal,
    });
  }
  modal = () => (
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
      <MDBModalHeader toggle={this.toggle}>{allStrings.print}</MDBModalHeader>
      <MDBModalBody>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
              <form style={{ marginLeft: '15%', marginRight: '15%' }}
                className="needs-validation"
                onSubmit={this.getDataHandel}
                noValidate
              >
                <MDBContainer>
                  {/*<MDBRow>
                  <TreeSelect 
                    style={{ width: '100%',float:'right',height:'40px'  }} 
                    placeholder={allStrings.printType}
                    value = {this.state.printType}
                    onChange={(value) => {
                      this.setState({printType:value})
                    }} 
                  >
                  
                    <TreeNode value="TYPE1" title={allStrings.type1}></TreeNode>
                    <TreeNode value="TYPE2"title={allStrings.type2}></TreeNode>
                    
                  </TreeSelect>
                </MDBRow>
                <br></br>
                {this.state.printType ==="TYPE2"&&
                */}
                  <MDBRow>
                    <div style={{ margin: '3rem auto ', color: 'white', background: '#4F73AC', width: '30%', padding: '11px 20px', height: '35px', }}
                      className="btn z-depth-1a "
                      color="primary"
                      onClick={() => {
                        this.setState({ printType: "TYPE1", enablePrint: false })
                      }}
                    >
                      {allStrings.samplePrint}
                    </div>
                    <div style={{ margin: '3rem auto ', color: 'white', background: '#4F73AC', width: '30%', padding: '11px 20px', height: '35px', }}
                      className="btn z-depth-1a "
                      color="primary"
                      onClick={() => {
                        this.setState({ printType: "TYPE2", reportType: "DURATION", enablePrint: false })
                      }}
                    >
                      {allStrings.specialDuration}
                    </div>
                    <div style={{ margin: '3rem auto ', color: 'white', background: '#4F73AC', width: '30%', padding: '11px 20px', height: '35px', }}
                      className="btn z-depth-1a "
                      color="primary"
                      onClick={() => {
                        this.setState({ printType: "TYPE2", reportType: "ALL", enablePrint: false })
                      }}
                    >
                      {allStrings.all}
                    </div>
                    {/*
                  <TreeSelect 
                    style={{ width: '100%',float:'right',height:'40px'  }} 
                    placeholder={allStrings.type}
                    value = {this.state.reportType}
                    onChange={(value) => {
                      this.setState({reportType:value,enablePrint:false})
                    }} 
                  >
                  
                    <TreeNode value="DURATION" title={allStrings.duration}></TreeNode>
                    <TreeNode value="ALL"title={allStrings.all}></TreeNode>
                    
                  </TreeSelect>*/}
                  </MDBRow>
                  {/*}*/}
                  {this.state.reportType === "DURATION" && this.state.printType === "TYPE2" &&
                    <MDBRow>
                      <MDBCol xl="1" lg="1" md="12" sm="12"></MDBCol>
                      <MDBCol xl="8" lg="8" md="12" sm="12">
                        <div style={{ paddingtop: '15px' }}>
                          <div style={{ marginBottom: '10px', textAlign: 'center' }}>
                            <span className="fromSpan" style={{ float: this.props.isRTL ? 'right' : 'left', marginRight: '8%' }}>{allStrings.from}</span>
                            <div style={{ display: 'inline-block', textAlign: 'center', marginLeft: this.props.isRTL ? '55px' : "-255px" }}>
                              <ConfigProvider locale={this.props.isRTL ? locale_ar : locale_en}>
                                <DatePicker defaultValue={moment(this.state.printFrom, 'YYYY/MM/DD')} onChange={this.onChangePrintFrom} />
                              </ConfigProvider>
                              <span className="newSpan" style={{ left: '110px' }}><img alt="" style={{ marginRight: '4px' }} src={require('../../assets/images/icons8_edit_property.png')}></img>{this.state.printFrom}</span>
                            </div>
                          </div>
                          <div style={{ marginBottom: '10px', textAlign: 'center' }}>
                            <span className="fromSpan" style={{ float: this.props.isRTL ? 'right' : 'left', marginRight: '8%' }}>{allStrings.to}</span>
                            <div style={{ display: 'inline-block', textAlign: 'center', marginLeft: this.props.isRTL ? '55px' : "-255px" }}>
                              <ConfigProvider locale={this.props.isRTL ? locale_ar : locale_en}>
                                <DatePicker defaultValue={moment(this.state.printTo, 'YYYY/MM/DD')} onChange={this.onChangePrintTo} />
                              </ConfigProvider>
                              <span className="newSpan" style={{ left: '110px' }}><img alt="" style={{ marginRight: '4px' }} src={require('../../assets/images/icons8_edit_property.png')}></img>{this.state.printTo}</span>
                            </div>
                          </div>
                        </div>
                      </MDBCol>
                      <MDBCol xl="1" lg="1" md="12" sm="12"></MDBCol>
                    </MDBRow>
                  }

                  <MDBRow>
                    {this.state.enablePrint === false ?
                      <MDBBtn type="submit" style={{ margin: '3rem auto ', width: '100%', padding: '11px 20px', height: '35px', }}
                        className="btn z-depth-1a "
                        color="primary"
                      >
                        {allStrings.readyToPrint}
                      </MDBBtn>
                      :
                      <MDBCol md="12" sm="12">
                        <ReactToPrint
                          documentTitle={"تقرير العملاء بتاريخ " + moment().format("YYYY/MM/DD")}
                          trigger={() => {
                            return (
                              <div style={{ textAlign: 'center', margin: '3rem 10rem' }}>
                                <img alt="" style={{ cursor: 'pointer' }} src={require('../../assets/images/icons8_pdf.png')}></img>
                                <span
                                  style={{
                                    color: '#FF3B40',
                                    fontWeight: '600',
                                    padding: '10px',
                                    fontSize: '20px',
                                    position: 'absolute',
                                    cursor: 'pointer'
                                  }}>{allStrings.print}</span>
                              </div>
                            );
                          }}
                          content={() => this.componentRef}
                        />
                        <div style={{ display: 'none' }}>
                          <ComponentToPrint
                            printType={this.state.printType}
                            reportType={this.state.reportType}
                            printFromDate={printFromDate}
                            printToDate={printToDate}
                            data={this.state.printType === "TYPE1" ? this.state.clientAdd : this.state.orders}
                            theCompany={this.state.theCompany}
                            totalRemain={this.state.totalRemain}
                            totalPaid={this.state.totalPaid}
                            instalmentOrdersCount={this.state.instalmentOrdersCount}
                            instalmentClientsCount={this.state.instalmentClientsCount}
                            ref={el => (this.componentRef = el)} />
                        </div>
                      </MDBCol>
                    }

                  </MDBRow>
                </MDBContainer>
              </form>
            </MDBCol>

          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
  );

  render() {
    console.log(this.state.filterType)
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.pagesPermission.includes('CLIENTS')) return <Redirect to='/Home' />

    return (
      <div className="Category">
        <div className="categoryTop" style={{ marginBottom: '3rem' }}>
          <MDBContainer>
            {this.modal()}
            <MDBRow>
              <MDBCol xl="3" lg="3" md="3" sm="12">
                <div onClick={this.toggle}>
                  <img alt="" style={{ cursor: 'pointer' }} src={require('../../assets/images/icons8_pdf.png')}></img>
                  <span
                    style={{
                      color: '#FF3B40',
                      fontWeight: '600',
                      padding: '10px',
                      fontSize: '20px',
                      position: 'absolute',
                      cursor: 'pointer'
                    }}>{allStrings.print}</span>
                </div>
              </MDBCol>
              <MDBCol xl="3" lg="3" md="3" sm="12" style={{ paddingTop: '1rem' }}>
                <img alt="" width='40px' src={require('../../assets/images/icons8_client_management.png')}></img>
                <span
                  style={{
                    color: 'mediumblue',
                    fontWeight: '600',
                    fontSize: '22px',
                    position: 'absolute',
                    padding: '5px 10px'
                  }}>{this.state.clientsCount}</span>
                <p style={{
                  color: "mediumblue",
                  fontSize: "14px",
                  marginTop: "6px",
                  marginBottom: 0,
                  fontWeight: "600"
                }}>{allStrings.clientsCount}</p>
              </MDBCol>
              <MDBCol xl="3" lg="3" md="3" sm="12" style={{ paddingTop: '1rem' }}>
                <img alt="" width='40px' src={require('../../assets/images/icons8_product.png')}></img>
                <span
                  style={{
                    color: 'mediumblue',
                    fontWeight: '600',
                    fontSize: '22px',
                    position: 'absolute',
                    padding: '5px 10px'
                  }}>{this.state.ordersCount}</span>
                <p style={{
                  color: "mediumblue",
                  fontSize: "14px",
                  marginTop: "6px",
                  marginBottom: 0,
                  fontWeight: "600"
                }}>{allStrings.ordersCount}</p>
              </MDBCol>
              <MDBCol xl="3" lg="3" md="3" sm="12">
                <NavLink to="clientForm">
                  <div className="addButton">
                    <MDBIcon icon="plus" />
                  </div>
                  <span
                    style={{
                      float: 'right',
                      color: '#43425D',
                      fontWeight: '600',
                      padding: '11px 2px',
                      fontSize: '20px',
                      cursor: 'pointer',
                    }}>{allStrings.addClient}</span>
                </NavLink>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <div onClick={() => this.getClients(1, false)} style={{ width: '100%', textAlign: 'end', paddingRight: '18px' }}>
                <span style={{ color: 'cadetblue', cursor: 'pointer' }}
                  class="material-icons">undo</span>
              </div>
            </MDBRow>
          </MDBContainer>


        </div>
        <div className="filters" style={{ marginBottom: "2rem" }}>
          <MDBContainer>
            <MDBRow>
              <MDBCol xl="3" lg="3" md="6" sm="12">
                <Input
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      if (hasValue("" + e.target.value)) {
                        console.log("ENTER   ", e.target.value)
                        this.setState({ filterType: 'cardNum', value: e.target.value })
                        this.getClients(1, false, "cardNum", e.target.value)
                      } else {
                        message.warning(allStrings.noValue);
                        console.log("ENTER NO VALUE   ", e.target.value)
                      }
                    }
                  }}
                  placeholder={allStrings.cardFilter}
                  size="large" prefix={<SearchOutlined />} />
              </MDBCol>
              <MDBCol xl="3" lg="3" md="6" sm="12">
                <Input
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      if (hasValue("" + e.target.value)) {
                        console.log("ENTER   ", e.target.value)
                        this.setState({ filterType: 'id', value: e.target.value })
                        this.getClients(1, false, "id", e.target.value)
                      } else {
                        message.warning(allStrings.noValue);
                        console.log("ENTER NO VALUE   ", e.target.value)
                      }
                    }
                  }}
                  placeholder={allStrings.idFilter}
                  size="large" prefix={<SearchOutlined />} />
              </MDBCol>
              <MDBCol xl="3" lg="3" md="6" sm="12">
                <Input
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      if (hasValue("" + e.target.value)) {
                        console.log("ENTER   ", e.target.value)
                        this.setState({ filterType: 'name', value: e.target.value })
                        this.getClients(1, false, "name", e.target.value)
                      } else {
                        message.warning(allStrings.noValue);
                        console.log("ENTER NO VALUE   ", e.target.value)
                      }
                    }
                  }}
                  placeholder={allStrings.nameFilter}
                  size="large" prefix={<SearchOutlined />} />
              </MDBCol>
              <MDBCol xl="3" lg="3" md="6" sm="12">
                <Select
                  style={{ width: '100%' }}
                  placeholder={allStrings.salesManFilter}
                  onChange={(value) => {
                    console.log(value)
                    this.setState({ filterType: 'salesMan', value: value })
                    this.getClients(1, false, "salesMan", value)

                  }}
                >
                  <Option value="all">{allStrings.all}</Option>
                  {this.state.salesMen.map(val => (
                    <Option value={val.id} style={{ direction: this.props.isRTL ? 'rtl' : 'ltr' }}>
                      {val.fullname}
                    </Option>
                  ))}
                </Select>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
        <MDBContainer>
          <MDBRow>
            {this.state.loading ?
              <LoadCard />
              :
              this.state.Clients.length > 0 ?
                this.state.Clients.map(client => (

                  <MDBCol xl="4" lg="6" md="12" sm="12">

                    <div className="categoryCard" >
                      <MDBContainer>
                        <MDBRow>
                          <MDBCol xl="2" lg="2" md="2" sm="2">
                            <div className="icons">
                              <Popconfirm
                                title={allStrings.areYouSureClient}
                                onConfirm={this.OKBUTTON}
                                onCancel={this.fCANCELBUTTON}
                                okText={allStrings.ok}
                                cancelText={allStrings.cancel}
                              >
                                <p style={{
                                  fontSize: '24px',
                                  color: '#A4A3B0',
                                  marginBottom: '8px',
                                  cursor: 'pointer',
                                  zIndex: '4',
                                  position: 'relative'
                                }} onClick={this.selectClientId} id={"client" + client.id}>
                                  <MDBIcon icon="times" onClick={this.selectClientId} id={"client" + client.id} />
                                </p>

                              </Popconfirm>

                              <p onClick={() => this.props.history.push('/clientForm', { data: client })} style={{
                                fontSize: '18px',
                                color: '#A4A3B0',
                                marginBottom: '8px',
                                cursor: 'pointer',
                              }}>
                                <MDBIcon icon="pen" />
                              </p>

                            </div>
                          </MDBCol>
                          <MDBCol xl="6" lg="6" md="6" sm="6" className="noPadding">
                            <div className="categoryInfo" onClick={() => this.props.history.push('/clientForm', { data: client })} >
                              <p style={{
                                fontWeight: '600',
                                color: '#43425D',
                                marginBottom: '6px',
                                fontSize: '1.2rem'

                              }}>{client.fullname.length > 15 ? client.fullname.substring(0, 12) + ".." : client.fullname}</p>

                              <p style={{
                                color: '#43425D',
                                fontSize: '16px',
                                marginBottom: '8px',
                                display: 'inline-block'
                              }}>{client.phone} </p>
                              <p style={{
                                color: '#43425D',
                                fontSize: '16px',
                                margin: 0,
                                marginBottom: '10px'
                              }}>{client.anotherPhone}</p>
                              <p style={{
                                color: '#43425D',
                                fontSize: '16px',
                                margin: 0,
                                marginBottom: '10px'
                              }}>#{

                                  client.clientCompanyIds.find(e => e.company === this.state.company) ? client.clientCompanyIds.find(e => e.company === this.state.company).value : ""
                                }</p>

                            </div>
                          </MDBCol>
                          <MDBCol xl="4" lg="4" md="4" sm="4">

                            <div className="categoryImg" >
                              <img alt="" style={{
                                width: '85px',
                                height: '85px',
                                borderRadius: '100%'
                              }} src={client.img ? client.img : require('../../assets/images/profileicon.jpg')}></img>
                            </div>
                          </MDBCol>
                        </MDBRow>

                      </MDBContainer>
                    </div>
                  </MDBCol>
                ))
                :
                <div className="noData">{allStrings.noData}</div>
            }
          </MDBRow>
          <MDBRow>
            {this.state.Clients.length > 0 &&
              <div style={{ textAlign: 'center', margin: 'auto', marginTop: '3rem' }}>
                <Pagination
                  onChange={(event, page) => {
                    console.log("page   ", page)
                    if (page !== this.state.page) {
                      this.getClients(page, false, this.state.filterType, this.state.value)
                      this.setState({ page: page })
                    }

                  }}
                  defaultPage={1} page={this.state.page} count={this.state.pages} style={{ color: `blue !important` }} />
              </div>
            }

          </MDBRow>
        </MDBContainer>
      </div>
    )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
}

export default withRouter(connect(mapToStateProps, mapDispatchToProps)(Clients))
