/* eslint-disable eqeqeq */
import React from 'react';
import './receipts.css'
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { ChangeLanguage } from '../../redux/actions/LanguageAction'
import { ChangeCompany, ChangeBranch } from '../../redux/actions/CompanyAction'
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { BASE_END_POINT } from '../../urls'
import axios from 'axios'
import { setUser } from '../../redux/actions/AuthActions'
import { Select, Checkbox, DatePicker, ConfigProvider } from 'antd';
import Pagination from '@material-ui/lab/Pagination';
import { Redirect } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton';
import moment from 'moment'
import locale_ar from 'antd/lib/locale/ar_EG';
import locale_en from 'antd/lib/locale/en_GB';
import 'moment/locale/ar';
import ReactToPrint from 'react-to-print';
import PrintReceipt2 from '../../component/receipt2/receipt2'
import PrintReceipt1 from '../../component/receipt1/receipt1'

const { Option } = Select;
let fromDate
let toDate
class ComponentToPrint extends React.Component {

  render() {
    return (
      <div>
        {this.props.type === "TYPE1" &&
          this.props.data.map((val, i) => (
            val.order.processType == "MULTI" ?
              <PrintReceipt2 data={val} marginBottom={i % 3 === 2 ? '193px' : '16px'} index={i} theLength={this.props.data.length} advertisementUrl={this.props.advertisementUrl} />
              :
              <PrintReceipt1 data={val} marginBottom={i % 3 === 2 ? '193px' : '16px'} index={i} theLength={this.props.data.length} />

          ))
        }
        {this.props.type === "TYPE2" &&
          this.props.data.map((val, i) => (
            <PrintReceipt2 data={val} marginBottom={i % 3 === 2 ? '193px' : '16px'} index={i} theLength={this.props.data.length} advertisementUrl={this.props.advertisementUrl} />

          ))
        }
      </div>
    );
  }
}
class Receipts extends React.Component {

  state = {
    Receipts: [],
    loading: true,
    refresh: false,
    page: 1,
    pages: 1,
    salesMen: [],
    products: [],
    premiums: [],
    receiptType: this.props.currentUser.user.recieptType || 'TYPE1',
    filterType: { "dateFilter": true },
    company: false,
    value: '',
    from: this.props.isRTL ? moment().startOf('month').format("YYYY/MM/DD") : moment().startOf('month').locale('en').format("YYYY/MM/DD"),
    to: this.props.isRTL ? moment().endOf('month').format("YYYY/MM/DD") : moment().endOf('month').locale('en').format("YYYY/MM/DD"),
    sortNum: null,
    sortFilter: false,
    fromDate: '',
    toDate: '',
    client: this.props.location.state ? this.props.location.state.client ? this.props.location.state.client : null : null,
    premiumsSelectedInfo: [],
    enablePrint: false,
    advertisementUrl: ''
  }
  constructor(props) {
    super(props)
    console.log("isr   ", this.props.history)
    if (this.props.currentUser.user.type !== 'ADMIN') {
      console.log("type  ", this.props.currentUser.user)
      if (this.props.currentUser.user.recieptType) {
        this.setState({ receiptType: this.props.currentUser.user.recieptType })
      }
    }
    this.getSetting()
    this.getDuration()
    if (this.props.isRTL) {
      allStrings.setLanguage('ar')
      moment.locale('ar');
    } else {
      allStrings.setLanguage('en')
      moment.locale('en');
    }
    window.scrollTo(0, 0)
  }
  getSetting = () => {
    this.setState({ loading: true })
    axios.get(`${BASE_END_POINT}setting`, {
      headers: {
        'Authorization': `Bearer ${this.props.currentUser.token}`,
        'Accept-Language': this.props.isRTL ? 'ar' : 'en'
      }
    })
      .then(response => {
        console.log("Setting   ", response.data)
        this.setState({
          advertisementUrl: response.data.advertisementUrl,
        })
      })
      .catch(error => {
        console.log("error   ", error.response)
        console.log("error2   ", error)
        this.setState({ loading: false, })
      })
  }
  getSalesMen = () => {
    let url = ``;
    if (this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN") {
      if (this.props.company === false) {
        url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN`
      } else {
        url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&company=${this.props.company}`
      }
    } else {
      url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&company=${this.props.currentUser.user.id}`
    }
    axios.get(url)
      .then(response => {
        console.log(response.data.users)
        this.setState({ salesMen: response.data.users })
      })
      .catch(error => {
        console.log("ALL salesMen ERROR")
        console.log(error.response)
      })
  }
  getDuration = () => {
    //first day
    let date = new Date()
    let newDate = date.toISOString().slice(0, 8)
    let firstDay = newDate + '01';
    //get days in month
    function daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }

    let DaysInCurrentMonth = daysInMonth(date.getMonth() + 1, date.getFullYear()); //number of day in month
    //last day
    let lastDay = newDate + DaysInCurrentMonth;
    console.log(firstDay)
    console.log(lastDay)
    if (this.props.isRTL) {
      this.setState({ from: moment(firstDay).format("YYYY/MM/DD"), to: moment(lastDay).format("YYYY/MM/DD") })
    } else {
      this.setState({ from: moment(firstDay).locale('en').format("YYYY/MM/DD"), to: moment(lastDay).locale('en').format("YYYY/MM/DD") })

    }

    fromDate = firstDay
    toDate = lastDay

  }

  componentDidMount() {
    this.getReceipts(1, false, this.state.filterType, false)
    this.getpremiumsSelectedInfo(1, false, this.state.filterType)
    this.getPremuims(1, false, this.state.filterType, false, false)
    this.getSalesMen()
    this.getDuration()

  }
  getPremuims = (page, refresh, filterType, sort, print) => {
    this.setState({ enablePrint: false })
    let query = `${BASE_END_POINT}premiums/withoutPagenation/get?page=${page}&type=INSTALLMENT`

    if (print) {
      query = `${BASE_END_POINT}premiums/withoutPagenation/get?page=${page}&print=${print}&typePDF=1&type=INSTALLMENT`
      console.log("PRINT")
    }
    console.log(query)
    //company
    let company = false
    if (this.props.currentUser.user.type === "ADMIN") {
      if (this.props.company === false) {
        this.setState({ company: false })
        company = false
      } else {
        this.setState({ company: this.props.company })
        company = this.props.company
      }
    }
    if (this.props.currentUser.user.type === "SUB-ADMIN" && this.props.currentUser.user.gineralSubAdmin === true) {
      if (this.props.company === false) {
        this.setState({ company: false })
        company = false
      } else {
        this.setState({ company: this.props.company })
        company = this.props.company
      }
    }
    if (this.props.currentUser.user.type === "COMPANY") {
      this.setState({ company: this.props.currentUser.user.id })
      company = this.props.currentUser.user.id
    }
    if (this.props.currentUser.user.type === "SUB-ADMIN" && this.props.currentUser.user.gineralSubAdmin === false) {
      this.setState({ company: this.props.currentUser.user.company[0].id })
      company = this.props.currentUser.user.company[0].id
    }
    console.log(company)
    console.log("filters", filterType)
    console.log("sort", sort)
    if (company !== false) {
      let str = '&company=' + company
      query += str
    }
    if (this.state.client != null) {
      let str = '&client=' + this.state.client
      query += str
    } else {
      let str = '&status=PENDING'
      query += str
    }
    if (filterType) {
      if (filterType.salesMan) {
        let str = '&salesMan=' + filterType.salesMan
        query += str
      }
      if (filterType.lastMonth) {
        let str = '&lastMonth=' + filterType.lastMonth
        query += str
      }
      if (filterType.dateFilter) {
        let str = '&start=' + fromDate + '&end=' + toDate
        console.log("str", str)
        query += str
      }
    }

    if (sort !== false) {
      if (sort === "sortByPurchaseDate-DOWN") {
        let str = '&sortByPurchaseDate=DOWN'
        query += str
      }
      if (sort === "sortByPurchaseDate-UP") {
        let str = '&sortByPurchaseDate=UP'
        query += str
      }
      if (sort === "sortByclient-UP") {
        let str = '&sortByclient=UP'
        query += str
      }
      if (sort === "sortByclient-DOWN") {
        let str = '&sortByclient=DOWN'
        query += str
      }
      if (sort === "sortByJob-UP") {
        let str = '&sortByJob=UP'
        query += str
      }
      if (sort === "sortByJob-DOWN") {
        let str = '&sortByJob=DOWN'
        query += str
      }
      if (sort === "sortByJobLocation-UP") {
        let str = '&sortByJobLocation=UP'
        query += str
      }
      if (sort === "sortByJobLocation-DOWN") {
        let str = '&sortByJobLocation=DOWN'
        query += str
      }
    }
    axios.get(query, {
      headers: {
        'Authorization': `Bearer ${this.props.currentUser.token}`
      }
    })
      .then(response => {
        console.log("premiums FOT TEST", this.state.filterType.salesMan, response.data)
        if (print) {
          this.setState({
            premiums: response.data.premiums,
            enablePrint: true
          })
          let time = 2000
          if (!this.state.filterType.salesMan) {
            time = 5500
          }
          setTimeout(function () {
            document.getElementById("spin").style.display = 'none'
            document.getElementById("show").style.display = 'inline'
            document.getElementById("show").href = response.data.url

          }, time);


        } else {
          this.setState({
            premiums: response.data,
            enablePrint: true
          })
        }

      })
      .catch(error => {
        console.log("error   ", error.response)
        console.log("error2   ", error)
      })

  }
  preparePDF = () => {
    this.getPremuims(1, false, this.state.filterType, false, true)
    document.getElementById("print").style.display = 'none'
    document.getElementById("spin").style.display = 'inline'

  }
  resetButton() {
    document.getElementById("print").style.display = 'inline'
    document.getElementById("show").style.display = 'none'
  }
  getpremiumsSelectedInfo = (page, refresh, filterType) => {

    let query = `${BASE_END_POINT}premiums/findSelectedInfo?page=${page}&type=INSTALLMENT`
    //company
    let company = false
    if (this.props.currentUser.user.type === "ADMIN") {
      if (this.props.company === false) {
        this.setState({ company: false })
        company = false
      } else {
        this.setState({ company: this.props.company })
        company = this.props.company
      }
    }
    if (this.props.currentUser.user.type === "SUB-ADMIN" && this.props.currentUser.user.gineralSubAdmin === true) {
      if (this.props.company === false) {
        this.setState({ company: false })
        company = false
      } else {
        this.setState({ company: this.props.company })
        company = this.props.company
      }
    }
    if (this.props.currentUser.user.type === "COMPANY") {
      this.setState({ company: this.props.currentUser.user.id })
      company = this.props.currentUser.user.id
    }
    if (this.props.currentUser.user.type === "SUB-ADMIN" && this.props.currentUser.user.gineralSubAdmin === false) {
      this.setState({ company: this.props.currentUser.user.company[0].id })
      company = this.props.currentUser.user.company[0].id
    }
    console.log(company)
    console.log("filters", filterType)
    if (company !== false) {
      let str = '&company=' + company
      query += str
    }
    if (this.state.client != null) {
      let str = '&client=' + this.state.client
      query += str
    } else {
      let str = '&status=PENDING'
      query += str
    }
    if (filterType) {
      if (filterType.salesMan) {
        let str = '&salesMan=' + filterType.salesMan
        query += str
      }
      if (filterType.lastMonth) {
        let str = '&lastMonth=' + filterType.lastMonth
        query += str
      }
      if (filterType.dateFilter) {
        let str = '&start=' + fromDate + '&end=' + toDate
        console.log("str", str)
        query += str
      }
    }

    axios.get(query, {
      headers: {
        'Authorization': `Bearer ${this.props.currentUser.token}`
      }
    })
      .then(response => {
        console.log("premiums selected count  ", response.data)
        this.setState({
          premiumsSelectedInfo: response.data,
        })
      })
      .catch(error => {
        console.log("error   ", error.response)
        console.log("error2   ", error)
      })

  }
  getReceipts = (page, refresh, filterType, sort) => {
    this.setState({ loading: true })
    let query = `${BASE_END_POINT}premiums?page=${page}&type=INSTALLMENT`
    //company
    let company = false
    if (this.props.currentUser.user.type === "ADMIN") {
      if (this.props.company === false) {
        this.setState({ company: false })
        company = false
      } else {
        this.setState({ company: this.props.company })
        company = this.props.company
      }
    }
    if (this.props.currentUser.user.type === "SUB-ADMIN" && this.props.currentUser.user.gineralSubAdmin === true) {
      if (this.props.company === false) {
        this.setState({ company: false })
        company = false
      } else {
        this.setState({ company: this.props.company })
        company = this.props.company
      }
    }
    if (this.props.currentUser.user.type === "COMPANY") {
      this.setState({ company: this.props.currentUser.user.id })
      company = this.props.currentUser.user.id
    }
    if (this.props.currentUser.user.type === "SUB-ADMIN" && this.props.currentUser.user.gineralSubAdmin === false) {
      this.setState({ company: this.props.currentUser.user.company[0].id })
      company = this.props.currentUser.user.company[0].id
    }
    console.log(company)
    console.log("filters", filterType)
    console.log("sort", sort)
    if (company !== false) {
      let str = '&company=' + company
      query += str
    }
    if (this.state.client != null) {
      let str = '&client=' + this.state.client
      query += str
    } else {
      let str = '&status=PENDING'
      query += str
    }
    if (filterType) {
      if (filterType.salesMan) {
        let str = '&salesMan=' + filterType.salesMan
        query += str
      }
      if (filterType.lastMonth) {
        let str = '&lastMonth=' + filterType.lastMonth
        query += str
      }
      if (filterType.dateFilter) {
        let str = '&start=' + fromDate + '&end=' + toDate
        console.log("str", str)
        query += str
      }
    }

    if (sort !== false) {
      if (sort === "sortByPurchaseDate-DOWN") {
        let str = '&sortByPurchaseDate=DOWN'
        query += str
      }
      if (sort === "sortByPurchaseDate-UP") {
        let str = '&sortByPurchaseDate=UP'
        query += str
      }
      if (sort === "sortByclient-UP") {
        let str = '&sortByclient=UP'
        query += str
      }
      if (sort === "sortByclient-DOWN") {
        let str = '&sortByclient=DOWN'
        query += str
      }
      if (sort === "sortByJob-UP") {
        let str = '&sortByJob=UP'
        query += str
      }
      if (sort === "sortByJob-DOWN") {
        let str = '&sortByJob=DOWN'
        query += str
      }
      if (sort === "sortByJobLocation-UP") {
        let str = '&sortByJobLocation=UP'
        query += str
      }
      if (sort === "sortByJobLocation-DOWN") {
        let str = '&sortByJobLocation=DOWN'
        query += str
      }
    }
    console.log(query)
    axios.get(query, {
      headers: {
        'Authorization': `Bearer ${this.props.currentUser.token}`
      }
    })
      .then(response => {
        console.log("Receipts   ", response.data)
        this.setState({
          loading: false,
          refresh: false,
          Receipts: response.data.data,
          page: response.data.page,
          pages: response.data.pageCount,
        })
      })
      .catch(error => {
        console.log("error   ", error.response)
        console.log("error2   ", error)
        this.setState({ loading: false, })
      })

  }
  onChangeFrom = (date, dateString) => {
    if (this.props.isRTL) {
      this.setState({ from: moment(dateString).format("YYYY/MM/DD") })
    } else {
      this.setState({ from: moment(dateString).locale('en').format("YYYY/MM/DD") })
    }
    fromDate = moment(dateString).locale('en').format("YYYY-MM-DD")
  }
  onChangeTo = (date, dateString) => {
    if (this.props.isRTL) {
      this.setState({ to: moment(dateString).format("YYYY/MM/DD") })
    } else {
      this.setState({ to: moment(dateString).locale('en').format("YYYY/MM/DD") })

    }
    toDate = moment(dateString).locale('en').format("YYYY-MM-DD")
    let filters = this.state.filterType;

    filters.dateFilter = true;
    console.log(filters)
    this.setState({ filterType: filters })
    this.getReceipts(1, false, filters)
    this.getPremuims(1, false, filters, true, false)
    this.getpremiumsSelectedInfo(1, false, filters)
  }
  render() {
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.pagesPermission.includes('RECIEPTS')) return <Redirect to='/Home' />
    console.log(this.state.client)
    return (
      <div className="Receipt">
        <div className="categoryTop" style={{ marginBottom: '3rem' }}>
          <MDBContainer>
            <MDBRow>
              <MDBCol xl="3" lg="12" md="12" sm="12" style={{ paddingTop: '1rem', marginBottom: '1rem' }}>

                {this.state.receiptType === "TYPE2" ? <div >
                  <img alt="" style={{ cursor: 'pointer' }} src={require('../../assets/images/icons8_pdf.png')}></img>
                  <span
                    id='print'
                    onClick={this.preparePDF}
                    style={{
                      color: '#FF3B40',
                      fontWeight: '600',
                      padding: '10px',
                      fontSize: '20px',
                      position: 'absolute',
                      cursor: 'pointer'
                    }}>{allStrings.print}</span>
                  <span id='spin' style={{
                    display: 'none', padding: '10px',
                    fontSize: '20px', color: '#FF3B40',
                  }}  ><div class="spinner-border text-warning" role="status">
                      <span class="sr-only">Loading...</span>
                    </div  > تجهيز الملف للطباعة</span>
                  <a id='show' style={{
                    display: 'none', padding: '10px',
                    color: '#FF3B40', cursor: 'pointer', fontSize: "18px", fontFamily: 'Almarai'
                  }} onClick={this.resetButton} href="https://api.sahelly.com/uploads/pdfs/testMerged.pdf" >عرض الملف</a>

                </div> : this.state.enablePrint === true ?
                  <ReactToPrint
                    documentTitle={"ايصالات  بتاريخ " + moment().format("YYYY/MM/DD")}
                    trigger={() => {
                      return (
                        <div>
                          <img alt="" style={{ cursor: 'pointer' }} src={require('../../assets/images/icons8_pdf.png')}></img>
                          <span
                            style={{
                              color: '#FF3B40',
                              fontWeight: '600',
                              padding: '10px',
                              fontSize: '20px',
                              position: 'absolute',
                              cursor: 'pointer'
                            }}>{allStrings.print}</span>
                        </div>
                      );
                    }}
                    content={() => this.componentRef}
                  /> :

                  <div className="pt1">
                    <img alt="" style={{ cursor: 'not-allowed' }} src={require('../../assets/images/icons8_pdf.png')}></img>
                    <span
                      style={{
                        color: '#FF3B40',
                        fontWeight: '600',
                        padding: '10px',
                        fontSize: '20px',
                        position: 'absolute',
                        cursor: 'pointer'
                      }}>{allStrings.print}</span>
                  </div>

                }
                <div style={{ display: 'none' }}>
                  <ComponentToPrint data={this.state.premiums} advertisementUrl={this.state.advertisementUrl} theLength={this.state.premiums.length} type={this.state.receiptType} ref={el => (this.componentRef = el)} />
                </div>

              </MDBCol>
              <MDBCol xl="2" lg="6" md="6" sm="12" style={{ marginBottom: '1rem' }}>

                <Checkbox
                  style={{ float: 'right', paddingTop: '3px' }}
                  defaultChecked={this.state.hasGuarantor}
                  onChange={(e) => {
                    console.log(e.target.checked)
                    this.setState({ hasGuarantor: e.target.checked })
                    if (e.target.checked === true) {
                      this.setState({ filterType: 'lastMonth', value: e.target.checked })
                      this.getDuration()
                      let filters = this.state.filterType;
                      filters.lastMonth = true;
                      this.setState({ filterType: filters })
                      this.getReceipts(1, false, filters)
                      this.getPremuims(1, false, filters)
                      this.getpremiumsSelectedInfo(1, false, filters)
                    }
                    if (e.target.checked === false) {
                      this.setState({ filterType: 'lastMonth', value: e.target.checked })
                      let filters = this.state.filterType;
                      filters.lastMonth = false;
                      this.setState({ filterType: filters })
                      this.getReceipts(1, false, filters)
                      this.getPremuims(1, false, filters)
                      this.getpremiumsSelectedInfo(1, false, filters)
                    }

                  }} >
                  <label htmlFor="defaultFormRegisterNameEx2"
                    style={{
                      fontWeight: '500',
                      color: '#589CF8',
                      fontSize: '18px',
                      margin: '20px 0px 20px 6px'
                    }}>
                    {allStrings.onlyLastMonth}
                  </label>
                </Checkbox>

              </MDBCol>
              <MDBCol xl="2" lg="6" md="6" sm="12" style={{ paddingTop: '1rem', marginBottom: '1rem' }}>
                <Select
                  style={{ width: '100%', height: '40px' }}
                  placeholder={allStrings.chooseSalesMan}
                  onChange={(value) => {
                    console.log(value)
                    this.setState({ filterType: 'salesMan', value: value })
                    let filters = this.state.filterType;

                    if (value == 'all') {
                      filters.salesMan = null;
                    } else {
                      filters.salesMan = value;
                    }
                    this.setState({ filterType: filters })
                    this.getReceipts(1, false, filters)
                    this.getPremuims(1, false, filters)
                    this.getpremiumsSelectedInfo(1, false, filters)
                  }}
                >
                  <Option value="all">{allStrings.all}</Option>
                  {this.state.salesMen.map(val => (
                    <Option value={val.id} style={{ direction: this.props.isRTL ? 'rtl' : 'ltr' }}>
                      {val.fullname}
                    </Option>
                  ))}
                </Select>

              </MDBCol>

              <MDBCol xl="2" lg="6" md="6" sm="12" style={{ paddingTop: '1rem', marginBottom: '1rem' }}>
                {
                  <Select
                    style={{ width: '100%', float: 'right', height: '40px' }}
                    placeholder={allStrings.chooseReceiptType}
                    onChange={(value) => {
                      this.setState({ receiptType: value })

                    }}
                  >
                    {this.props.currentUser.user.type === "COMPANY" ?
                      <Option value={this.props.currentUser.user.recieptType}>{this.props.currentUser.user.recieptType === "TYPE1" ? allStrings.type1 : allStrings.type2}</Option>
                      :
                      <Option value="TYPE1">{allStrings.type1}</Option>
                    }
                    <Option style={{ display: this.props.currentUser.user.type === "COMPANY" ? 'none' : 'block' }} value="TYPE2">{allStrings.type2}</Option>


                    {/*<Option value="TYPE3">{allStrings.type3}</Option>*/}
                  </Select>
                }
              </MDBCol>

              <MDBCol xl="3" lg="6" md="6" sm="12" style={{ marginBottom: '1rem' }}>
                <div style={{ paddingtop: '15px' }}>
                  <div style={{ marginBottom: '10px', textAlign: 'center' }}>
                    <span className="fromSpan" style={{ float: this.props.isRTL ? 'right' : 'left' }}>{allStrings.from}</span>
                    <div style={{ display: 'inline-block', textAlign: 'center', marginLeft: this.props.isRTL ? '0px' : "-70px" }}>
                      <ConfigProvider locale={this.props.isRTL ? locale_ar : locale_en}>
                        <DatePicker defaultValue={moment(this.state.from, 'YYYY/MM/DD')} onChange={this.onChangeFrom} />
                      </ConfigProvider>
                      <span className="newSpan" style={{ left: this.props.isRTL ? '40px' : '70px' }}><img alt="" style={{ marginRight: '4px' }} src={require('../../assets/images/icons8_edit_property.png')}></img>{this.state.from}</span>
                    </div>
                  </div>
                  <div style={{ marginBottom: '10px', textAlign: 'center' }}>
                    <span className="fromSpan" style={{ float: this.props.isRTL ? 'right' : 'left' }}>{allStrings.to}</span>
                    <div style={{ display: 'inline-block', textAlign: 'center', marginLeft: this.props.isRTL ? '0px' : "-70px" }}>
                      <ConfigProvider locale={this.props.isRTL ? locale_ar : locale_en}>
                        <DatePicker defaultValue={moment(this.state.to, 'YYYY/MM/DD')} onChange={this.onChangeTo} />
                      </ConfigProvider>
                      <span className="newSpan" style={{ left: this.props.isRTL ? '40px' : '70px' }}><img alt="" style={{ marginRight: '4px' }} src={require('../../assets/images/icons8_edit_property.png')}></img>{this.state.to}</span>
                    </div>
                  </div>
                </div>
              </MDBCol>

            </MDBRow>
            <MDBRow>
              <div onClick={() => {
                this.setState({ client: null, sortNum: null })
                setTimeout(() => {
                  this.getReceipts(1, false, this.state.filterType, false)
                  this.getpremiumsSelectedInfo(1, false, this.state.filterType)
                  this.getPremuims(1, false, this.state.filterType, false, false)
                }, 200);

              }}
                style={{ width: '100%', textAlign: 'end', paddingRight: '18px' }}>
                <span style={{ color: 'cadetblue', cursor: 'pointer' }}
                  class="material-icons">undo</span>
              </div>
            </MDBRow>
            <MDBRow>
              <MDBCol md="6" sm="6">
                <p style={{ marginTop: '0px', fontSize: '20px', fontWeight: '700', color: '#43425D', textAlign: 'center' }}>
                  <span>{" " + this.state.premiumsSelectedInfo.moneyCount + " "}</span>
                  <span>{allStrings.totalCost}</span>
                </p>
              </MDBCol>
              <MDBCol md="6" sm="6">
                <p style={{ marginTop: '0px', fontSize: '20px', fontWeight: '700', color: '#43425D', textAlign: 'center' }}>

                  <span>{" " + this.state.premiumsSelectedInfo.clientLastPremuims + " "}</span>
                  <span>{allStrings.clientsLastMonth}</span>
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="12" sm="12">
                <div>
                  <div className="receiptTable">
                    <table class="table table-borderless">
                      <thead class="thead">
                        <tr style={{ background: '#F5F7F8', color: '#A4A3B0' }}>
                          <th scope="col">

                            <div style={{ display: 'inline-block', position: 'relative' }}>
                              <span onClick={() => {
                                let filters = this.state.filterType;
                                this.getPremuims(1, false, filters, 'sortByPurchaseDate-UP', false)
                                this.getReceipts(1, false, filters, 'sortByPurchaseDate-UP')
                                this.setState({ sortNum: 1 })
                              }}
                                class="material-icons" style={{ cursor: 'pointer', position: "relative", top: "-5px", color: this.state.sortNum === 1 ? 'green' : 'silver' }}>arrow_drop_up
                              </span>
                              <span onClick={() => {
                                let filters = this.state.filterType;
                                this.getReceipts(1, false, filters, 'sortByPurchaseDate-DOWN')
                                this.getPremuims(1, false, filters, 'sortByPurchaseDate-DOWN', false)
                                this.setState({ sortNum: 2 })
                              }} class="material-icons" style={{ position: 'absolute', top: '15px', left: '0', cursor: 'pointer', color: this.state.sortNum === 2 ? 'red' : 'silver' }}>arrow_drop_down</span>
                            </div>
                            {allStrings.purchaseDate}
                          </th>
                          <th scope="col">{allStrings.paidDate2}</th>
                          <th scope="col">{allStrings.salesMan}</th>
                          <th scope="col">{allStrings.buySalesMan}</th>
                          <th scope="col">{allStrings.product}</th>
                          <th scope="col">{allStrings.clientCode}</th>
                          <th scope="col">
                            <div style={{ display: 'inline-block', position: 'relative' }}>
                              <span onClick={() => {
                                let filters = this.state.filterType;
                                this.getReceipts(1, false, filters, 'sortByclient-UP')
                                this.getPremuims(1, false, filters, 'sortByclient-UP', false)

                                this.setState({ sortNum: 3 })
                              }} class="material-icons" style={{ cursor: 'pointer', position: "relative", top: "-5px", color: this.state.sortNum === 3 ? 'green' : 'silver' }}>arrow_drop_up</span>
                              <span onClick={() => {
                                let filters = this.state.filterType;
                                this.getReceipts(1, false, filters, 'sortByclient-DOWN')
                                this.getPremuims(1, false, filters, 'sortByclient-DOWN', false)
                                this.setState({ sortNum: 4 })
                              }} class="material-icons" style={{ position: 'absolute', top: '15px', left: '0', cursor: 'pointer', color: this.state.sortNum === 4 ? 'red' : 'silver' }}>arrow_drop_down</span>
                            </div>
                            {allStrings.client}
                          </th>
                          <th scope="col">{allStrings.phone}</th>
                          <th scope="col">
                            <div style={{ display: 'inline-block', position: 'relative' }}>
                              <span onClick={() => {
                                let filters = this.state.filterType;
                                this.getReceipts(1, false, filters, 'sortByJob-UP')
                                this.getPremuims(1, false, filters, 'sortByJob-UP', false)
                                this.setState({ sortNum: 5 })
                              }} class="material-icons" style={{ cursor: 'pointer', position: "relative", top: "-5px", color: this.state.sortNum === 5 ? 'green' : 'silver' }}>arrow_drop_up</span>
                              <span onClick={() => {
                                let filters = this.state.filterType;
                                this.getReceipts(1, false, filters, 'sortByJob-DOWN')
                                this.getPremuims(1, false, filters, 'sortByJob-DOWN', false)
                                this.setState({ sortNum: 6 })
                              }} class="material-icons" style={{ position: 'absolute', top: '15px', left: '0', cursor: 'pointer', color: this.state.sortNum === 6 ? 'red' : 'silver' }}>arrow_drop_down</span>
                            </div>
                            {allStrings.job}
                          </th>
                          <th scope="col">
                            <div style={{ display: 'inline-block', position: 'relative' }}>
                              <span onClick={() => {
                                let filters = this.state.filterType;
                                this.getReceipts(1, false, filters, 'sortByJobLocation-UP')
                                this.getPremuims(1, false, filters, 'sortByJobLocation-UP')
                                this.setState({ sortNum: 7 })
                              }} class="material-icons" style={{ cursor: 'pointer', position: "relative", top: "-5px", color: this.state.sortNum === 7 ? 'green' : 'silver' }}>arrow_drop_up</span>
                              <span onClick={() => {
                                let filters = this.state.filterType;
                                this.getReceipts(1, false, filters, 'sortByJobLocation-DOWN')
                                this.getPremuims(1, false, filters, 'sortByJobLocation-DOWN', false)
                                this.setState({ sortNum: 8 })
                              }} class="material-icons" style={{ position: 'absolute', top: '15px', left: '0', cursor: 'pointer', color: this.state.sortNum === 8 ? 'red' : 'silver' }}>arrow_drop_down</span>
                            </div>
                            {allStrings.jobLocation}</th>
                          <th scope="col">

                            {allStrings.jobArea}
                          </th>
                          <th scope="col">{allStrings.address}</th>
                          <th scope="col">{allStrings.paid}</th>
                          <th scope="col">{allStrings.remain}</th>
                          <th scope="col">{allStrings.firstPaid}</th>
                          <th scope="col">{allStrings.monthNum}</th>
                          <th scope="col">{allStrings.number}</th>
                          <th scope="col">{allStrings.cost}</th>


                        </tr>
                      </thead>
                      <tbody>
                        {this.state.loading ?

                          <tr style={{ height: '200px' }}>
                            <th colspan="15" className="noData">
                              <Skeleton height={400} />
                            </th>
                          </tr>
                          :

                          this.state.Receipts.length > 0 ?
                            this.state.Receipts.map(val => (
                              <tr style={{ background: '#fff', borderBottom: '10px solid  #F0F2F5' }}>
                                <th scope="row">{moment(val.purchaseDate).format('YYYY/MM/DD')}</th>
                                <th scope="row">{moment(val.paidDate).format('YYYY/MM/DD')}</th>
                                <th scope="row">{val.salesMan ? val.salesMan.fullname : " "}</th>
                                <th scope="row">{val.order.buySalesMan ? val.order.buySalesMan.fullname.substring(0, 20) : ''}</th>
                                <th scope="row">{this.props.isRTL ? val.product.name_ar : val.product.name_en}</th>
                                <th scope="row">
                                  {/* {val.client.id} */}
                                  {val.client.clientCompanyIds.find(e => e.company === val.company.id) ? val.client.clientCompanyIds.find(e => e.company === val.company.id).value : ""}
                                </th>
                                <th scope="row">{val.client.fullname}</th>
                                <th scope="row">{val.client.phone}</th>
                                <th scope="row">{val.client.job ? val.client.job.substring(0, 20) : "لايوجد"}</th>
                                <th scope="row">{val.client.jobLocation ? val.client.jobLocation.substring(0, 20) : "لايوجد"}</th>
                                <th scope="row">{val.client.jobArea ? val.client.jobArea.substring(0, 20) : "لايوجد"}</th>
                                <th scope="row">{val.client.Address ? val.client.Address : ""}</th>
                                <th scope="row">{val.order.costPaid ? val.order.costPaid : ""}</th>
                                <th scope="row">{val.order.costRemaining ? val.order.costRemaining : ""}</th>
                                <th scope="row">{val.order.firstPaid}</th>
                                <th scope="row">{val.order.monthCount}</th>
                                <th scope="row">{val.receiptNum}</th>
                                <th scope="row">{val.costPerMonth}</th>

                              </tr>
                            ))
                            :
                            <tr style={{ height: '200px' }}>
                              <th colspan="9" className="noData">
                                <p style={{ marginTop: '4rem' }}>{allStrings.noData}</p>
                              </th>
                            </tr>
                        }

                      </tbody>
                    </table>
                  </div>
                  <hr></hr>
                  <MDBRow>
                    {this.state.Receipts.length > 0 &&
                      <div style={{ textAlign: 'center', margin: 'auto', marginTop: '3rem' }}>
                        <Pagination
                          onChange={(event, page) => {
                            console.log("page   ", page)
                            if (page !== this.state.page) {
                              this.getReceipts(page, false, this.state.filterType, this.state.sortFilter)
                              this.setState({ page: page })
                            }

                          }}
                          defaultPage={this.state.page} page={this.state.page} count={this.state.pages} style={{ color: `blue !important` }} />
                      </div>
                    }

                  </MDBRow>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </div>
    )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
}

export default withRouter(connect(mapToStateProps, mapDispatchToProps)(Receipts))
