/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React from 'react';
import './chat.css'
import { MDBContainer,  MDBRow, MDBCol,MDBIcon} from "mdbreact";
import Avatar from '@material-ui/core/Avatar';

import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import {hasValue} from '../../validations/validations'
import io from 'socket.io-client';
import {getUnseenMessagesCount} from '../../redux/actions/ChatAction'
import BounceLoader from "react-spinners/BounceLoader";
import { Redirect } from 'react-router-dom'


class Chat extends React.Component {

  scroll
  peoplePage=1
  messagesPage=1

  state={
    loading:true,
    people:[],
    people2:[],
    peoplePages:1,
    peopleLoading:true,
    messages:[],
    messagesPages:1,
    messagesLoading:true,
    img:null,
    msg:'',
    messagesTabs:[],
    userExistInPeope:false,
    tabIndex:this.props.location.state?-1:0,
    selectedUser:this.props.location.state?this.props.location.state.data.to:'',
  }

  constructor(props){
    super(props)
    console.log("isr   ",this.props.isRTL)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    
    window.scrollTo(0,0)
  }

  componentDidMount(){
    if(this.props.location.state){
      console.log("PROPS EXIST")
      console.log("PROPS  ",this.props.location.state.data)

    }else{
      console.log("PROPS NOT EXIST")
      console.log("PROPS  ",this.props.location.state)
    }
    this.getPeople(1)
    this.socket = io("https://qastha.herokuapp.com/chat",{ query: `id=${this.props.currentUser.user.id}`} );  
    this.socket.on('connect', () => {
      console.log("connect   " + this.socket.connected); // true
      console.log("connect   " + this.socket.id);
      console.log(this.socket);  
    });

    this.socket.on('newMessage', (data) => {
      console.log("newMessage   " + data); // true
      console.log(data); // true
      console.log("ID  ",this.state.selectedUser)
      if(this.state.selectedUser.id==data.user._id){
        this.setState({
          messages: [...this.state.messages,data] ,
        }) 
      }
    });
  }
  fileHandler = (event) => {
    this.setState({
      img: event.target.files[0],
      imgType:'data'
    })
    
  }
  removeImg = () => {
    this.setState({
        img:null
    });
  }
  seenMessages(otherId) {
    const {other} = this.props;
    axios.put(`${BASE_END_POINT}messages?userId=${this.props.currentUser.user.id}&&friendId=${other}`)
    .then(response => {
      console.log("Seen Msg  ",response) 
      this.props.getUnseenMessagesCount(this.props.currentUser.token)
    })
    .catch(error => {
      console.log("seen error  ",error.response);
    })
  }

  getPeople = (page) =>{
    const {currentUser} = this.props
    axios.get(`${BASE_END_POINT}messages/lastContacts?id=${this.props.currentUser.user.id}&page=${page}&limit=20`)
    .then(response => {
      this.setState({
        peopleLoading:false,
        people:[...this.state.people,...response.data.data],
        people2:[...this.state.people,...response.data.data],
        pages:response.data.pageCount,
      })

      
      if(this.props.location.state){
          response.data.data.map((val,index)=>{
            console.log("check .....   ")
            if(val.to.id==this.props.currentUser.user.id){
              console.log("check if   ")
              if(val.from.id == this.props.location.state.data.to.id ){
                console.log("check if if   ")
                this.setState({tabIndex:index, userExistInPeope:true})
              }
            }else{
              if(val.to.id ==  this.props.location.state.data.to.id ){
                console.log("check else   ")
                this.setState({tabIndex:index,userExistInPeope:true})
              }
            }
          })
      }else{
        this.setState({tabIndex:0,userExistInPeope:true})
        console.log("NO PROPS STATE")
      }

      if(!this.props.location.state){
        this.setState({selectedUser:response.data.data[0].to.id==currentUser.id?response.data.data[0].from:response.data.data[0].to,})
      }
      console.log('PEOPLE   ',response.data.data) 
      this.getMessages(1,this.props.location.state?this.props.location.state.data.to.id:response.data.data[0].to.id==currentUser.id?response.data.data[0].from.id:response.data.data[0].to.id)    
    })
    .catch(error => {
      this.setState({peopleLoading:false})
      console.log("error   ",error.response);   
    })
  }
  
  getMessages = (page,id,stopScroll,reload) =>{
    axios.get(`${BASE_END_POINT}messages?userId=${this.props.currentUser.user.id}&&friendId=${id}&&page=${page}&&limit=20`)
    .then(response => {
      this.setState({
        messagesLoading:false,
        messages:reload?[...response.data.data.reverse()]:[...response.data.data.reverse(),...this.state.messages],
        messagesPages:response.data.pageCount,
      })
      console.log('MESSAGES   ',response.data)   
      if(!stopScroll){
        setTimeout(()=>{
          this.scroll.scrollTop = this.scroll.scrollHeight  - this.scroll.clientHeight
        },1000)
      }
    })
    .catch(error => {
      this.setState({messagesLoading:false})
      console.log("error of messages   ",error.response);   
    })
  }
  

  sender  = (val) =>(
        <div className="sender">
          {val.image?
          <div style={{width:'100%',height:'235px'}}>
            <img alt=""  style={{margin:15 ,boxShadow:' 0px 0px 2px 0px #999', borderRadius:10,float:'left'}} src={val.image} width={200} height={200} />
          </div>
          : 
          <div className="message">
            <p>{val.text}</p>
            <p className="msgTime">{""+new Date(val.createdAt).toLocaleTimeString()}</p>
          </div>
          }
        </div>
  )

  me = (val)=>(
        <div className="me">
          {val.image?
          <div style={{width:'100%',height:'235px'}}>
            <img alt=""  style={{margin:15 ,boxShadow:' 0px 0px 2px 0px #999', borderRadius:10,float:'right'}} src={val.image} width={200} height={200} />
          </div>
          :
          <div className="message">
            <p>{val.text}</p>
            <p className="msgTime">{""+new Date(val.createdAt).toLocaleTimeString()}</p>
          </div>
          }
          
        </div>
  )

  sendMessage = () =>{
    const {img,msg,selectedUser,tabIndex} = this.state
    if(img||hasValue(""+msg)){
      if(hasValue(""+msg)){
        const data = {
          text:msg,
          image:"",
          createdAt: new Date(),
          user:{
            _id:this.props.currentUser.user.id,
            img:this.props.currentUser.user.img
          },
        }
        this.socket.emit("newMessage",{
          toId:selectedUser.id ,
          data: data
       });

       this.state.messagesTabs[tabIndex]=msg
       this.setState({ msg:'',img:null,messages: [...this.state.messages,data]})
      }else{
        console.log("IMG  ",img)
        var data = new FormData(); 
        data.append('img',img)
         
        axios.post(`${BASE_END_POINT}messages/upload`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${this.props.currentUser.token}`
          },
        })
        .then(response=>{
          const data = {
            text:'',
            image:response.data,
            createdAt: new Date(),
            user:{
              _id:this.props.currentUser.user.id,
              img:this.props.currentUser.user.img
            },
          }
          this.socket.emit("newMessage",{
            toId:selectedUser.id ,
            data: data
         });
         this.state.messagesTabs[tabIndex]='http://www.a3lnha/..'
         this.setState({msg:'',img:null,messages: [...this.state.messages,data]})
        })
        .catch(error=>{
          console.log("ERROR  ",error)
          console.log("ERROR  ",error.response)
        })
      }
    }else{
      console.log("NO VALUES TO SEND")
    }
  }

 chatTab = (data,index)=>{
   const {currentUser} = this.props
   const {tabIndex,messagesTabs} = this.state
   
   return(
    <div onClick={()=>{
        this.setState({tabIndex:index,selectedUser:data.to.id==currentUser.user.id?data.from:data.to,messages:[]})
        this.messagesPage=1
        this.getMessages(1,data.to.id==currentUser.user.id?data.from.id:data.to.id,false, true)
        this.seenMessages(data.to.id==currentUser.user.id?data.from.id:data.to.id)
        console.log("CHAT TAB")
    }} className="chatTabs" style={{cursor:'pointer',backgroundColor:tabIndex==index?'#F0F2F5':'#fff',}}>
      <div className="tab">
        <span className="name" style={{color:tabIndex==index?'#43425D':'black'}}>{data.to.id==currentUser.user.id?data.from.fullname:data.to.fullname}</span>
        <span className="time" style={{color:tabIndex==index?'#43425D':'#868686'}}>{""+new Date(data.incommingDate).toLocaleTimeString()}</span>
        <p className="shortMsg" style={{color:tabIndex==index?'#43425D':'#868686'}} >{messagesTabs[index]?messagesTabs[index]:data.content?data.content.substring(0,20) + "...":'http://a3lnha.com/..'}</p>
      </div>
      <Avatar alt="Remy Sharp" src={data.to.id==currentUser.user.id?data.from.img?data.from.img:require('../../assets/images/profileicon.jpg'):data.to.img?data.to.img:require('../../assets/images/profileicon.jpg')}/>
    </div>
   )
 }



  render(){
    const {currentUser} = this.props
    const {people2, selectedUser,peopleLoading,messages,messagesPages} = this.state
    if (this.props.currentUser.user.type == "COMPANY" && this.props.currentUser.user.sendMessage ==false) return <Redirect to='/Home' /> 
   // if (this.props.currentUser.user.type == "SUB-ADMIN" && this.props.currentUser.user.pagesPermission.includes('CHAT')) return <Redirect to='/Home' /> 
  
    return (
    <div className="chat">
      {peopleLoading?
       <div style={{minHeight:600,display:'flex',justifyContent:'center',alignItems:'center'}} >
       <BounceLoader
       size={150}
       color={"#43425D"}
       loading={this.state.loading}
       />
       </div>
       :
       <MDBContainer> 
        
            <MDBRow>
              
              <MDBCol xl="8" lg="8" md="12" sm="12" className="paddingLeft">
               <div className="inbox">
                  <div className="topInbox">
                    <Avatar alt="Remy Sharp" src={selectedUser.img?selectedUser.img:require('../../assets/images/profileicon.jpg')} />
                    <span className="online" style={{position: 'absolute',marginTop: '40px',marginLeft: '-13px'}}></span>
                    <p style={{color: '#4d4d4d',marginLeft:'1rem'}}>{selectedUser.fullname}
                    <span style={{color: '#A4A5B0',display:'block'}}>{selectedUser.type=="CLIENT"?allStrings.client:allStrings.salesMan}</span>
                    </p>
                    
                  </div>
                  <div ref={ref =>{this.scroll=ref}}  onScroll={(e)=>{
                      console.log("e.target.scrollHeight   ",e.target.scrollHeight)
                      console.log("e.target.scrollTop   ",e.target.scrollTop)
                      console.log("e.target.clientHeight   ",e.target.clientHeight)
                     if(e.target.scrollTop==0){
                        console.log("END OF SCROLL")
                        if(this.messagesPage<messagesPages){
                          this.messagesPage++
                          this.getMessages(this.messagesPage,selectedUser.id,true)
                        } 
                      }
                    }} className="bodyInbox">
                    {messages.map(val=>{
                      if(val.user._id==currentUser.user.id){
                        return this.me(val) 
                      }else{
                        return this.sender(val)
                      }
                    })
                    }

                  </div>
                 
                  <div className="inboxFooter">
                    <div className="inputsCont">
                      <textarea
                      style={{direction:this.props.isRTL?'rtl':'ltr'}}
                      value={this.state.msg}
                      onChange={(e)=>{
                        this.setState({msg:e.target.value})
                      }}
                      /*onKeyPress={(e)=>{
                        if (e.key === 'Enter') {
                          this.sendMessage()
                          console.log("ENTER PRESSED")
                        }
                      }}*/
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder={allStrings.typeMsg}
                      />
                      <span onClick={()=>this.sendMessage()} class="material-icons" 
                      style={{color:'#43425D',
                      position: 'absolute',
                      marginTop: '-60px',
                      marginLeft: '12px',
                      cursor: 'pointer'
                      }}>send</span>
                      <input onChange={e=>{
                        console.log("FILE    ",e.target.files[0])
                        this.setState({img:e.target.files[0]})
                        
                      }} type="file" className="uploadFile"></input>
                      <MDBIcon far icon="image" />

                    </div>
                    <div className="previewRaduis" >
                      {this.state.img!= null&&
                      <div style={{display:"inline-block",float:'right',margin:'10px ',position: 'relative'}}>
                          <img alt=""    src={URL.createObjectURL(this.state.img)} />
                          <MDBIcon icon="times" className="mr-2 removeImg" onClick={()=> this.removeImg()}></MDBIcon>
                      </div>
                      }
                    </div>
                    </div>
               </div>
              </MDBCol>
              <MDBCol xl="4" lg="4" md="12" sm="12" className="paddingRight">
                  <div className="chatPeaple">
                      <div style={{borderBottom: '1px solid #e4ecea'}}>
                      <h3>{allStrings.messagesHistory}</h3>
                      </div>
                      <div onScroll={(e)=>{
                      if(e.target.scrollTop+e.target.clientHeight==e.target.scrollHeight){
                        console.log("END OF SCROLL")
                        if(this.peoplePage<this.state.peoplePages){
                          this.peoplePage++
                          this.getPeople(this.peoplePage)
                        } 
                      }
                    }} className="peapleList">
                      {this.props.location.state&&this.state.userExistInPeope==false&&
                      this.chatTab(this.props.location.state.data,-1)}
                      {
                      people2.map((val,index)=>(
                         this.chatTab(val,index)
                       ))
                      }
                      
                      </div>
                  </div>
              </MDBCol>
            </MDBRow>
        </MDBContainer> 
      }
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  setUser,
  getUnseenMessagesCount,

}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(Chat)
);
