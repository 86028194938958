
import React, { useState, useEffect } from 'react';
import './menu.css'
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {setUser} from '../../redux/actions/AuthActions'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

const { Sider } = Layout;
const { SubMenu } = Menu;

export const SiderMenu = ({isRTL,currentUser,setUser,ChangeLanguage}) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
console.log("currentUser",currentUser)
  // Similar to componentDidMount and componentDidUpdate:
  if(isRTL){
    allStrings.setLanguage('ar')
  }else{
    allStrings.setLanguage('en')
  } 

  useEffect(() => {
    if(isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  });
console.log(isRTL)
  const onCollapse = (collapsed) => setCollapsed(collapsed);
  return (
    <Sider 
    style={{
      //overflow: 'auto',
      //height: '100vh',
      //position: collapsed?'fixed':'relative',
      //left: 0,
    }}
    collapsible collapsed={collapsed} onCollapse={onCollapse} >
      {collapsed?
        <div className="logo" >
          <p></p>
        </div>
      :
        <div className="logo" >
          <p>{allStrings.qast}</p>
        </div>
      }
      
      
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
      >
         {currentUser.user.type !="COMPANY" ?
        <Menu.Item key="/Home">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(1).png')}></img>
          <span className="menuTitle">{allStrings.home}</span>
          <Link to="/Home"></Link>
        </Menu.Item>
        :
        <Menu.Item key="/CompanyHome">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(1).png')}></img>
          <span className="menuTitle">{allStrings.home}</span>
          <Link to="/CompanyHome"></Link>
        </Menu.Item>
      }
      {/*category */}
        {currentUser.user.type =="ADMIN" ?
        <Menu.Item key="/Categories">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(2).png')}></img>
          <span className="menuTitle">{allStrings.categories}</span>
          <Link to="/Categories"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="SUB-ADMIN" && currentUser.user.pagesPermission.includes('CATEGORIES')&&
        <Menu.Item key="/Categories">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(2).png')}></img>
          <span className="menuTitle">{allStrings.categories}</span>
          <Link to="/Categories"></Link>
        </Menu.Item>
        }
        {/*stock */}
        {currentUser.user.type =="ADMIN" ?
        <Menu.Item key="/stock">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(3).png')}></img>
          <span className="menuTitle">{allStrings.stock}</span>
          <Link to="/stock"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="COMPANY" ?
        <Menu.Item key="/stock">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(3).png')}></img>
          <span className="menuTitle">{allStrings.stock}</span>
          <Link to="/stock"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="SUB-ADMIN" && currentUser.user.pagesPermission.includes('STOCK')&&
        <Menu.Item key="/stock">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(3).png')}></img>
          <span className="menuTitle">{allStrings.stock}</span>
          <Link to="/stock"></Link>
        </Menu.Item>
        }

        {/*ads */}
        {currentUser.user.type =="ADMIN" ?
         <Menu.Item key="/Ads">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(4).png')}></img>
          <span className="menuTitle">{allStrings.ads}</span>
          <Link to="/Ads"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="SUB-ADMIN" && currentUser.user.pagesPermission.includes('ADS')&&
          <Menu.Item key="/Ads">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(4).png')}></img>
          <span className="menuTitle">{allStrings.ads}</span>
          <Link to="/Ads"></Link>
        </Menu.Item>
        }
        
        
        {/*clients */}
        {currentUser.user.type =="ADMIN" ?
        <Menu.Item key="/Clients">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(5).png')}></img>
          <span className="menuTitle">{allStrings.clients}</span>
          <Link to="/Clients"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="COMPANY" ?
        <Menu.Item key="/Clients">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(5).png')}></img>
          <span className="menuTitle">{allStrings.clients}</span>
          <Link to="/Clients"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="SUB-ADMIN" && currentUser.user.pagesPermission.includes('CLIENTS')&&
        <Menu.Item key="/Clients">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(5).png')}></img>
          <span className="menuTitle">{allStrings.clients}</span>
          <Link to="/Clients"></Link>
        </Menu.Item>
        }
        {/*salesmen */}
        {currentUser.user.type =="ADMIN" ?
        <Menu.Item key="/SalesMan"> 
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(6).png')}></img>
          <span className="menuTitle">{allStrings.salesMen}</span>
          <Link to="/SalesMan"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="COMPANY" && currentUser.user.salesMen == true?
        <Menu.Item key="/SalesMan"> 
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(6).png')}></img>
          <span className="menuTitle">{allStrings.salesMen}</span>
          <Link to="/SalesMan"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="SUB-ADMIN" && currentUser.user.pagesPermission.includes('SALESMEN')&&
        <Menu.Item key="/SalesMan"> 
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(6).png')}></img>
          <span className="menuTitle">{allStrings.salesMen}</span>
          <Link to="/SalesMan"></Link>
        </Menu.Item>
        }
        {/*suppliers */}
        {currentUser.user.type =="ADMIN" ?
        <Menu.Item key="/suppliers">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(7).png')}></img>
          <span className="menuTitle">{allStrings.suppliers}</span>
          <Link to="/suppliers"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="COMPANY" ?
        <Menu.Item key="/suppliers">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(7).png')}></img>
          <span className="menuTitle">{allStrings.suppliers}</span>
          <Link to="/suppliers"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="SUB-ADMIN" && currentUser.user.pagesPermission.includes('SUPPLIERS')&&
        <Menu.Item key="/suppliers">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(7).png')}></img>
          <span className="menuTitle">{allStrings.suppliers}</span>
          <Link to="/suppliers"></Link>
        </Menu.Item>
        }
         {/*expenses */}
         {currentUser.user.type =="ADMIN" ?
        <Menu.Item key="/expenses">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(8).png')}></img>
          <span className="menuTitle">{allStrings.expenses}</span>
          <Link to="/expenses"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="COMPANY" ?
        <Menu.Item key="/expenses">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(8).png')}></img>
          <span className="menuTitle">{allStrings.expenses}</span>
          <Link to="/expenses"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="SUB-ADMIN" && currentUser.user.pagesPermission.includes('EXPENSES')&&
        <Menu.Item key="/expenses">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(8).png')}></img>
          <span className="menuTitle">{allStrings.expenses}</span>
          <Link to="/expenses"></Link>
        </Menu.Item>
        }
        {/*receipts */}
        {currentUser.user.type =="ADMIN" ?
        <Menu.Item key="/receipts">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(9).png')}></img>
          <span className="menuTitle">{allStrings.Receipts}</span>
          <Link to="/receipts"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="COMPANY" ?
        <Menu.Item key="/receipts">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(9).png')}></img>
          <span className="menuTitle">{allStrings.Receipts}</span>
          <Link to="/receipts"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="SUB-ADMIN" && currentUser.user.pagesPermission.includes('RECIEPTS')&&
        <Menu.Item key="/receipts">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(9).png')}></img>
          <span className="menuTitle">{allStrings.Receipts}</span>
          <Link to="/receipts"></Link>
        </Menu.Item>
        }
        {/*collectibles */}
        {currentUser.user.type =="ADMIN" ?
        <Menu.Item key="/collectibles">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(10).png')}></img>
          <span className="menuTitle">{allStrings.collectibles}</span>
          <Link to="/collectibles"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="COMPANY" ?
        <Menu.Item key="/collectibles">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(10).png')}></img>
          <span className="menuTitle">{allStrings.collectibles}</span>
          <Link to="/collectibles"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="SUB-ADMIN" && currentUser.user.pagesPermission.includes('COLLECTIBLES')&&
        <Menu.Item key="/collectibles">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(10).png')}></img>
          <span className="menuTitle">{allStrings.collectibles}</span>
          <Link to="/collectibles"></Link>
        </Menu.Item>
        }
        {/*CALCULATIONS */}
        {currentUser.user.type =="ADMIN" ?
        <Menu.Item key="/calculations">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(11).png')}></img>
          <span className="menuTitle">{allStrings.calculations}</span>
          <Link to="/calculations"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="COMPANY" ?
        <Menu.Item key="/calculations">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(11).png')}></img>
          <span className="menuTitle">{allStrings.calculations}</span>
          <Link to="/calculations"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="SUB-ADMIN" && currentUser.user.pagesPermission.includes('CALCULATIONS')&&
        <Menu.Item key="/calculations">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(11).png')}></img>
          <span className="menuTitle">{allStrings.calculations}</span>
          <Link to="/calculations"></Link>
        </Menu.Item>
        }
        {/*partners */}
        {currentUser.user.type =="ADMIN" ?
        <Menu.Item key="/partners"> 
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(12).png')}></img>
          <span className="menuTitle">{allStrings.partners}</span>
          <Link to="/partners"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="COMPANY" ?
        <Menu.Item key="/partners"> 
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(12).png')}></img>
          <span className="menuTitle">{allStrings.partners}</span>
          <Link to="/partners"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="SUB-ADMIN" && currentUser.user.pagesPermission.includes('PARTNERS')&&
        <Menu.Item key="/partners"> 
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(12).png')}></img>
          <span className="menuTitle">{allStrings.partners}</span>
          <Link to="/partners"></Link>
        </Menu.Item>
        }
        {/*reports */}
        {currentUser.user.type =="ADMIN" ?
        <Menu.Item key="/reports"> 
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(13).png')}></img>
          <span className="menuTitle">{allStrings.reports}</span>
          <Link to="/reports"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="COMPANY" ?
        <Menu.Item key="/reports"> 
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(13).png')}></img>
          <span className="menuTitle">{allStrings.reports}</span>
          <Link to="/reports"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="SUB-ADMIN" && currentUser.user.pagesPermission.includes('REPORTS')&&
        <Menu.Item key="/reports"> 
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(13).png')}></img>
          <span className="menuTitle">{allStrings.reports}</span>
          <Link to="/reports"></Link>
        </Menu.Item>
        }
        
        {/*branches */}
        {currentUser.user.type =="ADMIN" || currentUser.user.type == "COMPANY" ?
        <Menu.Item key="/branches"> 
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(14).png')}></img>
          <span className="menuTitle">{allStrings.branches}</span>
          <Link to="/branches"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="SUB-ADMIN" && currentUser.user.pagesPermission.includes('BRANCHES')&&
        <Menu.Item key="/branches"> 
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(14).png')}></img>
          <span className="menuTitle">{allStrings.branches}</span>
          <Link to="/branches"></Link>
        </Menu.Item>
        }
        {/*companies */}
        {currentUser.user.type =="ADMIN" ?
          <Menu.Item key="/companies"> 
            <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(15).png')}></img>
            <span className="menuTitle">{allStrings.companies}</span>
            <Link to="/companies"></Link>
          </Menu.Item>
        :
        currentUser.user.type =="SUB-ADMIN" && currentUser.user.pagesPermission.includes('COMPANIES')&&
          <Menu.Item key="/companies"> 
            <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(15).png')}></img>
            <span className="menuTitle">{allStrings.companies}</span>
            <Link to="/companies"></Link>
          </Menu.Item>
        }
       {/*orders */}
        {currentUser.user.type =="ADMIN" || currentUser.user.type == "COMPANY" ?
         <Menu.Item key="/orders"> 
         <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(16).png')}></img>
         <span className="menuTitle">{allStrings.orders}</span>
         <Link to="/orders"></Link>
       </Menu.Item>
        :
        currentUser.user.type =="SUB-ADMIN" && currentUser.user.pagesPermission.includes('ORDERS')&&
        <Menu.Item key="/orders"> 
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(16).png')}></img>
          <span className="menuTitle">{allStrings.orders}</span>
          <Link to="/orders"></Link>
        </Menu.Item>
        }
        {/*statistics */}
        {currentUser.user.type =="ADMIN" ?
         <Menu.Item key="/statistic">
         <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(17).png')}></img>
         <span className="menuTitle">{allStrings.statistic}</span>
         <Link to="/statistic"></Link>
       </Menu.Item>
        :
        currentUser.user.type =="SUB-ADMIN" && currentUser.user.pagesPermission.includes('STATISTIC')&&
        <Menu.Item key="/statistic">
        <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(17).png')}></img>
        <span className="menuTitle">{allStrings.statistic}</span>
        <Link to="/statistic"></Link>
      </Menu.Item>
        }

      {/*problems */}
        {currentUser.user.type =="ADMIN" ?
         <Menu.Item key="/problems">
         <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(18).png')}></img>
         <span className="menuTitle">{allStrings.problemsAndSuggestions}</span>
         <Link to="/problems"></Link>
       </Menu.Item>
        :
        currentUser.user.type =="SUB-ADMIN" && currentUser.user.pagesPermission.includes('PROBLEMS')&&
        <Menu.Item key="/problems">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(18).png')}></img>
          <span className="menuTitle">{allStrings.problemsAndSuggestions}</span>
          <Link to="/problems"></Link>
        </Menu.Item>
        }
        {/*settings */}
        <Menu.Item key="/settings">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(19).png')}></img>
          <span className="menuTitle">{allStrings.settings}</span>
          <Link to="/settings"></Link>
        </Menu.Item>
         {/*permissions */}
        {currentUser.user.type =="ADMIN" || currentUser.user.type == "COMPANY" ?
        <Menu.Item key="/users">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(20).png')}></img>
          <span className="menuTitle">{allStrings.usersAndPermission}</span>
          <Link to="/users"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="SUB-ADMIN" && currentUser.user.pagesPermission.includes('PERMISSIONS')&&
        <Menu.Item key="/users">
            <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/(20).png')}></img>
            <span className="menuTitle">{allStrings.usersAndPermission}</span>
            <Link to="/users"></Link>
       </Menu.Item>
        }
         {/*city */}
        {currentUser.user.type =="ADMIN" ?
        <Menu.Item key="/cities">
          <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/location_grey.svg')}></img>
          <span className="menuTitle">{allStrings.cities}</span>
          <Link to="/cities"></Link>
        </Menu.Item>
        :
        currentUser.user.type =="SUB-ADMIN" && currentUser.user.pagesPermission.includes('CITIES')&&
        <Menu.Item key="/cities">
            <img alt=""  style={{marginRight:collapsed?'40px':'15px'}} className="menuIconImg" src={require('../../assets/images/location_grey.svg')}></img>
            <span className="menuTitle">{allStrings.cities}</span>
            <Link to="/cities"></Link>
        </Menu.Item>
        }
      </Menu>
      
    </Sider>
  );
};
const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(SiderMenu)
);


