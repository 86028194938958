import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { BrowserRouter , Switch ,Route} from 'react-router-dom';
import { Provider } from 'react-redux'
import {store,persistor} from './redux/store';
import { PersistGate } from 'redux-persist/integration/react'
import Home from './screens/home/Home'
import CompanyHome from './screens/company home/Home'

import Login from './screens/login/login'
import ForgetPassword from './screens/forgetPassword/forgetPassword'
import Splash from './screens/splash/splash'
import Category from './screens/category/category'
import Stock from './screens/stock/stock'
import StockForm from './screens/stockForm/stockForm'
import Ads from './screens/ads/ads'
import AdsForm from './screens/adsForm/adsForm'
import Clients from './screens/client/client'
import ClientForm from './screens/clientForm/clientForm'
import ClientProducts from './screens/clientProducts/clientProducts'
import SalesMan from './screens/salesMan/salesMan'
import AddSalesMan from './screens/addSalesMan/addSalesMan'
import UpdateSalesMan from './screens/updateSalesMan/updateSalesMan'
import ReceivedProducts from './screens/receivedProducts/receivedProducts'
import Suppliers from './screens/supplier/supplier'
import SupplierForm from './screens/supplierForm/supplierForm'
import Transaction from './screens/transaction/transaction'
import Expenses from './screens/expenses/expenses'
import Statistic from './screens/statistic/statistic'
import Receipts from './screens/receipts/receipts'
import collectibles from './screens/collectibles/collectibles'
import calculations from './screens/calculations/calculations'
import calculationsInfo from './screens/calculations info/calculations info'
import Partner from './screens/partner/partner'
import AddPartner from './screens/addPartner/addPartner'
import updatePartner from './screens/updatePartner/updatePartner';
import branches from './screens/branches/branches';
import settings from './screens/settings/settings';
import company from './screens/company/company';
import orders from './screens/orders/orders';
import problems from './screens/problems/problems';
import CompanyForm from './screens/companyForm/companyForm'
import notifs from './screens/notifs/notifs';
import chat from './screens/chat/chat';
import search from './screens/search/search';
import city from './screens/cities/cities';
import users from './screens/users/users';
import userForm from './screens/userForm/userForm'
import reports from './screens/reports/reports'
import salesMenClients from './screens/salesMenClients/salesMenClients';
import profile from './screens/profile/profile';
import multiSale from './screens/multi sale/multi sale';
import editPremuims from './screens/edit premuims/edit premuims';

ReactDOM.render(
  
<Provider store={store}>
  <PersistGate loading={null} persistor={persistor}>
    <BrowserRouter >
      <Switch>
        <Route path={"/Login"} component={Login}/> 
        <Route exact path={"/"} component={Splash}/> 
        <Route path={"/forgetPassword"} component={ForgetPassword}/> 
        <App path={'/Home'} component={Home}/> 
        <App path={'/multiSale'} component={multiSale}/> 
        <App path={'/editPremuims'} component={editPremuims}/> 
        <App path={'/CompanyHome'} component={CompanyHome}/> 
        <App path={'/Categories'} component={Category}/>
        <App path={'/stock'} component={Stock}/> 
        <App path={'/Ads'} component={Ads}/> 
        <App path={'/stockForm'} component={StockForm}/> 
        <App path={'/adsForm'} component={AdsForm}/> 
        <App path={'/Clients'} component={Clients}/> 
        <App path={'/clientForm'} component={ClientForm}/> 
        <App path={'/clientOrders'} component={ClientProducts}/> 
        <App path={'/SalesMan'} component={SalesMan}/> 
        <App path={'/addSalesMan'} component={AddSalesMan}/>
        <App path={'/updateSalesMan'} component={UpdateSalesMan}/> 
        <App path={'/receivedProducts'} component={ReceivedProducts}/> 
        <App path={'/suppliers'} component={Suppliers}/> 
        <App path={'/supplierForm'} component={SupplierForm}/>
        <App path={'/transaction'} component={Transaction}/>
        <App path={'/expenses'} component={Expenses}/>
        <App path={'/statistic'} component={Statistic}/>
        <App path={'/receipts'} component={Receipts}/>
        <App path={'/collectibles'} component={collectibles}/>
        <App path={'/calculations'} component={calculations}/>
        <App path={'/calculationsInfo'} component={calculationsInfo}/>
        <App path={'/partners'} component={Partner}/>
        <App path={'/addPartner'} component={AddPartner}/>
        <App path={'/updatePartner'} component={updatePartner}/>
        <App path={'/branches'} component={branches}/>
        <App path={'/settings'} component={settings}/>
        <App path={'/companies'} component={company}/>
        <App path={'/orders'} component={orders}/>
        <App path={'/problems'} component={problems}/>
        <App path={'/companyForm'} component={CompanyForm}/>
        <App path={'/notifs'} component={notifs}/>
        <App path={'/chat'} component={chat}/>
        <App path={'/search'} component={search}/>
        <App path={'/cities'} component={city}/>
        <App path={'/users'} component={users}/>
        <App path={'/userForm'} component={userForm}/>
        <App path={'/reports'} component={reports}/>
        <App path={'/salesMenClients'} component={salesMenClients}/>
        <App path={'/profile'} component={profile}/>
      </Switch>
    </BrowserRouter>
  </PersistGate>
</Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
