/* eslint-disable eqeqeq */
import React from "react";
import "./addPartner.css";
import {MDBContainer,MDBRow,MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import {ChangeCompany,ChangeBranch} from '../../redux/actions/CompanyAction'

import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import {hasValue} from '../../validations/validations'
import {message} from 'antd';
import {NavLink} from 'react-router-dom';
import {ChangeLanguage} from '../../redux/actions/LanguageAction'

class addPartner extends React.Component {
  state = {
    requestType:"post",
    img:null,
    cardImg:null,
    cardImgViews:[],
    imgType:'data',
    cardImgType:'data',
    fullname:"",
    password:"",
    cardNum:"",
    anotherPhone:"",
    address:"",
    phone:"",
    notes:"",
    ratio:0,
    money:0,
    count:[],
    totalCapital:0
   
  };

  constructor(props){
    super(props)
    this.getCount()
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    window.scrollTo(0,0)
  }
  getCount = () => {
    //${this.props.currentUser.user.id}
    let url =``
    if(this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN"){
      if(this.props.company == false ){
        url = `${BASE_END_POINT}${this.props.currentUser.user.company[0].id}/companyAccounting`
      }else{
        url = `${BASE_END_POINT}${this.props.company}/companyAccounting`
      }
      
    } else{
      url = `${BASE_END_POINT}${this.props.currentUser.user.id}/companyAccounting`
    }
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log(response.data)
      this.setState({count:response.data,loading:false,totalCapital:response.data.totalCapital})
    })
    .catch(error=>{
      console.log("ALL count ERROR")
      console.log(error.response)
    })
  }
  
  addSalesMan = () => {
    const {fullname,cardImg,password,img,anotherPhone,notes,address,phone,cardNum,money,cardImgType} = this.state
   
    if(hasValue(fullname)&&hasValue(password)&&hasValue(cardNum)&&hasValue(phone)){
        let l = message.loading('Wait..', 2.5)
        var data = new FormData()
        data.append('fullname',fullname)
        if(cardImg !=null){
          if(cardImgType == 'data'){
            for(var i=0 ; i<= cardImg.length-1 ; i++){
               data.append('cardImg',cardImg[i]); 
            }
          }
        }
        
        data.append('cardNum',cardNum)
        data.append('type','PARTNER')
        data.append('password',password); 
        data.append('phone',phone); 
        data.append('money',money); 
        if(this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN" ){
          if(this.props.company == false){
            data.append('company',this.props.currentUser.user.company[0].id); 
          }else{
            data.append('company',this.props.company); 
          }
        } else{
          data.append('company',this.props.currentUser.user.id);
        }
        if(img != null){
          data.append('img',img); 
        }
        if(anotherPhone !==""){
          data.append('anotherPhone',anotherPhone); 
        }
        if(notes !==""){
          data.append('notes',notes); 
        }
        if(address !==""){
          data.append('address',address); 
        }
       
        
        axios.post(`${BASE_END_POINT}addUser`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.push('/partners')
        })
        .catch(error=>{
            console.log(error.response)
            if(error.response.data.errors[0].msg =="cardNum duplicated"){
              l.then(() => message.error(allStrings.duplicatedCardNum, 2.5))
            }
            if(error.response.data.errors[0].msg =="phone duplicated"){
              l.then(() => message.error(allStrings.duplicatedPhone, 2.5))
            }
        })
    }
  }
  
  componentDidMount(){
   
    //this.props.ChangeLanguage(false)
  }
  fileHandler = (event) => {
    this.setState({
      img: event.target.files[0],
      imgType:'data'
    })
    
  }
  removeImg = () => {
    this.setState({
        img:null
    });
  }
  //card img
  fileHandlerCardImg = (event) => {
    let imgs =[];
    for(var i=0 ; i<= event.target.files.length-1 ; i++){
      imgs.push(URL.createObjectURL(event.target.files[i]))
    }
    console.log(imgs)
    this.setState({
      cardImg: event.target.files,
      cardImgViews:imgs,
      recieptImgType:'data'
    })
    
  }
  removeCardImg = (url) => {
    let arr = this.state.cardImgViews;
    let arr2 =  Array.from(this.state.cardImg);
    console.log(arr2)
    var index = arr.indexOf(url);
    if (index !== -1) arr.splice(index, 1);
    if (index !== -1) arr2.splice(index, 1);
    console.log(arr2)
    this.setState({
      cardImgViews:arr,
      cardImg:arr2,
    });
    if(arr2.length == 0){
      this.setState({
        cardImg:null,
      });
    }
  };
  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  changeMoney = (event) => {
    let ratio = (event.target.value * 100) / this.state.totalCapital;
    console.log(this.state.totalCapital);
    console.log(ratio);
    this.setState({ [event.target.name]: event.target.value,ratio:ratio });
    
  }
  addSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.addSalesMan()
  };

  
  onChangeDate = (date, dateString) => {
    console.log(date, dateString)
    this.setState({start:dateString})
  }

  render() {
    return (
      <div className="addPartner">
        <MDBContainer>
          <MDBRow>
            <div className="top" style={{width:'100%',textAlign:'center'}}>
              <NavLink to="/partners">
                <MDBIcon icon="times" className="closeIcon"/>
              </NavLink>
              <span className="stockTitle">{this.state.requestType==="post"?allStrings.completeData:allStrings.edit}</span>
            </div>
          </MDBRow>
          <form
              className="needs-validation"
              onSubmit={this.addSubmitHandler}
              noValidate
              style={{marginLeft:'2rem'}}
          >
          <MDBRow>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="9" sm="9">
                  <input name="fullname" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx5" required
                    className="form-control" placeholder={allStrings.enterName} 
                    style={{direction:this.props.isRTL?'rtl':'ltr',marginBottom: '10px'}}/>
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisterNameEx5" className="grey-text formLabel">
                  {allStrings.name}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="9" sm="9">
                  <input name="password" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx5" required
                    className="form-control" placeholder={allStrings.enterPassword} 
                    style={{direction:this.props.isRTL?'rtl':'ltr',marginBottom: '10px'}} />
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisterNameEx5" className="grey-text formLabel">
                  {allStrings.password}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="9" sm="9">
                {this.state.img == null&&
                  <div className="smallUploadContainer">
                    <img alt=""  name="img" className="smallUploderImg" src={require('../../assets/images/Path 343.png')}></img>
                    <input 
                      onChange={this.fileHandler}
                      type="file"
                      id="defaultFormRegisterNameEx6"
                      required className="smallUploader" />
                    <MDBBtn color="primary" size="sm" className="smallUploaderBtn">{allStrings.chooseFile}</MDBBtn>
                    <p style={{fontSize: '14px',marginTop: '3px 15px',float:'right',paddingTop: '4px'}}  className="grey-text">
                      {allStrings.dropHere}
                    </p>
                  </div>
                }
                  <div className="previewRaduis">
                  {this.state.img!= null&&
                  <div style={{display:"inline-block",float:'right',marginBottom:'1rem'}}>
                      <img alt=""    src={this.state.imgType=="data"?URL.createObjectURL(this.state.img):this.state.img} />
                      <MDBIcon icon="times" className="mr-2 removeImg" onClick={()=> this.removeImg()}></MDBIcon>
                  </div>
                  }
                </div>
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisterNameEx7" className="grey-text formLabel">
                  {allStrings.profileImg}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                    <MDBCol md="9" sm="9">
                    {this.state.cardImg == null&&
                      <div className="smallUploadContainer">
                        <img alt=""  name="img" className="smallUploderImg" src={require('../../assets/images/Path 343.png')}></img>
                        <input 
                          multiple
                          onChange={this.fileHandlerCardImg}
                          type="file"
                          id="defaultFormRegisterNameE03"
                           className="smallUploader" />
                        <MDBBtn color="primary" size="sm" className="smallUploaderBtn">{allStrings.chooseFile}</MDBBtn>
                        <p style={{fontSize: '14px',marginTop: '3px 15px',float:'right',paddingTop: '4px'}}  className="grey-text">
                          {allStrings.dropHere}
                        </p>
                      </div>
                    }
                     
                      {this.state.cardImg!= null&&
                      <div className="previewRaduis">
                      
                      {this.state.cardImgViews.map((val) => (
                       
                      <div style={{display:"inline-block",float:'right',marginBottom:'1rem'}}>
                          <img alt=""    src={this.state.cardImgType=="data"?val:val} />
                          <MDBIcon icon="times" className="mr-2 removeImg" onClick={()=> this.removeCardImg(val)}></MDBIcon>
                      </div>
                      ))}
                    </div>
                    }
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx003" className="grey-text formLabel">
                      {allStrings.cardImg}
                      </label>
                    </MDBCol>
                  </MDBRow>
              
              <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                <MDBCol md="9" sm="9">
                  <input name="cardNum" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx12" 
                    className="form-control" placeholder={allStrings.enterCardNum} style={{direction:this.props.isRTL?'rtl':'ltr'}} required/>
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                  {allStrings.cardNum}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                <MDBCol md="9" sm="9">
                  <input name="address" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx13" 
                    className="form-control" placeholder={allStrings.enterAddress} style={{direction:this.props.isRTL?'rtl':'ltr'}}  />
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisterNameEx13" className="grey-text formLabel">
                  {allStrings.address}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                <MDBCol md="9" sm="9">
                  <input name="phone" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx15" 
                    className="form-control" placeholder={allStrings.enterPhone} style={{direction:this.props.isRTL?'rtl':'ltr'}} required />
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisterNameEx15" className="grey-text formLabel">
                  {allStrings.phone}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                <MDBCol md="9" sm="9">
                  <input name="anotherPhone" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx16" 
                    className="form-control" placeholder={allStrings.EnterAnotherPhone} style={{direction:this.props.isRTL?'rtl':'ltr'}}/>
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisterNameEx16" className="grey-text formLabel">
                  {allStrings.anotherPhone}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                <MDBCol md="9" sm="9">
                  <input name="money" onChange={this.changeMoney} type="number" min={0} id="defaultFormRegisterNameEx17" 
                    className="form-control" placeholder={allStrings.EnterPrice} style={{direction:this.props.isRTL?'rtl':'ltr'}} />
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisterNameEx17" className="grey-text formLabel">
                  {allStrings.receiveMoney}
                  </label> 
                </MDBCol>
              </MDBRow>
              <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                <MDBCol md="9" sm="9">
                  <input disabled name="ration" type="number" min={0} id="defaultFormRegisterNam17" 
                    value = { Math.floor(this.state.ratio * 100)/100 }
                    className="form-control"  style={{color:'red',border:'none',direction:this.props.isRTL?'rtl':'ltr'}} defaultValue ={this.state.ratio}/>
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisterNameEx17" className="grey-text formLabel">
                  {allStrings.ratio}
                  </label> 
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBRow>
          <MDBRow>
            <MDBBtn type="submit" 
            style={{ margin: '1rem',width:'150px',padding:'9px',height:'35px' }}
                className="btn z-depth-1a "
                color="primary"
              >
                {allStrings.add}
            </MDBBtn>
          </MDBRow>
          </form>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = state => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
    branch: state.company.branch,
    company: state.company.company,
  })
  
  const mapDispatchToProps = {
    setUser,
    ChangeLanguage,
    ChangeCompany,
    ChangeBranch,
  }
  
  export default  withRouter(
    connect(mapToStateProps,mapDispatchToProps)(addPartner)
  );
  
