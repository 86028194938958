/* eslint-disable eqeqeq */
import React from "react";
import "./companyForm.css";
import {MDBContainer,MDBRow,MDBCol,MDBBtn,MDBIcon,MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import {hasValue} from '../../validations/validations'
import {message,TreeSelect,Radio } from 'antd';
import {NavLink} from 'react-router-dom';
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import moment from 'moment'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

const {TreeNode} = TreeSelect

class CompanyForm extends React.Component {
  state = {
    fullname: this.props.location.state?this.props.location.state.data.fullname:"",
    companyOwner:this.props.location.state?this.props.location.state.data.companyOwner:"",
    password: this.props.location.state?this.props.location.state.data.password:"",
    phone: this.props.location.state?this.props.location.state.data.phone:"",
    anotherPhone: this.props.location.state?this.props.location.state.data.anotherPhone:"",
    address: this.props.location.state?this.props.location.state.data.address:"",
    notes: this.props.location.state?this.props.location.state.data.notes:"",
    cardNum: this.props.location.state?this.props.location.state.data.cardNum:"",
    contactDetails: this.props.location.state?this.props.location.state.data.contactDetails:"",
    companySpecialization: this.props.location.state?this.props.location.state.data.companySpecialization:"",
    
    city:this.props.location.state?this.props.location.state.data.city.id:[],
    profileImg:this.props.location.state?this.props.location.state.data.img !=""?this.props.location.state.data.img :null:null,
    cardImg:this.props.location.state?this.props.location.state.data.cardImg.length > 0?this.props.location.state.data.cardImg:null:null,
    cardImgViews:this.props.location.state?this.props.location.state.data.cardImg:[],
    profileImgType:this.props.location.state?'url':'data',
    cardImgType:this.props.location.state?'url':'data',
    Company:this.props.location.state?this.props.location.state.data:[],
    remainToPay:this.props.location.state?this.props.location.state.data?this.props.location.state.data.remainToPay?this.props.location.state.data.remainToPay:"":"":"",
    requestType:this.props.location.state?"put":"post",
    cities:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    contractType:this.props.location.state?this.props.location.state.data.contractType:"RATIO",
    contractPeriod:this.props.location.state?this.props.location.state.data.contractPeriod:0,
    contractPeriodType:this.props.location.state?this.props.location.state.data.contractPeriodType:'DAILY',
    contractCost:this.props.location.state?this.props.location.state.data.contractCost:0,
    sendMsg:this.props.location.state?this.props.location.state.data.sendMessage:true,
    addBranch:this.props.location.state?this.props.location.state.data.addBranch:true,
    salesMen:this.props.location.state?this.props.location.state.data.salesMen:true,
    appVisible:this.props.location.state?this.props.location.state.data.availableOnApp:true,
    sendNotifs:this.props.location.state?this.props.location.state.data.sendNotif:true,
    appProducts:this.props.location.state?this.props.location.state.data.productsOnApp:true,
    
    premuims:this.props.location.state?this.props.location.state.data.buyType!="CASH"?true:false:false,
    cash:this.props.location.state?this.props.location.state.data.buyType!="INSTALLMENT"?true:false:false,
    messageNum:this.props.location.state?this.props.location.state.data.limitMessages?this.props.location.state.data.limitMessages:0:0,
    recieptType:this.props.location.state?this.props.location.state.data.recieptType:"TYPE1",
    products:[],
    probuctsCount:0,
    modal: false,
    receiveMony:0,
    photoIndex: 0,
    isOpen1: false,
    isOpen2: false,
  };

  constructor(props){
    super(props)
    console.log("isr   ",this.props.history)
    this.getCities()
    if(this.state.requestType == "put"){
      this.getProducts()
    }
    if(this.state.contractType =="RATIO" && this.state.contractPeriod ==1000 && this.state.contractCost ==0){
        this.setState({contractPeriod:0,contractCost:0,contractType:1});
    }
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    window.scrollTo(0,0)
  }
  
  getProducts = () => {
    axios.get(`${BASE_END_POINT}products?page=1&company=${this.state.Company.id}`,{
      headers: {
      'Authorization':`Bearer ${this.props.currentUser.token}`
      },
      })
    .then(response=>{
      console.log(response.data)
      this.setState({products:response.data.data,probuctsCount:response.data.totalCount})
    })
    .catch(error=>{
      console.log("ALL products ERROR")
      console.log(error.response)
    })
  }
  getCities = () => {
    axios.get(`${BASE_END_POINT}cities`)
    .then(response=>{
      console.log(response.data)
      this.setState({cities:response.data})
    })
    .catch(error=>{
      console.log("ALL cities ERROR")
      console.log(error.response)
    })
  }
  componentDidMount(){
    if(this.state.contractType =="RATIO" && this.state.contractPeriod =="1000" && this.state.contractCost =="0"){
      this.setState({contractPeriod:0,contractCost:0,contractType:1});
    }
    this.getCities()
    
  }
  addCompany = () => {
    const {fullname,password,phone,anotherPhone,profileImg,messageNum,companyOwner,
      companySpecialization,contactDetails,
      cash,premuims,recieptType,addBranch,sendNotifs,sendMsg,salesMen,appProducts,appVisible,
      cardNum,address,notes,city,cardImg,contractCost,contractPeriod,contractPeriodType,contractType} = this.state
   console.log(city)
    if(hasValue(fullname)&&hasValue(password)&&hasValue(phone)&&hasValue(cardNum)
      &&city!=[]){
        let l = message.loading('Wait..', 2.5)
        var data = new FormData()
        data.append('fullname',fullname)
        if(cardImg != null){
            for(var i=0 ; i<= cardImg.length-1 ; i++){
               data.append('cardImg',cardImg[i]); 
            }
          
        }
       
        data.append('cardNum',cardNum)
        data.append('city',city)
        data.append('type','COMPANY')
        data.append('password',password); 
        data.append('phone',phone); 
        if(premuims ==true && cash ==false){
          data.append('buyType',"INSTALLMENT"); 
        }
        if(cash ==true && premuims ==false){
          data.append('buyType',"CASH"); 
        }
        if(cash ==true && premuims == true){
          data.append('buyType',"BOTH"); 
        }
        if(cash ==false && premuims == false){
          data.append('buyType',"BOTH"); 
        }
        data.append('recieptType',recieptType); 
        data.append('addBranch',addBranch); 
        data.append('sendNotif',sendNotifs); 
        data.append('sendMessage',sendMsg); 
        data.append('salesMen',salesMen); 
        data.append('availableOnApp',appVisible); 
        data.append('productsOnApp',appProducts);
        data.append('limitMessages',messageNum); 
        //contract 
        
        if(contractType == 1){
          console.log("mm")
          data.append('contractType','RATIO'); 
          data.append('contractPeriod','1000'); 
          data.append('contractPeriodType','YEARLY'); 
          data.append('contractCost',0);
        }else{
          data.append('contractCost',contractCost); 
          data.append('contractPeriod',contractPeriod); 
          data.append('contractPeriodType',contractPeriodType); 
          data.append('contractType',contractType); 
        }
        if(profileImg != null){
          data.append('img',profileImg); 
        }
        if(companyOwner !=="" && companyOwner != undefined){
          data.append('companyOwner',companyOwner); 
        }
        if(anotherPhone !=="" && anotherPhone != undefined){
          data.append('anotherPhone',anotherPhone); 
        }
        if(notes !=="" && notes != undefined){
          data.append('notes',notes); 
        }
        if(address !=="" && address != undefined){
          data.append('address',address); 
        }
        if(contactDetails !=="" && contactDetails != undefined){
          data.append('contactDetails',contactDetails); 
        }
        if(companySpecialization !=="" && companySpecialization != undefined){
          data.append('companySpecialization',companySpecialization); 
        }
        console.log(Array.from(data))
        
        axios.post(`${BASE_END_POINT}addUser`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.push('/companies')
        })
        .catch(error=>{
            console.log(error.response)
            if(error.response.data.errors[0].msg =="cardNum duplicated"){
              l.then(() => message.error(allStrings.duplicatedCardNum, 2.5))
            }
            if(error.response.data.errors[0].msg =="phone duplicated"){
              l.then(() => message.error(allStrings.duplicatedPhone, 2.5))
            }
        })
    }
  }
  updateCompany = () => {
    const {fullname,password,phone,anotherPhone,profileImg,messageNum,companyOwner,
      contactDetails,companySpecialization,cash,premuims,recieptType,addBranch,sendNotifs,sendMsg,salesMen,appProducts,appVisible,
    cardNum,address,notes,city,cardImg,contractCost,contractPeriod,contractPeriodType,contractType} = this.state
    if(hasValue(fullname)&&hasValue(phone)&&hasValue(cardNum)){
        let l = message.loading('Wait..', 2.5)
        var data = new FormData()
        data.append('fullname',fullname)
        if(cardImg != null){
          //if(cardImgType == 'data'){
            for(var i=0 ; i<= cardImg.length-1 ; i++){
               data.append('cardImg',cardImg[i]); 
            }
          //}
        }
        console.log(cash,premuims)
        data.append('cardNum',cardNum)
        data.append('city',city)
        data.append('type','COMPANY')
        if(contactDetails !=="" && contactDetails != undefined){
          data.append('contactDetails',contactDetails); 
        }
        if(companySpecialization !=="" && companySpecialization != undefined){
          data.append('companySpecialization',companySpecialization); 
        }
        if(password !="" && password != undefined){
          data.append('password',password); 
        }
        if(companyOwner !=="" && companyOwner != undefined){
          data.append('companyOwner',companyOwner); 
        }
        
        data.append('phone',phone); 
        
        if(premuims ==true && cash ==false){
          data.append('buyType',"INSTALLMENT"); 
        }
        if(cash ==true && premuims ==false){
          data.append('buyType',"CASH"); 
        }
        if(cash ==true && premuims == true){
          data.append('buyType',"BOTH"); 
        }
        if(cash ==false && premuims == false){
          data.append('buyType',"BOTH"); 
        }
        data.append('recieptType',recieptType); 
        data.append('addBranch',addBranch); 
        data.append('sendNotif',sendNotifs); 
        data.append('sendMessage',sendMsg); 
        data.append('salesMen',salesMen); 
        data.append('availableOnApp',appVisible); 
        data.append('productsOnApp',appProducts);
        data.append('limitMessages',messageNum); 
        //contract 
        
        if(contractType == 1){
          console.log("mm")
          data.append('contractType','RATIO'); 
          data.append('contractPeriod','1000'); 
          data.append('contractPeriodType','YEARLY'); 
          data.append('contractCost',0);
        }else{
          data.append('contractCost',contractCost); 
          data.append('contractPeriod',contractPeriod); 
          data.append('contractPeriodType',contractPeriodType); 
          data.append('contractType',contractType); 
        }
        //if(this.state.profileImgType == 'data'){
          if(profileImg != null){
            data.append('img',profileImg); 
          }
        //}
        
         
        if(anotherPhone !=="" && anotherPhone != undefined){
          data.append('anotherPhone',anotherPhone); 
        }
        if(notes !=="" && notes != undefined){
          data.append('notes',notes); 
        }
        if(address !=="" && address != undefined){
          data.append('address',address); 
        }
       
        
        console.log(Array.from(data))
        
        axios.put(`${BASE_END_POINT}user/${this.state.Company.id}/updateInfo`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.push('/companies')
        })
        .catch(error=>{
            console.log(error.response)
            if(error.response.data.errors[0].msg =="cardNum duplicated"){
              l.then(() => message.error(allStrings.duplicatedCardNum, 2.5))
            }
            if(error.response.data.errors[0].msg =="phone duplicated"){
              l.then(() => message.error(allStrings.duplicatedPhone, 2.5))
            }
        })
    }
  }
  
  fileHandlerProfileImg = (event) => {
    this.setState({
      profileImg: event.target.files[0],
      profileImgType:'data'
    })
    
  }
  removeProfileImg = () => {
    this.setState({
      profileImg:null
    });
  }

  //card img
  fileHandlerCardImg = (event) => {
    let imgs =[];
    for(var i=0 ; i<= event.target.files.length-1 ; i++){
      imgs.push(URL.createObjectURL(event.target.files[i]))
    }
    console.log(imgs)
    this.setState({
      cardImg: event.target.files,
      cardImgViews:imgs,
      cardImgType:'data'
    })
    
  }
  removeCardImg = (url) => {
    let arr = this.state.cardImgViews;
    let arr2 =  Array.from(this.state.cardImg);
    console.log(arr2)
    var index = arr.indexOf(url);
    if (index !== -1) arr.splice(index, 1);
    if (index !== -1) arr2.splice(index, 1);
    console.log(arr2)
    this.setState({
      cardImgViews:arr,
      cardImg:arr2,
    });
    if(arr2.length == 0){
      this.setState({
        cardImg:null,
      });
    }
  };
  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  CompanySubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.addCompany()
  };
  updateSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.updateCompany()

  };
  
  onChangeDate = (date, dateString) => {
    console.log(date, dateString)
    this.setState({start:dateString})
  }
  onChange = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      contractType: e.target.value,
    });
  };
  sendMsgOn =()=>{
    this.setState({
      sendMsg: true,
    })
  }
  sendMsgOff =()=>{
    this.setState({
      sendMsg: false,
    })
  }
  addBranchOn =()=>{
    this.setState({
      addBranch: true,
    })
  }
  addBranchOff =()=>{
    this.setState({
      addBranch: false,
    })
  }
  salesMenOn =()=>{
    this.setState({
      salesMen: true,
    })
  }
  salesMenOff =()=>{
    this.setState({
      salesMen: false,
    })
  }
  appVisibleOn =()=>{
    this.setState({
      appVisible: true,
    })
  }
  appVisibleOff =()=>{
    this.setState({
      appVisible: false,
    })
  }
  sendNotifsOn =()=>{
    this.setState({
      sendNotifs: true,
    })
  }
  sendNotifsOff =()=>{
    this.setState({
      sendNotifs: false,
    })
  }
  appProductsOn =()=>{
    this.setState({
      appProducts: true,
    })
  }
  appProductsOff =()=>{
    this.setState({
      appProducts: false,
    })
  }
  premuimsOn =()=>{
    this.setState({
      premuims: true,
    })
  }
  premuimsOff =()=>{
    this.setState({
      premuims: false,
    })
  }
  cashOn =()=>{
    this.setState({
      cash: true,
    })
  }
  cashOff =()=>{
    this.setState({
      cash: false,
    })
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  }
  receiveMoney = () => {
    
        let l = message.loading('Wait..', 2.5)
        axios.put(`${BASE_END_POINT}${this.state.Company.id}/payDebt`,{} ,{
        headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          //this.toggle()
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error('Error', 2.5))
        })
    
  }
  changeHandlerReceiveMoney = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  receiveMoneySubmit = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.receiveMoney()
  };
  modal = () =>(
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
      <MDBModalHeader toggle={this.toggle}>{allStrings.completeData}</MDBModalHeader>
      <MDBModalBody>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
              <form style={{marginLeft: '15%'}}
                className="needs-validation"
                onSubmit={this.receiveMoneySubmit}
                noValidate
              >
                <MDBContainer>
                  <MDBRow>
                    <MDBCol md="9">
                      <textarea name="receiveMoney" onChange={this.changeHandlerReceiveMoney} type="number" min={0} id="defaultFormRegisterNamx1" required
                        placeholder={allStrings.cost} className="form-control" style={{direction:this.props.isRTL?'rtl':'ltr'}} />
                    </MDBCol>
                    <MDBCol md="3">
                      <label  htmlFor="defaultFmRegisterNameEx1" className="grey-text formLabel">
                      {allStrings.cost}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBBtn type="submit" style={{ margin: '3rem 1rem ',width:'170px',padding:'11px 20px',height:'35px', }}
                        className="btn z-depth-1a "
                        color="primary"
                      >
                        {allStrings.receiveMoney}
                    </MDBBtn>
                  </MDBRow>
                </MDBContainer>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
   );


  render() {
    console.log("contractPeriod",this.state.contractPeriod)
    let {cardImg,profileImg, photoIndex, isOpen1,isOpen2} = this.state;
     profileImg = [profileImg]
    return (
      
      <div className="CompanyForm">
        <MDBContainer>
          {this.modal()}
          <MDBRow>
            <div className="top" style={{width:'100%',textAlign:this.state.requestType==="post" ?'center':'right'}}>
              <NavLink to="/companies">
                <MDBIcon icon="times" className="closeIcon"/>
              </NavLink>
              {this.state.requestType==="post" ?
              <span className="stockTitle">{allStrings.completeData}</span>
              :
              <span>
              <span className="newTitle" style={{margin: '1rem 0rem 1rem .5rem'}}>{this.state.Company.lastSeen?moment().calendar(this.state.Company.lastSeen):moment().calendar()}</span>
                <span className="newTitle">
                  {allStrings.lastSeen}
                </span>
              </span>
              }
              
            </div>
          </MDBRow>
          <form
              className="needs-validation"
              onSubmit={this.state.requestType==="post"?this.CompanySubmitHandler:this.updateSubmitHandler}
              noValidate
              style={{marginLeft:'2rem'}}
          >
          <MDBRow>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="6">
                  <MDBRow>
                    <MDBCol md="12">
                      <label  htmlFor="defaultFormRegisterNameEx2"
                      style={{
                        float:'right',
                        fontWeight: '800',
                        color: '#43425D',
                        fontSize: '18px',
                        marginBottom: '2rem',
                        marginRight:'1rem'
                      }}>
                      {allStrings.appRatio}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <Radio.Group onChange={this.onChange} value={this.state.contractType}>
                  <MDBRow>
                    <MDBCol md="12" style={{textAlign: 'right',paddingRight:'2.5rem'}}>
                      <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                        {allStrings.notExist}
                      </label>
                      <Radio value={1}></Radio> 
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="3" sm="6" style={{padding:'3px'}}>
                      <input name="contractPeriod" onChange={this.changeHandler}type="number" min={0} id="defaultFormRegisterNameExfd12" 
                        className="form-control" placeholder={allStrings.duration} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.Company.contractType !="RATIO"?this.state.contractPeriod:''} />
                    </MDBCol>
                    <MDBCol md="3" sm="6" style={{padding:'3px'}}>
                      <TreeSelect
                        value={this.state.contractPeriodType}
                        showSearch={false} 
                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left'}} 
                        treeCheckable = {false}         
                        treeNodeFilterProp="title"                      
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={allStrings.chooseDurationType}
                        onChange={(value) => {
                          console.log(value)
                          this.setState({contractPeriodType:value});
                        }} 
                      
                      >
                        <TreeNode value="DAILY" title={allStrings.Daily} />
                        <TreeNode value="MONTHLY" title={allStrings.monthly} />
                        <TreeNode value="YEARLY" title={allStrings.yearly} />
                      </TreeSelect>
                    </MDBCol>
                    <MDBCol md="3" sm="6" style={{padding:'3px'}}>
                      <input name="contractCost" onChange={this.changeHandler} type="number" min={0} id="defaultFormRegisterNamdeEx12" 
                        className="form-control" placeholder={allStrings.thePrice} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.Company.contractType!="RATIO"?this.state.contractCost:''} />
                    </MDBCol>
                    <MDBCol md="3" sm="6" style={{paddingLeft:'0px',textAlign: 'center',padding:'3px'}}>
                        <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                        {allStrings.thePrice}
                        </label>
                      <Radio value="NUMBER">
                      </Radio>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    
                    <MDBCol md="3" sm="6" style={{padding:'3px'}}>
                      <input name="contractPeriod" onChange={this.changeHandler} type="number" min={0} id="defaultFormRegistferNameEx12" 
                        className="form-control" placeholder={allStrings.duration} style={{direction:this.props.isRTL?'rtl':'ltr'}} value={this.state.Company.contractType=="RATIO"?this.state.contractPeriod:''} />
                    </MDBCol>
                    <MDBCol md="3" sm="6" style={{padding:'3px'}}>
                      <TreeSelect
                        value={this.state.contractPeriodType}
                        showSearch={false} 
                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left'}} 
                        treeCheckable = {false}         
                        treeNodeFilterProp="title"                      
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={allStrings.chooseDurationType}
                        onChange={(value) => {
                          console.log(value)
                          this.setState({contractPeriodType:value});
                        }} 
                      
                      >
                        <TreeNode value="DAILY" title={allStrings.Daily} />
                        <TreeNode value="MONTHLY" title={allStrings.monthly} />
                        <TreeNode value="YEARLY" title={allStrings.yearly} />
                      </TreeSelect>
                    </MDBCol>
                    <MDBCol md="3" sm="6" style={{padding:'3px'}}>
                      <input name="contractCost" onChange={this.changeHandler} type="number" min={0} id="defaultFormRegisterfdNameEx12" 
                        className="form-control" placeholder={allStrings.ratio} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.Company.contractType =="RATIO"?this.state.contractCost:''} />
                    </MDBCol>
                    <MDBCol md="3" sm="6" style={{paddingLeft:'0px',textAlign: 'center',padding:'3px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                        {allStrings.ratio}
                      </label>
                      <Radio value="RATIO"></Radio>
                    </MDBCol>
                   
                  </MDBRow>
                  </Radio.Group>
                  <br></br>
                  <br></br>
                  <MDBRow>
                    <MDBCol md="12">
                      <label  htmlFor="defaultFormRegisterNameEx2"
                      style={{
                        float:'right',
                        fontWeight: '800',
                        color: '#43425D',
                        fontSize: '18px',
                        marginBottom: '2rem',
                        marginRight:'1rem'
                      }}>
                      {allStrings.permissions}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.sendMsgOff}
                            style={{background:this.state.sendMsg?'transparent':"mediumseagreen"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.sendMsg?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.sendMsgOn}
                             style={{background:this.state.sendMsg?"mediumseagreen":'transparent'}}>
                                <span style={{
                                  color:this.state.sendMsg?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.sendMessages}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.addBranchOff}
                            style={{background:this.state.addBranch==false?'mediumseagreen':"transparent"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.addBranch?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.addBranchOn}
                             style={{background:this.state.addBranch==true?'mediumseagreen':"transparent"}}>
                                <span style={{
                                  color:this.state.addBranch?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.addBranchs}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.salesMenOff}
                            style={{background:this.state.salesMen?'transparent':"mediumseagreen"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.salesMen?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.salesMenOn}
                             style={{background:this.state.salesMen?"mediumseagreen":'transparent'}}>
                                <span style={{
                                  color:this.state.salesMen?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.salesMen}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.appVisibleOff}
                            style={{background:this.state.appVisible?'transparent':"mediumseagreen"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.appVisible?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.appVisibleOn}
                             style={{background:this.state.appVisible?"mediumseagreen":'transparent'}}>
                                <span style={{
                                  color:this.state.appVisible?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.appVisible}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.sendNotifsOff}
                            style={{background:this.state.sendNotifs?'transparent':"mediumseagreen"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.sendNotifs?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.sendNotifsOn}
                             style={{background:this.state.sendNotifs?"mediumseagreen":'transparent'}}>
                                <span style={{
                                  color:this.state.sendNotifs?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.sendNotifs}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.appProductsOff}
                            style={{background:this.state.appProducts?'transparent':"mediumseagreen"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.appProducts?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.appProductsOn}
                             style={{background:this.state.appProducts?"mediumseagreen":'transparent'}}>
                                <span style={{
                                  color:this.state.appProducts?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.appProducts}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <input name="messageNum" onChange={this.changeHandler}type="number" min={0} id="defaultFormRegiserNameExfd12" 
                             className="form-control" placeholder={allStrings.messageNum} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.messageNum} />
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.messageNum}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.premuimsOff}
                            style={{background:this.state.premuims?'transparent':"mediumseagreen"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.premuims?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.premuimsOn}
                             style={{background:this.state.premuims?"mediumseagreen":'transparent'}}>
                                <span style={{
                                  color:this.state.premuims?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.installment}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <TreeSelect
                            value={this.state.recieptType}
                            showSearch={false} 
                            style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left'}} 
                            treeCheckable = {false}         
                            treeNodeFilterProp="title"                      
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            onChange={(value) => {
                              console.log(value)
                              this.setState({recieptType:value});
                            }} >
                            <TreeNode value="TYPE1" title={allStrings.type1} />
                            <TreeNode value="TYPE2" title={allStrings.type2} />
                          </TreeSelect>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.receiptsType}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBRow>
                        <MDBCol md="6">
                          <div className="checking">
                            <div className="checkingLeft" onClick={this.cashOff}
                            style={{background:this.state.cash?'transparent':"mediumseagreen"}}>
                               <span style={{
                                 padding: '7px 25px',
                                 display: 'inline-block',
                                 color:this.state.cash?'crimson':'#fff',
                                 fontWeight: '600'
                               }}>{allStrings.no}</span>
                            </div>
                            <div className="checkingRight" onClick={this.cashOn}
                             style={{background:this.state.cash?"mediumseagreen":'transparent'}}>
                                <span style={{
                                  color:this.state.cash?'#fff':'crimson',
                                  padding:' 6px 15px',
                                  display: 'inline-block',
                                  fontWeight: '600'}}>{allStrings.yes}</span>
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol md="6">
                          <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                            {allStrings.cash}
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <br></br>
                  <MDBRow>
                    <MDBCol md="12">
                    <div style={{width:'94%',marginTop: '2.5rem'}}>
                    <label  htmlFor="defaultFormRegisterNameE"
                      style={{
                        float:'right',
                        fontWeight: '800',
                        color: '#43425D',
                        fontSize: '18px',
                      }}>
                      {allStrings.products}
                      </label>
                      <label  htmlFor="defaultFormRegisterNameE"
                      style={{
                        float:'left',
                        fontWeight: '400',
                        color: '#43425D',
                        fontSize: '16px',
                      }}>
                      {this.state.probuctsCount}
                      </label>
                    </div>
                    </MDBCol>
                  </MDBRow>
                  {this.state.requestType == "put" &&
                    <MDBRow>
                      <MDBCol md = "3">
                      <div style={{paddingTop:'5px',cursor:'pointer'}}  onClick={() => this.props.history.push('/stock',{data:this.state.Company.id})}>
                        <span style={{
                          fontSize: '17px',
                          color: '#478DFF',
                          fontWeight: '600',
                          margin: '5px'
                        }}>{allStrings.showD}</span>
                        <img alt=""  width="20px" src={require('../../assets/images/icons8_external_link_480px_2.png')}></img>
                      </div>
                      </MDBCol>
                      <MDBCol md="9" style={{paddingRight:'30px'}}  >
                      {this.state.products.map((val) => (
                        <div style={{display:'inline-block',margin:'2px',float:'right'}}>
                          <img alt=""  className="clientProduct" src={val.mainImg}></img>
                          <span className="clientProductCount">{val.quantity}</span>
                        </div>
                      ))}
                      </MDBCol>
                    </MDBRow>
                  }
                  
                  <MDBRow>
                    <MDBBtn type="submit" 
                    style={{ margin: '1rem',width:'150px',padding:'9px',height:'35px' }}
                        className="btn z-depth-1a "
                        color="primary"
                      >
                        {this.state.requestType=="post"?allStrings.add:allStrings.edit}
                    </MDBBtn>
                  </MDBRow>
                </MDBCol>
                <MDBCol md="6" style={{borderLeft:'1px solid'}}>
                  <MDBRow>
                    <MDBCol md="12">
                      <label  htmlFor="defaultFormRegisterNameEx2"
                      style={{
                        float:'right',
                        fontWeight: '800',
                        color: '#43425D',
                        fontSize: '18px',
                        marginBottom: '2rem',
                        marginRight:'4rem'
                      }}>
                      {allStrings.company}
                      </label>
                    </MDBCol>
                  </MDBRow>
    
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                      <input name="fullname" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx5" required
                        className="form-control" placeholder={allStrings.enterName} 
                        style={{direction:this.props.isRTL?'rtl':'ltr',marginBottom: '10px'}} defaultValue={this.state.fullname} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx5" className="grey-text formLabel">
                      {allStrings.name}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                      <textarea name="contactDetails" onChange={this.changeHandler} type="text" id="defaultFormRegisterNam5" required
                        className="form-control" placeholder={allStrings.contactDetails} 
                        style={{direction:this.props.isRTL?'rtl':'ltr',marginBottom: '10px'}} defaultValue={this.state.contactDetails} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNam5" className="grey-text formLabel">
                      {allStrings.contactDetails}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                      <textarea name="companySpecialization" onChange={this.changeHandler} type="text" id="defaultFormRegisterNam5" required
                        className="form-control" placeholder={allStrings.companySpecialization} 
                        style={{direction:this.props.isRTL?'rtl':'ltr',marginBottom: '10px'}} defaultValue={this.state.companySpecialization} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNam5" className="grey-text formLabel">
                      {allStrings.companySpecialization}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                      <input name="companyOwner" onChange={this.changeHandler} type="text" id="defaultFormRegisterNam5" required
                        className="form-control" placeholder={allStrings.enterCompanyOwner} 
                        style={{direction:this.props.isRTL?'rtl':'ltr',marginBottom: '10px'}} defaultValue={this.state.companyOwner} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNam5" className="grey-text formLabel">
                      {allStrings.companyOwner}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                      <input name="password" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx5" 
                        className="form-control" placeholder={allStrings.enterPassword} 
                        style={{direction:this.props.isRTL?'rtl':'ltr',marginBottom: '10px'}} defaultValue={this.state.password} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx5" className="grey-text formLabel">
                      {allStrings.password}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                    {this.state.profileImg == null&&
                      <div className="smallUploadContainer">
                        <img alt=""  name="img" className="smallUploderImg" src={require('../../assets/images/Path 343.png')}></img>
                        <input 
                          onChange={this.fileHandlerProfileImg}
                          type="file"
                          id="defaultFormRegisterNameEx6"
                          required className="smallUploader" />
                        <MDBBtn color="primary" size="sm" className="smallUploaderBtn">{allStrings.chooseFile}</MDBBtn>
                        <p style={{fontSize: '14px',marginTop: '3px 15px',float:'right',paddingTop: '4px'}}  className="grey-text">
                          {allStrings.dropHere}
                        </p>
                      </div>
                    }
                      <div className="previewRaduis">
                      {this.state.profileImg!= null&&
                      <MDBRow>
                        
                        <MDBCol md = "3">
                        <div style={{paddingTop:'5px',cursor:'pointer'}}  onClick={() => this.setState({ isOpen2: true })}>
                          <span style={{
                            fontSize: '17px',
                            color: '#478DFF',
                            fontWeight: '600',
                            margin: '5px'
                          }}>{allStrings.showD}</span>
                          <img alt=""  className="showImge" width="20px" src={require('../../assets/images/icons8_external_link_480px_2.png')}></img>
                        </div>
                        </MDBCol>
                        <MDBCol md="9" style={{paddingRight:'30px'}}  >
                          {this.state.profileImg!= null&&
                            <div style={{display:"inline-block",float:'right',marginBottom:'1rem'}}>
                                <img alt=""    src={this.state.profileImgType=="data"?URL.createObjectURL(this.state.profileImg):this.state.profileImg} />
                                <MDBIcon icon="times" className="mr-2 removeImg" onClick={()=> this.removeProfileImg()}></MDBIcon>
                            </div>
                          }
                        </MDBCol>
                      </MDBRow>
                      }
                      {isOpen2 && (
                      <Lightbox
                        mainSrc={profileImg[photoIndex]}
                        nextSrc={profileImg[(photoIndex + 1) % profileImg.length]}
                        prevSrc={profileImg[(photoIndex + profileImg.length - 1) % profileImg.length]}
                        onCloseRequest={() => this.setState({ isOpen2: false,photoIndex:0  })}
                        onMovePrevRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + profileImg.length - 1) % profileImg.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + 1) % profileImg.length,
                          })
                        }
                      />
                    )}
                    </div>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx7" className="grey-text formLabel">
                      {allStrings.profileImg}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                    {this.state.cardImg == null&&
                      <div className="smallUploadContainer">
                        <img alt=""  name="img" className="smallUploderImg" src={require('../../assets/images/Path 343.png')}></img>
                        <input 
                          multiple
                          onChange={this.fileHandlerCardImg}
                          type="file"
                          id="defaultFormRegisterNameE03"
                           className="smallUploader" />
                        <MDBBtn color="primary" size="sm" className="smallUploaderBtn">{allStrings.chooseFile}</MDBBtn>
                        <p style={{fontSize: '14px',marginTop: '3px 15px',float:'right',paddingTop: '4px'}}  className="grey-text">
                          {allStrings.dropHere}
                        </p>
                      </div>
                    }
                     
                    {this.state.cardImg!= null&&
                      <div className="previewRaduis">
                      <MDBRow>
                        <MDBCol md = "3">
                        <div style={{paddingTop:'5px',cursor:'pointer'}}  onClick={() => this.setState({ isOpen1: true })}>
                          <span style={{
                            fontSize: '17px',
                            color: '#478DFF',
                            fontWeight: '600',
                            margin: '5px'
                          }}>{allStrings.showD}</span>
                          <img alt=""  className="showImge" width="20px" src={require('../../assets/images/icons8_external_link_480px_2.png')}></img>
                        </div>
                        </MDBCol>
                        <MDBCol md="9" style={{paddingRight:'30px'}}  >
                         {this.state.cardImgViews.map((val) => (
                          <div style={{display:"inline-block",float:'right',marginBottom:'1rem'}}>
                              <img alt=""    src={this.state.cardImgType=="data"?val:val} />
                              <MDBIcon icon="times" className="mr-2 removeImg" onClick={()=> this.removeCardImg(val)}></MDBIcon>
                          </div>
                          ))}
                        </MDBCol>
                      </MDBRow>
                    {isOpen1 && (
                      <Lightbox
                        mainSrc={cardImg[photoIndex]}
                        nextSrc={cardImg[(photoIndex + 1) % cardImg.length]}
                        prevSrc={cardImg[(photoIndex + cardImg.length - 1) % cardImg.length]}
                        onCloseRequest={() => this.setState({ isOpen1: false,photoIndex:0  })}
                        onMovePrevRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + cardImg.length - 1) % cardImg.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + 1) % cardImg.length,
                          })
                        }
                      />
                    )}
                    </div>
                    }

                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx003" className="grey-text formLabel">
                      {allStrings.cardImg}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="cardNum" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx12" 
                        className="form-control" placeholder={allStrings.enterCardNum} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.cardNum} required/>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                      {allStrings.cardNum}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="address" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx13" 
                        className="form-control" placeholder={allStrings.enterAddress} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.address} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx13" className="grey-text formLabel">
                      {allStrings.address}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <TreeSelect
                        value= {this.state.city}
                        showSearch={false} 
                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left'}} 
                        treeCheckable = {false}         
                        treeNodeFilterProp="title"                      
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={allStrings.chooseCity}
                        onChange={(value) => {
                          console.log(value)
                          this.setState({city:value});
                        }} 
                      
                      >
                      {/*<TreeNode style={{background:"antiquewhite",color:'#fff !important'}} value="newCompany" title={allStrings.newCompany} key={allStrings.newCompany} />*/}
                      {this.state.cities.map(val=>(
                          <TreeNode value={val.id} title={this.props.isRTL?val.cityName_ar:val.cityName_en} key={val.id} />
                      ))}
                      </TreeSelect>
                      
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx14" className="grey-text formLabel">
                      {allStrings.city}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="phone" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx15" 
                        className="form-control" placeholder={allStrings.enterPhone} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.phone} required />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx15" className="grey-text formLabel">
                      {allStrings.phone}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="anotherPhone" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx16" 
                        className="form-control" placeholder={allStrings.EnterAnotherPhone} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.anotherPhone} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx16" className="grey-text formLabel">
                      {allStrings.anotherPhone}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <textarea name="notes" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx17" 
                        className="form-control" placeholder={allStrings.enterNote} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.notes} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx17" className="grey-text formLabel">
                      {allStrings.note}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  {this.state.requestType == "put" &&
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="debt" disabled type="text" id="defaultFormRegiste" 
                        className="form-control" style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.Company.debt } />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegiste" className="grey-text formLabel">
                      {allStrings.debt}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  }
                   {this.state.requestType == "put" &&
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="deptPayment" disabled type="text" id="defaultFormRegisteEx16" 
                        className="form-control" style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.remainToPay + " " +allStrings.day} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisteEx16" className="grey-text formLabel">
                      {allStrings.deptPayment}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  }
                  {this.state.requestType == "put" &&
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="12" sm="12">
                     <MDBBtn 
                      onClick={()=>this.receiveMoney()}
                        style={{ margin: '1rem auto',padding:'11px 20px',height:'35px',width:'98%',fontSize: '12px',
                        fontWeight: '600',background:'#4F73AC',color:'#fff' }}
                        className="btn z-depth-1a "
                        color='#4F73AC'
                      >
                          {allStrings.receiveMoney}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                   }
                </MDBCol>
              
              </MDBRow>
            </MDBContainer>
          </MDBRow>
          
          </form>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = state => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  })
  
  const mapDispatchToProps = {
    setUser,
    ChangeLanguage
  }
  
  export default  withRouter(
    connect(mapToStateProps,mapDispatchToProps)(CompanyForm)
  );
  
