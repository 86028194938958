/* eslint-disable eqeqeq */
import React from "react";
import "./profile.css";
import {MDBContainer,MDBRow,MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import {hasValue} from '../../validations/validations'
import {message} from 'antd';
import {NavLink} from 'react-router-dom';
import {ChangeLanguage} from '../../redux/actions/LanguageAction'

class profile extends React.Component {
  state = {
    requestType:"put",
    img:this.props.location.state?this.props.location.state.data.img:null,
    imgType:this.props.location.state?'url':'data',
    fullname: this.props.location.state?this.props.location.state.data.fullname:"",
    phone: this.props.location.state?this.props.location.state.data.phone:"",
    anotherPhone: this.props.location.state?this.props.location.state.data.anotherPhone:"",
    cardNum: this.props.location.state?this.props.location.state.data.cardNum:"",
    password:"",
    user:[],
  };

  constructor(props){
    super(props)
    console.log("isr   ",this.props.history)
    this.getById()
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    window.scrollTo(0,0)
  }
  getById = () => {
    axios.get(`${BASE_END_POINT}${this.props.currentUser.user.id}/findById`,{
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
    })
    .then(response=>{
      console.log(response.data)
      this.setState({user:response.data,img:response.data.img})
    })
    .catch(error=>{
      console.log("ALL get by id ERROR")
      console.log(error.response)
    })
  }
  updateProfile = () => {
    const {fullname,img,anotherPhone,phone,cardNum,password,imgType} = this.state
   
    if(hasValue(fullname)&&hasValue(cardNum) &&hasValue(phone) ){
        let l = message.loading('Wait..', 2.5)
        var data = new FormData()
        data.append('fullname',fullname)
        data.append('cardNum',cardNum)
        data.append('phone',phone); 
        data.append('type',this.props.currentUser.user.type); 
        if(imgType == "data"){
          data.append('img',img); 
        }
        if(anotherPhone !==''){
          data.append('anotherPhone',anotherPhone); 
        }
        if(password !==''){
          data.append('password',password); 
        }
        
        axios.put(`${BASE_END_POINT}user/${this.state.user.id}/updateInfo`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          localStorage.setItem('@USERQSAT', JSON.stringify(response.data));  
          this.props.history.goBack()
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error('Error', 2.5))
        })
    }
  }
  
  componentDidMount(){
   
    //this.props.ChangeLanguage(false)
  }
  fileHandler = (event) => {
    this.setState({
      img: event.target.files[0],
      imgType:'data'
    })
    
  }
  removeImg = () => {
    this.setState({
        img:null
    });
  }

  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  updateSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.updateProfile()
  };
  render() {
    return (
      <div className="profile">
        <MDBContainer>
          <MDBRow>
            <div className="top" style={{width:'100%',textAlign:'center'}}>
              <NavLink to={this.props.currentUser.user.type==="ADMIN"?"/Home":"/CompanyHome"}>
                <MDBIcon icon="times" className="closeIcon"/>
              </NavLink>
              <span className="stockTitle">{allStrings.edit}</span>
            </div>
          </MDBRow>
          <form
              className="needs-validation"
              onSubmit={this.updateSubmitHandler}
              noValidate
              style={{marginLeft:'2rem'}}
          >
          <MDBRow>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="9" sm="9">
                  <input name="fullname" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx5" required
                    className="form-control" placeholder={allStrings.enterName} defaultValue={this.state.user.fullname} 
                    style={{direction:this.props.isRTL?'rtl':'ltr',marginBottom: '10px'}}/>
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisterNameEx5" className="grey-text formLabel">
                  {allStrings.name}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="9" sm="9">
                  <input name="password" onChange={this.changeHandler} type="password" id="defaultFormRegisteameEx5"
                    className="form-control" placeholder={allStrings.enterPassword} 
                    style={{direction:this.props.isRTL?'rtl':'ltr',marginBottom: '10px'}}/>
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisteameEx5" className="grey-text formLabel">
                  {allStrings.password}
                  </label>
                </MDBCol>
              </MDBRow>
             
              <MDBRow>
                <MDBCol md="9" sm="9">
                {this.state.img == null&&
                  <div className="smallUploadContainer">
                    <img alt=""  name="img" className="smallUploderImg" src={require('../../assets/images/Path 343.png')}></img>
                    <input 
                      onChange={this.fileHandler}
                      type="file"
                      id="defaultFormRegisterNameEx6"
                      required className="smallUploader" />
                    <MDBBtn color="primary" size="sm" className="smallUploaderBtn">{allStrings.chooseFile}</MDBBtn>
                    <p style={{fontSize: '14px',marginTop: '3px 15px',float:'right',paddingTop: '4px'}}  className="grey-text">
                      {allStrings.dropHere}
                    </p>
                  </div>
                }
                  <div className="previewRaduis">
                  {this.state.img!= null&&
                  <div style={{display:"inline-block",float:'right',marginBottom:'1rem'}}>
                      <img alt=""    src={this.state.imgType=="data"?URL.createObjectURL(this.state.img):this.state.img} />
                      <MDBIcon icon="times" className="mr-2 removeImg" onClick={()=> this.removeImg()}></MDBIcon>
                  </div>
                  }
                </div>
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisterNameEx7" className="grey-text formLabel">
                  {allStrings.profileImg}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                <MDBCol md="9" sm="9">
                  <input name="cardNum" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx12" defaultValue={this.state.user.cardNum} 
                    className="form-control" placeholder={allStrings.enterCardNum} style={{direction:this.props.isRTL?'rtl':'ltr'}} required/>
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                  {allStrings.cardNum}
                  </label>
                </MDBCol>
              </MDBRow>
              
              <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                <MDBCol md="9" sm="9">
                  <input name="phone" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx15" defaultValue={this.state.user.phone} 
                    className="form-control" placeholder={allStrings.enterPhone} style={{direction:this.props.isRTL?'rtl':'ltr'}} required />
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisterNameEx15" className="grey-text formLabel">
                  {allStrings.phone}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                <MDBCol md="9" sm="9">
                  <input name="anotherPhone" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx16" defaultValue={this.state.user.anotherPhone} 
                    className="form-control" placeholder={allStrings.EnterAnotherPhone} style={{direction:this.props.isRTL?'rtl':'ltr'}}/>
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisterNameEx16" className="grey-text formLabel">
                  {allStrings.anotherPhone}
                  </label>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBRow>
          <MDBRow>
            <MDBBtn type="submit" 
            style={{ margin: '1rem',width:'150px',padding:'9px',height:'35px' }}
                className="btn z-depth-1a "
                color="primary"
              >
                 {this.state.requestType=="post"?allStrings.add:allStrings.edit}
            </MDBBtn>
          </MDBRow>
          </form>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = state => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
  })
  
  const mapDispatchToProps = {
    setUser,
    ChangeLanguage
  }
  
  export default  withRouter(
    connect(mapToStateProps,mapDispatchToProps)(profile)
  );
  
