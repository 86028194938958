/* eslint-disable eqeqeq */
import React from 'react';
import './salesMan.css'
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { ChangeLanguage } from '../../redux/actions/LanguageAction'
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { BASE_END_POINT } from '../../urls'
import axios from 'axios'
import { setUser } from '../../redux/actions/AuthActions'
import { Popconfirm, message, Input } from 'antd';
import Pagination from '@material-ui/lab/Pagination';
import LoadCard from '../../component/load card/load card'
import { hasValue } from "../../validations/validations";
import { SearchOutlined } from '@ant-design/icons';
import ReactToPrint from 'react-to-print';
import { ChangeCompany, ChangeBranch } from '../../redux/actions/CompanyAction'
import { Redirect, NavLink } from 'react-router-dom'

class ComponentToPrint extends React.Component {

  render() {
    console.log(this.props)
    return (
      <form class="col s12">
        <div className='tabelDev'>
          <div class="row">
            <table class="table table-borderless">
              <thead class="thead-light">
                <tr>
                  <th>الاسم</th>
                  <th>الهاتف</th>
                  <th>هاتف اخر</th>
                  <th>رقم البطاقه</th>
                  <th>المستحقات</th>
                  <th>الديون</th>
                  <th>عدد المبيعات</th>
                  <th>المنتجات المستلمه</th>
                  <th>التحصيل الاضافى</th>
                </tr>
                <tr>
                  <th>Items</th>
                  <th>Phone</th>
                  <th>Phone2</th>
                  <th>Card Num</th>
                  <th>Money</th>
                  <th>Debt</th>
                  <th>Sales Count</th>
                  <th>Recieved Products</th>
                  <th>Addition Collectaibles</th>
                </tr>
              </thead>

              <tbody>
                {this.props.data.map(salesMan => (
                  <tr>
                    <td>{salesMan.user.fullname ? salesMan.user.fullname : ''}</td>
                    <td>{salesMan.user.phone ? salesMan.user.phone : ''}</td>
                    <td>{salesMan.user.anotherPhone ? salesMan.user.anotherPhone : ''}</td>
                    <td>{salesMan.user.cardNum ? salesMan.user.cardNum : ''}</td>
                    <td>{salesMan.user.balance ? salesMan.user.balance : 0}</td>
                    <td>{salesMan.user.debt ? salesMan.user.debt : 0}</td>
                    <td>{salesMan.user.saleCount ? salesMan.user.saleCount : 0}</td>
                    <td>{salesMan.recievedProducts ? salesMan.recievedProducts : 0}</td>
                    <td>{salesMan.additionCollectaibles ? salesMan.additionCollectaibles : 0}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </form>
    );
  }
}
class SalesMans extends React.Component {

  state = {
    SalesMans: [],
    loading: true,
    refresh: false,
    page: 1,
    pages: 1,
    print: false,
    selectedSalesMan: null,
    salesMen: [],
    filterType: null,
    value: '',
    salesMenAdd: []

  }
  constructor(props) {
    super(props)
    console.log("isr   ", this.props.history)
    this.getSalesMans(1, false)

    if (this.props.isRTL) {
      allStrings.setLanguage('ar')
    } else {
      allStrings.setLanguage('en')
    }
    window.scrollTo(0, 0)
  }


  getSalesMenWithoutPagenation = () => {
    let url = ``;
    if (this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN") {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN`
      } else {
        url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&company=${this.props.company}`
      }
    } else {
      url = `${BASE_END_POINT}withoutPagenation/get?type=SALES-MAN&company=${this.props.currentUser.user.id}`
    }
    axios.get(url)
      .then(response => {
        console.log(response.data.users)
        this.setState({ salesMen: response.data.users })
      })
      .catch(error => {
        console.log("ALL salesMen ERROR")
        console.log(error.response)
      })
  }
  getSalesMenAdditionWithoutPagenation = () => {
    let url = ``;
    if (this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN") {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}withoutPagenation/additionGet?type=SALES-MAN`
      } else {
        url = `${BASE_END_POINT}withoutPagenation/additionGet?type=SALES-MAN&company=${this.props.company}`
      }
    } else {
      url = `${BASE_END_POINT}withoutPagenation/additionGet?type=SALES-MAN&company=${this.props.currentUser.user.id}`
    }
    axios.get(url)
      .then(response => {
        console.log(response.data.users)
        this.setState({ salesMenAdd: response.data.users })
      })
      .catch(error => {
        console.log("ALL salesMen ERROR")
        console.log(error.response)
      })
  }
  getReceivedWithoutPagenation = () => {
    let url = ``;
    if (this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN") {
      if (this.props.company == false) {
        url = `${BASE_END_POINT}productsReceived/withoutPagenation/get?type=SALES-MAN`
      } else {
        url = `${BASE_END_POINT}productsReceived/withoutPagenation/get?type=SALES-MAN&company=${this.props.company}`
      }
    } else {
      url = `${BASE_END_POINT}productsReceived/withoutPagenation/get?type=SALES-MAN&company=${this.props.currentUser.user.id}`
    }
    axios.get(url)
      .then(response => {
        console.log(response.data.recieves)
        this.setState({ recieves: response.data.recieves, recievesCount: response.data.recievesCount })
      })
      .catch(error => {
        console.log("ALL salesMen ERROR")
        console.log(error.response)
      })
  }
  getSalesMans = (page, refresh, filterType, value) => {
    this.setState({ loading: true })
    let url = ``;

    console.log(filterType)
    console.log(value)
    if (filterType != null && value != '') {
      if (filterType == "cardNum") {
        if (this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN") {
          if (this.props.company == false) {
            url = `${BASE_END_POINT}find?type=SALES-MAN&page=${page}&cardNum=${value}`
          } else {
            url = `${BASE_END_POINT}find?type=SALES-MAN&page=${page}&cardNum=${value}&company=${this.props.company}`
          }
        } else {
          url = `${BASE_END_POINT}find?type=SALES-MAN&page=${page}&company=${this.props.currentUser.user.id}&cardNum=${value}`
        }
      }
      if (filterType == "id") {
        if (this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN") {
          if (this.props.company == false) {
            url = `${BASE_END_POINT}find?type=SALES-MAN&page=${page}&id=${value}`
          } else {
            url = `${BASE_END_POINT}find?type=SALES-MAN&page=${page}&id=${value}&company=${this.props.company}`
          }
        } else {
          url = `${BASE_END_POINT}find?type=SALES-MAN&page=${page}&company=${this.props.currentUser.user.id}&id=${value}`
        }
      }
      if (filterType == "name") {
        if (this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN") {
          if (this.props.company == false) {
            url = `${BASE_END_POINT}find?type=SALES-MAN&page=${page}&name=${value}`
          } else {
            url = `${BASE_END_POINT}find?type=SALES-MAN&page=${page}&name=${value}&company=${this.props.company}`
          }
        } else {
          url = `${BASE_END_POINT}find?type=SALES-MAN&page=${page}&company=${this.props.currentUser.user.id}&name=${value}`
        }
      }

    } else {
      if (this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN") {
        url = `${BASE_END_POINT}find?type=SALES-MAN&page=${page}`
        if (this.props.company == false) {
          url = `${BASE_END_POINT}find?type=SALES-MAN&page=${page}`
        } else {
          url = `${BASE_END_POINT}find?type=SALES-MAN&page=${page}&company=${this.props.company}`
        }
      } else {
        url = `${BASE_END_POINT}find?type=SALES-MAN&page=${page}&company=${this.props.currentUser.user.id}`
      }
    }

    axios.get(url, {
      headers: {
        'Authorization': `Bearer ${this.props.currentUser.token}`
      }
    })
      .then(response => {
        console.log("SalesMans   ", response.data)
        this.setState({
          loading: false,
          refresh: false,
          SalesMans: response.data.data,
          salesMenCount: response.data.totalCount,
          page: response.data.page,
          pages: response.data.pageCount,
        })
        if (!this.state.print) {
          this.state.print = true
          this.getSalesMenWithoutPagenation()

          this.getSalesMenAdditionWithoutPagenation()
        }
      })
      .catch(error => {
        console.log("error   ", error.response)
        console.log("error2   ", error)
        this.setState({ loading: false, })
      })
    this.getReceivedWithoutPagenation()
  }
  selectSalesManId = (e) => {
    e.preventDefault();
    let id = e.target.id.substring(8)
    console.log(id)
    this.setState({ selectedSalesMan: id })
  }
  deleteSalesMan = (id) => {
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}${id}/delete`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`
      },
    })
      .then(response => {
        console.log("SalesMan DELETED")
        l.then(() => message.success(allStrings.done, 2))
        this.getSalesMans(this.state.page)
      })
      .catch(error => {
        console.log(error.response)
        if (error.response.data.errors == "salesMan have premiums") {
          l.then(() => message.error(allStrings.deleteSalesManError, 2.5))
        }
      })
  }
  OKBUTTON = (e) => {
    this.deleteSalesMan(this.state.selectedSalesMan)
  }

  render() {
    console.log(this.state.filterType)
    if (this.props.currentUser.user.type == "COMPANY" && this.props.currentUser.user.salesMen == false) return <Redirect to='/Home' />
    if (this.props.currentUser.user.type == "SUB-ADMIN" && !this.props.currentUser.user.pagesPermission.includes('SALESMEN')) return <Redirect to='/Home' />

    return (
      <div className="Category">
        <div className="categoryTop" style={{ marginBottom: '3rem' }}>
          <MDBContainer>
            <MDBRow>
              <MDBCol xl="3" lg="3" md="3" sm="12">
                <ReactToPrint
                  trigger={() => {
                    return (
                      <div>
                        <img alt="" style={{ cursor: 'pointer' }} src={require('../../assets/images/icons8_pdf.png')}></img>
                        <span
                          style={{
                            color: '#FF3B40',
                            fontWeight: '600',
                            padding: '10px',
                            fontSize: '20px',
                            position: 'absolute',
                            cursor: 'pointer'
                          }}>{allStrings.print}</span>
                      </div>
                    );
                  }}
                  content={() => this.componentRef}
                />
                <div style={{ display: 'none' }}>
                  <ComponentToPrint data={this.state.salesMenAdd} ref={el => (this.componentRef = el)} />
                </div>

              </MDBCol>
              <MDBCol xl="3" lg="3" md="3" sm="12" style={{ paddingTop: '1rem' }}>
                <img alt="" width='40px' src={require('../../assets/images/icons8_workers.png')}></img>
                <span
                  style={{
                    color: 'mediumblue',
                    fontWeight: '600',
                    fontSize: '22px',
                    position: 'absolute',
                    padding: '5px 10px'
                  }}>{this.state.salesMenCount}</span>
                <p style={{
                  color: "mediumblue",
                  fontSize: "14px",
                  marginTop: "6px",
                  marginBottom: 0,
                  fontWeight: "600"
                }}>{allStrings.salesMenCount}</p>
              </MDBCol>
              <MDBCol xl="3" lg="3" md="3" sm="12" style={{ paddingTop: '1rem' }}>
                <img alt="" width='40px' src={require('../../assets/images/icons8_product.png')}></img>
                <span
                  style={{
                    color: 'mediumblue',
                    fontWeight: '600',
                    fontSize: '22px',
                    position: 'absolute',
                    padding: '5px 10px'
                  }}>{this.state.recievesCount}</span>
                <p style={{
                  color: "mediumblue",
                  fontSize: "14px",
                  marginTop: "6px",
                  marginBottom: 0,
                  fontWeight: "600"
                }}>{allStrings.recievesCount}</p>
              </MDBCol>
              <MDBCol xl="3" lg="3" md="3" sm="12">
                <NavLink to="addSalesMan">
                  <div className="addButton">
                    <MDBIcon icon="plus" />
                  </div>
                  <span
                    style={{
                      float: 'right',
                      color: '#43425D',
                      fontWeight: '600',
                      padding: '11px 2px',
                      fontSize: '20px',
                      cursor: 'pointer',
                    }}>{allStrings.addSalesMan}</span>
                </NavLink>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <div onClick={() => this.getSalesMans(1, false)} style={{ width: '100%', textAlign: 'end', paddingRight: '18px' }}>
                <span style={{ color: 'cadetblue', cursor: 'pointer' }}
                  class="material-icons">undo</span>
              </div>
            </MDBRow>
          </MDBContainer>


        </div>
        <div className="filters" style={{ marginBottom: "2rem" }}>
          <MDBContainer>
            <MDBRow>
              <MDBCol xl="4" lg="4" md="4" sm="12">
                <Input
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      if (hasValue("" + e.target.value)) {
                        console.log("ENTER   ", e.target.value)
                        this.setState({ filterType: 'cardNum', value: e.target.value })
                        this.getSalesMans(1, false, "cardNum", e.target.value)
                      } else {
                        message.warning(allStrings.noValue);
                        console.log("ENTER NO VALUE   ", e.target.value)
                      }
                    }
                  }}
                  placeholder={allStrings.cardFilter}
                  size="large" prefix={<SearchOutlined />} />
              </MDBCol>
              <MDBCol xl="4" lg="4" md="4" sm="12">
                <Input
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      if (hasValue("" + e.target.value)) {
                        console.log("ENTER   ", e.target.value)
                        this.setState({ filterType: 'id', value: e.target.value })
                        this.getSalesMans(1, false, "id", e.target.value)
                      } else {
                        message.warning(allStrings.noValue);
                        console.log("ENTER NO VALUE   ", e.target.value)
                      }
                    }
                  }}
                  placeholder={allStrings.idFilter}
                  size="large" prefix={<SearchOutlined />} />
              </MDBCol>
              <MDBCol xl="4" lg="4" md="4" sm="12">
                <Input
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      if (hasValue("" + e.target.value)) {
                        console.log("ENTER   ", e.target.value)
                        this.setState({ filterType: 'name', value: e.target.value })
                        this.getSalesMans(1, false, "name", e.target.value)
                      } else {
                        message.warning(allStrings.noValue);
                        console.log("ENTER NO VALUE   ", e.target.value)
                      }
                    }
                  }}
                  placeholder={allStrings.nameFilter}
                  size="large" prefix={<SearchOutlined />} />
              </MDBCol>

            </MDBRow>
          </MDBContainer>
        </div>
        <MDBContainer>
          <MDBRow>
            {this.state.loading ?
              <LoadCard />
              :
              this.state.SalesMans.length > 0 ?
                this.state.SalesMans.map(SalesMan => (

                  <MDBCol xl="4" lg="6" md="12" sm="12">

                    <div className="categoryCard" >
                      <MDBContainer>
                        <MDBRow>
                          <MDBCol xl="2" lg="2" md="2" sm="2">
                            <div className="icons">
                              <Popconfirm
                                title={allStrings.areYouSure}
                                onConfirm={this.OKBUTTON}
                                onCancel={this.fCANCELBUTTON}
                                okText={allStrings.ok}
                                cancelText={allStrings.cancel}
                              >
                                <p style={{
                                  fontSize: '24px',
                                  color: '#A4A3B0',
                                  marginBottom: '8px',
                                  cursor: 'pointer',
                                  zIndex: '4',
                                  position: 'relative'
                                }} onClick={this.selectSalesManId} id={"SalesMan" + SalesMan.id}>
                                  <MDBIcon icon="times" onClick={this.selectSalesManId} id={"SalesMan" + SalesMan.id} />
                                </p>

                              </Popconfirm>

                              <p onClick={() => this.props.history.push('/updateSalesMan', { data: SalesMan })} style={{
                                fontSize: '18px',
                                color: '#A4A3B0',
                                marginBottom: '8px',
                                cursor: 'pointer',
                              }}>
                                <MDBIcon icon="pen" />
                              </p>

                            </div>
                          </MDBCol>
                          <MDBCol xl="6" lg="6" md="6" sm="6" className="noPadding">
                            <div className="categoryInfo" onClick={() => this.props.history.push('/updateSalesMan', { data: SalesMan })} >
                              <p style={{
                                fontWeight: '600',
                                color: '#43425D',
                                marginBottom: '6px',
                                fontSize: '1.2rem'

                              }}>{SalesMan.fullname.length > 15 ? SalesMan.fullname.substring(0, 12) + ".." : SalesMan.fullname}</p>

                              <p style={{
                                color: '#43425D',
                                fontSize: '16px',
                                marginBottom: '8px',
                                display: 'inline-block'
                              }}>{SalesMan.phone} </p>
                              <p style={{
                                color: '#43425D',
                                fontSize: '16px',
                                margin: 0,
                                marginBottom: '10px'
                              }}>{SalesMan.anotherPhone}</p>

                            </div>
                          </MDBCol>
                          <MDBCol xl="4" lg="4" md="4" sm="4">

                            <div className="categoryImg" >
                              <img alt="" style={{
                                width: '85px',
                                height: '85px',
                                borderRadius: '100%'
                              }} src={SalesMan.img ? SalesMan.img : require('../../assets/images/profileicon.jpg')}></img>
                            </div>
                          </MDBCol>
                        </MDBRow>

                      </MDBContainer>
                    </div>
                  </MDBCol>
                ))
                :
                <div className="noData">{allStrings.noData}</div>
            }
          </MDBRow>
          <MDBRow>
            {this.state.SalesMans.length > 0 &&
              <div style={{ textAlign: 'center', margin: 'auto', marginTop: '3rem' }}>
                <Pagination
                  onChange={(event, page) => {
                    console.log("page   ", page)
                    if (page != this.state.page) {
                      this.getSalesMans(page, false, this.state.filterType, this.state.value)
                      this.setState({ page: page })
                    }

                  }}
                  defaultPage={1} page={this.state.page} count={this.state.pages} style={{ color: `blue !important` }} />
              </div>
            }

          </MDBRow>
        </MDBContainer>
      </div>
    )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
}

export default withRouter(connect(mapToStateProps, mapDispatchToProps)(SalesMans))
