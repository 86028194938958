/* eslint-disable eqeqeq */
import React from "react";
import "./edit premuims.css";
import {MDBContainer,MDBRow,MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import {message,TreeSelect,DatePicker,ConfigProvider} from 'antd';
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import {ChangeCompany,ChangeBranch} from '../../redux/actions/CompanyAction'
import moment from 'moment'
import locale_ar from 'antd/lib/locale/ar_EG';
import locale_en from 'antd/lib/locale/en_GB';
import 'moment/locale/ar';
import Skeleton from 'react-loading-skeleton';

const {TreeNode} = TreeSelect

class editPremuims extends React.Component {
  state = {
    purchaseDate:this.props.location.state?this.props.location.state.data.startDate?moment(this.props.location.state.data.paidDate).format("YYYY/MM/DD"):moment().format("YYYY/MM/DD"):moment().format("YYYY/MM/DD"),
    order:this.props.location.state?this.props.location.state.data:[],
    paymentSystem:this.props.location.state?this.props.location.state.data?this.props.location.state.data.paymentSystem:'cash':'cash',
    salesMan:this.props.location.state?this.props.location.state.data.salesMan?this.props.location.state.data.salesMan:null:null,
    client:this.props.location.state?this.props.location.state.data.client?this.props.location.state.data.client:null:null,
    status:'PENDING',
    multiProducts:[{product:null,count:1,price:0 , cost:0}],
    buySalesMen:[],
    total:0,
    oldTotal:this.props.location.state?this.props.location.state.data.total?this.props.location.state.data.total:0:0,
    loading:true,
    salesMen:[],
    products:[],
    newTotal:0,
    premiums:[],
  };

  constructor(props){
    super(props)
    console.log("isr   ",this.props.location)
    
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    window.scrollTo(0,0)
  }
  componentDidMount(){
    this.getPremiums()
    if(this.state.order){
      console.log("buySalesMen",this.state.order.multiBuySalesMen)
      let buySalesMen =[]
      let buySalesManIds = []
      this.state.order.multiBuySalesMen.forEach(element => {
          buySalesMen.push(element.buySalesMan)
          buySalesManIds.push(element.buySalesMan.id)
      });
      this.setState({buySalesMen:buySalesMen,buySalesManIds:buySalesManIds})
      //multi products
      let multiProducts =[]
      this.state.order.multiProducts.forEach(element => {
        multiProducts.push({
          product: element.product.id,
          name_ar: element.product.name_ar,
          name_en: element.product.name_en,
          price: element.price?element.price:0,
          count: element.count,
          cost:element.cost,
        })
      });
      console.log("multiProducts",multiProducts)
      this.setState({multiProducts:multiProducts})
    }
    
    
  }
  getPremiums = () =>{
    this.setState({loading:true})
    let url = `${BASE_END_POINT}premiums/withoutPagenation/get?type=INSTALLMENT&order=${this.state.order.id}`;
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("premiums   ",response.data)
      let premiums = [];
      let total = 0;
      let status = 'PENDING';
      response.data.forEach(element => {
        premiums.push({
          premium:element.id,
          receiptNum:element.receiptNum,
          paidDate:element.paidDate,
          status:element.status,
          costPerMonth: element.costPerMonth
        })
        if(element.status !== 'PENDING'){
          status = "FANASHED"
        }
        total += element.costPerMonth

      });
      this.setState({loading:false,premiums:premiums,total:total,newTotal:total,status:status})
    })
    .catch(error=>{
      console.log("error   ",error.response)
      this.setState({loading:false})
    })

  }
  updatePremiums = () => {
      let l = message.loading('Wait..', 2.5)
      let premiums= [...new Set(this.state.premiums.filter(e => e.premium != null))]
      console.log("premiums",premiums)
      let data = {premiums:premiums,total:this.state.newTotal}
      axios.put(`${BASE_END_POINT}premiums/${this.state.order.id}/updateOrderPremuims`,data,{
      headers: {
      'Content-Type': 'application/json',
      'Authorization':`Bearer ${this.props.currentUser.token}`
      },
      })
      .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
        this.props.history.goBack()
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error('Error', 2.5))
      })
  }
  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.updatePremiums()
  };
  //theProducts inputs
  theProducts(){
    return this.state.multiProducts.map((v, i) =>
        <MDBRow> 
              <MDBCol md="3" sm="3">
                <label htmlFor="defaultFormRegisterNameEx2" className="grey-text formLabel" style={{float:'right'}}>{allStrings.total}</label>
                <input name="cost" disabled onChange={(event)=>{
                    let multiProducts = [...this.state.multiProducts];
                    multiProducts[i].cost = event.target.value;
                    console.log("multiProducts",multiProducts)
                    let total = 0;
                    multiProducts.forEach(element => {
                      total += parseInt(element.cost)
                    });
                    this.setState({ total });
                    this.changeTotal(total)
                    this.setState({ multiProducts });
                  }} min={1} type="number" id="defaultFormRegisterNameEx2" 
                  className="form-control" value={v.cost} placeholder={allStrings.total} style={{direction:this.props.isRTL?'rtl':'ltr',border:'.5px solid #aaa'}} />
                
              </MDBCol>
              <MDBCol md="2" sm="2" style={{paddingLeft: 0,paddingRight:'5px'}}>
                <label htmlFor="defaultFormRegisterNameEx2" className="grey-text formLabel" style={{float:'right'}}>{this.state.paymentSystem=='cash'?allStrings.cashPrice:allStrings.installmentPrice}</label>
                <input name="price" disabled onChange={(event)=>{
                    let multiProducts = [...this.state.multiProducts];
                    multiProducts[i].price = event.target.value;
                    multiProducts[i].cost = event.target.value * v.count;
                    let total = 0;
                    multiProducts.forEach(element => {
                      total += parseInt(element.cost)
                    });
                    this.setState({ total });
                    this.changeTotal(total)
                    console.log("multiProducts",multiProducts)
                    this.setState({ multiProducts });
                  }} min={1} type="number" id="defaultFormRegisterNameEx2" 
                  className="form-control" value={v.price} placeholder={allStrings.price} style={{direction:this.props.isRTL?'rtl':'ltr',border:'.5px solid #aaa'}} />
              </MDBCol>
              <MDBCol md="3" sm="3" style={{paddingLeft: 0,paddingRight:'5px'}}>
                <label htmlFor="defaultFormRegisterNameEx2" className="grey-text formLabel" style={{float:'right'}}>{allStrings.count}</label>
                <input name="count" disabled onChange={(event)=>{
                    let multiProducts = [...this.state.multiProducts];
                    multiProducts[i].count = event.target.value;
                    multiProducts[i].cost = event.target.value * multiProducts[i].price;
                    let total = 0;
                    multiProducts.forEach(element => {
                      total += parseInt(element.cost)
                    });
                    this.setState({ total });
                    this.changeTotal(total)
                    console.log("multiProducts",multiProducts)
                    this.setState({ multiProducts });
                  }} min={1} type="number" id="defaultFormRegisterNameEx2" 
                  className="form-control" value={v.count} placeholder={allStrings.count} style={{direction:this.props.isRTL?'rtl':'ltr',border:'.5px solid #aaa'}} />
              </MDBCol>
              <MDBCol md="4" sm="4" style={{paddingLeft: 0,paddingRight:'5px'}}>
              <label htmlFor="defaultFormRegisterNameEx2" className="grey-text formLabel" style={{float:'right'}}>{allStrings.product}</label>
                <TreeSelect
                  showSearch={true} 
                  value={v.product != null?v.product:allStrings.chooseProduct}
                  style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left'}} 
                  treeCheckable = {false}     
                  disabled    
                  treeNodeFilterProp="title"                      
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto',left:1000 }}
                  placeholder={allStrings.chooseProduct}
                  onChange={(value) => {
                    console.log(value)
                    let multiProducts = [...this.state.multiProducts];
                    multiProducts[i].product = value;
                    this.setState({ multiProducts });
                    this.getProductById(value,v.count)
                  }} 
                
                >
                {/*<TreeNode style={{background:"antiquewhite",color:'#fff !important'}} value="newClient" title={allStrings.newClient} key={allStrings.newClient} />*/}
                
                  <TreeNode value={v.product} title={this.props.isRTL?v.name_ar:v.name_en} key={v.product} />
                
                </TreeSelect>
              </MDBCol>
        </MDBRow>  
    )
  }
  
  

  render() {
    console.log("buySalesMen",this.state.buySalesMen)
    return (
      
      <div className="editPremuims">
        <MDBContainer>
          <MDBRow>
            <div className="top" style={{width:'100%',textAlign:this.state.requestType==="post" ?'center':'right'}}>
              <span onClick={()=> this.props.history.goBack()}>
                <MDBIcon icon="times" className="closeIcon"/>
              </span>
            </div>
          </MDBRow>
          <MDBRow>
            <MDBCol md="4"></MDBCol>
            <MDBCol md="4" style={{textAlign:'center'}}>
              <label  htmlFor="defaultFormRegisterNameEx2"
              style={{
                //float:'right',
                fontWeight: '800',
                color: '#43425D',
                fontSize: '18px',
                marginBottom: '2rem',
                //marginRight:'1rem'
              }}>
              {allStrings.updatePremuims}
              </label>
            </MDBCol>
            <MDBCol md="4"></MDBCol>
          </MDBRow>
          <form
              className="needs-validation"
              onSubmit={this.submitHandler}
              noValidate
              style={{marginLeft:'2rem'}}
          >
          <MDBRow>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="6">
                  {/*left */}
                  <MDBRow>
                    <MDBCol  md="12" sm="12">
                    <div className='tabelDevEdit'>     
                      <div class="row">
                        <table class="table  table-sm">
                            <thead>
                                <tr class="thead-dark" >
                                  <th>{allStrings.status}</th>
                                  <th>{allStrings.cost}</th>
                                  <th>{allStrings.date}</th>
                                  <th> {allStrings.number}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.loading?
                              <tr style={{height: '200px'}}>
                                  <th colSpan="6" className="noData">
                                  <Skeleton height={400}/> 
                                  </th>
                              </tr>
                              :
                              this.state.premiums.map((v, i) =>(
                                  <tr>
                                    <td style={{padding:'8px'}}>{v.status=="FINISHED"?allStrings.paid:v.status=="DELAY"?allStrings.delay:allStrings.notPaid}</td>
                                    <td style={{padding:'8px'}}>
                                      <input name="prem" onChange={(event)=>{
                                        let premiums = [...this.state.premiums];
                                        premiums[i].costPerMonth = parseInt(event.target.value);
                                        console.log("premiums",premiums)
                                        let newTotal = 0;
                                        premiums.forEach(element => {
                                          newTotal += parseInt(element.costPerMonth)
                                        });
                                        console.log("newTotal",newTotal)
                                        this.setState({ total:newTotal });
                                        this.setState({ premiums });
                                      }} min={1} type="number" id="defaultFormRegisterNameEx2" disabled={v.status !="PENDING"?true:false}
                                      className="form-control" value={v.costPerMonth} style={{direction:this.props.isRTL?'rtl':'ltr',border:'none',
                                      width:'100px',height:'20px',textAlign:'center',fontSize: '15px',fontWeight: '300',margin:'0px auto',marginRight: '67px'
                                      }} />
               
                                    </td>
                                    <td style={{padding:'8px'}}>{moment(v.paidDate).format('DD/MM/YYYY')}</td>
                                    <td style={{padding:'8px'}}>{v.receiptNum}</td>
                                  </tr>
                              ))}
                            </tbody>
                        </table>
                      </div>
                    </div> 
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol  md="6" sm="6" style={{marginBottom:'3rem',textAlign: 'center',fontWeight: 600,fontSize: '20px',color: '#797474'}}>
                      <p>{allStrings.newTotal}</p>
                      
                      <input name="newTotal"  min={1} type="number" 
                      className="form-control" value={this.state.newTotal} 
                      style={{direction:this.props.isRTL?'rtl':'ltr',border:'none',
                      width:'200px',height:'20px',textAlign:'center',
                      fontSize: '20px',fontWeight: '500',margin:'0px auto',marginRight: '67px',
                      color:this.state.total == this.state.newTotal?'green':'red'
                      
                      }}
                      onChange={(e)=>this.setState({newTotal:e.target.value})} />
                    </MDBCol>
                    <MDBCol  md="6" sm="6" style={{marginBottom:'3rem',textAlign: 'center',fontWeight: 600,fontSize: '20px',color: '#797474'}}>
                      <p>{allStrings.currentTotal}</p>
                      <p>{this.state.total}</p>
                    </MDBCol>
                  </MDBRow>
                  <br></br>
                  <MDBRow>
                    <MDBBtn type="submit" 
                    style={{ margin: '1rem',width:'150px',padding:'9px',height:'35px',position:'absolute',bottom:'0px' }}
                        className="btn z-depth-1a "
                        color="primary"
                        disabled={this.state.status ==="PENDING"?this.state.newTotal != this.state.total? true: false:true}
                      >
                        {allStrings.edit}
                    </MDBBtn>
                  </MDBRow>
                </MDBCol>
                <MDBCol md="6" style={{borderLeft:'1px solid'}}>
                  {/* right */}
                  {this.theProducts()}
                  <br></br>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}} className="theClient">
                    <MDBCol md="9" sm="9">
                      <input name="client" disabled  type="text" id="defaultFormRegisterName" 
                        className="form-control"  style={{border:'.5px solid #aaa',direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.client?this.state.client.fullname:allStrings.clientName}/>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterName" className="grey-text formLabel">
                      {allStrings.clientName}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}} className="theTotal">
                    <MDBCol md="9" sm="9">
                      <input name="total"  min={1} type="number" id="defaultFormRegisterNameEx2" 
                        className="form-control" disabled value={this.state.oldTotal} placeholder={allStrings.total} style={{direction:this.props.isRTL?'rtl':'ltr',border:'.5px solid #aaa'}} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx2" className="grey-text formLabel">
                      {allStrings.total}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                     <TreeSelect
                        showSearch={true} 
                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left'}} 
                        treeCheckable = {true}  
                        value={this.state.buySalesManIds}       
                        treeNodeFilterProp="title"                      
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={allStrings.chooseSalesMan}
                        disabled
                        onChange={(value) => {
                          console.log(value)
                          this.setState({buySalesMen:value});
                        }} 
                      
                      >
                      {/*<TreeNode style={{background:"antiquewhite",color:'#fff !important'}} value="newClient" title={allStrings.newClient} key={allStrings.newClient} />*/}
                      {this.state.buySalesMen.map(val=>(
                          <TreeNode value={val.id} title={val.fullname} key={val.id} />
                      ))}
                      </TreeSelect>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx1" className="grey-text formLabel">
                      {allStrings.buySalesMan}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                    <TreeSelect
                        showSearch={true} 
                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left'}} 
                        treeCheckable = {false} 
                        defaultValue= {this.state.salesMan?this.state.salesMan.id:''}
                        disabled        
                        treeNodeFilterProp="title"                      
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={allStrings.chooseSalesMan}
                        onChange={(value) => {
                          console.log(value)
                          this.setState({salesMan:value});
                        }} 
                      
                      >
                        <TreeNode value={this.state.salesMan?this.state.salesMan.id:''} title={this.state.salesMan?this.state.salesMan.fullname:''} />
                      </TreeSelect>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx1" className="grey-text formLabel">
                      {allStrings.collectSalesMan}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                    <div style={{marginBottom:'10px',textAlign:'right'}}>
                      <div style={{display:'inline-block',textAlign:'center',marginLeft:this.props.isRTL? '0':"-93px"}}>
                      <ConfigProvider locale={this.props.isRTL?locale_ar:locale_en}>
                        <DatePicker  defaultValue={moment(this.state.purchaseDate, 'YYYY/MM/DD')}  disabled/>
                      </ConfigProvider>
                      <span className="newSpanM"><img alt=""  style={{marginRight:'4px'}} src={require('../../assets/images/icons8_edit_property.png')}></img>{this.state.purchaseDate}</span>
                      </div>
                    </div>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNam2" className="grey-text formLabel">
                      {allStrings.purchaseDate}
                      </label>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              
              </MDBRow>
            </MDBContainer>
          </MDBRow>
          
          </form>
        </MDBContainer>
      </div>
    );
  }
}


const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
}
  
  export default  withRouter(
    connect(mapToStateProps,mapDispatchToProps)(editPremuims)
  );
  
