import React from 'react';
import './stock.css'
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { ChangeLanguage } from '../../redux/actions/LanguageAction'
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { BASE_END_POINT } from '../../urls'
import axios from 'axios'
import { setUser } from '../../redux/actions/AuthActions'
import { Popconfirm, message, Select, Input } from 'antd';
import Pagination from '@material-ui/lab/Pagination';
import LoadCard from '../../component/load card/load card'
import { hasValue } from "../../validations/validations";
import { SearchOutlined } from '@ant-design/icons';
import { Redirect, NavLink } from 'react-router-dom'
import ReactToPrint from 'react-to-print';
import { ChangeCompany, ChangeBranch } from '../../redux/actions/CompanyAction'
import moment from 'moment'
import 'moment/locale/ar';

const { Option } = Select;
class ComponentToPrint extends React.Component {

  render() {
    console.log(this.props)
    return (
      <MDBContainer>
        <br></br>
        <br></br>
        <MDBRow style={{ textAlign: 'center' }}>
          <MDBCol size="4" style={{ textAlign: 'center' }}>
            <img alt="" style={{ width: '70px', height: '70px', borderRadius: '50%' }} src={require('../../assets/images/icons8_pdf.png')}></img>
          </MDBCol>
          <MDBCol size="4">
            <p style={{ fontWeight: '500', fontSize: '24px' }}>جرد المخزن</p>
          </MDBCol>
          <MDBCol size="4"></MDBCol>
        </MDBRow>
        <br></br>
        <MDBRow style={{ textAlign: 'right' }}>
          <MDBCol size="4">
            <p style={{ fontWeight: '500' }}>
              <span> عدد الصفح من  / </span>
              <span style={{ color: 'red', textDecoration: 'underline' }}>1</span>
              <span> الى </span>
              <span style={{ color: 'red', textDecoration: 'underline' }}>{Math.ceil(this.props.productsCount / 40)}</span>
            </p>
          </MDBCol>
          <MDBCol size="4">
            <p style={{ fontWeight: '500' }}>
              <span > عدد المنتجات / </span>
              <span style={{ color: 'red', textDecoration: 'underline' }}>{this.props.productsCount}</span>
            </p>
          </MDBCol>
          <MDBCol size="4">
            <p style={{ fontWeight: '500' }}>
              <span style={{ float: 'right' }}>/ اسم الشركه  </span>
              <span style={{ color: 'red', textDecoration: 'underline' }}>{this.props.theCompany.fullname ? this.props.theCompany.fullname : ""}</span>
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow style={{ textAlign: 'right' }}>
          <MDBCol size="4">
            <p style={{ fontWeight: '500' }}>
              <span> تاريخ الطباعه / </span>
              <span style={{ color: 'red', textDecoration: 'underline' }}>{moment().format('YYYY/MM/DD')} </span>
            </p>
          </MDBCol>
          <MDBCol size="4"></MDBCol>
          <MDBCol size="4">
            <p style={{ fontWeight: '500' }}>
              <span> اجمالى سعر الشراء بالمخزن / </span>
              <span style={{ color: 'red', textDecoration: 'underline' }}>{this.props.total}</span>
            </p>
          </MDBCol>

        </MDBRow>
        <MDBRow>
          <MDBCol md="12" sm="12">
            <div className='tabelDev'>
              <div class="row">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr class="thead-light" >
                      <th>نوع البيع</th>
                      <th>العموله</th>
                      <th>عدد مرات البيع</th>
                      <th>الكميه بالمخزن</th>
                      <th>القسم</th>
                      <th>سعر الكاش</th>
                      <th>سعر الشراء</th>
                      <th>اسم المنتج</th>
                      <th>رقم</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.data.map(product => (
                      <tr>

                        <td style={{ padding: '8px' }}>{product.buyType ? product.buyType === "CASH" ? allStrings.cash : product.buyType === "PREMIUM" ? allStrings.installment : allStrings.cashAndInstallment : ''}</td>
                        <td style={{ padding: '8px' }}>{product.commission ? product.commission : 0}</td>
                        <td style={{ padding: '8px' }}>{product.salesNumber ? product.salesNumber : 0}</td>
                        <td style={{ padding: '8px' }}>{product.quantity ? product.quantity : 0}</td>
                        <td style={{ padding: '8px' }}>{product.category ? product.category.categoryName_ar : ''}</td>
                        <td style={{ padding: '8px' }}>{product.cashPrice ? product.cashPrice : 0}</td>
                        <td style={{ padding: '8px' }}>{product.purchasePrice ? product.purchasePrice : 0}</td>
                        <td style={{ padding: '8px' }}>{this.props.isRTL ? product.name_en : product.name_ar}</td>
                        <td style={{ padding: '8px' }}>{product.id ? product.id : 0}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}
class Product extends React.Component {

  state = {
    Products: [],
    theCompany: [],
    loadin: true,
    refresh: false,
    page: 1,
    pages: 1,
    print: false,
    selectedProduct: null,
    categories: [],
    filterType: null,
    value: '',
    productsCount: '',
    printFrom: this.props.isRTL ? moment().startOf('month').format("YYYY/MM/DD") : moment().startOf('month').locale('en').format("YYYY/MM/DD"),
    printTo: this.props.isRTL ? moment().endOf('month').format("YYYY/MM/DD") : moment().endOf('month').locale('en').format("YYYY/MM/DD"),

    total: '',
    products: [],
    Company: this.props.location.state ? this.props.location.state.data : null,
    printType: 'TYPE1',
    modal: false,
  }
  constructor(props) {
    super(props)
    console.log("isr   ", this.props.history)

    this.getCompanyData()
    if (this.props.isRTL) {
      allStrings.setLanguage('ar')
    } else {
      allStrings.setLanguage('en')
    }
    window.scrollTo(0, 0)
  }
  getCompanyData = () => {
    let url = ``;
    if (this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN") {
      if (this.props.company !== false) {

        url = `${BASE_END_POINT}${this.props.company}/findById`
      }
    } else {
      url = `${BASE_END_POINT}${this.props.currentUser.user.id}/findById`
    }
    console.log("url", url)
    axios.get(url, {
      headers: {
        'Authorization': `Bearer ${this.props.currentUser.token}`
      }
    })
      .then(response => {
        console.log(response.data)
        this.setState({ theCompany: response.data })
      })
      .catch(error => {
        console.log("ALL salesMen ERROR")
        console.log(error.response)
      })
  }
  getProductsWithoutPagenation = () => {
    let url = ``;
    if (this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN") {
      if (this.props.company === false) {
        url = `${BASE_END_POINT}products/withoutPagenation/get`
      } else {
        url = `${BASE_END_POINT}products/withoutPagenation/get?company=${this.props.company}`
      }
    } else {
      url = `${BASE_END_POINT}products/withoutPagenation/get?company=${this.props.currentUser.user.id}`
    }
    axios.get(url)
      .then(response => {
        console.log(response.data.products)
        this.setState({ products: response.data.products, total: response.data.total })
      })
      .catch(error => {
        console.log("ALL salesMen ERROR")
        console.log(error.response)
      })
  }
  componentDidMount() {
    this.getProducts(1, false)
    this.getCategories()
    //this.props.ChangeLanguage(false)
  }
  getCategories = () => {
    axios.get(`${BASE_END_POINT}categories/get/Normal`)
      .then(response => {
        console.log(response.data)
        this.setState({ categories: response.data })
      })
      .catch(error => {
        console.log("ALL Categories ERROR")
        console.log(error.response)
      })
  }
  getProducts = (page, refresh, filterType, value) => {
    this.setState({ loading: true })
    let url = ``;

    console.log(filterType)
    console.log(value)
    if (filterType != null && value !== '') {
      if (filterType === "id") {
        if (this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN") {
          if (this.state.Company != null) {
            url = `${BASE_END_POINT}products?page=${page}&id=${value}&company=${this.state.Company}`
          } else {
            if (this.props.company === false) {
              url = `${BASE_END_POINT}products?page=${page}&id=${value}`
            } else {
              url = `${BASE_END_POINT}products?page=${page}&id=${value}&company=${this.props.company}`
            }

          }


        } else {
          url = `${BASE_END_POINT}products?page=${page}&company=${this.props.currentUser.user.id}&id=${value}`
        }
      }
      if (filterType === "name") {
        if (this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN") {
          if (this.state.Company != null) {
            url = `${BASE_END_POINT}products?page=${page}&name=${value}&company=${this.state.Company}`
          } else {
            if (this.props.company === false) {
              url = `${BASE_END_POINT}products?page=${page}&name=${value}`
            } else {
              url = `${BASE_END_POINT}products?page=${page}&name=${value}&company=${this.props.company}`
            }
          }

        } else {
          url = `${BASE_END_POINT}products?page=${page}&company=${this.props.currentUser.user.id}&name=${value}`
        }
      }
      if (filterType === "category") {
        if (value !== "all") {
          if (this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN") {
            if (this.state.Company != null) {
              url = `${BASE_END_POINT}products?page=${page}&category=${value}&company=${this.state.Company}`
            } else {
              if (this.props.company === false) {
                url = `${BASE_END_POINT}products?page=${page}&category=${value}`
              } else {
                url = `${BASE_END_POINT}products?page=${page}&category=${value}&company=${this.props.company}`
              }
            }
          } else {
            url = `${BASE_END_POINT}products?page=${page}&company=${this.props.currentUser.user.id}&category=${value}`
          }
        } else {
          if (this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN") {
            if (this.state.Company != null) {
              url = `${BASE_END_POINT}products?page=${page}&company=${this.state.Company}`
            } else {
              if (this.props.company === false) {
                url = `${BASE_END_POINT}products?page=${page}`
              } else {
                url = `${BASE_END_POINT}products?page=${page}&company=${this.props.company}`
              }
            }
          } else {
            url = `${BASE_END_POINT}products?page=${page}&company=${this.props.currentUser.user.id}`
          }
        }

      }
    } else {
      if (this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN") {
        if (this.state.Company != null) {
          url = `${BASE_END_POINT}products?page=${page}&company=${this.state.Company}`
        } else {
          if (this.props.company === false) {
            url = `${BASE_END_POINT}products?page=${page}`
          } else {
            url = `${BASE_END_POINT}products?page=${page}&company=${this.props.company}`
          }
        }
      } else {
        url = `${BASE_END_POINT}products?page=${page}&company=${this.props.currentUser.user.id}`
      }
    }

    axios.get(url, {
      headers: {
        'Authorization': `Bearer ${this.props.currentUser.token}`
      }
    })
      .then(response => {
        console.log("Products   ", response.data)
        this.setState({
          loading: false,
          refresh: false,
          Products: response.data.data,
          productsCount: response.data.totalCount,
          page: response.data.page,
          pages: response.data.pageCount,
        })
        if (!this.state.print) {
          this.state.print = true

        }
      })
      .catch(error => {
        console.log("error   ", error.response)
        console.log("error2   ", error)
        this.setState({ loading: false, })
      })
    this.getProductsWithoutPagenation()
  }
  selectProductId = (e) => {
    e.preventDefault();
    let id = e.target.id.substring(7)
    console.log(id)
    this.setState({ selectedProduct: id })
  }
  deleteProduct = (id) => {
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}products/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`
      },
    })
      .then(response => {
        console.log("Product DELETED")
        l.then(() => message.success(allStrings.done, 2))
        this.getProducts(this.state.page)
      })
      .catch(error => {
        console.log(error.response)
        l.then(() => message.error('Error', 2.5))
      })
  }
  OKBUTTON = (e) => {
    this.deleteProduct(this.state.selectedProduct)
  }

  render() {
    console.log(this.state.filterType)
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.pagesPermission.includes('STOCK')) return <Redirect to='/Home' />

    return (
      <div className="Category">
        <div className="categoryTop" style={{ marginBottom: '3rem' }}>
          {/**/}

          {/**/}
          <MDBContainer>
            <MDBRow>
              <MDBCol xl="3" lg="3" md="3" sm="12">
                <ReactToPrint
                  documentTitle={"جرد المخزن بتاريخ " + moment().format("YYYY/MM/DD")}
                  trigger={() => {
                    return (
                      <div>
                        <img alt="" style={{ cursor: 'pointer' }} src={require('../../assets/images/icons8_pdf.png')}></img>
                        <span
                          style={{
                            color: '#FF3B40',
                            fontWeight: '600',
                            fontSize: '20px',
                            position: 'absolute',
                            padding: '10px 10px',
                            cursor: 'pointer'
                          }}>{allStrings.print}</span>
                      </div>
                    );
                  }}
                  content={() => this.componentRef}
                />
                <div style={{ display: 'none' }}>
                  <ComponentToPrint theCompany={this.state.theCompany} data={this.state.products} total={this.state.total} productsCount={this.state.productsCount} ref={el => (this.componentRef = el)} />
                </div>
              </MDBCol>
              <MDBCol xl="3" lg="3" md="3" sm="12">
                <img alt="" src={require('../../assets/images/icons8_depot.png')}></img>
                <span
                  style={{
                    color: 'mediumblue',
                    fontWeight: '600',
                    fontSize: '20px',
                    position: 'absolute',
                    padding: '25px 10px'
                  }}>{this.state.productsCount}</span>
                <p style={{
                  color: "mediumblue",
                  fontSize: "14px",
                  marginTop: "6px",
                  marginBottom: 0,
                  fontWeight: "600"
                }}>{allStrings.productsCount}</p>
              </MDBCol>
              <MDBCol xl="3" lg="3" md="3" sm="12">
                <img alt="" src={require('../../assets/images/icons8_money_bag.png')}></img>
                <span
                  style={{
                    color: 'mediumblue',
                    fontWeight: '600',
                    fontSize: '20px',
                    position: 'absolute',
                    padding: '25px 10px'
                  }}>{this.state.total}</span>
                <p style={{
                  color: "mediumblue",
                  fontSize: "14px",
                  marginTop: "6px",
                  marginBottom: 0,
                  fontWeight: "600"
                }}>{allStrings.totalProducts}</p>
              </MDBCol>
              <MDBCol xl="3" lg="3" md="3" sm="12">
                <NavLink to="stockForm">
                  <div className="addButton">
                    <MDBIcon icon="plus" />
                  </div>
                  <span
                    style={{
                      float: 'right',
                      color: '#43425D',
                      fontWeight: '600',
                      padding: '11px 2px',
                      fontSize: '20px',
                      cursor: 'pointer',
                    }}>{allStrings.addProduct}</span>
                </NavLink>
              </MDBCol>
            </MDBRow>

          </MDBContainer>


        </div>
        <MDBRow>
          <div onClick={() => this.getProducts(1, false)} style={{ width: '100%', textAlign: 'end', paddingRight: '28px' }}>
            <span style={{ color: 'cadetblue', cursor: 'pointer' }}
              class="material-icons">undo</span>
          </div>
        </MDBRow>
        <div className="filters" style={{ marginBottom: "2rem" }}>
          <MDBContainer>
            <MDBRow>
              <MDBCol xl="4" lg="6" md="12" sm="12">
                <Input
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      if (hasValue("" + e.target.value)) {
                        console.log("ENTER   ", e.target.value)
                        this.setState({ filterType: 'id', value: e.target.value })
                        this.getProducts(1, false, "id", e.target.value)

                      } else {
                        message.warning(allStrings.noValue);
                        console.log("ENTER NO VALUE   ", e.target.value)
                      }
                    }
                  }}

                  placeholder={allStrings.idFilter}
                  size="large" prefix={<SearchOutlined />} />
              </MDBCol>
              <MDBCol xl="4" lg="6" md="12" sm="12">
                <Input
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      if (hasValue("" + e.target.value)) {
                        console.log("ENTER   ", e.target.value)
                        this.setState({ filterType: 'name', value: e.target.value })
                        this.getProducts(1, false, "name", e.target.value)
                      } else {
                        message.warning(allStrings.noValue);
                        console.log("ENTER NO VALUE   ", e.target.value)
                      }
                    }
                  }}
                  placeholder={allStrings.nameFilter}
                  size="large" prefix={<SearchOutlined />} />
              </MDBCol>
              <MDBCol xl="4" lg="6" md="12" sm="12">
                <Select
                  style={{ width: '100%' }}
                  placeholder={allStrings.category}
                  onChange={(value) => {
                    console.log(value)
                    this.setState({ filterType: 'category', value: value })
                    this.getProducts(1, false, "category", value)

                  }}
                >
                  <Option value="all">{allStrings.all}</Option>
                  {this.state.categories.map(val => (
                    <Option value={val.id} style={{ direction: this.props.isRTL ? 'rtl' : 'ltr' }}>
                      {this.props.isRTL ? val.categoryName_ar : val.categoryName_en}
                    </Option>
                  ))}
                </Select>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
        <MDBContainer>
          <MDBRow>
            {this.state.loading ?
              <LoadCard />
              :
              this.state.Products.length > 0 ?
                this.state.Products.map(product => (

                  <MDBCol xl="4" lg="6" md="12" sm="12">

                    <div className="categoryCard">
                      <MDBContainer>
                        <MDBRow>

                          <MDBCol xl="2" lg="2" md="2" sm="2">
                            <div className="icons">
                              <Popconfirm
                                title={allStrings.areYouSure}
                                onConfirm={this.OKBUTTON}
                                onCancel={this.fCANCELBUTTON}
                                okText={allStrings.ok}
                                cancelText={allStrings.cancel}
                              >
                                <p style={{
                                  fontSize: '24px',
                                  color: '#A4A3B0',
                                  marginBottom: '8px',
                                  cursor: 'pointer',
                                  zIndex: '4',
                                  position: 'relative'
                                }} onClick={this.selectProductId} id={"product" + product.id}>
                                  <MDBIcon icon="times" onClick={this.selectProductId} id={"product" + product.id} />
                                </p>

                              </Popconfirm>

                              <p onClick={() => this.props.history.push('/stockForm', { data: product })}
                                style={{
                                  fontSize: '18px',
                                  color: '#A4A3B0',
                                  marginBottom: '8px',
                                  cursor: 'pointer',
                                }}>
                                <MDBIcon icon="pen" />
                              </p>
                              <p style={{ fontWeight: '700', color: '#43425D' }}>{product.quantity}</p>
                            </div>
                          </MDBCol>
                          <MDBCol xl="6" lg="6" md="6" sm="6" className="noPadding">
                            <div className="categoryInfo" onClick={() => this.props.history.push('/stockForm', { data: product })}>
                              <p style={{
                                fontWeight: '600',
                                color: '#43425D',
                                marginBottom: '6px',
                                fontSize: '22px'

                              }}>{product.name_ar.length > 15 ? product.name_ar.substring(0, 12) + ".." : product.name_ar}</p>
                              <p style={{
                                color: '#A4A3B0',
                                fontSize: '14px',
                                marginBottom: '8px'
                              }}>{product.name_en.length > 15 ? product.name_en.substring(0, 12) + ".." : product.name_en}</p>
                              <p style={{
                                color: '#43425D',
                                fontSize: '16px',
                                margin: 0,
                                fontWeight: '400', marginBottom: '10px'
                              }}> # {product.id}</p>
                            </div>
                          </MDBCol>
                          <MDBCol xl="4" lg="4" md="4" sm="4">

                            <div className="categoryImg" onClick={() => this.props.history.push('/stockForm', { data: product })}>
                              <img alt="" src={product.mainImg} width="80px" height="80px"></img>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </MDBContainer>
                    </div>
                  </MDBCol>
                ))
                :
                <div className="noData">{allStrings.noData}</div>
            }
          </MDBRow>
          <MDBRow>
            {this.state.Products.length > 0 &&
              <div style={{ textAlign: 'center', margin: 'auto', marginTop: '3rem' }}>
                <Pagination
                  onChange={(event, page) => {
                    console.log("page   ", page)
                    if (page !== this.state.page) {
                      this.getProducts(page, false, this.state.filterType, this.state.value)
                      this.setState({ page: page })
                    }

                  }}
                  defaultPage={1} page={this.state.page} count={this.state.pages} style={{ color: `blue !important` }} />
              </div>
            }

          </MDBRow>
        </MDBContainer>
      </div>
    )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
}

export default withRouter(
  connect(mapToStateProps, mapDispatchToProps)(Product)
);
