/* eslint-disable eqeqeq */
import React from "react";
import "./login.css";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
} from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import {hasValue} from '../../validations/validations'
import {message} from 'antd';
import {NavLink} from 'react-router-dom';
import {ChangeCompany,ChangeBranch} from '../../redux/actions/CompanyAction'

class Login extends React.Component {
  state = {
    phone: "",
    password: "",
  };

  constructor(props){
    super(props)
    console.log("isr   ",this.props.history)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    window.scrollTo(0,0)
  }

  login = () =>{
      const {phone,password} = this.state
      if(hasValue(phone)&&hasValue(password)){

        let l = message.loading('Wait..', 2.5)
        console.log("HAS VAL")
        const data={
            phone:phone,
            password:password
        }
        axios.post(`${BASE_END_POINT}signin`,JSON.stringify(data),{
            headers:{
                "Content-Type":"application/json"
            }
        })
        .then(response=>{ 
            //if(response.data.user.type=='ADMIN'){
                l.then(() => message.success('Welcome', 2.5) )
                this.props.setUser(response.data)
                console.log(response.data)
                localStorage.setItem('@USERQSAT',JSON.stringify(response.data))
                //this.props.history.replace('/Home') 
                if(response.data.user.type == "ADMIN"){
                  this.props.ChangeCompany(response.data.user.company.id)
                  localStorage.setItem("@COMPANY",response.data.user.company.id)
                }
                
                if(response.data.user.type == "ADMIN" ){
                  this.props.history.push('/Home')  
                }
                if(response.data.user.type == "SUB-ADMIN" && response.data.user.gineralSubAdmin == true){
                  this.props.history.push('/Home')  
                }
                if(response.data.user.type == "COMPANY" ){
                  this.props.history.push('/CompanyHome')  
                }  
                if(response.data.user.type == "SUB-ADMIN" && response.data.user.gineralSubAdmin == false){
                  this.props.history.push('/CompanyHome')  
                }


            //}else{
            //    l.then(() => message.error(allStrings.thisUserIsnotAdmin, 2.5))
            //}
        })
        .catch(error=>{
            console.log("ERROR   ",error.response)
            l.then(() => message.error(allStrings.userDataIncorrect, 2.5))
             
        })
      }
  }

  submitHandler = (event) => {
    event.preventDefault();
    console.log(event.target);
    event.target.className += " was-validated";
    this.login()
  };

  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <div className="login">
        <MDBContainer className="loginContainer">
          <MDBRow>
            <MDBCol  xl="6" lg="6" md="4" sm="12" className="noPadding">
              <div className="loginLeft">

              </div>
            </MDBCol>
            <MDBCol  xl="6" lg="6" md="8" sm="12">
              <div className="loginRight">
                <div className="logoDiv">
                  <img alt=""  style={{
                      margin: 'auto',
                      display: 'block',
                      marginTop: '4rem'
                  }} width="120px" src={require('../../assets/images/upload.png')}></img>
                </div>
                <br></br>
                <div className="formContainer">
                <MDBContainer>
                      <MDBRow>
                        <MDBCol md="12">
                          <form
                            className="needs-validation"
                            onSubmit={this.submitHandler}
                            noValidate
                          >
                            {/*<p className="h5 text-center mb-4">Welcome</p>*/}
                            <div className="grey-text">
                              <MDBInput
                                name="phone"
                                hint={allStrings.username}
                                style={{direction:this.props.isRTL?'rtl':'ltr'}}
                                //icon="envelope"
                                group
                                type="text"
                                onChange={this.changeHandler}
                                required
                                validate
                                error="wrong"
                                success="right"
                              />
                              <div className="valid-feedback">Looks good!</div>
                              <MDBInput
                                onChange={this.changeHandler}
                                name="password"
                                required
                                hint={allStrings.password}
                                style={{direction:this.props.isRTL?'rtl':'ltr'}}
                                //icon="lock"
                                group
                                type="password"
                                validate
                              />
                              <div className="valid-feedback">Looks good!</div>
                            </div>
                            <NavLink to="/forgetPassword">
                              <p className="forgetP">{allStrings.forgetPassword}</p>
                            </NavLink>
                           
                            <div className="text-center">
                              <MDBBtn
                                type="submit"
                                style={{ backgroundColor: '#43425D ',width:'170px' }}
                                rounded
                                className="btn z-depth-1a"
                              >
                                {allStrings.signin}
                              </MDBBtn>
                            </div>
                          </form>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

  const mapToStateProps = state => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
    branch: state.company.branch,
    company: state.company.company,
  })
  
  const mapDispatchToProps = {
    setUser,
    ChangeCompany,
    ChangeBranch,
  }
  export default  withRouter(
    connect(mapToStateProps,mapDispatchToProps)(Login)
  );
  
