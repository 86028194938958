/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from 'react';
import moment from 'moment'
import locale_ar from 'antd/lib/locale/ar_EG';
import locale_en from 'antd/lib/locale/en_GB';
import 'moment/locale/ar';
import { connect } from 'react-redux'
import {MDBContainer,MDBRow,MDBCol} from "mdbreact";
import "./print guarantor.css";
import allStrings from '../../assets/strings'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import {ChangeCompany,ChangeBranch} from '../../redux/actions/CompanyAction'
import {setUser} from '../../redux/actions/AuthActions'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
class PrintGuarantor extends React.Component {
  state = {
    advertisementUrl:''
  }
  constructor(props){
    super(props)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
   
  }
  getSetting = () =>{
    this.setState({loading:true})
    axios.get(`${BASE_END_POINT}setting`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log("Setting   ",response.data)
      this.setState({
        advertisementUrl:response.data.advertisementUrl,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  componentDidMount() {
    //this.getSetting()
    console.log("data",this.props)
    
  }

  render(){
  const {data,order} = this.props
  console.log("data",data)
      return ( 
        <div style={{textAlign:'center',background:'#FFFFFF',fontFamily:'Roboto'}}>
         {/* بيانات الضامن */}
        {data&&order&&
          <MDBContainer>
              <MDBRow>
                <MDBCol size="12">
                    <p style={{background: '#DADADA',padding: '5px',marginBottom:'0px',
                      fontWeight: '600',fontSize: '20px',border:'2px solid ',borderBottom:'none'}}>بيانات العميل</p>
                  <MDBRow>
                    <MDBCol size="5" style={{paddingRight:'0px'}}>
                      <div style={{textAlign: 'end',padding: '10px',height: '140px',fontWeight: '600',
                          fontSize: '20px',border:'2px solid ',borderRight:'none'}}>
                        <p>
                          <span>تاريخ الشراء / </span>
                          <span>{order?moment(order.createdAt).format("YYYY/MM/DD"):""}</span>
                        </p>
                        <p>
                          <span> المنتج :  </span>
                          <span> {order.product?order.product.name_ar:""}</span>
                          <span>
                            {order.paymentSystem==="installment" && order.processType != "MULTI" ?
                            <span>
                              <span style={{flexDirection:'row',display: 'inline-flex',justifyContent: 'center',marginLeft:'2px',marginRight:'2px'}}>
                                {order.product?order.product.name_ar.substring(0, 30):""}
                              </span>
                              {" - "+data.count + " "}
                              
                            </span>
                            
                            :
                            order.product?order.product.name_ar:""
                            }
                          </span>
                        </p>
                      </div>
                    </MDBCol>
                    <MDBCol size="7" style={{paddingLeft:'0px'}}>
                      <div style={{textAlign: 'end',padding: '10px',height: '140px',
                          fontWeight: '600',fontSize: '30px',border:'2px solid '}}>
                        <p style={{marginBottom:'5px'}}>{order?order.company.fullname.substring(0, 20):"لايوجد"}</p>
                        <p>{order.company?order.company.companySpecialization.substring(0, 20):"شاشات - لابات - اجهزه كهربائيه - موبايلات"}</p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol size="6"  style={{paddingRight:'0px'}}>
                      <div style={{textAlign: 'end',padding: '10px',height: '140px',fontWeight: '600',
                          fontSize: '20px',border:'2px solid ',borderRight:'none',borderTop:'none'}}>
                        <p>
                          <span> مندوب البيع :  </span>
                          <span>  {order.buySalesMan?order.buySalesMan.fullname.substring(0, 20):"لايوجد"}</span>
                        </p>
                        <p>
                          <span> مندوب التحصيل :  </span>
                          <span>  {order.salesMan?order.salesMan.fullname.substring(0, 20):"لايوجد"}</span>
                        </p>
                      </div>
                    </MDBCol>
                    <MDBCol size="6" style={{paddingLeft:'0px'}}>
                      <div style={{textAlign: 'end',padding: '10px',height: '140px',fontWeight: '600',
                          fontSize: '20px',border:'2px solid ',borderLeft:'none',borderTop:'none'}}>
                        <p>
                          <span> كود العميل / </span>
                          {/* <span>{order.client?order.client.id:"لايوجد"}</span> */}
                          <span>{order.client.clientCompanyIds.find(e => e.company === order.company.id)?order.client.clientCompanyIds.find(e => e.company === order.company.id).value:"لايوجد"}</span>
                        </p>
                        <p>
                          <span> اسم العميل :  </span>
                          <span>{order.client?order.client.fullname.substring(0, 20):"لايوجد"}</span>
                        </p>
                        <p>
                          <span>  تليفون العميل :  </span>
                          <span> {order.client?order.client.phone.substring(0, 20):"لايوجد"} </span>
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <br></br>
                  <p style={{textAlign:'center',fontWeight:'400',fontSize:'20px'}}>صور البطاقه</p>
                  <br></br>
                  <MDBRow>
                    {data.guarantorCardImg?data.guarantorCardImg.map(val=>(
                      <MDBCol size="6">
                      <img style={{width: '100%',height: '270px',objectFit: 'contain'}} 
                      src={val} alt=""></img>
                      </MDBCol>
                    )):""}
                  </MDBRow>
                  <br></br>
                  <br></br>
                  <p style={{textAlign:'center',fontWeight:'400',fontSize:'20px'}}> صور الايصالات</p>
                  <br></br>
                  <MDBRow>
                    {data.receipts?data.receipts.map(val=>(
                      <MDBCol size="12">
                      <img style={{width: '100%',height: '270px',objectFit: 'contain'}} 
                      src={val} alt=""></img>
                      </MDBCol>
                    )):""}
                  </MDBRow>
                </MDBCol>
              </MDBRow>
          </MDBContainer>
        }
        </div>
      );
    }
  }

  const mapToStateProps = state => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
    branch: state.company.branch,
    company: state.company.company,
  })
  
  const mapDispatchToProps = {
    setUser,
    ChangeLanguage,
    ChangeCompany,
    ChangeBranch,
  }

  export default  connect(mapToStateProps,mapDispatchToProps)(PrintGuarantor);
