
import {LANG} from '../actions/types';

const initState = {
    isRTL:false,
    
}

const LanguageReducer = (state=initState,action) => {
    switch(action.type){
        case LANG:
            return {...state,isRTL:action.payload}
        default:
            return state;
    }
}

export default LanguageReducer;