/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from 'react';
import moment from 'moment'
import locale_ar from 'antd/lib/locale/ar_EG';
import locale_en from 'antd/lib/locale/en_GB';
import 'moment/locale/ar';
import { connect } from 'react-redux'
import {MDBContainer,MDBRow,MDBCol} from "mdbreact";
import "./receipt2.css";
import allStrings from '../../assets/strings'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import {ChangeCompany,ChangeBranch} from '../../redux/actions/CompanyAction'
import {setUser} from '../../redux/actions/AuthActions'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import zIndex from '@material-ui/core/styles/zIndex';
class PrintReceipt2 extends React.Component {
  state = {
    advertisementUrl:''
  }
  constructor(props){
    super(props)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
   
  }
  getSetting = () =>{
    this.setState({loading:true})
    axios.get(`${BASE_END_POINT}setting`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log("Setting   ",response.data)
      this.setState({
        advertisementUrl:response.data.advertisementUrl,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  componentDidMount() {
    //this.getSetting()
    console.log("data",this.props)
    
  }

  render(){
  const {data,marginBottom,index,theLength,advertisementUrl} = this.props
  console.log("advertisementUrl",advertisementUrl)
      return ( 
        <div style={{textAlign:'center',background:'#FFFFFF',fontSize:'16px',fontWeight:600,fontFamily:'Roboto'}}>
          {data&&
          <MDBContainer>
            <MDBRow>
              <MDBCol size="12" style={{paddingRight:'2px',paddingLeft:'2px'}}>
                <div style={{border:'6px solid',padding:'2px',height:'450px', overflow: 'hidden',marginBottom:marginBottom, marginTop:'10px'}}>
                  <MDBRow>
                    <MDBCol size="4" style={{paddingRight:'4px'}}>
                      <div className='tabelNewReciept2'>     
                          <table class="table table-bordered table-sm">
                              <thead style={{width:'100%'}}>
                                  <tr class="thead-light"  style={{border:'4px solid',width:'100%'}}>
                                    <th style={{border:'3px solid',width:'25%' ,textAlign:'center'}}>
                                      <p style={{marginBottom:'7px',marginTop:'7px',fontSize:'20px'}}>السعر</p>
                                    </th>
                                    <th style={{width:'60%',textAlign:'center'}}>
                                      <p style={{marginBottom:'7px',marginTop:'7px',fontSize:'20px'}}>المنتج</p>
                                    </th>
                                    <th style={{width:'15%',textAlign:'right'}}>
                                      <p style={{transform: 'rotate(-90deg)',display: 'inline-block',marginBottom:'7px',marginTop:'7px',fontSize:'20px'}}>العدد</p>
                                    </th>
                                  </tr>
                              </thead>
                              <tbody style={{height:'280px',display: 'block',width:'100%',textAlign:'center'}} className="bodyOrders">
                              {data.order?data.order.multiProducts.map(val=>(
                                <div style={{height:'30px',overflow:"hidden",width:"100%"}}>
                                  <tr style={{borderColor:'#fff',border:'1px solid #fff'}} className="trRemove">
                                    <th scope="row" style={{border:'none',borderColor:'#fff',width:'25%' ,textAlign:'center'}}><span style={{fontSize:'20px'}}>{val.cost}</span></th>
                                    <th scope="row" style={{border:'none',borderColor:'#fff',width:'60%',textAlign:'center'}}><span style={{textDecoration:'underline',fontSize:'20px'}}>{val.product?val.product.name_ar:""}</span></th>
                                    <th scope="row" style={{border:'none',borderColor:'#fff',width:'15%' ,textAlign:'right',}}><span style={{paddingRight:'10px',fontSize:'20px'}}>{val.count}</span></th>
                                  </tr>
                                  </div>
                              )):""}
                              </tbody>
                              <div className="salesMenDiv" ><div className="salesMenSpan" >
                              <tr >
                              <th colspan="2" style={{ paddingBottom: '4px', paddingTop: '4px' }}>
                              {data.order ? data.order.multiBuySalesMen.length > 1 ? data.order.multiBuySalesMen.map((val, i) => (
                                i < 3 ?
                                  <span  style={{fontSize:'19px'}}> {val.buySalesMan ? val.buySalesMan.fullname.substring(0, 8) : ""} - </span>
                                  :
                                  "..."
                              )) : <span>{data.order.buySalesMan.fullname}</span> : ""}
                            </th>
                                <th colspan="1">
                                  <span> : البيع </span>
                                </th></tr>
                                </div>
                              </div>
                          </table>
                      </div> 
                    </MDBCol>
                    <MDBCol size="4" style={{paddingLeft:'0px',paddingRight:'0px'}}>
                      <div className='tabelNewReciept' >     
                          <table class="table table-bordered table-sm">
                              <thead>
                                  <tr class="thead-light" >
                                    <th colspan="2">
                                    <div className='topAdvURL'>
                                      <p>{advertisementUrl?advertisementUrl.substring(0,40):"لايوجد"} </p>
                                    </div>
                                    </th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                    {/* <th scope="row">{data.order?data.order.costPaid+data.costPerMonth:''}</th> */}
                                    <th scope="row">
                                    <div className='tableItem'>
                                      {data.order.customPremiums == true?
                                        <span>{data.order?data.costPaid?data.costPaid:'0':'0'}</span>
                                        :
                                        <span>{data.order?data.order.paymentSystem=="cash"?data.order.total:data.order.firstPaid + (parseInt(data.receiptNum) * data.costPerMonth):''}</span>
                                      }
                                      </div>
                                    </th>
                                    
                                  
                                   
                                    <th scope="row">  <div className='tableItem'>  المدفوع  </div></th>
                                     
                                 
                                  </tr>
                                  <tr>
                                    {/* <th scope="row">{data.order?(data.order.monthRemaining * data.costPerMonth) - data.costPerMonth:''}</th> */}
                                    <th scope="row">
                                    <div className='tableItem'>
                                      {data.order.customPremiums == true?
                                      <span>{data.order?data.costRemaining?data.costRemaining:'0':'0'}</span>
                                      :
                                      <span>{data.order?data.order.paymentSystem=="cash"?'0':((data.order.monthCount * data.costPerMonth) + data.order.firstPaid) - (data.order.firstPaid + (parseInt(data.receiptNum) * data.costPerMonth)):''}</span> 

                                      }
                                      </div>
                                    </th>

                                    
                                    <th scope="row"><div className='tableItem'> المتبقى</div></th>
                                  </tr>
                                  <tr>
                                    <th scope="row"><div className='tableItem'> {data.order?data.order.firstPaid:''}</div></th>
                                    <th scope="row"><div className='tableItem'> المقدم</div></th>
                                  </tr>
                                  <tr>
                                    <th scope="row"><div className='tableItem'> {data.order?data.order.premuimsDesc:""}</div></th>
                                    <th scope="row"><div className='tableItem'> عدد الاقساط </div></th>
                                  </tr>
                                  <tr>
                                    <th scope="row"> 
                                    <div className='tableItem'>
                                      {data.order.delayPremiums>0&&  
                                        <span style={{flexDirection:'row',display: 'inline-flex',justifyContent: 'center',marginLeft:'2px',marginRight:'2px'}}>
                                          <span> ( </span>
                                          <span style={{flexDirection:'row',display: 'inline-flex',justifyContent: 'center',marginLeft:'2px',marginRight:'2px'}}> م  </span>
                                          <span style={{flexDirection:'row',display: 'inline-flex',justifyContent: 'center',marginLeft:'2px',marginRight:'2px'}}> 
                                            <span style={{flexDirection:'row',display: 'inline-flex',justifyContent: 'center'}}>{data.order?data.order.delayPremiums:"0"}</span>
                                            <span> ) </span>
                                          </span>
                                        </span>
                                      }
                                      <span style={{flexDirection:'row',display: 'inline-flex',justifyContent: 'center',marginLeft:'2px',marginRight:'2px'}}> {data.order?data.order.monthCount:""} </span>
                                      <span style={{flexDirection:'row',display: 'inline-flex',justifyContent: 'center',marginLeft:'2px',marginRight:'2px'}}> من </span>
                                      <span style={{flexDirection:'row-reverse',display: 'inline-flex',justifyContent: 'center',marginLeft:'2px',marginRight:'2px'}}> قسط </span>
                                      <span style={{flexDirection:'row',display: 'inline-flex',justifyContent: 'center',marginLeft:'2px',marginRight:'2px'}}>{data?data.receiptNum:"1"}</span>
                                      </div>
                                    </th>
                                    <th scope="row"><div className='tableItem'> القسط رقم</div></th>
                                    
                                  </tr>
                                  <tr>
                                    <td scope="row"><div className='tableItem'>
                                    <p style={{background: '#DBDADA'}}>{data.costPerMonth?data.costPerMonth:""}</p>
                                  </div>  </td>
                                    <th  scope="row" ><div className='tableItem'> قيمه القسط</div></th>
                                  </tr>
                                  <tr>
                                    <th scope="row"><div className='tableItem'>{data.order?data.order.total:""}</div></th>
                                    <th scope="row"><div className='tableItem'> قيمه الشراء</div></th>
                                  </tr>
                                  <tr>
                                    <th scope="row"><div className='tableItem'>{data.order?moment(data.order.startDate).format("YYYY/MM/DD"):""}</div></th>
                                    <th scope="row"><div className='tableItem'> تاريخ الشراء</div></th>
                                  </tr>
                                  <tr>
                                    <th scope="row"><div className='tableItem'>{data?moment(data.paidDate).format("YYYY/MM/DD"):""}</div></th>
                                    <th scope="row"><div className='tableItem'> تاريخ التحصيل</div></th>
                                  </tr>
                                  <tr>
                                    <th scope="row"><div className='tableItem'>
                                      <span>{data.salesMan?data.salesMan.fullname.substring(0,15):""}</span>
                                      <span> - </span>
                                      <span>{data.salesMan?data.salesMan.phone.substring(0,12):""}</span>
                                      </div>
                                    </th>
                                    <th scope="row"><div className='tableItem'>مندوب التحصيل </div></th>
                                  </tr>
                              </tbody>
                          </table>
                      </div> 
                    </MDBCol>
                    <MDBCol size="4" style={{paddingLeft:'0px',textAlign:'right'}}>
                      <div className='tabelNewReciept'>     
                          <table class="table table-bordered table-sm">
                              <thead>
                                  <tr class="thead-light" >
                                    <th colspan="2">
                                    <div className='topCompanyName'>
                                      <p>
                                        {data.company?data.company.fullname.substring(0, 20):"لايوجد"} 
                                      </p>
                                      </div>
                                      <div className='topCompanyDesc'>
                                      <p>
                                        {data.company?data.company.companySpecialization.substring(0, 40):"شاشات - لابات - اجهزه كهربائيه - موبايلات"}
                                      </p>
                                      </div>
                                    </th>
                                  </tr>
                              </thead>
                              <tbody>
                             
                                  <tr>
                                 
                                    <th scope="row" >
                                       <div className='tableItem'>
                                      {/* {data.client?data.client.id:"لايوجد"} */}
                                      {data.client.clientCompanyIds.find(e => e.company === data.company.id)?data.client.clientCompanyIds.find(e => e.company === data.company.id).value:""}
                                   </div> 
                                   </th>
                                    <th scope="row">   <div className='tableItem'>
                                      كود العميل</div></th>
                                     
                                  </tr>
                                 
                                  <tr>
                                    <th scope="row"><div className='tableItem'>
                                        {data.client.jobArea?data.client.jobArea.substring(0, 20):"لايوجد"}</div>
                                    </th>
                                    <th scope="row"> <div className='tableItem'>المنطقه</div></th>
                                  </tr>
                                  <tr>
                                    <th scope="row"><div className='tableItem'>{data.client?data.client.fullname.substring(0, 20):"لايوجد"}</div></th>
                                    <th scope="row"><div className='tableItem'> الاسم</div></th>
                                  </tr>
                                  <tr>
                                    <th scope="row"><div className='tableItem'>{data.client?data.client.phone.substring(0, 20):"لايوجد"} </div></th>
                                    <th scope="row"><div className='tableItem'> رقم التليفون</div></th>
                                  </tr>
                                  <tr>
                                    <th scope="row"><div className='tableItem'>{data.client?data.client.address.substring(0, 20):"لايوجد"} </div></th>
                                    <th scope="row"> <div className='tableItem'>العنوان</div></th>
                                  </tr>
                                  <tr>
                                    <th scope="row"><div className='tableItem'>{data.client?data.client.cardNum.substring(0, 20):"لايوجد"} </div></th>
                                    <th scope="row"> <div className='tableItem'>الرقم القومى</div></th>
                                  </tr>
                                  <tr>
                                    <th scope="row"><div className='tableItem'>{data.client?data.client.jobLocation.substring(0, 20):"لايوجد"}</div></th>
                                    <th scope="row"> <div className='tableItem'>مكان العمل</div></th>
                                  </tr>
                                  <tr>
                                    <th scope="row"><div className='tableItem'>{data.client?data.client.job.substring(0, 20):"لايوجد"}</div></th>
                                    <th scope="row"><div className='tableItem'>الوظيفه </div></th>
                                  </tr>
                                  <tr>
                                    <th scope="row"><div className='tableItem'>{data.client?data.client.jobLevel.substring(0, 20):"لايوجد"}</div></th>
                                    <th scope="row"><div className='tableItem'>الدور </div></th>
                                  </tr>
                              </tbody>
                          </table>
                      </div> 
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol size="12">
                    <div className='bottomCompanyInformation'>
                      <p >
                       {data.company?data.company.contactDetails?data.company.contactDetails.substring(0, 280):"لايوجد":"لايوجد"} 
                      </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <br></br>
                  {index===theLength-1 && index%3 ===1?
                  <p style={{fontSize:'25px',textAlign:'center',fontWeight:'bold',position:'absolute',top:'460px',left:'49%',zIndex:'99999'}}>{ index===2?1:index>0?index%3 === 1?Math.ceil(index/3):"":""}</p>
                  :
                  <p style={{fontSize:'25px',textAlign:'center',fontWeight:'bold',position:'absolute',top:'460px',left:'49%',zIndex:'99999'}}>{ index===2?1:index>0?index%3 === 2?Math.ceil(index/3):"":""}</p>
                  }
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          }
        </div>
      );
    }
  }

  const mapToStateProps = state => ({
    isRTL: state.lang.isRTL,
    currentUser: state.auth.currentUser,
    branch: state.company.branch,
    company: state.company.company,
  })
  
  const mapDispatchToProps = {
    setUser,
    ChangeLanguage,
    ChangeCompany,
    ChangeBranch,
  }

  export default  connect(mapToStateProps,mapDispatchToProps)(PrintReceipt2);
