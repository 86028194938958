export const LANG = 'LANG';
export const BRANCH = 'BRANCH';
export const COMPANY = 'COMPANY';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const USER_TOKEN = 'USER_TOKEN';
export const LOGOUT = 'LOGOUT';
export const CURRENT_USER = 'CURRENT_USER';
export const LOGOUT_LOADING = 'LOGOUT_LOADING';
export const LOGOUT_LOADING_END = 'LOGOUT_LOADING_END';
export const CHAT = 'CHAT';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const UNREADED_NOTIFICATION = 'UNREADED_NOTIFICATION';







