import React from 'react';
import './branches.css'
import { MDBContainer,  MDBRow, MDBCol,MDBBtn,MDBIcon,MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import {ChangeCompany,ChangeBranch} from '../../redux/actions/CompanyAction'
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import { Popconfirm,message,Input,TreeSelect} from 'antd';
import { SearchOutlined  } from '@ant-design/icons';
import Pagination from '@material-ui/lab/Pagination';
import LoadCard from '../../component/load card/load card'
import { hasValue } from "../../validations/validations";
import ReactToPrint from 'react-to-print';
const{TreeNode} = TreeSelect
class ComponentToPrint extends React.Component {
 
  render() {
console.log(this.props)
    return (
      <form class="col s12">
          <div className='tabelDev'>     
            <div class="row">
              <table class="table table-borderless">
                  <thead class="thead-light">
                      <tr>
                        <th>الاسم</th>
                       
                      </tr>
                      <tr>
                        <th>Branch Name</th>
                        
                      </tr>
                  </thead>

                  <tbody>
                    { this.props.data.map(branch=>(
                        <tr>
                          <td>{branch.branchName?branch.branchName:''}</td>
                        </tr>
                    ))}
                  </tbody>
              </table>
              </div>
              </div>          
         </form>
    );
  }
}
class Branch extends React.Component {
  
  state = {
    Branchs:[],
    Branch:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    modal: false,
    branchName:'',
    requestType:'post',
    selectedBranch:null,
    filterType:null,
    value:'',
    branches:[],
    company:this.props.company != false ?this.props.company:this.props.currentUser.user.id,
    theCompany:null,
    selectCompany:false,
    companies:[]
  }
  constructor(props){
    super(props)
    
    this.getBranchesWithoutPagenation()
    console.log("isr   ",this.props.history)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    window.scrollTo(0,0)
  }
  getBranchesWithoutPagenation = () => {
    let url =``;
      if(this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN"){
        if(this.props.company == false){
          url = `${BASE_END_POINT}branch/withoutPagenation/get`
        }else{
          url = `${BASE_END_POINT}branch/withoutPagenation/get?company=${this.props.company}`
        }
        
      } else{
        url = `${BASE_END_POINT}branch/withoutPagenation/get?company=${this.props.currentUser.user.id}`
      }
    axios.get(url)
    .then(response=>{
      console.log(response.data.Branchs)
      this.setState({branches:response.data.Branchs,branchCount:response.data.branchsCount})
    })
    .catch(error=>{
      console.log("ALL branches ERROR")
      console.log(error.response)
    })
  }

  componentDidMount(){
    if(this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN" ){
      if(this.props.company == false){
        this.setState({company:this.props.currentUser.user.company[0].id,selectCompany:false})
      }else{
        this.setState({company:this.props.company,selectCompany:true})
      }
    } else{
      this.setState({company:this.props.currentUser.user.id,selectCompany:false})
    } 
    if(this.props.currentUser.user.gineralSubAdmin == false|| this.props.currentUser.user.type == "SUB-ADMIN" ){
      this.setState({company:this.props.currentUser.user.company[0].id,selectCompany:false})
    }
    this.getBranchs(1,false)
    this.getCompany()
    //this.props.ChangeLanguage(false)
  }
  getCompany = () => {
    let url =``;
    url = `${BASE_END_POINT}withoutPagenation/get?type=COMPANY`
    axios.get(url)
    .then(response=>{
      console.log(response.data.users[0])
      this.setState({companies:response.data.users,})
    })
    .catch(error=>{
      console.log("ALL companies ERROR")
      console.log(error.response)
    })
  }
  getBranchs = (page,refresh,filterType,value) =>{
    this.setState({loading:true})
    let url = ``;
    if(filterType != null && value != ''){
      if(filterType == "name"){
        if(this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN"){
          if(this.props.company == false){
            url = `${BASE_END_POINT}branch?page=${page}&name=${value}`
          }else{
            url = `${BASE_END_POINT}branch?page=${page}&name=${value}&company=${this.props.company}`
          }
          
        } else{
          url = `${BASE_END_POINT}branch?page=${page}&company=${this.props.currentUser.user.id}&name=${value}`
        }
      }
    }else{
      if(this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN"){
        if(this.props.company == false){
          url = `${BASE_END_POINT}branch?page=${page}`
        }else{
          url = `${BASE_END_POINT}branch?page=${page}&company=${this.props.company}`
        }
      } else{
        url = `${BASE_END_POINT}branch?page=${page}&company=${this.props.currentUser.user.id}`
      }
    }
    
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("Branchs   ",response.data)
      this.setState({
        loading:false,
        refresh:false,
        Branchs:response.data.data,
        page:response.data.page,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })

  }
  selectBranchId = (e) =>{
    e.preventDefault();
    let id = e.target.id.substring(6)
    console.log(id)    
    this.setState({selectedBranch:id})
  }
  deleteBranch = (id) => {
    let l = message.loading('Wait..', 2.5)
    console.log(this.props.currentUser.user.type)
    axios.delete(`${BASE_END_POINT}branch/${id}`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`
     },
    })
    .then(response=>{
      console.log("Branch DELETED")
      l.then(() => message.success(allStrings.done, 2) )
      this.getBranchs(this.state.page)
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error('Error', 2.5))
    })
 }
 updateBranch = () => {
  const {branchName,theCompany,company} = this.state
  if(hasValue(branchName)){
      let l = message.loading('Wait..', 2.5)
      let data = {
        'branchName':branchName
      }
      if(theCompany != null){
        data.company = theCompany
      }else{
        data.company = company
      }
      axios.put(`${BASE_END_POINT}branch/${this.state.selectedBranch}`,data,{
      headers: {
      'Content-Type': 'application/json',
      'Authorization':`Bearer ${this.props.currentUser.token}`
      },
      })
      .then(response=>{
      l.then(() => message.success(allStrings.done, 2.5) )
        this.setState({loading:true}) 
        this.toggle()
        this.getBranchs(1,false)

      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error('Error', 2.5))
      })
  }
}
 addBranch = () => {
  const {branchName,theCompany,company} = this.state
  if(hasValue(branchName)){
      let l = message.loading('Wait..', 2.5)
      let data = {
        'branchName':branchName
      }
      if(theCompany != null){
        data.company = theCompany
      }else{
        data.company = company
      }
           
      axios.post(`${BASE_END_POINT}branch`,data,{
      headers: {
      'Content-Type': 'application/json',
      'Authorization':`Bearer ${this.props.currentUser.token}`
      },
      })
      .then(response=>{
      l.then(() => message.success(allStrings.done, 2.5) )
        this.setState({loading:true}) 
        this.toggle()
        this.getBranchs(1,false)
        window.location.reload()

      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(error.response.data.errors[0].msg, 2.5))
      })
  }
}

  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  addSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.addBranch()
  };
  openToAdd = () =>{
    this.setState({requestType:'post'})
    this.toggle()
  }
  selectBranch = (e)=>{
    
    e.preventDefault();
    let id = e.target.value;
    console.log(e.target.value)
    axios.get(`${BASE_END_POINT}branch/${id}`,{},{
      headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`
      },
    })
    .then(response=>{
      console.log(response.data)
      this.toggle()
      this.setState({selectedBranch:id,requestType:'put',
      Branch:response.data,
      branchName:response.data.branchName})
    })
    .catch(error=>{
        console.log(error.response)
    })
  }
  updateSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.updateBranch()
  };

  OKBUTTON = (e) => {
    this.deleteBranch(this.state.selectedBranch)
   }
   toggle = () => {
    this.setState({
      modal: !this.state.modal,
      Branch:[],
      branchName:'',
    });
  }
  modal = () =>(
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
      <MDBModalHeader toggle={this.toggle}>{this.state.requestType=="post"?allStrings.completeData:allStrings.edit}</MDBModalHeader>
      <MDBModalBody>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
              <form style={{marginLeft: '15%'}}
                className="needs-validation"
                onSubmit={this.state.requestType==="post"?this.addSubmitHandler:this.updateSubmitHandler}
                noValidate
              >
                <MDBContainer>
                  
                  <MDBRow>
                    <MDBCol md="9">
                      <TreeSelect
                        showSearch={false} 
                        disabled={this.state.selectCompany == false?true:false}
                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'40px',width:'100%'}} 
                        treeCheckable = {false}         
                        treeNodeFilterProp="title"  
                        value={this.state.company}
                        placeholder={allStrings.chooseCompany}                  
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        onChange={(value) => {
                          console.log(value)
                          this.setState({theCompany:value})
                          
                        }} 
                      >
                        {this.state.companies.map(val=>(
                      <TreeNode value={val.id} title={val.fullname}  key={val.id} />
                      ))}
                    </TreeSelect>
                    </MDBCol>
                    <MDBCol md="3">
                      <label  htmlFor="defaultFormRegisterNameEx1" className="grey-text formLabel">
                      {allStrings.company}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="9">
                      <input name="branchName" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx1" required
                        placeholder={allStrings.branchName} className="form-control" style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.Branch.branchName} />
                    </MDBCol>
                    <MDBCol md="3">
                      <label  htmlFor="defaultFormRegisterNameEx1" className="grey-text formLabel">
                      {allStrings.branchName}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBBtn type="submit" style={{ margin: '3rem 1rem ',width:'170px',padding:'11px 20px',height:'35px', }}
                        className="btn z-depth-1a "
                        color="primary"
                      >
                        {this.state.requestType=="post"?allStrings.add:allStrings.edit}
                    </MDBBtn>
                  </MDBRow>
                </MDBContainer>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
   );
    
   
  render(){
    console.log(this.state.requestType)
  return (
    <div className="Branch">
      {this.modal()}
      <MDBContainer> 
      <MDBRow>
          <MDBCol xl="3" lg="3" md="3" sm="12">
            <ReactToPrint
              trigger={() => {
                return (
                  <div>
                    <img alt=""  style={{cursor:'pointer'}} src={require('../../assets/images/icons8_pdf.png')}></img>
                    <span
                    style={{
                      color: '#FF3B40',
                      fontWeight: '600',
                      padding: '10px',
                      fontSize: '20px',
                      position: 'absolute',
                      padding: '10px 10px',
                      cursor:'pointer'
                    }}>{allStrings.print}</span>
                  </div>
                ) ;
              }}
              content={() => this.componentRef}
            />
            <div style={{display:'none'}}>
            <ComponentToPrint  data={this.state.branches} ref={el => (this.componentRef = el)} />
            </div>
          </MDBCol>
         
          <MDBCol xl="6" lg="6" md="6" sm="12" style={{textAlign:'center'}}>
          <img alt=""  width= '40px' src={require('../../assets/images/icons8_merge_git.png')}></img>
            <span
            style={{
              color: 'mediumblue',
              fontWeight: '600',
              padding: '10px',
              fontSize: '30px',
              position: 'absolute',
              padding: '5px 10px'
            }}>{this.state.branchCount}</span>
            <p style={{
              color: "mediumblue",
              fontSize: "14px",
              marginTop: "6px",
              marginBottom: 0,
              fontWeight: "600"
            }}>{allStrings.branchCount}</p>
          </MDBCol>
          <MDBCol xl="3" lg="3" md="3" sm="12">
            <div className="addButton" onClick={()=>this.setState({requestType:'post'}),this.toggle}>
              <MDBIcon icon="plus" />
            </div>
            <span
            onClick={()=>this.setState({requestType:'post'}),this.toggle}
            style={{
              float: 'right',
              color: '#43425D',
              fontWeight: '600',
              padding: '11px 2px',
              fontSize: '20px',
              cursor:'pointer',
            }}>{allStrings.addBranch}</span>
          </MDBCol>
        </MDBRow>
        
        <div className="filters" style={{marginBottom: "2rem",marginTop: '2rem'}}>
        <MDBContainer> 
        <MDBRow>
          <div onClick={()=>this.getBranchs(1,false)} style={{width: '100%',textAlign: 'end',paddingRight: '18px'}}>
            <span style={{color: 'cadetblue',cursor: 'pointer'}}
            class="material-icons">undo</span>
          </div>
        </MDBRow>
          <MDBRow>
          <MDBCol xl="8" lg="8" md="6" sm="12">
            
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12">
            <Input 
              onKeyPress={(e)=>{
                if (e.key === 'Enter') {
                  if(hasValue(""+e.target.value)){
                    console.log("ENTER   ",e.target.value)
                    this.setState({filterType:'name',value:e.target.value})
                    this.getBranchs(1,false,"name",e.target.value)
                  }else{
                    message.warning(allStrings.noValue);
                    console.log("ENTER NO VALUE   ",e.target.value)
                  }
                }
              }}  
              placeholder={allStrings.nameFilter}
              size="large"  prefix={<SearchOutlined />} />
          </MDBCol>
          
          </MDBRow>
        </MDBContainer>
        </div>
        <MDBRow>
        {this.state.loading?
          <LoadCard/>
          :
          this.state.Branchs.length > 0 ?
            this.state.Branchs.map(Branch=>(
              
              <MDBCol xl="4" lg="6" md="12" sm="12">
                
              <div className="BranchCard">
                <button className="hiddenButton" onClick={this.selectBranch}  value={Branch.id}></button>
              <MDBContainer> 
                <MDBRow>
                  
                  <MDBCol xl="2" lg="2" md="2" sm="2">
                    <div className="icons">
                    <Popconfirm
                        title={allStrings.areYouSure}
                        onConfirm={this.OKBUTTON}
                        onCancel={this.fCANCELBUTTON}
                        okText={allStrings.ok}
                        cancelText={allStrings.cancel}
                      >
                      <p style={{
                            fontSize: '22px',
                            color: '#A4A3B0',
                            marginBottom: '4px',
                            cursor: 'pointer',
                            zIndex:'4',
                            position: 'relative'
                      }} onClick={this.selectBranchId} id={"Branch"+Branch.id}>
                        <MDBIcon icon="times" onClick={this.selectBranchId} id={"Branch"+Branch.id}/>
                      </p>
                      <p style={{
                          fontSize: '16px',
                          color: '#A4A3B0',
                          marginBottom: '4px',
                          cursor: 'pointer',
                      }}>
                      <MDBIcon icon="pen" />
                      </p>

                    </Popconfirm>
                    </div>
                  </MDBCol>
                  <MDBCol xl="10" lg="10" md="10" sm="10" className="noPadding">
                    <div className="BranchInfo">
                      <p style={{
                        fontWeight: '600',
                        color:'#43425D',
                        marginBottom: '6px',
                        fontSize: '22px',
                        textAlign: 'center',
  
                      }}>{Branch.branchName}</p>
                     
                    </div>
                  </MDBCol>
                 
                </MDBRow>
              </MDBContainer> 
              </div>
              </MDBCol>
            ))
            :
            <div className="noData">{allStrings.noData}</div>
        }
        </MDBRow>
        <MDBRow>
          {this.state.Branchs.length > 0 &&
            <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
              <Pagination
                onChange={(event,page)=>{
                console.log("page   ",page)
                if(page!=this.state.page){
                  this.getBranchs(page,false,this.state.filterType,this.state.value)
                  this.setState({page:page})
                }
                
                }} 
                defaultPage={1} page={this.state.page}  count={this.state.pages} style={{color:`blue !important`}} />
            </div>
          }
          
        </MDBRow>
      </MDBContainer> 
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(Branch)
);
