
import {BRANCH,COMPANY} from './types'

export function ChangeCompany(company){
  return(dispatch) => {
      dispatch({type:COMPANY,payload:company})
  }
}

export function ChangeBranch(branch){
  return(dispatch) => {
      dispatch({type:BRANCH,payload:branch})
  }
}