/* eslint-disable eqeqeq */
import React from "react";
import "./clientForm.css";
import {MDBContainer,MDBRow,MDBCol,MDBBtn,MDBIcon,MDBModal, MDBModalBody, MDBModalHeader} from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT,SOCKET_BASE_END} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import {hasValue} from '../../validations/validations'
import {message,TreeSelect,Checkbox } from 'antd';
import {NavLink} from 'react-router-dom';
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import moment from 'moment'
import io from 'socket.io-client';
import {ChangeCompany,ChangeBranch} from '../../redux/actions/CompanyAction'

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

const {TreeNode} = TreeSelect

class ClientForm extends React.Component {
  state = {
    fullname: this.props.location.state?this.props.location.state.data.fullname:"",
    password: this.props.location.state?this.props.location.state.data.password:"",
    phone: this.props.location.state?this.props.location.state.data.phone:"",
    anotherPhone: this.props.location.state?this.props.location.state.data.anotherPhone:"",
    address: this.props.location.state?this.props.location.state.data.address:"",
    notes: this.props.location.state?this.props.location.state.data.notes:"",
    cardNum: this.props.location.state?this.props.location.state.data.cardNum:"",
    job: this.props.location.state?this.props.location.state.data.job:"",
    jobLocation: this.props.location.state?this.props.location.state.data.jobLocation:"",
    jobArea: this.props.location.state?this.props.location.state.data.jobArea:"",
    jobLevel: this.props.location.state?this.props.location.state.data.jobLevel:"",

    guarantorName: this.props.location.state?this.props.location.state.data.guarantorName:"",
    guarantorCardNum: this.props.location.state?this.props.location.state.data.guarantorCardNum:"",
    guarantorPhone: this.props.location.state?this.props.location.state.data.guarantorPhone:"",
    guarantorPhone2: this.props.location.state?this.props.location.state.data.guarantorPhone2:"",
    guarantorNote: this.props.location.state?this.props.location.state.data.guarantorNote:"",
    
    city:this.props.location.state?this.props.location.state.data.city?this.props.location.state.data.city.id:[]:[],
    profileImg:this.props.location.state?this.props.location.state.data.img !=""?this.props.location.state.data.img :null:null,

    cardImg:this.props.location.state?this.props.location.state.data.cardImg.length > 0?this.props.location.state.data.cardImg:null:null,
    cardImgViews:this.props.location.state?this.props.location.state.data.cardImg:[],

    receipts:this.props.location.state?this.props.location.state.data.receipts.length > 0?this.props.location.state.data.receipts:null:null,
    receiptsViews:this.props.location.state?this.props.location.state.data.receipts:[],
    guarantorReceiptViews:this.props.location.state?this.props.location.state.data.guarantorReceipt:[], 

    guarantorCardImg:this.props.location.state?this.props.location.state.data.guarantorCardImg.length > 0?this.props.location.state.data.guarantorCardImg:null:null,
    guarantorCardImgViews:this.props.location.state?this.props.location.state.data.guarantorCardImg:[],
    guarantorReceipt:this.props.location.state?this.props.location.state.data.guarantorReceipt.length > 0?this.props.location.state.data.guarantorReceipt:null:null,

    profileimgType:this.props.location.state?'url':'data',
    cardImgType:this.props.location.state?'url':'data',
    recieptImgType:this.props.location.state?'url':'data',
    guarantorReceiptType:this.props.location.state?'url':'data',
    guarantorCardImgType:this.props.location.state?'url':'data',

    client:this.props.location.state?this.props.location.state.data:[],
    requestType:this.props.location.state?"put":"post",
    cities:[],
    hasGuarantor:this.props.location.state?this.props.location.state.data.guarantorCardNum?true:false:false,
    loading:true,
    orders:[],
    refresh:false,
    page:1,
    pages:1,
    modal: false,
    modalType:'map',
    message:'',
    photoIndex: 0,
    isOpen1: false,
    isOpen2: false,
    isOpen3: false,
    isOpen4: false,
    isOpen5: false,
    companyClientId:'',
  };

  constructor(props){
    super(props)
    
    console.log("isr   ",this.props.history)
    this.socket = io(SOCKET_BASE_END,{ query: `id=${this.props.currentUser.user.id}`} );  
    this.getCities()
    if(this.state.requestType =="put"){
      this.getOrders(1,false)
      
    }

    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    window.scrollTo(0,0)
  }
  getOrders = (page,refresh) =>{
    this.setState({loading:true})
    axios.get(`${BASE_END_POINT}orders?page=${page}&limit=5&client=${this.state.client.id}&company=${this.props.currentUser.user.type == 'ADMIN' ? this.props.company ? this.props.company : this.props.currentUser.user.company[0].id : this.props.currentUser.user.id}`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("orders   ",response.data)
      this.setState({
        loading:false,
        refresh:false,
        orders:response.data.data,
        page:response.data.page,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })

  }
  getCities = () => {
    axios.get(`${BASE_END_POINT}cities`)
    .then(response=>{
      console.log(response.data)
      this.setState({cities:response.data})
    })
    .catch(error=>{
      console.log("ALL cities ERROR")
      console.log(error.response)
    })
  }
  componentDidMount(){
    this.getCities()
    if(this.state.requestType =="put"){
      if(this.state.client.clientCompanyIds.length > 0){
        let company 
        if(this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN" ){
          if(this.props.company == false){
            company = this.props.currentUser.user.company[0].id
          }else{
            company = this.props.company 
          }
        } else{
          company = this.props.currentUser.user.id
        }
        let arr = this.state.client.clientCompanyIds
        var found = arr.find(e => e.company == company);
        console.log("found", found)
        this.setState({companyClientId:found.value})
      }
    }

  }
  addClient = () => {
    const {fullname,phone,anotherPhone,profileImg,receipts,
      guarantorName,guarantorPhone,guarantorPhone2,guarantorCardNum,guarantorNote,guarantorReceipt,
           cardNum,address,notes,city,cardImg,guarantorCardImg,job,jobArea,jobLevel,jobLocation} = this.state
   console.log(city)
   console.log(receipts)
    if(hasValue(fullname)&&hasValue(phone)&&hasValue(cardNum)
      &&city!=[]){
        let l = message.loading('Wait..', 2.5)
        var data = new FormData()
        data.append('fullname',fullname)
        if(cardImg != null){
          for(let i=0 ; i<= cardImg.length-1 ; i++){
             data.append('cardImg',cardImg[i]); 
          }
        }
        data.append('cardNum',cardNum)
        data.append('city',city)
        data.append('type','CLIENT')
        data.append('password',cardNum); 
        data.append('phone',phone); 
        if(this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN" ){
          if(this.props.company == false){
            data.append('company',this.props.currentUser.user.company[0].id); 
          }else{
            data.append('company',this.props.company); 
          }
        } else{
          data.append('company',this.props.currentUser.user.id);
        }
        if(receipts != null){
          for(var j = 0 ; j<= receipts.length-1 ; j++){
            console.log(receipts[j])
            data.append(`receipts`,receipts[j] )
          }
        }
        if(profileImg != null){
          data.append('img',profileImg); 
        }
         
        if(anotherPhone !=="" && anotherPhone != undefined){
          data.append('anotherPhone',anotherPhone); 
        }if(job !=="" && job != undefined){
          data.append('job',job); 
        }
        if(jobArea !=="" && jobArea != undefined){
          data.append('jobArea',jobArea); 
        }
        if(jobLocation !=="" && jobLocation != undefined){
          data.append('jobLocation',jobLocation); 
        }
        if(jobLevel !=="" && jobLevel != undefined){
          data.append('jobLevel',jobLevel); 
        }
        if(notes !=="" && notes != undefined){
          data.append('notes',notes); 
        }
        if(address !=="" && address != undefined){
          data.append('address',address); 
        }
        //guarantor
        if(guarantorCardNum !=="" && guarantorCardNum != undefined){
          data.append('guarantorCardNum',guarantorCardNum); 
        }
        if(guarantorName !=="" && guarantorName != undefined){
          data.append('guarantorName',guarantorName); 
        }
        if(guarantorPhone !=="" && guarantorPhone != undefined){
          data.append('guarantorPhone',guarantorPhone); 
        }
        if(guarantorPhone2 !=="" && guarantorPhone2 != undefined){
          data.append('guarantorPhone2',guarantorPhone2); 
        }
        if(guarantorNote !=="" && guarantorNote != undefined){
          data.append('guarantorNote',guarantorNote); 
        }
        if(guarantorCardImg != null ){
          for(var k=0 ; k<= guarantorCardImg.length-1 ; k++){
            data.append(`guarantorCardImg`,guarantorCardImg[k] )
          } 
        }
        if(guarantorReceipt != null){
          for(var v=0 ; v<= guarantorReceipt.length-1 ; v++){
            data.append(`guarantorReceipt`,guarantorReceipt[v] )
          } 
        }
        //////
        
        console.log(Array.from(data))
        
        axios.post(`${BASE_END_POINT}addUser`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
        console.log("response",response)
        this.setState({client:response.data.user,requestType:'put'})
          if(response.data.user.clientCompanyIds.length > 0){
            let company 
            if(this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN" ){
              if(this.props.company == false){
                company = this.props.currentUser.user.company[0].id
              }else{
                company = this.props.company 
              }
            } else{
              company = this.props.currentUser.user.id
            }
            let arr = response.data.user.clientCompanyIds
            var found = arr.find(e => e.company == company);
            console.log("found", found)
            this.setState({companyClientId:found.value})
          }
        
          //this.props.history.push('/Clients')
        })
        .catch(error=>{

            console.log(error.response)
            if(error.response.data.errors[0].msg =="cardNum duplicated"){
              l.then(() => message.error(allStrings.duplicatedCardNum, 2.5))
            }
            if(error.response.data.errors[0].msg =="phone duplicated"){
              l.then(() => message.error(allStrings.duplicatedPhone, 2.5))
            }
        })
    }
  }
  updateClient = () => {
    const {fullname,password,phone,anotherPhone,profileImg,receipts,
      guarantorName,guarantorPhone,guarantorPhone2,guarantorCardNum,guarantorNote,guarantorReceipt,
           cardNum,address,notes,city,cardImg,guarantorCardImg,job,jobLevel,jobArea,jobLocation} = this.state
   console.log(city)
   console.log(receipts)
    if(hasValue(fullname)&&hasValue(phone)&&hasValue(cardNum)
      &&city!=[]){
        let l = message.loading('Wait..', 2.5)
        var data = new FormData()
        data.append('fullname',fullname)
        data.append('cardNum',cardNum)
        data.append('city',city)
        data.append('type','CLIENT')
        if(password != "" && password != undefined)
           data.append('password',password); 
        data.append('phone',phone); 
        if(this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN" ){
          if(this.props.company == false){
            data.append('company',this.props.currentUser.user.company[0].id); 
          }else{
            data.append('company',this.props.company); 
          }
        } else{
          data.append('company',this.props.currentUser.user.id);
        } 
        if(receipts != null){
          //if(recieptImgType == 'data'){
            for(let i=0 ; i<= receipts.length-1 ; i++){
              data.append(`receipts`,receipts[i] )
            }
          //}
        }
        
        if(job !="" && job != undefined){
          data.append('job',job); 
        }
        if(jobArea !="" && jobArea != undefined){
          data.append('jobArea',jobArea); 
        }
        if(jobLocation !="" && jobLocation != undefined){
          data.append('jobLocation',jobLocation); 
        }
        if(jobLevel !="" && jobLevel != undefined){
          data.append('jobLevel',jobLevel); 
        }
        if(profileImg != null){
          //if(profileimgType == 'data'){
            data.append('img',profileImg); 
          //}
        }
        if(cardImg != null){
         // if(cardImgType == 'data'){
            
            for(let i=0 ; i<= cardImg.length-1 ; i++){
              data.append('cardImg',cardImg[i]); 
            }
         // }
        }
         
        if(anotherPhone !="" && anotherPhone != undefined){
          data.append('anotherPhone',anotherPhone); 
        }
        if(notes !="" && notes != undefined){
          data.append('notes',notes); 
        }
        if(address !="" && address != undefined){
          data.append('address',address); 
        }
        //guarantor
        if(guarantorCardNum !="" && guarantorCardNum != undefined){
          data.append('guarantorCardNum',guarantorCardNum); 
        }
        if(guarantorName !="" && guarantorName != undefined){
          data.append('guarantorName',guarantorName); 
        }
        if(guarantorPhone !="" && guarantorPhone != undefined){
          data.append('guarantorPhone',guarantorPhone); 
        }
        if(guarantorPhone2 !="" && guarantorPhone2 != undefined){
          data.append('guarantorPhone2',guarantorPhone2); 
        }
        if(guarantorNote !="" && guarantorNote != undefined){
          data.append('guarantorNote',guarantorNote); 
        }
        if(guarantorCardImg != null){
          //if(guarantorCardImgType == 'data'){
            for(let i=0 ; i<= guarantorCardImg.length-1 ; i++){
              data.append(`guarantorCardImg`,guarantorCardImg[i] )
            } 
         // }
        }
        if(guarantorReceipt != null){
          //if(guarantorReceiptType == 'data'){
            for(let i=0 ; i<= guarantorReceipt.length-1 ; i++){
              data.append(`guarantorReceipt`,guarantorReceipt[i] )
            } 
         // }
        }
        //////
        
        console.log(Array.from(data))
        
        axios.put(`${BASE_END_POINT}user/${this.state.client.id}/updateInfo`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.push('/Clients')
        })
        .catch(error=>{
            
          console.log(error.response)
            if(error.response.data.errors[0].msg =="cardNum duplicated"){
              l.then(() => message.error(allStrings.duplicatedCardNum, 2.5))
            }
            if(error.response.data.errors[0].msg =="phone duplicated"){
              l.then(() => message.error(allStrings.duplicatedPhone, 2.5))
            }
            
        })
    }
  }
  sendNotif = () => {
    const msg = this.state.message
    console.log(msg)
    if(hasValue(msg)){
        let l = message.loading('Wait..', 2.5)
        let data ={
          "description":msg,
          "users":this.state.client.id
        }
        axios.post(`${BASE_END_POINT}sendnotif`,data,{
        headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          this.toggle()
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error('Error', 2.5))
        })
    }
  }
  sendMessage = () => {
    const msg = this.state.message
    console.log(msg)
    if(hasValue(msg)){
        let l = message.loading('Wait..', 2.5)
        const data = {
          text: msg,
          user:{
              _id:this.props.currentUser.user.id
          },
        }                      
        this.socket.emit("newMessage",{
          toId: this.state.client.id,
          data: data
        });
        l.then(() => message.success(allStrings.done, 2.5) )
        this.toggle()
    }
  }
  
  
  fileHandlerProfileImg = (event) => {
    this.setState({
      profileImg: event.target.files[0],
      profileImgType:'data'
    })
    
  }
  removeProfileImg = () => {
    this.setState({
      profileImg:null
    });
  }
  //card img
  fileHandlerCardImg = (event) => {
    let imgs =[];
    for(let i=0 ; i<= event.target.files.length-1 ; i++){
      imgs.push(URL.createObjectURL(event.target.files[i]))
    }
    console.log(imgs)
    this.setState({
      cardImg: event.target.files,
      cardImgViews:imgs,
      cardImgType:'data'
    })
    
  }
  removeCardImg = (url) => {
    let arr = this.state.cardImgViews;
    let arr2 =  Array.from(this.state.cardImg);
    console.log(arr2)
    let index = arr.indexOf(url);
    if (index !== -1) arr.splice(index, 1);
    if (index !== -1) arr2.splice(index, 1);
    console.log(arr2)
    this.setState({
      cardImgViews:arr,
      cardImg:arr2,
    });
    if(arr2.length == 0){
      this.setState({
        cardImg:null,
      });
    }
  };
//reciept img
  fileHandlerRecieptImg = (event) => {
    let imgs =[];
    for(let i=0 ; i<= event.target.files.length-1 ; i++){
      imgs.push(URL.createObjectURL(event.target.files[i]))
    }
    console.log(imgs)
    this.setState({
      receipts: event.target.files,
      receiptsViews:imgs,
      recieptImgType:'data'
    })
    
  }
  removeRecieptImg = (url) => {
    let arr = this.state.receiptsViews;
    let arr2 =  Array.from(this.state.receipts);
    console.log(arr2)
    let index = arr.indexOf(url);
    if (index !== -1) arr.splice(index, 1);
    if (index !== -1) arr2.splice(index, 1);
    console.log(arr2)
    this.setState({
      receiptsViews:arr,
      receipts:arr2,
    });
    if(arr2.length == 0){
      this.setState({
        receipts:null,
      });
    }
  };

  //guarantor  card img
  fileHandlerGuarantorCardImg = (event) => {
    let imgs =[];
    for(let i=0 ; i<= event.target.files.length-1 ; i++){
      imgs.push(URL.createObjectURL(event.target.files[i]))
    }
    console.log(imgs)
    this.setState({
      guarantorCardImg: event.target.files,
      guarantorCardImgViews:imgs,
      recieptImgType:'data'
    })
    
  }
  removeGuarantorCardImg = (url) => {
    let arr = this.state.guarantorCardImgViews;
    let arr2 =  Array.from(this.state.guarantorCardImg);
    console.log(arr2)
    let index = arr.indexOf(url);
    if (index !== -1) arr.splice(index, 1);
    if (index !== -1) arr2.splice(index, 1);
    console.log(arr2)
    this.setState({
      guarantorCardImgViews:arr,
      guarantorCardImg:arr2,
    });
    if(arr2.length == 0){
      this.setState({
        guarantorCardImg:null,
      });
    }
  };
  //guarantor receipt
  fileHandlerGuarantorReceipt = (event) => {
    let imgs =[];
    for(let i=0 ; i<= event.target.files.length-1 ; i++){
      imgs.push(URL.createObjectURL(event.target.files[i]))
    }
    console.log(imgs)
    this.setState({
      guarantorReceipt: event.target.files,
      guarantorReceiptViews:imgs,
      guarantorReceiptType:'data'
    })
    
  }
  removeGuarantorReceipt = () => {
    this.setState({
      guarantorReceipt:null
    });
  }
  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  clientSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.addClient()
  };
  updateSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.updateClient()

  };
  notifSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.sendNotif()

  };
  msgSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.sendMessage()

  };
  
  onChangeDate = (date, dateString) => {
    console.log(date, dateString)
    this.setState({start:dateString})
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  }
  openMap = () =>{
    this.setState({modalType:'map'})
    this.toggle()
  }
  openNotif = () =>{
    this.setState({modalType:'notif'})
    this.toggle()
  }
  openMsg = () =>{
    this.setState({modalType:'msg'})
    this.toggle()
  }
  modal = () =>(
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
      <MDBModalHeader toggle={this.toggle}>{this.state.modalType=="map"?allStrings.location:allStrings.completeData}</MDBModalHeader>
      <MDBModalBody>
        <MDBContainer>
          <MDBRow>
            {this.state.modalType !="map" &&
            <MDBCol md="12">
              <form style={{marginLeft: '15%'}}
                className="needs-validation"
                onSubmit={this.state.modalType=="notif"?this.notifSubmitHandler:this.msgSubmitHandler}
                noValidate
              >
                <MDBContainer>
                  <MDBRow>
                    <MDBCol md="9">
                      <textarea name="message" onChange={this.changeHandler} type="text" id="defaultFormRegisterNamx1" required
                        placeholder={allStrings.message} className="form-control" style={{direction:this.props.isRTL?'rtl':'ltr'}} />
                    </MDBCol>
                    <MDBCol md="3">
                      <label  htmlFor="defaultFmRegisterNameEx1" className="grey-text formLabel">
                      {allStrings.message}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBBtn type="submit" style={{ margin: '3rem 1rem ',width:'170px',padding:'11px 20px',height:'35px', }}
                        className="btn z-depth-1a "
                        color="primary"
                      >
                        {allStrings.send}
                    </MDBBtn>
                  </MDBRow>
                </MDBContainer>
              </form>
            </MDBCol>
            }
            {this.state.modalType =="map" &&
            <MDBCol md="12">
              <div style={{width:'100%',height:'300px'}}>

              </div>
            </MDBCol>
            }
            
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
   );
   

  render() {
    //client card img slider
   let {cardImg,profileImg,guarantorCardImg,guarantorReceipt,receipts,
    photoIndex, isOpen1,isOpen2,isOpen3,isOpen4,isOpen5} = this.state;
   profileImg = [profileImg]
  console.log("img",profileImg)
    return (
      <div className="stockForm clientForm">
        <MDBContainer>
          {this.modal()}
          <MDBRow>
            <div className="top" style={{width:'100%',textAlign:this.state.requestType==="post" ?'center':'right'}}>
              <NavLink to="/clients">
                <MDBIcon icon="times" className="closeIcon"/>
              </NavLink>
              {this.state.requestType==="post" ?
              <span className="stockTitle">{allStrings.completeData}</span>
              :
              <span>
              <span className="newTitle" style={{margin: '1rem 0rem 1rem .5rem'}}>{this.state.client.lastSeen?moment().calendar(this.state.client.lastSeen):moment().calendar()}</span>
                <span className="newTitle">
                  {allStrings.lastSeen}
                </span>
              </span>
              }
              
            </div>
          </MDBRow>
          <form
              className="needs-validation"
              onSubmit={this.state.requestType==="post"?this.clientSubmitHandler:this.updateSubmitHandler}
              noValidate
              style={{marginLeft:'2rem'}}
          >
          <MDBRow>
            <MDBContainer>
              <MDBRow className="mdRevers">
                <MDBCol md="6">
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="jobArea" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx12" 
                        className="form-control" placeholder={allStrings.enterJobArea} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.client.jobArea} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                      {allStrings.jobArea}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="jobLocation" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx12" 
                        className="form-control" placeholder={allStrings.enterJobLocation} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.client.jobLocation} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                      {allStrings.jobLocation}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="job" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx12" 
                        className="form-control" placeholder={allStrings.enterJob} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.client.job} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                      {allStrings.job}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="jobLevel" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx12" 
                        className="form-control" placeholder={allStrings.enterJobLevel} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.client.jobLevel} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                      {allStrings.jobLevel}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="12" >
                    <Checkbox 
                    style={{float:'right',marginTop:'2px',marginRight:'2rem'}}
                    defaultChecked={this.state.hasGuarantor}
                    onChange={(e) => {
                      console.log(e.target.checked)
                      this.setState({hasGuarantor:e.target.checked})
                    }} >
                    </Checkbox>
                      <label  htmlFor="defaultFormRegisterNameEx2"
                      style={{
                        float:'right',
                        fontWeight: '800',
                        color: '#43425D',
                        fontSize: '18px',
                        marginBottom: '2rem',
                        marginRight:'10px'
                      }}>
                      {allStrings.guarantor}
                      </label>
                    </MDBCol>
                  </MDBRow> 
                  
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                      <input name="guarantorName" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx00" 
                        className="form-control" placeholder={allStrings.enterName} disabled={this.state.hasGuarantor?false:true}
                        style={{direction:this.props.isRTL?'rtl':'ltr',marginBottom: '10px'}} defaultValue={this.state.client.guarantorName} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}} >
                      <label  htmlFor="defaultFormRegisterNameEx00" className="grey-text formLabel">
                      {allStrings.name}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                    {this.state.guarantorCardImg == null&&
                      <div className="smallUploadContainer">
                        <img alt=""  name="img" className="smallUploderImg" src={require('../../assets/images/Path 343.png')}></img>
                        <input 
                        disabled={this.state.hasGuarantor?false:true}
                          multiple
                          onChange={this.fileHandlerGuarantorReceipt}
                          type="file"
                          id="defaultFormRegisterNameEx003"
                           className="smallUploader" />
                        <MDBBtn disabled={this.state.hasGuarantor?false:true} color="primary" size="sm" className="smallUploaderBtn">{allStrings.chooseFile}</MDBBtn>
                        <p style={{fontSize: '14px',marginTop: '3px 15px',float:'right',paddingTop: '4px'}}  className="grey-text">
                          {allStrings.dropHere}
                        </p>
                      </div>
                    }
                     
                      {this.state.guarantorCardImg!= null&&
                       <div className="previewRaduis">
                       <MDBRow>
                         <MDBCol md = "3">
                         <div style={{paddingTop:'5px',cursor:'pointer'}}  onClick={() => this.setState({ isOpen4: true })}>
                           <span style={{
                             fontSize: '17px',
                             color: '#478DFF',
                             fontWeight: '600',
                             margin: '5px'
                           }}>{allStrings.showD}</span>
                           <img alt=""  className="showImge" width="20px" src={require('../../assets/images/icons8_external_link_480px_2.png')}></img>
                         </div>
                         </MDBCol>
                         <MDBCol md="9" style={{paddingRight:'30px'}}  >
                          {this.state.guarantorCardImgViews.map((val) => (
                            <div style={{display:"inline-block",float:'right',marginBottom:'1rem'}}>
                                <img alt=""    src={this.state.guarantorCardImgType=="data"?val:val} />
                                <MDBIcon icon="times" className="mr-2 removeImg" onClick={()=> this.removeguarantorCardImg(val)}></MDBIcon>
                            </div>
                            ))}
                         </MDBCol>
                       </MDBRow>
                        {isOpen4 && (
                          <Lightbox
                            mainSrc={guarantorCardImg[photoIndex]}
                            nextSrc={guarantorCardImg[(photoIndex + 1) % guarantorCardImg.length]}
                            prevSrc={guarantorCardImg[(photoIndex + guarantorCardImg.length - 1) % guarantorCardImg.length]}
                            onCloseRequest={() => this.setState({ isOpen4: false,photoIndex:0 })}
                            onMovePrevRequest={() =>
                              this.setState({
                                photoIndex: (photoIndex + guarantorCardImg.length - 1) % guarantorCardImg.length,
                              })
                            }
                            onMoveNextRequest={() =>
                              this.setState({
                                photoIndex: (photoIndex + 1) % guarantorCardImg.length,
                              })
                            }
                          />
                        )}
                     </div>
                    }
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx003" className="grey-text formLabel">
                      {allStrings.receipts}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                    {this.state.guarantorReceipt == null&&
                      <div className="smallUploadContainer">
                        <img alt=""  name="img" className="smallUploderImg" src={require('../../assets/images/Path 343.png')}></img>
                        <input 
                          multiple
                          disabled={this.state.hasGuarantor?false:true}
                          onChange={this.fileHandlerGuarantorReceipt}
                          type="file"
                          id="defaultFormRegisterNameEx003"
                           className="smallUploader" />
                        <MDBBtn disabled={this.state.hasGuarantor?false:true} color="primary" size="sm" className="smallUploaderBtn">{allStrings.chooseFile}</MDBBtn>
                        <p style={{fontSize: '14px',marginTop: '3px 15px',float:'right',paddingTop: '4px'}}  className="grey-text">
                          {allStrings.dropHere}
                        </p>
                      </div>
                    }
                     
                      {this.state.guarantorReceipt!= null&&
                        <div className="previewRaduis">
                      <MDBRow>
                        <MDBCol md = "3">
                        <div style={{paddingTop:'5px',cursor:'pointer'}}  onClick={() => this.setState({ isOpen5: true })}>
                          <span style={{
                            fontSize: '17px',
                            color: '#478DFF',
                            fontWeight: '600',
                            margin: '5px'
                          }}>{allStrings.showD}</span>
                          <img alt=""  className="showImge" width="20px" src={require('../../assets/images/icons8_external_link_480px_2.png')}></img>
                        </div>
                        </MDBCol>
                        <MDBCol md="9" style={{paddingRight:'30px'}}  >
                          {this.state.guarantorReceiptViews.map((val) => (
                            <div style={{display:"inline-block",float:'right',marginBottom:'1rem'}}>
                                <img alt=""    src={this.state.guarantorReceiptType=="data"?val:val} />
                                <MDBIcon icon="times" className="mr-2 removeImg" onClick={()=> this.removeGuarantorReceipt(val)}></MDBIcon>
                            </div>
                          ))}
                        </MDBCol>
                      </MDBRow>
                        {isOpen5 && (
                        <Lightbox
                          mainSrc={guarantorReceipt[photoIndex]}
                          nextSrc={guarantorReceipt[(photoIndex + 1) % guarantorReceipt.length]}
                          prevSrc={guarantorReceipt[(photoIndex + guarantorReceipt.length - 1) % guarantorReceipt.length]}
                          onCloseRequest={() => this.setState({ isOpen5: false,photoIndex:0  })}
                          onMovePrevRequest={() =>
                            this.setState({
                              photoIndex: (photoIndex + guarantorReceipt.length - 1) % guarantorReceipt.length,
                            })
                          }
                          onMoveNextRequest={() =>
                            this.setState({
                              photoIndex: (photoIndex + 1) % guarantorReceipt.length,
                            })
                          }
                        />
                      )}
                  </div>
                      }
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx003" className="grey-text formLabel">
                      {allStrings.receipts}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input disabled={this.state.hasGuarantor?false:true}
                      name="guarantorCardNum" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx2" 
                        className="form-control" placeholder={allStrings.enterCardNum} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.client.guarantorCardNum} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx002" className="grey-text formLabel">
                      {allStrings.cardNum}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="guarantorPhone" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx3" 
                        className="form-control" disabled={this.state.hasGuarantor?false:true}
                         placeholder={allStrings.enterPhone} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.client.guarantorPhone} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx022" className="grey-text formLabel">
                      {allStrings.phone}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="guarantorPhone2" disabled={this.state.hasGuarantor?false:true} onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx004" 
                        className="form-control" placeholder={allStrings.EnterAnotherPhone} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.client.guarantorPhone2} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx004" className="grey-text formLabel">
                      {allStrings.anotherPhone}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <textarea name="guarantorNote" disabled={this.state.hasGuarantor?false:true} onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx005" 
                        className="form-control" placeholder={allStrings.enterNote} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.client.guarantorNote} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx005" className="grey-text formLabel">
                      {allStrings.note}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{padding:'0px 15px'}}>
                    <MDBCol md="12" 
                    style={{cursor: 'pointer',
                    borderTop: '1px solid #000',
                    marginTop: '2rem',
                    paddingTop: '1rem',
                    }}>
                     <label  htmlFor="defaultFormRegisterNameE"
                      style={{
                        float:'right',
                        fontWeight: '800',
                        color: '#43425D',
                        fontSize: '18px',
                      }}>
                      {allStrings.recievedProducts}
                      </label>

                     
                    </MDBCol>
                   
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md = "3">
                      <div style={{paddingTop:'5px',cursor:'pointer'}} onClick={() => this.props.history.push('/clientOrders',{data:this.state.client})} >
                        <span style={{
                          fontSize: '17px',
                          color: '#478DFF',
                          fontWeight: '600',
                          margin: '5px'
                        }}>{allStrings.showD}</span>
                        <img alt=""  width="20px" src={require('../../assets/images/icons8_external_link_480px_2.png')}></img>
                      </div>
                    </MDBCol>
                    <MDBCol md="9" style={{paddingRight:'30px'}} >
                    {this.state.orders.map((val) => (
                      <div style={{display:'inline-block',margin:'2px',float:'right'}}>
                        <img alt=""  className="clientProduct" src={val.product.mainImg}></img>
                        <span className="clientProductCount">{val.count}</span>
                      </div>
                    ))}
                    </MDBCol>
                    
                  </MDBRow>










                  <MDBRow>
                    <MDBBtn type="submit" 
                    style={{ margin: '1rem',width:'150px',padding:'9px',height:'35px' }}
                        className="btn z-depth-1a "
                        color="primary"
                      >
                        {this.state.requestType=="post"?allStrings.add:allStrings.edit}
                    </MDBBtn>
                  </MDBRow>
                </MDBCol>
                <MDBCol md="6" style={{borderLeft:'1px solid'}}>
                  <MDBRow>
                    <MDBCol md="12">
                      <label  htmlFor="defaultFormRegisterNameEx2"
                      style={{
                        float:'right',
                        fontWeight: '800',
                        color: '#43425D',
                        fontSize: '18px',
                        marginBottom: '2rem',
                        marginRight:'4rem'
                      }}>
                      {allStrings.client}
                      </label>
                      {this.state.client.id && this.props.currentUser.user.type =="ADMIN"&&
                      <span style={{
                        float: 'right',
                        margin: '-3px 25px',
                        fontWeight: 600,
                        fontSize: '20px',
                        color: 'red'
                      }}>
                        <span>{allStrings.generalCode}</span>
                        <span>{this.state.client.id}</span>
                      </span>
                      }
                    </MDBCol>
                  </MDBRow>
                  {this.state.companyClientId && 
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                      <input name="#"  type="text" id="defaultFormRegisterEx5" disabled
                        className="form-control" 
                        style={{direction:this.props.isRTL?'rtl':'ltr',marginBottom: '10px'}} defaultValue={this.state.companyClientId} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterEx5" className="grey-text formLabel">
                      {allStrings.clientCode}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  }
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                      <input name="fullname" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx5" required
                        className="form-control" placeholder={allStrings.enterName} 
                        style={{direction:this.props.isRTL?'rtl':'ltr',marginBottom: '10px'}} defaultValue={this.state.client.fullname} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx5" className="grey-text formLabel">
                      {allStrings.name}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  {/*<MDBRow>
                    <MDBCol md="9" sm="9">
                      <input name="password" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx5" required
                        className="form-control" placeholder={allStrings.enterPassword} 
                        style={{direction:this.props.isRTL?'rtl':'ltr',marginBottom: '10px'}} defaultValue={this.state.client.password} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx5" className="grey-text formLabel">
                      {allStrings.password}
                      </label>
                    </MDBCol>
                  </MDBRow>*/}
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                    {this.state.profileImg == null&&
                      <div className="smallUploadContainer">
                        <img alt=""  name="img" className="smallUploderImg" src={require('../../assets/images/Path 343.png')}></img>
                        <input 
                          onChange={this.fileHandlerProfileImg}
                          type="file"
                          id="defaultFormRegisterNameEx6"
                          required className="smallUploader" />
                        <MDBBtn color="primary" size="sm" className="smallUploaderBtn">{allStrings.chooseFile}</MDBBtn>
                        <p style={{fontSize: '14px',marginTop: '3px 15px',float:'right',paddingTop: '4px'}}  className="grey-text">
                          {allStrings.dropHere}
                        </p>
                      </div>
                    }
                      <div className="previewRaduis">
                      {this.state.profileImg!= null&&
                      <MDBRow>
                        
                        <MDBCol md = "3">
                        <div style={{paddingTop:'5px',cursor:'pointer'}}  onClick={() => this.setState({ isOpen2: true })}>
                          <span style={{
                            fontSize: '17px',
                            color: '#478DFF',
                            fontWeight: '600',
                            margin: '5px'
                          }}>{allStrings.showD}</span>
                          <img alt=""  className="showImge" width="20px" src={require('../../assets/images/icons8_external_link_480px_2.png')}></img>
                        </div>
                        </MDBCol>
                        <MDBCol md="9" style={{paddingRight:'30px'}}  >
                          {this.state.profileImg!= null&&
                            <div style={{display:"inline-block",float:'right',marginBottom:'1rem'}}>
                                <img alt=""    src={this.state.profileImgType=="data"?URL.createObjectURL(this.state.profileImg):this.state.profileImg} />
                                <MDBIcon icon="times" className="mr-2 removeImg" onClick={()=> this.removeProfileImg()}></MDBIcon>
                            </div>
                          }
                        </MDBCol>
                      </MDBRow>
                      }
                      {isOpen2 && (
                      <Lightbox
                        mainSrc={profileImg[photoIndex]}
                        nextSrc={profileImg[(photoIndex + 1) % profileImg.length]}
                        prevSrc={profileImg[(photoIndex + profileImg.length - 1) % profileImg.length]}
                        onCloseRequest={() => this.setState({ isOpen2: false,photoIndex:0  })}
                        onMovePrevRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + profileImg.length - 1) % profileImg.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + 1) % profileImg.length,
                          })
                        }
                      />
                    )}
                    </div>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx7" className="grey-text formLabel">
                      {allStrings.profileImg}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                    {this.state.cardImg == null&&
                      <div className="smallUploadContainer">
                        <img alt=""  name="img" className="smallUploderImg" src={require('../../assets/images/Path 343.png')}></img>
                        <input 
                          multiple
                          onChange={this.fileHandlerCardImg}
                          type="file"
                          id="defaultFormRegisterNameE03"
                           className="smallUploader" />
                        <MDBBtn color="primary" size="sm" className="smallUploaderBtn">{allStrings.chooseFile}</MDBBtn>
                        <p style={{fontSize: '14px',marginTop: '3px 15px',float:'right',paddingTop: '4px'}}  className="grey-text">
                          {allStrings.dropHere}
                        </p>
                      </div>
                    }
                     
                    {this.state.cardImg!= null&&
                      <div className="previewRaduis">
                      <MDBRow>
                        <MDBCol md = "3">
                        <div style={{paddingTop:'5px',cursor:'pointer'}}  onClick={() => this.setState({ isOpen1: true })}>
                          <span style={{
                            fontSize: '17px',
                            color: '#478DFF',
                            fontWeight: '600',
                            margin: '5px'
                          }}>{allStrings.showD}</span>
                          <img alt=""  className="showImge" width="20px" src={require('../../assets/images/icons8_external_link_480px_2.png')}></img>
                        </div>
                        </MDBCol>
                        <MDBCol md="9" style={{paddingRight:'30px'}}  >
                         {this.state.cardImgViews.map((val) => (
                          <div style={{display:"inline-block",float:'right',marginBottom:'1rem'}}>
                              <img alt=""    src={this.state.cardImgType=="data"?val:val} />
                              <MDBIcon icon="times" className="mr-2 removeImg" onClick={()=> this.removeCardImg(val)}></MDBIcon>
                          </div>
                          ))}
                        </MDBCol>
                      </MDBRow>
                    {isOpen1 && (
                      <Lightbox
                        mainSrc={cardImg[photoIndex]}
                        nextSrc={cardImg[(photoIndex + 1) % cardImg.length]}
                        prevSrc={cardImg[(photoIndex + cardImg.length - 1) % cardImg.length]}
                        onCloseRequest={() => this.setState({ isOpen1: false,photoIndex:0  })}
                        onMovePrevRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + cardImg.length - 1) % cardImg.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + 1) % cardImg.length,
                          })
                        }
                      />
                    )}
                    </div>
                    }

                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx003" className="grey-text formLabel">
                      {allStrings.cardImg}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  
                  <MDBRow>
                    <MDBCol md="9" sm="9">
                    {this.state.receipts == null&&
                      <div className="smallUploadContainer">
                        <img alt=""  name="img" className="smallUploderImg" src={require('../../assets/images/Path 343.png')}></img>
                        <input 
                          multiple
                          onChange={this.fileHandlerRecieptImg}
                          type="file"
                          id="defaultFormRegisterNameEx10"
                          required className="smallUploader" />
                        <MDBBtn color="primary" size="sm" className="smallUploaderBtn">{allStrings.chooseFile}</MDBBtn>
                        <p style={{fontSize: '14px',marginTop: '3px 15px',float:'right',paddingTop: '4px'}}  className="grey-text">
                          {allStrings.dropHere}
                        </p>
                      </div>
                    }
                    {this.state.receipts!= null&&
                      <div className="previewRaduis">
                        <MDBRow>
                          <MDBCol md = "3">
                          <div style={{paddingTop:'5px',cursor:'pointer'}}  onClick={() => this.setState({ isOpen3: true })}>
                            <span style={{
                              fontSize: '17px',
                              color: '#478DFF',
                              fontWeight: '600',
                              margin: '5px'
                            }}>{allStrings.showD}</span>
                            <img alt=""  className="showImge" width="20px" src={require('../../assets/images/icons8_external_link_480px_2.png')}></img>
                          </div>
                          </MDBCol>
                          <MDBCol md="9" style={{paddingRight:'30px'}}  >
                            {this.state.receiptsViews.map((val) => (
                              <div style={{display:"inline-block",float:'right',marginBottom:'1rem'}}>
                                  <img alt=""    src={this.state.recieptImgType=="data"?val:val} />
                                  <MDBIcon icon="times" className="mr-2 removeImg" onClick={()=> this.removeRecieptImg(val)}></MDBIcon>
                              </div>
                            ))}
                          </MDBCol>
                        </MDBRow>
                     
                      {isOpen3 && (
                      <Lightbox
                        mainSrc={receipts[photoIndex]}
                        nextSrc={receipts[(photoIndex + 1) % receipts.length]}
                        prevSrc={receipts[(photoIndex + receipts.length - 1) % receipts.length]}
                        onCloseRequest={() => this.setState({ isOpen3: false ,photoIndex:0 })}
                        onMovePrevRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + receipts.length - 1) % receipts.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + 1) % receipts.length,
                          })
                        }
                      />
                    )}
                    </div>
                    }
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx11" className="grey-text formLabel">
                      {allStrings.receipts}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="cardNum" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx12" 
                        className="form-control" placeholder={allStrings.enterCardNum} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.client.cardNum} required/>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                      {allStrings.cardNum}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="address" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx13" 
                        className="form-control" placeholder={allStrings.enterAddress} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.client.address} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx13" className="grey-text formLabel">
                      {allStrings.address}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <TreeSelect
                        value= {this.state.city}
                        showSearch={false} 
                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left'}} 
                        treeCheckable = {false}         
                        treeNodeFilterProp="title"                      
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={allStrings.chooseCity}
                        onChange={(value) => {
                          console.log(value)
                          this.setState({city:value});
                        }} 
                      
                      >
                      {/*<TreeNode style={{background:"antiquewhite",color:'#fff !important'}} value="newClient" title={allStrings.newClient} key={allStrings.newClient} />*/}
                      {this.state.cities.map(val=>(
                          <TreeNode value={val.id} title={this.props.isRTL?val.cityName_ar:val.cityName_en} key={val.id} />
                      ))}
                      </TreeSelect>
                      
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx14" className="grey-text formLabel">
                      {allStrings.city}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="phone" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx15" 
                        className="form-control" placeholder={allStrings.enterPhone} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.client.phone} required />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx15" className="grey-text formLabel">
                      {allStrings.phone}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="anotherPhone" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx16" 
                        className="form-control" placeholder={allStrings.EnterAnotherPhone} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.client.anotherPhone} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx16" className="grey-text formLabel">
                      {allStrings.anotherPhone}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <textarea name="notes" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx17" 
                        className="form-control" placeholder={allStrings.enterNote} style={{direction:this.props.isRTL?'rtl':'ltr'}} defaultValue={this.state.client.notes} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx17" className="grey-text formLabel">
                      {allStrings.note}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  {this.state.requestType=="put" &&
                  <MDBRow>
                    <MDBCol lg="4" md="12" style={{padding:'6px'}}>
                      <MDBBtn 
                        onClick={() => this.props.history.push('/collectibles',{client:this.state.client.id})}
                        style={{ margin: '1rem auto',padding:'11px 20px',height:'35px',width:'98%',fontSize: '12px',
                        fontWeight: '600',background:'#4F73AC',color:'#fff' }}
                        className="btn z-depth-1a "
                        color='#4F73AC'
                      >
                          {allStrings.delayPremuim}
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol lg="4" md="12"style={{padding:'6px'}}>
                      <MDBBtn  
                        onClick={() => this.props.history.push('/clientOrders',{data:this.state.client})}
                        style={{ margin: '1rem auto',padding:'11px 20px',height:'35px',width:'98%',fontSize: '12px',
                        fontWeight: '600',background:'#4F73AC',color:'#fff' }}
                        className="btn z-depth-1a "
                        color='#4F73AC'
                      >
                          {allStrings.payCash}
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol lg="4" md="12"style={{padding:'6px'}}>
                      <MDBBtn  
                        onClick={() => this.props.history.push('/collectibles',{client:this.state.client.id})}
                        style={{ margin: '1rem auto',padding:'11px 20px',height:'35px',width:'98%',fontSize: '12px',
                        fontWeight: '600',background:'#4F73AC',color:'#fff' }}
                        className="btn z-depth-1a "
                        color='#4F73AC'
                      >
                          {allStrings.collectPremuims}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                  }{this.state.requestType=="put" &&
                  <MDBRow>
                    <MDBCol xl="3" lg="6" md="6" style={{ padding:'6px'}}>
                      <MDBBtn 
                        onClick={() => this.props.history.push('/receipts',{client:this.state.client.id})}
                        style={{ margin: '1rem auto',padding:'11px 20px',height:'35px',width:'98%',fontSize: '12px',
                        fontWeight: '600',background:'#4F73AC',color:'#fff' }}
                        className="btn z-depth-1a "
                        color='#4F73AC'
                      >
                          {allStrings.printReceitp}
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol xl="3" lg="6" md="6"  style={{ padding:'6px'}}>
                      <MDBBtn 
                        onClick={this.openNotif}
                        style={{ margin: '1rem auto',padding:'11px 20px',height:'35px',width:'98%',fontSize: '12px',
                        fontWeight: '600',background:'#4F73AC',color:'#fff' }}
                        className="btn z-depth-1a "
                        color='#4F73AC'
                      >
                          {allStrings.sendNotif}
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol xl="3" lg="6" md="6"  style={{ padding:'6px'}}>
                      <MDBBtn 
                        onClick={this.openMsg}
                        style={{ margin: '1rem auto',padding:'11px 20px',height:'35px',width:'98%',fontSize: '12px',
                        fontWeight: '600',background:'#4F73AC',color:'#fff' }}
                        className="btn z-depth-1a "
                        color='#4F73AC'
                      >
                          {allStrings.sendMessage}
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol xl="3" lg="6" md="6"  style={{ padding:'6px'}}>
                      <MDBBtn
                        onClick={this.openMap}
                        style={{ margin: '1rem auto',padding:'11px 20px',height:'35px',width:'98%',fontSize: '12px',
                        fontWeight: '600',background:'#4F73AC',color:'#fff' }}
                        className="btn z-depth-1a "
                        color='#4F73AC'
                      >
                          {allStrings.showLocation}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                }
                </MDBCol>
              
              </MDBRow>
            </MDBContainer>
          </MDBRow>
          
          </form>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
}
  export default  withRouter(
    connect(mapToStateProps,mapDispatchToProps)(ClientForm)
  );
  
