import React from "react";
import "./receivedProducts.css";
import {
  MDBContainer,MDBRow,MDBCol,MDBBtn,MDBIcon,MDBModal, MDBModalBody, 
  MDBModalHeader} from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import {hasValue} from '../../validations/validations'
import {message,TreeSelect} from 'antd';
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import LoadCard from '../../component/load card/load card'
import Pagination from '@material-ui/lab/Pagination';
import {ChangeCompany,ChangeBranch} from '../../redux/actions/CompanyAction'

const {TreeNode} = TreeSelect
class ReceivedProducts extends React.Component {
  state = {
    count: "",
    product: [],
    products:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    modal: false,
    receiveProducts:[],
    salesMan:this.props.location.state?this.props.location.state.data:[]
  };

  constructor(props){
    super(props)
    console.log("isr   ",this.props.history)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    window.scrollTo(0,0)
  }
  getProducts = () => {
    let url = ``;
    if(this.props.currentUser.user.type==="ADMIN" || this.props.currentUser.user.type==="SUB-ADMIN"){
      if(this.props.company === false){
        url=`${BASE_END_POINT}products/withoutPagenation/get`
      }else{
        url=`${BASE_END_POINT}products/withoutPagenation/get?company=${this.props.company}`

      }
    }else{
      url=`${BASE_END_POINT}products/withoutPagenation/get?company=${this.props.currentUser.user.id}`
    }
    axios.get(url)
    .then(response=>{
      console.log(response.data.products)
      this.setState({products:response.data.products})
    })
    .catch(error=>{
      console.log("ALL products ERROR")
      console.log(error.response)
    })
  }
  
  getReceiveProducts = (page,refresh) =>{
    this.setState({loading:true})
    axios.get(`${BASE_END_POINT}productsReceived?salesMan=${this.state.salesMan.id}&page=${page}`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("ReceiveProducts   ",response.data)
      this.setState({
        loading:false,
        refresh:false,
        receiveProducts:response.data.data,
        page:response.data.page,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })

  }

 
  componentDidMount(){
    this.getReceiveProducts(1,false)
    this.getProducts()
    //this.props.ChangeLanguage(false)
  }
  add = () => {
    const {product,count} = this.state
    console.log(count)
    console.log(product)
    if(product !==[]&&hasValue(count)){
        let l = message.loading('Wait..', 2.5)
        var data = {
          "product":product,
          "count":count,
          "salesMan":this.state.salesMan.id
        }
        if(this.props.currentUser.user.type === "ADMIN" || this.props.currentUser.user.type === "SUB-ADMIN" ){
          if(this.props.company === false){
            data.company = this.props.currentUser.user.company[0].id
          }else{
            data.company = this.props.company
          }
        } else{
          data.company = this.props.currentUser.user.id
        }
       
       
       
        
        axios.post(`${BASE_END_POINT}productsReceived`,JSON.stringify(data) ,{
        headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          this.setState({loading:true,modal:false})
          this.getReceiveProducts()
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error('Error', 2.5))
        })
    }
  }
  editCount = (type,id) => {
    console.log(id)
      let l = message.loading('Wait..', 2.5)
      var data = {
        "processType":type
      }
      axios.put(`${BASE_END_POINT}productsReceived/${id}/editProductCount`,JSON.stringify(data) ,{
      headers: {
      'Content-Type': 'application/json',
      'Authorization':`Bearer ${this.props.currentUser.token}`
      },
      })
      .then(response=>{
      l.then(() => message.success(allStrings.done, 2.5) )
        this.getReceiveProducts()
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error('Error', 2.5))
      })
    
  }
  

  submitHandler = (event) => {
    event.preventDefault();
    console.log(event.target);
    event.target.className += " was-validated";
    this.add()
  };

  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  }

  modal = () =>(
    <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
      <MDBModalHeader toggle={this.toggle}>{allStrings.completeData}</MDBModalHeader>
      <MDBModalBody>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
              <form
                style={{marginLeft:'4rem'}}
                className="needs-validation"
                onSubmit={this.submitHandler}
                noValidate
              >
                <MDBContainer>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                    <TreeSelect
                        value= {this.state.product}
                        showSearch={true} 
                        style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left'}} 
                        treeCheckable = {false}         
                        treeNodeFilterProp="title"                      
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={allStrings.chooseProduct}
                        onChange={(value) => {
                          console.log(value)
                          this.setState({product:value});
                        }} 
                      
                      >
                      {/*<TreeNode style={{background:"antiquewhite",color:'#fff !important'}} value="newReceived" title={allStrings.newReceived} key={allStrings.newReceived} />*/}
                      {this.state.products.map(val=>(
                          <TreeNode value={val.id} title={this.props.isRTL?val.name_ar:val.name_en} key={val.id} />
                      ))}
                      </TreeSelect>
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx1" className="grey-text formLabel">
                      {allStrings.chooseProduct}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                    <MDBCol md="9" sm="9">
                      <input name="count" onChange={this.changeHandler} min={1} type="number" id="defaultFormRegisterNameEx2" 
                        className="form-control" placeholder={allStrings.count} style={{direction:this.props.isRTL?'rtl':'ltr'}} />
                    </MDBCol>
                    <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                      <label  htmlFor="defaultFormRegisterNameEx2" className="grey-text formLabel">
                      {allStrings.count}
                      </label>
                    </MDBCol>
                  </MDBRow>
                  
                  <MDBRow>
                    <MDBBtn type="submit" style={{ margin: '4rem 0',width:'170px',padding:'10px' }}
                        className="btn z-depth-1a "
                        color="primary"
                      >
                        {allStrings.add}
                    </MDBBtn>
                  </MDBRow>
                </MDBContainer>
              </form>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBModalBody>
    </MDBModal>
   );
    
  render() {
    return (
      <div className="ReceivedProducts">
        {this.modal()}
        <MDBContainer>
          <MDBRow>
            <MDBCol md="4" sm="4" xs="4">
              <div className="top" style={{width:'100%',textAlign:'center'}} onClick={()=> this.props.history.goBack()}>
                <MDBIcon icon="times" className="closeIcon"/>
              </div>
            </MDBCol>
            <MDBCol md="4" sm="4" xs="4">
              <label  htmlFor="defaultFormRegisterNameE"
                style={{
                  fontWeight: '800',
                  color: '#43425D',
                  fontSize: '20px',
                  margin: '1rem',
                  textAlign: 'center',
                  display: 'block'
                }}>
                {allStrings.receivedProducts}
              </label>
            </MDBCol>
            <MDBCol md="4" sm="4" xs="4">
                <div className="addButton" onClick={this.toggle}>
                <MDBIcon icon="plus" />
                </div>
                <span onClick={this.toggle}
                style={{
                  float: 'right',
                  color: '#43425D',
                  fontWeight: '600',
                  padding: '11px 2px',
                  fontSize: '20px',
                  cursor:'pointer',
                }}>{allStrings.DliveryNewProduct}</span>
            </MDBCol>
          </MDBRow>
          <MDBRow>
         
            {this.state.loading?
              <div>
                <LoadCard/>
                <LoadCard/>
              </div>
            
            :
            this.state.receiveProducts.length > 0 ?
            this.state.receiveProducts.map((val) => (
              
              <MDBCol xl="3" lg="6" md="6" sm="12">
                <div className="salesManRecProduct">
                  <img alt=""  src={val.product.mainImg} width="100px" height="120px"/>
                  <span style={{
                    color: 'rgb(59, 134, 255)',
                    fontWeight: 400,
                    position: 'relative',
                    top: '-130px',
                    right: '20px',
                    background: '#fff',
                    borderRadius: '50%',
                    fontSize: '14px',
                    padding: '6px',
                    width: '35px',
                    height: '35px',
                    display: 'inline-block'
                  }}>{val.product.id}</span>
                </div>
                <p style={{
                    textAlign: 'center',
                    color: '#3B86FF',
                    marginTop: '14px',
                    fontWeight: '600'
                  }}>{this.props.isRTL?val.product.name_ar:val.product.name_en}</p>
                {val.delivered?
                <p style={{
                  textAlign: 'center',color: 'limegreen',fontSize: '20px'
                }}>{allStrings.deliverd}</p>
                :
                <div style={{marginLeft:'4rem'}}>
                  
                  <div onClick={()=>this.editCount("min",val.id)}
                    className="spanRec spanRec1"><span style={{lineHeight: "20px"}}>-</span>
                  </div>
                  <div className="centerDiv"><span className="centerSpan">{val.remain}</span></div>
                  <div onClick={()=>this.editCount("add",val.id)}
                  className="spanRec spanRec2"><span style={{lineHeight: "20px"}}>+</span>
                  </div>
                  
                </div>
                }
                
              </MDBCol>
             
             )) :
             <div className="noData">{allStrings.noData}</div>
            }
          </MDBRow>
          <MDBRow>
          {this.state.receiveProducts.length > 0 &&
            <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
              <Pagination
                onChange={(event,page)=>{
                console.log("page   ",page)
                if(page!==this.state.page){
                  this.getReceiveProducts(page)
                  this.setState({page:page})
                }
                
                }} 
                defaultPage={1} page={this.state.page}  count={this.state.pages} style={{color:`blue !important`}} />
            </div>
          }
          
        </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
}
  export default  withRouter(
    connect(mapToStateProps,mapDispatchToProps)(ReceivedProducts)
  );
  
