/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React from 'react';
import './ads.css'
import { MDBContainer,  MDBRow, MDBCol,MDBIcon} from "mdbreact";
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import { Popconfirm,message,Select,Input} from 'antd';
import Pagination from '@material-ui/lab/Pagination';
import LoadCard from '../../component/load card/load card'
import { hasValue } from "../../validations/validations";
import { SearchOutlined  } from '@ant-design/icons';
import moment from 'moment'
import { Redirect, NavLink } from 'react-router-dom'
import ReactToPrint from 'react-to-print';

import { createBrowserHistory } from 'history';
let history = createBrowserHistory();
const { Option } = Select;
class ComponentToPrint extends React.Component {
  
  render() {
console.log(this.props)
    return (
      <form class="col s12">
          <div className='tabelDev'>     
            <div class="row">
              <table class="table table-borderless">
                  <thead class="thead-light">
                      <tr>
                        <th>العنوان</th>
                        <th>المده</th>
                        <th>البدايه</th>
                        <th>النهايه</th>
                        <th>المشاهدات</th>
                        <th>الهاتف</th>
                        <th>الرابط</th>
                      </tr>
                      <tr>
                        <th>Title</th>
                        <th>Duration</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Views</th>
                        <th>Phone</th>
                        <th>Link</th>
                      </tr>
                  </thead>

                  <tbody>
                    { this.props.data.map(ads=>(
                        <tr>
                          <td>{ads.name?ads.name:''}</td>
                          <td>{ads.duration?ads.duration:''}</td>
                          <td>{ads.start?moment(ads.start).calendar():''}</td>
                          <td>{ads.end?moment(ads.end).calendar():''}</td>
                          <td>{ads.views?ads.views:0}</td>
                          <td>{ads.phone?ads.phone:''}</td>
                          <td>{ads.link?ads.link:''}</td>
                        </tr>
                    ))}
                  </tbody>
              </table>
              </div>
              </div>          
         </form>
    );
  }
}
class Ads extends React.Component {
  
  state = {
    Ads:[],
    loadin:true,
    refresh:false,
    page:1,
    pages:1,
    selectedAds:null,
    categories:[],
    products:[],
    filterType:null,
    value:'',
    ads:[],
    viewsCount:'',
    adsCount:''
  }
  constructor(props){
    super(props)
    console.log("isr   ",this.props.history)
    this.getAdsWithoutPagenation()
    this.getProducts()
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    window.scrollTo(0,0)
  }
  getAdsWithoutPagenation = () => {
    let url =``;
    url = `${BASE_END_POINT}ads/withoutPagenation/get?all=true`
    axios.get(url)
    .then(response=>{
      console.log(response.data.ads)
      this.setState({ads:response.data.ads,viewsCount:response.data.views,adsCount:response.data.adsCount})
    })
    .catch(error=>{
      console.log("ALL salesMen ERROR")
      console.log(error.response)
    })
  }
  componentDidMount(){
    this.getAds(1,false)
    this.getCategories()
    this.getProducts()

  }
  getCategories = () => {
    axios.get(`${BASE_END_POINT}categories/get/Normal`)
    .then(response=>{
      console.log(response.data)
      this.setState({categories:response.data})
    })
    .catch(error=>{
      console.log("ALL Categories ERROR")
      console.log(error.response)
    })
  }
  getProducts = () => {
    axios.get(`${BASE_END_POINT}products/withoutPagenation/get`)
    .then(response=>{
      console.log(response.data.products)
      this.setState({products:response.data.products})
    })
    .catch(error=>{
      console.log("ALL products ERROR")
      console.log(error.response)
    })
  }
  getAds = (page,refresh,filterType,value) =>{
    this.setState({loading:true})
    let url = ``;
  
    console.log(filterType)
    console.log(value)
    if(filterType != null && value != ''){
      if(filterType == "product"){
        console.log('h')
        if(this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN"){
          url = `${BASE_END_POINT}ads?all=true&page=${page}&product=${value}`
        } else{
          url = `${BASE_END_POINT}ads?all=true&page=${page}&company=${this.props.currentUser.user.id}&product=${value}`
        }
      }
      if(filterType == "name"){
        if(this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN"){
          url = `${BASE_END_POINT}ads?all=true&page=${page}&name=${value}`
        } else{
          url = `${BASE_END_POINT}ads?all=true&page=${page}&company=${this.props.currentUser.user.id}&name=${value}`
        }
      }
      if(filterType == "category"){
        if(value != "all"){
          if(this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN"){
          
            url = `${BASE_END_POINT}ads?all=true&page=${page}&category=${value}`
          } else{
            url = `${BASE_END_POINT}ads?all=true&page=${page}&company=${this.props.currentUser.user.id}&category=${value}`
          }
        }else{
          if(this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN"){
          
            url = `${BASE_END_POINT}ads?all=true&page=${page}`
          } else{
            url = `${BASE_END_POINT}ads?all=true&page=${page}&company=${this.props.currentUser.user.id}`
          }
        }
       
      }
    }else{
      if(this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN"){
        url = `${BASE_END_POINT}ads?all=true&page=${page}`
      } else{
        url = `${BASE_END_POINT}ads?all=true&page=${page}&company=${this.props.currentUser.user.id}`
      }
    }
    
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("ads   ",response.data)
      this.setState({
        loading:false,
        refresh:false,
        Ads:response.data.data,
        page:response.data.page,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })

  }
  selectAdsId = (e) =>{
    e.preventDefault();
    let id = e.target.id.substring(3)
    console.log(id)    
    this.setState({selectedAds:id})
  }
  deleteAds = (id) => {
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}ads/${id}`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`
     },
    })
    .then(response=>{
      console.log("Ads DELETED")
      l.then(() => message.success(allStrings.done, 2) )
      this.getAds(this.state.page)
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error('Error', 2.5))
    })
 }
 OKBUTTON = (e) => {
  this.deleteAds(this.state.selectedAds)
 }

  render(){
    console.log(this.state.filterType)
  if (this.props.currentUser.user.type == "COMPANY") return <Redirect to='/Home' /> 
  if (this.props.currentUser.user.type == "SUB-ADMIN" && !this.props.currentUser.user.pagesPermission.includes('ADS')) return <Redirect to='/Home' /> 

  return (
    <div className="Category">
      <div className="categoryTop" style={{marginBottom:'3rem'}}>
      <MDBContainer> 
        <MDBRow>
          <MDBCol xl="3" lg="3" md="3" sm="6">
            <ReactToPrint
              trigger={() => {
                return (
                  <div>
                    <img alt=""  style={{cursor:'pointer'}} src={require('../../assets/images/icons8_pdf.png')}></img>
                    <span
                    style={{
                      color: '#FF3B40',
                      fontWeight: '600',
                      padding: '10px',
                      fontSize: '20px',
                      position: 'absolute',
                      cursor:'pointer'
                    }}>{allStrings.print}</span>
                  </div>
                ) ;
              }}
              content={() => this.componentRef}
            />
            <div style={{display:'none'}}>
            <ComponentToPrint  data={this.state.ads} ref={el => (this.componentRef = el)} />
            </div>
          </MDBCol>
          <MDBCol xl="3" lg="3" md="3" sm="6"  style={{paddingTop:'1rem'}}>
          <img alt=""  width= '40px' src={require('../../assets/images/icons8_billboard_1.png')}></img>
            <span
            style={{
              color: 'mediumblue',
              fontWeight: '600',
              fontSize: '22px',
              position: 'absolute',
              padding: '2px 10px'
            }}>{this.state.adsCount}</span>
            <p style={{
              color: "mediumblue",
              fontSize: "14px",
              marginTop: "6px",
              marginBottom: 0,
              fontWeight: "600"
            }}>{allStrings.adsCount}</p>
          </MDBCol>
          <MDBCol xl="3" lg="3" md="3" sm="6" style={{paddingTop:'1rem'}}>
          <img alt=""  width= '40px' src={require('../../assets/images/icons8_eye.png')}></img>
            <span
            style={{
              color: 'mediumblue',
              fontWeight: '600',
              fontSize: '22px',
              position: 'absolute',
              padding: '0px 10px'
            }}>{this.state.viewsCount}</span>
            <p style={{
              color: "mediumblue",
              fontSize: "14px",
              marginTop: "6px",
              marginBottom: 0,
              fontWeight: "600"
            }}>{allStrings.viewsCount}</p>
          </MDBCol>
          <MDBCol xl="3" lg="3" md="3" sm="6">
            <NavLink to="adsForm">
            <div className="addButton">
              <MDBIcon icon="plus" />
            </div>
            <span
            style={{
              float: 'right',
              color: '#43425D',
              fontWeight: '600',
              padding: '11px 2px',
              fontSize: '20px',
              cursor:'pointer',
            }}>{allStrings.addAds}</span>
            </NavLink>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <div onClick={()=>this.getAds(1,false)} style={{width: '100%',textAlign: 'end',paddingRight: '18px'}}>
            <span style={{color: 'cadetblue',cursor: 'pointer'}}
            class="material-icons">undo</span>
          </div>
        </MDBRow>
      </MDBContainer>
          

        </div>
        <div className="filters" style={{marginBottom: "2rem"}}>
        <MDBContainer> 
          <MDBRow>
          <MDBCol xl="4" lg="6" md="12" sm="12">
            <Select 
              style={{ width: '100%' }} 
              placeholder={allStrings.product}
              onChange={(value) => {
                console.log(value)
                  this.setState({filterType:'product',value:value})
                  this.getAds(1,false,"product",value)
                
              }} 
            >
              <Option value="all">{allStrings.all}</Option>
              {this.state.products.map(val=>(
                  <Option value={val.id} style={{direction:this.props.isRTL?'rtl':'ltr'}}>
                    {this.props.isRTL?val.name_ar:val.name_en}
                  </Option>
              ))}
            </Select>
          </MDBCol>
          <MDBCol xl="4" lg="6" md="12" sm="12">
              <Input 
                onKeyPress={(e)=>{
                  if (e.key === 'Enter') {
                    if(hasValue(""+e.target.value)){
                      console.log("ENTER   ",e.target.value)
                      this.setState({filterType:'name',value:e.target.value})
                      this.getAds(1,false,"name",e.target.value)
                    }else{
                      message.warning(allStrings.noValue);
                      console.log("ENTER NO VALUE   ",e.target.value)
                    }
                  }
                }}  
                placeholder={allStrings.nameFilter}
                size="large"  prefix={<SearchOutlined />} />
          </MDBCol>
          <MDBCol xl="4" lg="6" md="12" sm="12">
            <Select 
              style={{ width: '100%' }} 
              placeholder={allStrings.category}
              onChange={(value) => {
                console.log(value)
                  this.setState({filterType:'category',value:value})
                  this.getAds(1,false,"category",value)
                
              }} 
            >
              <Option value="all">{allStrings.all}</Option>
              {this.state.categories.map(val=>(
                  <Option value={val.id} style={{direction:this.props.isRTL?'rtl':'ltr'}}>
                    {this.props.isRTL?val.categoryName_ar:val.categoryName_en}
                  </Option>
              ))}
            </Select>
          </MDBCol>
          </MDBRow>
        </MDBContainer>
        </div>
      <MDBContainer> 
        <MDBRow>
        {this.state.loading?
          <LoadCard/>
          :
          this.state.Ads.length > 0 ?
            this.state.Ads.map(ads=>(
              
              <MDBCol xl="4" lg="6" md="12" sm="12">
                
              <div className="categoryCard" >
              <MDBContainer> 
                <MDBRow>
                  <MDBCol xl="2" lg="2" md="2" sm="2">
                    <div className="icons">
                    <Popconfirm
                        title={allStrings.areYouSure}
                        onConfirm={this.OKBUTTON}
                        onCancel={this.fCANCELBUTTON}
                        okText={allStrings.ok}
                        cancelText={allStrings.cancel}
                      >
                      <p style={{
                            fontSize: '24px',
                            color: '#A4A3B0',
                            marginBottom: '8px',
                            cursor: 'pointer',
                            zIndex:'4',
                            position: 'relative'
                      }} onClick={this.selectAdsId} id={"Ads"+ads.id}>
                        <MDBIcon icon="times" onClick={this.selectAdsId} id={"Ads"+ads.id}/>
                      </p>

                    </Popconfirm>
                       
                      <p 
                      onClick={() => this.props.history.push('/adsForm',{data:ads})} 
                      style={{
                          fontSize: '18px',
                          color: '#A4A3B0',
                          marginBottom: '8px',
                          cursor: 'pointer',
                      }}>
                      <MDBIcon icon="pen" />
                      </p>
                     
                    </div>
                  </MDBCol>
                  <MDBCol xl="6" lg="6" md="6" sm="6" className="noPadding" onClick={() => this.props.history.push('/adsForm',{data:ads})} >
                    <div className="categoryInfo">
                      <p style={{
                        fontWeight: '600',
                        color:'#43425D',
                        marginBottom: '6px',
                        fontSize: '22px'
  
                      }}>{ads.name.length>10?ads.name.substring(0, 11) + "..":ads.name}</p>
                      <span style={{
                      color: '#43425D',
                      fontSize: '18px',
                      marginBottom: '8px'}}>{allStrings.day}</span>
                      <p style={{
                      color: '#43425D',
                      fontSize: '16px',
                      marginBottom: '8px',
                      display:'inline-block'}}>{ads.duration} </p>
                      <p style={{
                      color: '#43425D',
                      fontSize: '16px',
                      margin: 0,
                      fontWeight: '400', marginBottom: '10px'}}>{moment(ads.start).calendar()}</p>
                      <p style={{
                      color: '#43425D',
                      fontSize: '16px',
                      margin: 0,
                      fontWeight: '400', marginBottom: '10px'}}>{moment(ads.end).calendar()}</p>
                    </div>
                  </MDBCol>
                  <MDBCol xl="4" lg="4" md="4" sm="4"onClick={() => this.props.history.push('/adsForm',{data:ads})} >
                  
                    <div className="categoryImg" >
                      {ads.dataType =='img'?
                        <img alt=""    style={{width: '70px',
                        height: '70px',
                        borderRadius: '100%'}}  src={ads.img[0]}></img>
                        :
                        <video style={{width: '70px',
                          height: '70px',
                          borderRadius: '100%',
                          }} src={ads.img[0]}></video>
                      }
                      
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                <MDBCol xl="4" lg="4" md="4" sm="4">
                <p style={{fontWeight: '700',color: '#43425D'}}>{ads.views}</p>
                </MDBCol>
                <MDBCol xl="4" lg="4" md="4" sm="4">
                </MDBCol>
                <MDBCol xl="4" lg="4" md="4" sm="4">
                <p style={{
                      color: ads.visible?'limegreen':'red',
                      fontSize: '18px',
                      marginBottom: '8px',
                      display:'inline-block'}}>{ads.visible?allStrings.show:allStrings.notShow} </p>
                </MDBCol>
                </MDBRow>
              </MDBContainer> 
              </div>
              </MDBCol>
            ))
            :
            <div className="noData">{allStrings.noData}</div>
        }
        </MDBRow>
        <MDBRow>
          {this.state.Ads.length > 0 &&
            <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
              <Pagination
                onChange={(event,page)=>{
                console.log("page   ",page)
                if(page!=this.state.page){
                  this.getAds(page,false,this.state.filterType,this.state.value)
                  this.setState({page:page})
                }
                
                }} 
                defaultPage={1} page={this.state.page}  count={this.state.pages} style={{color:`blue !important`}} />
            </div>
          }
          
        </MDBRow>
      </MDBContainer> 
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage
}

export default  withRouter( connect(mapToStateProps,mapDispatchToProps) (Ads))
