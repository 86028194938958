/* eslint-disable eqeqeq */

import React from 'react';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import './settings.css'
import {TreeSelect,message} from 'antd';
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { setUser } from "../../redux/actions/AuthActions";
import 'antd/dist/antd.css';
import { MDBContainer ,MDBRow,MDBCol,MDBBtn} from 'mdbreact';
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
const {TreeNode} =TreeSelect


class Settings extends React.Component {
  constructor(props){
    super(props)
    console.log("isr   ",this.props.isRTL)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  }

  state ={
   language:this.props.isRTL,
   advertisementUrl:'',
   appRation:'',
   setting:[]
  }

  componentDidMount(){
    this.getSetting()
  }
  getSetting = () =>{
    this.setState({loading:true})
    axios.get(`${BASE_END_POINT}setting`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`,
        'Accept-Language':this.props.isRTL?'ar':'en'
      }
    })
    .then(response=>{
      console.log("Setting   ",response.data)
      this.setState({
        loading:false,
        setting:response.data,
        advertisementUrl:response.data.advertisementUrl,
        appRation:response.data.appRation
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
  update = () => {
      const {advertisementUrl,appRation} = this.state
      let l = message.loading('Wait..', 2.5)
      const data={
        "advertisementUrl":advertisementUrl,
        "appRation":appRation
      }
      console.log("data",data)
      axios.put(`${BASE_END_POINT}setting/${this.state.setting.id}`,data,{
      headers: {
      'Content-Type': 'application/json',
      'Authorization':`Bearer ${this.props.currentUser.token}`,
      'Accept-Language':this.props.isRTL?'ar':'en'
      },
      })
      .then(response=>{
      console.log("Setting UPDATED  ",response.data)
      l.then(() => message.success(allStrings.done, 2.5) )
      this.props.history.goBack() 
      })
      .catch(error=>{
          console.log(error.response)
          l.then(() => message.error(allStrings.error, 2.5))
      })
      
  }
  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };


  render(){
  return (
    <div className="settings">
      <MDBContainer>
        <MDBRow>
          <MDBCol md = "6" sm="6">

          </MDBCol>
          <MDBCol md = "6" sm="6" style={{marginTop:'2rem'}}>
            <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse' }}>
            <MDBCol md = "9" sm="9">
            <TreeSelect
                value={this.state.language}
                showSearch={false} 
                style={{ width: '100%' ,textAlign:this.props.isRTL?'right':'left',height:'40px'}} 
                treeCheckable = {false}         
                treeNodeFilterProp="title"                      
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder={allStrings.chooseLang}
                onChange={(value) => {
                  console.log(value)
                  this.setState({language:value});
                  this.props.ChangeLanguage(value)
                  if(value == true){
                    allStrings.setLanguage('ar')
                    localStorage.setItem("@LANG",'ar')
                  }else{
                    allStrings.setLanguage('en')
                    localStorage.setItem("@LANG",'en')
                  }
                }} 
              >
              <TreeNode value={true} title="اللغه العربيه"  key={true} />
              <TreeNode value={false} title="English" key={false} />
            </TreeSelect>
            </MDBCol>
            <MDBCol md = "3" sm="3">
               <label className="grey-text formLabel" style={{padding: '10px'}}>
                {allStrings.lang}
                </label>
            </MDBCol>
            </MDBRow>
           </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md = "6" sm="6"></MDBCol>
          <MDBCol md = "6" sm="6">
            <MDBRow style={{flexDirection:this.props.isRTL?'row':'row-reverse' }}>
              <MDBCol md="9" sm="9">
                <input name="advertisementUrl" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx5" required
                  className="form-control" placeholder={allStrings.advertisementUrl} defaultValue={this.state.advertisementUrl} 
                  style={{direction:this.props.isRTL?'rtl':'ltr',marginBottom: '10px'}}/>
              </MDBCol>
              <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                <label  htmlFor="defaultFormRegisterNameEx5" className="grey-text formLabel">
                {allStrings.advertisementUrl}
                </label>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow>
        <MDBCol md = "6" sm="6"></MDBCol>
        <MDBCol md = "6" sm="6">
          <MDBBtn onClick={() =>this.update()}
            style={{ margin: '1rem 8rem',width:'150px',padding:'9px',height:'35px',float:'right' }}
                className="btn z-depth-1a "
                color="primary"
              >
                 {allStrings.done}
          </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,

})

const mapDispatchToProps = {
  ChangeLanguage,
  setUser,
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(Settings)
);
