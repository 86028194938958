/* eslint-disable eqeqeq */
import React from 'react';
import './statistic.css'
import { MDBContainer,  MDBRow, MDBCol,MDBIcon} from "mdbreact";

import { Line } from "react-chartjs-2";
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import { NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment'
import {DatePicker,ConfigProvider } from 'antd';
import locale_ar from 'antd/lib/locale/ar_EG';
import locale_en from 'antd/lib/locale/en_GB';
import 'moment/locale/ar';
let fromDate
let toDate
class Statistic extends React.Component {
  
  state = {
    count:[],
    countPrev:[],
    graph:[],
    tops:[],
    topProducts:[],
    ads:[],
    ordersCount:[],
    adsView:[],
    monthA:[],
    monthE:[],
    fromDate:'',
    toDate:'',
    loading:true,
    graphStep:0,
    maxValue:0,
    from:this.props.isRTL?moment().startOf('month').format("YYYY/MM/DD"):moment().startOf('month').locale('en').format("YYYY/MM/DD"),
    to:this.props.isRTL?moment().endOf('month').format("YYYY/MM/DD"):moment().endOf('month').locale('en').format("YYYY/MM/DD"),

    topSalesMan:[],
    topCompany:[],
    topProduct:[]
  }
  
   
  constructor(props){
    super(props)
    this.getDuration()
    this.getTops()
    this.getCount()
    this.getCountPrevMonth()
    this.getGraph()
    this.getTopProducts()
    this.getAds()
    window.scrollTo(0,0)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
      moment.locale('ar');
    }else{
      allStrings.setLanguage('en')
      moment.locale('en');
    } 
  }
  getDuration = () =>{
    //first day
    let date = new Date()
    let newDate = date.toISOString().slice(0, 8)  
    let firstDay = newDate + '01';
    //get days in month
    function daysInMonth (month, year) {
        return new Date(year, month , 0).getDate();
    }

    let DaysInCurrentMonth = daysInMonth(date.getMonth() + 1,date.getFullYear()); //number of day in month
    //last day
    let lastDay= newDate + DaysInCurrentMonth;
    console.log(firstDay)
    console.log(lastDay)
    if(this.props.isRTL){
      this.setState({from:moment(firstDay).format("YYYY/MM/DD") ,to:moment(lastDay).format("YYYY/MM/DD") })
    }else{
      this.setState({from:moment(firstDay).locale('en').format("YYYY/MM/DD") ,to:moment(lastDay).locale('en').format("YYYY/MM/DD") })

    }

    fromDate = firstDay
      toDate = lastDay
    
  }
  getCount = () => {
    console.log(this.state.fromDate)
    axios.get(`${BASE_END_POINT}admin/statistics?fromDate=${fromDate}&toDate=${toDate}`,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`
      },
    })
    .then(response=>{
      console.log(response.data)
      this.setState({count:response.data,loading:false})
    })
    .catch(error=>{
      console.log("ALL count ERROR")
      console.log(error.response)
    })
  }
  getCountPrevMonth = () => {
    let date = new Date()
    //let newDate = date.toISOString().slice(0, 8)  
    function theMonth (month, year) {
      const datee = new Date(year, month , 0); 
      return datee.getMonth()
    }
    let month1 = theMonth(date.getMonth() +1,date.getFullYear())
    console.log(month1)
    if(month1 == 0){
      month1 = 12
  }
    let FirstDay = date.getFullYear()+'-'+ month1 + '-01';
    if(month1 > 0 && month1 < 10){
      FirstDay = date.getFullYear()+'-0'+ month1 + '-01';
    }
    console.log(FirstDay)
    //get days in month
    function daysInMonth (month, year) {
        return new Date(year, month , 0).getDate();
    }

    let DaysInCurrentMonth = daysInMonth(date.getMonth() ,date.getFullYear()); //number of day in month
    //last day
    let LastDay = date.getFullYear()+'-'+ month1 +'-'+ DaysInCurrentMonth ;
    if(month1 > 0 && month1 < 10){
      LastDay = date.getFullYear()+'-0'+ month1 +'-'+ DaysInCurrentMonth ;
    }
    console.log(LastDay)
    axios.get(`${BASE_END_POINT}admin/statistics?fromDate=${FirstDay}&toDate=${LastDay}`,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.props.currentUser.token}`
      },
    })
    .then(response=>{
      console.log(response.data)
      this.setState({countPrev:response.data,loading:false})
    })
    .catch(error=>{
      console.log("ALL count ERROR")
      console.log(error.response)
    })
  }
  getTopProducts = () => {
    axios.get(`${BASE_END_POINT}orders/topProduct?start=${fromDate}&end=${toDate}&product=true`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log(response.data)
      this.setState({topProducts:response.data})
    })
    .catch(error=>{
      console.log("ALL top products ERROR")
      console.log(error.response)
    })
  }
  getTops = () => {
    axios.get(`${BASE_END_POINT}orders/tops?start=${fromDate}&end=${toDate}`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log(response.data.topProduct)
      this.setState({tops:response.data,topCompany:response.data.topCompany,
        topSalesMan:response.data.topSalesMan,topProduct:response.data.topProduct})
    })
    .catch(error=>{
      console.log("ALL tops ERROR")
      console.log(error.response)
    })
  }
  getGraph = () => {
    axios.get(`${BASE_END_POINT}admin/graph`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log(response.data)
      let monthE = []
      let monthA = []
      function monthNameE(mon) {
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September','October' , 'November','December'][mon - 1];
      }
      function monthNameA(mon) {
        return ['يناير', 'فبراير', 'مارس', 'ابريل', 'مايو', 'يونيو', 'يوليو', 'اغسطس', 'سبتمبر','اكتوبر', 'نوفمبر', 'ديسمبر'][mon - 1];
      }
      response.data.months.forEach(month => {
        monthE.push(monthNameE(month))
        monthA.push(monthNameA(month))
      });
      console.log(monthE)
      console.log(monthA)
      let arr = response.data.ordersCount
      let maxValue1 = Math.max( ...arr );

      let max = maxValue1;

      let arr2 = response.data.adsView
      let maxValue2 = Math.max( ...arr2 );
      if(maxValue2 > maxValue1)
        max = maxValue2

       
      this.setState({adsView:response.data.adsView,
        ordersCount:response.data.ordersCount,
        monthE:monthE,
        monthA:monthA,
        maxValue:max,
        graphStep:Math.floor(max / 10)
      })
    })
    .catch(error=>{
      console.log("ALL graph ERROR")
      console.log(error.response)
    })
  }

  getAds = () => {
    axios.get(`${BASE_END_POINT}ads?all=true&page=1&limit=7`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log(response.data.data)
      this.setState({ads:response.data.data})
    })
    .catch(error=>{
      console.log("ALL ads ERROR")
      console.log(error.response)
    })
  }

  componentDidMount(){
    //this.props.ChangeLanguage(false)
    this.getDuration()
  
  }
  onChangeFrom = (date,dateString) => { 
    console.log(dateString) 
    if(this.props.isRTL){
      this.setState({from:moment(dateString).format("YYYY/MM/DD")})
    }else{
      this.setState({from:moment(dateString).locale('en').format("YYYY/MM/DD") })
    }
    fromDate = moment(dateString).locale('en').format("YYYY-MM-DD")
  }
  onChangeTo = (date,dateString) => {
    if(this.props.isRTL){
      this.setState({to:moment(dateString).format("YYYY/MM/DD") })
    }else{
      this.setState({to:moment(dateString).locale('en').format("YYYY/MM/DD") })

    }
    toDate = moment(dateString).locale('en').format("YYYY-MM-DD")
    this.getTops()
    this.getTopProducts()
    this.getCount()
  }

  render(){
    const data = {
      labels: this.props.isRTL?this.state.monthA:this.state.monthE,
      datasets: [
        {
          label: allStrings.viewsCount,
          data: this.state.adsView,
          fill: true,
          backgroundColor: 'rgba(157, 248, 255, 0.411)',
          borderColor: 'rgba(157, 248, 255, 0.411)',
          pointBorderColor:'rgba(157, 248, 255, 0.411)',
          pointBackgroundColor:'rgba(255, 255,255)',
          pointBorderWidth:5,
          yAxisID: 'y-axis-1',
          
        },
        {
          label: allStrings.ordersCount,
          data:this.state.ordersCount,
          fill: true,
          backgroundColor: 'rgba(195, 193, 252, 0.555)', 
          borderColor: 'rgba(54, 162, 235, 0.2)',
          pointBorderColor:'rgba(54, 162, 235, 0.2)',
          pointBackgroundColor:'rgba(255, 255, 255, 1)',
          pointBorderWidth:5,
          yAxisID: 'y-axis-2',
        },
      ],
    }
    
    const options = {
      maintainAspectRatio : false,
      responsive: true,
      legend: {
        display: true,
        position:'bottom',
        align:'start',

        labels: {
            // This more specific font property overrides the global property
            //fontColor: 'transparent',
            fontColor:'#000',
            borderRadius:'4px',
            boxWidth:20,
            padding:20
            
        }
      },
      scales: {
        
        yAxes: [
          {
            type: 'linear',
            display: true,
            stacked: true,
            position: 'left',
            id: 'y-axis-1',
            ticks: {
              max: this.state.maxValue,
              min: 0,
              stepSize: this.state.graphStep
            },
            barPercentage: 1,
            offsetGridLines: false,
            gridLines: {
              offsetGridLines: false,
              display: true,
              color: "#F0F2F5",
              borderStyle:'dashed'
            },
          },
          {
            type: 'linear',
            display: false,
            position: 'right',
            id: 'y-axis-2',
            ticks: {
              max: this.state.maxValue,
              min: 0,
              stepSize: this.state.graphStep
            },
            barPercentage: 1,
            offsetGridLines: false,
            gridLines: {
              offsetGridLines: false,
              display: true,
              color: "#F0F2F5",
              borderStyle:'dashed'
            },
            
          },
        ],
      },
    }
    let {loading,topProducts} = this.state;
   console.log(this.state.topProduct.product)
  return (
    <div className="Home">
      <MDBContainer> 
      <MDBRow>
          <MDBCol xl="3" lg="2" md="6" sm="12">
            <img alt=""  src={require('../../assets/images/icons8_pdf.png')}></img>
            <span
            style={{
              color: '#FF3B40',
              fontWeight: '600',
              padding: '10px',
              fontSize: '20px',
              position: 'absolute',
            }}>{allStrings.print}</span>
          </MDBCol>
          
          <MDBCol xl="3" lg="2" md="6" sm="12" style={{paddingBottom:'2rem'}}>
            <div style={{textAlign: 'right',paddingtop: '15px'}}>
              {/*<img alt=""  width="40px" style={{float:'right'}} src={require('../../assets/images/icons8_calculate.png')}/>*/}
            </div>
          </MDBCol>
          <MDBCol xl="3" lg="4" md="6" sm="12">
          <div style={{marginBottom:'10px',textAlign:'center'}}>
                <span className="fromSpan" style={{float:this.props.isRTL? 'right':'left',marginRight:'0%'}}>{allStrings.to}</span>
                <div style={{display:'inline-block',textAlign:'center',marginLeft: this.props.isRTL? '-80px':"-60px"}}>
                <ConfigProvider locale={this.props.isRTL?locale_ar:locale_en}>
                  <DatePicker  defaultValue={moment(this.state.to, 'YYYY/MM/DD')}  onChange={this.onChangeTo}/>
                </ConfigProvider>
                <span className="newSpan" style={{left: this.props.isRTL? '16px':'60px'}}><img alt=""  style={{marginRight:'4px'}} src={require('../../assets/images/icons8_edit_property.png')}></img>{this.state.to}</span>
                </div>
              </div>
          </MDBCol>
          <MDBCol xl="3" lg="4" md="6" sm="12">
            <div style={{marginBottom:'10px',textAlign:'center'}}>
              <span className="fromSpan" style={{float:this.props.isRTL? 'right':'left',marginRight:'0%'}}>{allStrings.from}</span>
              <div style={{display:'inline-block',textAlign:'center',marginLeft:this.props.isRTL? '-80px':"-60px"}}>
              <ConfigProvider locale={this.props.isRTL?locale_ar:locale_en}>
                  <DatePicker  defaultValue={moment(this.state.from, 'YYYY/MM/DD')}  onChange={this.onChangeFrom}/>
                  </ConfigProvider>
              <span className="newSpan" style={{left: this.props.isRTL? '16px':'70px'}}><img alt=""  style={{marginRight:'4px'}} src={require('../../assets/images/icons8_edit_property.png')}></img>{this.state.from}</span>
              </div>
            </div>
          </MDBCol>
         
        </MDBRow>
        <MDBRow>

          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.adsViewsCount}</p>
                <MDBRow>
                  <MDBCol  md="6" sm="6">
                    <img alt=""  style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart1.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count">{loading?<Skeleton height={20} />:this.state.count.adsViewCount}</p>
                    <p className="arang" style={{color:this.state.count.adsViewCount> this.state.countPrev.adsViewCount?'lightGreen':'red'}}>
                    <MDBIcon icon={this.state.count.adsViewCount> this.state.countPrev.adsViewCount?'long-arrow-alt-up':'long-arrow-alt-down'} style={{color:this.state.count.adsViewCount> this.state.countPrev.adsViewCount?'lightGreen':'red'}}/>
                    {
                      this.state.count.adsViewCount == this.state.countPrev.adsViewCount?
                      <span>--</span>
                      :
                      this.state.countPrev.adsViewCount >= this.state.count.adsViewCount?
                      Math.ceil((((this.state.countPrev.adsViewCount - this.state.count.adsViewCount) / this.state.countPrev.adsViewCount) * 100) * 100)/100                     
                      :
                      Math.ceil((((this.state.count.adsViewCount - this.state.countPrev.adsViewCount) / this.state.count.adsViewCount) * 100) * 100)/100                     
                    }
                    </p>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.allSoldProducts}</p>
                <MDBRow>
                  <MDBCol  md="6" sm="6">
                    <img alt=""  style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart2.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count">{loading?<Skeleton height={20} />:this.state.count.ordersCount}</p>
                    <p className="arang" style={{color:this.state.count.ordersCount> this.state.countPrev.ordersCount?'lightGreen':'red'}}>
                    <MDBIcon icon={this.state.count.ordersCount> this.state.countPrev.ordersCount?'long-arrow-alt-up':'long-arrow-alt-down'} />
                    
                      {
                        this.state.count.ordersCount == this.state.countPrev.ordersCount?
                        <span>--</span>
                        :
                        this.state.countPrev.ordersCount > this.state.count.ordersCount?
                        Math.ceil((((this.state.countPrev.ordersCount - this.state.count.ordersCount) / this.state.countPrev.ordersCount) * 100) * 100)/100                     
                        :
                        Math.ceil((((this.state.count.ordersCount - this.state.countPrev.ordersCount) / this.state.count.ordersCount) * 100) * 100)/100                     
                    }
                    </p>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.TotalSalesOfAllCompanies}</p>
                <MDBRow>
                  <MDBCol  md="6" sm="6">
                    <img alt=""  style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart3.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count">{loading?<Skeleton height={20} />:this.state.count.totalOrders}</p>
                    <p className="arang" style={{color:this.state.count.totalOrders> this.state.countPrev.totalOrders?'lightGreen':'red'}}>
                    <MDBIcon icon={this.state.count.totalOrders> this.state.countPrev.totalOrders?'long-arrow-alt-up':'long-arrow-alt-down'} />
                    {
                      this.state.count.totalOrders == this.state.countPrev.totalOrders?
                      <span>--</span>
                      :
                      this.state.countPrev.totalOrders > this.state.count.totalOrders?
                      Math.ceil((((this.state.countPrev.totalOrders - this.state.count.totalOrders) / this.state.countPrev.totalOrders) * 100) * 100)/100                     
                      :
                      Math.ceil((((this.state.count.totalOrders - this.state.countPrev.totalOrders) / this.state.count.totalOrders) * 100) * 100)/100                     

                    }
                    </p>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.companiesInApp}</p>
                <MDBRow>
                  <MDBCol  md="6" sm="6">
                    <img alt=""  style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart4.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count">{loading?<Skeleton height={20} />:this.state.count.companiesCount}</p>
                    <p className="arang" style={{color:this.state.count.companiesCount> this.state.countPrev.companiesCount?'lightGreen':'red'}}>
                    <MDBIcon icon={this.state.count.companiesCount> this.state.countPrev.companiesCount?'long-arrow-alt-up':'long-arrow-alt-down'} />
                    {
                      this.state.count.companiesCount == this.state.countPrev.companiesCount?
                      <span>--</span>
                      :
                      this.state.countPrev.companiesCount > this.state.count.companiesCount?
                      Math.ceil((((this.state.countPrev.companiesCount - this.state.count.companiesCount) / this.state.countPrev.companiesCount) * 100) * 100)/100                     
                      :
                      Math.ceil((((this.state.count.companiesCount - this.state.countPrev.companiesCount) / this.state.count.companiesCount) * 100) * 100)/100                     
                      
                    }
                    </p>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.allUsersCount}</p>
                <MDBRow>
                  <MDBCol  md="6" sm="6">
                    <img alt=""  style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart5.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count">{loading?<Skeleton height={20} />:this.state.count.usersCount}</p>
                    <p className="arang" style={{color:this.state.count.usersCount> this.state.countPrev.usersCount?'lightGreen':'red'}}>
                    <MDBIcon icon={this.state.count.usersCount> this.state.countPrev.usersCount?'long-arrow-alt-up':'long-arrow-alt-down'}/>
                    {
                      this.state.count.usersCount == this.state.countPrev.usersCount?
                      <span>--</span>
                      :
                      this.state.countPrev.usersCount >= this.state.count.usersCount?
                      Math.ceil((((this.state.countPrev.usersCount - this.state.count.usersCount) / this.state.countPrev.usersCount) * 100) * 100)/100                     
                      :
                      Math.ceil((((this.state.count.usersCount - this.state.countPrev.usersCount) / this.state.count.usersCount) * 100) * 100)/100                     

                      }
                    </p>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.newUsers}</p>
                <MDBRow>
                  <MDBCol  md="6" sm="6">
                    <img alt=""  style={{width: '100%',marginTop: '20px'}} src={require('../../assets/images/Bar Chart6.png')}></img>
                  </MDBCol>
                  <MDBCol  md="6" sm="6">
                    <p className="count">{loading?<Skeleton height={20} />:this.state.count.dailyUsersCount}</p>
                    <p className="arang" style={{color:this.state.count.dailyUsersCount> this.state.countPrev.dailyUsersCount?'lightGreen':'red'}}>
                    <MDBIcon icon={this.state.count.dailyUsersCount> this.state.countPrev.dailyUsersCount?'long-arrow-alt-up':'long-arrow-alt-down'} />
                    {
                      this.state.count.dailyUsersCount == this.state.countPrev.dailyUsersCount?
                      <span>--</span>
                      :
                      this.state.countPrev.dailyUsersCount >= this.state.count.dailyUsersCount?
                      Math.ceil((((this.state.countPrev.dailyUsersCount - this.state.count.dailyUsersCount) / this.state.countPrev.dailyUsersCount) * 100) * 100)/100                     
                      :
                      Math.ceil((((this.state.count.dailyUsersCount - this.state.countPrev.dailyUsersCount) / this.state.count.dailyUsersCount) * 100) * 100)/100                     

                    }
                    </p>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol  md="12">
            <p style={{
                fontFamily: 'system-ui',
                background:' #fff',
                marginBottom: '0px',
                padding: '18px 22px',
                fontWeight: '600',
                color: '#43425D',
                fontSize: '17px'
            }}>{allStrings.statistic}</p>
            <div className="homeChart">
            
            <Line height="400px" data={data} options={options} />
            </div>
          </MDBCol>
        </MDBRow>
        <br></br>
        <MDBRow>
          <MDBCol xl="4" lg="6" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.topProduct}</p>
                <MDBRow>
                  <MDBCol xl="4" md="4" sm="4">
                    <p className="centerCount">{loading?<Skeleton height={20} />:this.state.topProduct.count?this.state.topProduct.count:0}</p>
                  </MDBCol>
                  <MDBCol  md="4" sm="4" style={{paddingRight:0}}>
                   <p className="centerName">{loading?<Skeleton height={20} />:this.state.topProduct.product?this.props.isRTL?this.state.topProduct.product.name_ar:this.state.topProduct.product.name_en:" "}</p>
                  </MDBCol>
                  <MDBCol  md="4" sm="4">
                  <img alt=""  style={{width: '70px',height: '70px',borderRadius:'100%',marginBottom:'10px',float: 'right'}} src={require('../../assets/images/profileicon.jpg')}></img>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="6" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.topCompany}</p>
                <MDBRow>
                  <MDBCol  md="4" sm="4">
                    <p className="centerCount">{loading?<Skeleton height={20} />:this.state.topCompany.count?this.state.topCompany.count:0}</p>
                  </MDBCol>
                  <MDBCol  md="4" sm="4" style={{paddingRight:0}}>
                   <p className="centerName">{loading?<Skeleton height={20} />:this.state.topCompany.company?this.state.topCompany.company.fullname:" "}</p>
                  </MDBCol>
                  <MDBCol  md="4" sm="4">
                  <img alt=""  style={{width: '70px',height: '70px',borderRadius:'100%',marginBottom:'10px',float: 'right'}} src={require('../../assets/images/profileicon.jpg')}></img>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="6" md="6" sm="12" style={{marginBottom: '1.5rem'}}>
            <div className="theCard">
              <p className="topCard">{allStrings.topSalesMan}</p>
                <MDBRow>
                  <MDBCol  md="4" sm="4">
                    <p className="centerCount">{loading?<Skeleton height={20} />:this.state.topSalesMan.count?this.state.topSalesMan.count:0}</p>
                  </MDBCol>
                  <MDBCol  md="4" sm="4" style={{paddingRight:0}}>
                   <p className="centerName">{loading?<Skeleton height={20} />:this.state.topSalesMan.salesMan?this.state.topSalesMan.salesMan.fullname:" "}</p>
                  </MDBCol>
                  <MDBCol  md="4" sm="4">
                  <img alt=""  style={{width: '70px',height: '70px',borderRadius:'100%',marginBottom:'10px',float: 'right'}} src={require('../../assets/images/profileicon.jpg')}></img>
                  </MDBCol>
                </MDBRow>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol xl="8" lg="7" md="12" sm="12">
            <div className="theCard">
              <p className="topCard" style={{color: '#43425D'}}>{allStrings.ads}</p>
              <br></br>
              <table class="table table-borderless">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">{allStrings.theAdd}</th>
                    <th scope="col">{allStrings.dalyViews}</th>
                    <th scope="col">{allStrings.duration}</th>
                    <th scope="col">{allStrings.allViews}</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.loading?
                
                <div>
                  <Skeleton height={40}/> 
                  <Skeleton height={40}/>
                  <Skeleton height={40}/>
                  <Skeleton height={40}/>
                  <Skeleton height={40}/>
                  <Skeleton height={40}/>
                </div>
                :
                this.state.ads.length > 0 ?
                  this.state.ads.map(ads=>(
                      <tr>
                        <th scope="row">{ads.name}</th>
                        <td>{ads.DailyViews}</td>
                        <td>{ads.duration}</td>
                        <td>{ads.views}</td>
                      </tr>
                  ))
                  :
                  <div className="noData">{allStrings.noData}</div>
                }
                </tbody>
              </table>
              <hr></hr>
              <NavLink to="ads">
                <span style={{display:'block',marginBottom:'8px'}}>{allStrings.showAll}</span>
              </NavLink>
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="5" md="12" sm="12">
            <div className="theCard">
              <NavLink to="stock">
                <span style={{position: 'absolute'}}>{allStrings.showAll}</span>
              </NavLink>
              <p className="topCard"  style={{color: '#43425D'}}>{allStrings.topProducts}</p>
              <img alt=""  width="100%" src={require('../../assets/images/Map.png')}></img>
              <div>
                <span className="rightSpan">{topProducts[0]?this.props.isRTL?topProducts[0].product.name_ar:topProducts[0].product.name_en:' ' }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{color:'#54D8FF'}}></span>
                  <span className="spanNum">{topProducts[0]?this.props.isRTL?topProducts[0].count:topProducts[0].count:0 }</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topProducts[1]?this.props.isRTL?topProducts[1].product.name_ar:topProducts[1].product.name_en:' ' }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#5EE2A0'}}></span>
                  <span className="spanNum">{topProducts[1]?this.props.isRTL?topProducts[1].count:topProducts[1].count:0}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topProducts[2]?this.props.isRTL?topProducts[2].product.name_ar:topProducts[2].product.name_en:' ' }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#FFA177'}}></span>
                  <span className="spanNum">{topProducts[2]?this.props.isRTL?topProducts[2].count:topProducts[2].count:0}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topProducts[3]?this.props.isRTL?topProducts[3].product.name_ar:topProducts[3].product.name_en:' ' }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#A3A1FB'}}></span>
                  <span className="spanNum">{topProducts[3]?this.props.isRTL?topProducts[3].count:topProducts[3].count:0}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topProducts[4]?this.props.isRTL?topProducts[4].product.name_ar:topProducts[4].product.name_en:' ' }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#FBA69D'}}></span>
                  <span className="spanNum">{topProducts[4]?this.props.isRTL?topProducts[4].count:topProducts[4].count:0}</span>
                </p>
              </div>
              <div>
                <span className="rightSpan">{topProducts[5]?this.props.isRTL?topProducts[5].product.name_ar:topProducts[5].product.name_en:' ' }</span>
                <p style={{marginBottom:0}}>
                  <span className="spanCicle" style={{borderColor:'#FF7CC3'}}></span>
                  <span className="spanNum">{topProducts[5]?this.props.isRTL?topProducts[5].count:topProducts[5].count:0}</span>
                </p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer> 
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(Statistic)
);
