/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React from "react";
import "./supplierForm.css";
import {MDBContainer,MDBRow,MDBCol,MDBBtn,MDBIcon} from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import {hasValue} from '../../validations/validations'
import {message} from 'antd';
import {NavLink} from 'react-router-dom';
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import {ChangeCompany,ChangeBranch} from '../../redux/actions/CompanyAction'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

class SupplierForm extends React.Component {
  state = {
    requestType:this.props.location.state?"put":"post",
    img:this.props.location.state?this.props.location.state.data.img !=""?this.props.location.state.data.img :null:null,

    imgType:this.props.location.state?'url':'data',
    fullname: this.props.location.state?this.props.location.state.data.fullname:"",
    phone: this.props.location.state?this.props.location.state.data.phone:"",
    anotherPhone: this.props.location.state?this.props.location.state.data.anotherPhone:"",
    cardNum: this.props.location.state?this.props.location.state.data.cardNum:"",
    supplier:this.props.location.state?this.props.location.state.data:[],
    photoIndex: 0,
    isOpen1: false,
    isOpen2: false,
  };

  constructor(props){
    super(props)
    console.log("isr   ",this.props.history)

    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    window.scrollTo(0,0)
  }

  
  addSupplier = () => {
    const {fullname,img,anotherPhone,phone,cardNum} = this.state
   
    if(hasValue(fullname)&&hasValue(cardNum) &&hasValue(phone) ){
        let l = message.loading('Wait..', 2.5)
        var data = new FormData()
        data.append('fullname',fullname)
        data.append('cardNum',cardNum)
        data.append('phone',phone); 
        if(img != null){
          data.append('img',img); 
        }
        if(anotherPhone !==""){
          data.append('anotherPhone',anotherPhone); 
        }
        if(this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN" ){
          if(this.props.company == false){
            data.append('company',this.props.currentUser.user.company[0].id); 
          }else{
            data.append('company',this.props.company); 
          }
        } else{
          data.append('company',this.props.currentUser.user.id);
        }
        
        axios.post(`${BASE_END_POINT}suppliers`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.push('/suppliers')
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error('Error', 2.5))
        })
    }
  }
  updateSupplier = () => {
    const {fullname,img,anotherPhone,phone,cardNum} = this.state
   
    if(hasValue(fullname)&&hasValue(cardNum) &&hasValue(phone) ){
        let l = message.loading('Wait..', 2.5)
        var data = new FormData()
        data.append('fullname',fullname)
        data.append('cardNum',cardNum)
        data.append('phone',phone); 
        if(img != null){
          data.append('img',img); 
        }
        if(anotherPhone !==""){
          data.append('anotherPhone',anotherPhone); 
        }
        
        axios.put(`${BASE_END_POINT}suppliers/${this.state.supplier.id}`,data,{
        headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
        l.then(() => message.success(allStrings.done, 2.5) )
          this.props.history.push('/suppliers')
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error('Error', 2.5))
        })
    }
  }
  
  componentDidMount(){
   
    //this.props.ChangeLanguage(false)
  }
  fileHandler = (event) => {
    this.setState({
      img: event.target.files[0],
      imgType:'data'
    })
    
  }
  removeImg = () => {
    this.setState({
        img:null
    });
  }

  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  addSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.addSupplier()
  };
  updateSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.updateSupplier()
  };

  
  onChangeDate = (date, dateString) => {
    console.log(date, dateString)
    this.setState({start:dateString})
  }

  render() {
    let {img,photoIndex, isOpen1} = this.state;
     img = [img]
    return (
      <div className="SupplierForm">
        <MDBContainer>
          <MDBRow>
            <div className="top" style={{width:'100%',textAlign:'center'}}>
              <NavLink to="/suppliers">
                <MDBIcon icon="times" className="closeIcon"/>
              </NavLink>
              <span className="stockTitle">{this.state.requestType==="post"?allStrings.completeData:allStrings.edit}</span>
            </div>
          </MDBRow>
          <form
              className="needs-validation"
              onSubmit={this.state.requestType==="post"?this.addSubmitHandler:this.updateSubmitHandler}
              noValidate
              style={{marginLeft:'2rem'}}
          >
          <MDBRow>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="9" sm="9">
                  <input name="fullname" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx5" required
                    className="form-control" placeholder={allStrings.enterName} defaultValue={this.state.fullname} 
                    style={{direction:this.props.isRTL?'rtl':'ltr',marginBottom: '10px'}}/>
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisterNameEx5" className="grey-text formLabel">
                  {allStrings.name}
                  </label>
                </MDBCol>
              </MDBRow>
             
              <MDBRow>
                <MDBCol md="9" sm="9">
                {this.state.img == null&&
                  <div className="smallUploadContainer">
                    <img alt=""  name="img" className="smallUploderImg" src={require('../../assets/images/Path 343.png')}></img>
                    <input 
                      onChange={this.fileHandler}
                      type="file"
                      id="defaultFormRegisterNameEx6"
                      required className="smallUploader" />
                    <MDBBtn color="primary" size="sm" className="smallUploaderBtn">{allStrings.chooseFile}</MDBBtn>
                    <p style={{fontSize: '14px',marginTop: '3px 15px',float:'right',paddingTop: '4px'}}  className="grey-text">
                      {allStrings.dropHere}
                    </p>
                  </div>
                }
                  
                  <div className="previewRaduis">
                      {this.state.img!= null&&
                      <MDBRow>
                        
                        <MDBCol md = "3">
                        <div style={{paddingTop:'5px',cursor:'pointer'}}  onClick={() => this.setState({ isOpen1: true })}>
                          <span style={{
                            fontSize: '17px',
                            color: '#478DFF',
                            fontWeight: '600',
                            margin: '5px'
                          }}>{allStrings.showD}</span>
                          <img alt=""  className="showImge" width="20px" src={require('../../assets/images/icons8_external_link_480px_2.png')}></img>
                        </div>
                        </MDBCol>
                        <MDBCol md="9" style={{paddingRight:'30px'}}  >
                          {this.state.img!= null&&
                            <div style={{display:"inline-block",float:'right'}}>
                                <img alt=""  width="220px" src={this.state.imgType=="data"?URL.createObjectURL(this.state.img):this.state.img} />
                                <MDBIcon icon="times" className="mr-2 removeImg" onClick={()=> this.removeImg()}></MDBIcon>
                            </div>
                          }
                          
                        </MDBCol>
                      </MDBRow>
                      }
                      {isOpen1 && (
                      <Lightbox
                        mainSrc={img[photoIndex]}
                        nextSrc={img[(photoIndex + 1) % img.length]}
                        prevSrc={img[(photoIndex + img.length - 1) % img.length]}
                        onCloseRequest={() => this.setState({ isOpen1: false,photoIndex:0  })}
                        onMovePrevRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + img.length - 1) % img.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + 1) % img.length,
                          })
                        }
                      />
                    )}
                    </div>
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisterNameEx7" className="grey-text formLabel">
                  {allStrings.profileImg}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                <MDBCol md="9" sm="9">
                  <input name="cardNum" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx12" defaultValue={this.state.cardNum} 
                    className="form-control" placeholder={allStrings.enterCardNum} style={{direction:this.props.isRTL?'rtl':'ltr'}} required/>
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisterNameEx12" className="grey-text formLabel">
                  {allStrings.cardNum}
                  </label>
                </MDBCol>
              </MDBRow>
              
              <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                <MDBCol md="9" sm="9">
                  <input name="phone" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx15" defaultValue={this.state.phone} 
                    className="form-control" placeholder={allStrings.enterPhone} style={{direction:this.props.isRTL?'rtl':'ltr'}} required />
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisterNameEx15" className="grey-text formLabel">
                  {allStrings.phone}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                <MDBCol md="9" sm="9">
                  <input name="anotherPhone" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx16" defaultValue={this.state.anotherPhone} 
                    className="form-control" placeholder={allStrings.EnterAnotherPhone} style={{direction:this.props.isRTL?'rtl':'ltr'}}/>
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisterNameEx16" className="grey-text formLabel">
                  {allStrings.anotherPhone}
                  </label>
                </MDBCol>
              </MDBRow>
              <MDBRow style={{marginBottom:'6px',marginTop:'6px'}}>
                <div style={{textAlign: 'center',margin: '1rem auto'}} onClick={() => this.props.history.push('/transaction',{data:this.state.supplier})}>
                    <a style={{color:'blue'}}>{allStrings.addTransaction}</a>
                </div>
              </MDBRow>
             
            </MDBContainer>
          </MDBRow>
          <MDBRow>
            <MDBBtn type="submit" 
            style={{ margin: '1rem',width:'150px',padding:'9px',height:'35px' }}
                className="btn z-depth-1a "
                color="primary"
              >
                 {this.state.requestType=="post"?allStrings.add:allStrings.edit}
            </MDBBtn>
          </MDBRow>
          </form>
        </MDBContainer>
      </div>
    );
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
}
  
  export default  withRouter(
    connect(mapToStateProps,mapDispatchToProps)(SupplierForm)
  );
  
