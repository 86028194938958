import React from 'react';
import './company.css'
import { MDBContainer,  MDBRow, MDBCol,MDBIcon} from "mdbreact";
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import { Popconfirm,message,Input} from 'antd';
import Pagination from '@material-ui/lab/Pagination';
import LoadCard from '../../component/load card/load card'
import { hasValue } from "../../validations/validations";
import { SearchOutlined  } from '@ant-design/icons';
import { Redirect, NavLink } from 'react-router-dom'
import ReactToPrint from 'react-to-print';

class ComponentToPrint extends React.Component {
  
  render() {
console.log(this.props)
    return (
      <form class="col s12">
          <div className='tabelDev'>     
            <div class="row">
              <table class="table table-borderless">
                  <thead class="thead-light">
                      <tr>
                        <th>الاسم</th>
                        <th>الهاتف</th>
                        <th>هاتف اخر</th>
                        <th>رقم البطاقه</th>
                        <th>المدينه</th>
                        <th>التعاقد</th>
                        <th>قيمه التعاقد</th>
                        <th>نوع مده التعاقد</th>
                        <th>مده التعاقد</th>
                      </tr>
                      <tr>
                        <th>Items</th>
                        <th>Phone</th>
                        <th>Phone2</th>
                        <th>Card Num</th>
                        <th>City</th>
                        <th>Contract Type</th>
                        <th>Contract Cost</th>
                        <th>Contract Period Type</th>
                        <th>Contract Period</th>
                      </tr>
                  </thead>

                  <tbody>
                    { this.props.data.map(company=>(
                        <tr>
                          <td>{company.fullname?company.fullname:''}</td>
                          <td>{company.phone?company.phone:''}</td>
                          <td>{company.anotherPhone?company.anotherPhone:''}</td>
                          <td>{company.cardNum?company.cardNum:''}</td>
                          <td>{company.city?this.props.isRTL?company.city.cityName_ar:company.city.cityName_en:''}</td>
                          <td>{company.contractType?company.contractType === "RATIO"?allStrings.ratio:allStrings.cost:''}</td>
                          <td>{company.contractCost?company.contractCost:''}</td>
                          <td>{company.contractPeriodType?company.contractPeriodType==="DAILY"?allStrings.daily:company.contractPeriodType==="MONTHLY"?allStrings.monthly:allStrings.yearly:''}</td>
                          <td>{company.contractPeriod?company.contractPeriod:''}</td>
                        </tr>
                    ))}
                  </tbody>
              </table>
              </div>
              </div>          
         </form>
    );
  }
}
class Companies extends React.Component {
  
  state = {
    Companies:[],
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    selectedCompany:null,
    filterType:null,
    value:'',
    companies:[],
    counts:[]


  }
  constructor(props){
    super(props)
    console.log("isr   ",this.props.history)
    this.getCompanies(1,false)
    this.getCompanyWithoutPagenation()
    this.getCounts()
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
    window.scrollTo(0,0)
  }
  getCounts = () =>{
    this.setState({loading:true})
    axios.get(`${BASE_END_POINT}companyDebt`,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("count   ",response.data)
      this.setState({loading:false,counts:response.data})
    })
    .catch(error=>{
      console.log("error   ",error.response)
      this.setState({loading:false,})
    })

  }
  getCompanyWithoutPagenation = () => {
    let url =``;
    url = `${BASE_END_POINT}withoutPagenation/get?type=COMPANY`
    axios.get(url)
    .then(response=>{
      console.log(response.data.users)
      this.setState({companies:response.data.users,companiesCount:response.data.usersCount})
    })
    .catch(error=>{
      console.log("ALL salesMen ERROR")
      console.log(error.response)
    })
  }
  
  getCompanies = (page,refresh,filterType,value) =>{
    this.setState({loading:true})
    let url = ``;
    if(filterType != null && value !== ''){
      if(filterType === "name"){
        url = `${BASE_END_POINT}find?type=COMPANY&page=${page}&name=${value}`
      }
    }else{
        url = `${BASE_END_POINT}find?type=COMPANY&page=${page}`
     
    }
    
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("Companies   ",response.data)
      this.setState({
        loading:false,
        refresh:false,
        Companies:response.data.data,
        page:response.data.page,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })

  }
  selectCompanyId = (e) =>{
    e.preventDefault();
    let id = e.target.id.substring(7)
    console.log(id)    
    this.setState({selectedCompany:id})
  }
  deleteCompany = (id) => {
    let l = message.loading('Wait..', 2.5)
    axios.delete(`${BASE_END_POINT}${id}/delete`,{
     headers: {
       'Content-Type': 'application/json',
       'Authorization':`Bearer ${this.props.currentUser.token}`
     },
    })
    .then(response=>{
      console.log("Company DELETED")
      l.then(() => message.success(allStrings.done, 2) )
      this.getCompanies(this.state.page)
    })
    .catch(error=>{
        console.log(error.response)
        l.then(() => message.error('Error', 2.5))
    })
 }
 OKBUTTON = (e) => {
  this.deleteCompany(this.state.selectedCompany)
 }

  render(){
    console.log(this.state.filterType)
    if (this.props.currentUser.user.type === "COMPANY") return <Redirect to='/Home' /> 
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.pagesPermission.includes('COMPANIES')) return <Redirect to='/Home' /> 
  
  return (
    <div className="Category">
      <div className="categoryTop" style={{marginBottom:'3rem'}}>
      <MDBContainer> 
        <MDBRow>
          <MDBCol xl="4" lg="4" md="4" sm="12">
          <ReactToPrint
              trigger={() => {
                return (
                  <div>
                    <img alt=""  style={{cursor:'pointer'}} src={require('../../assets/images/icons8_pdf.png')}></img>
                    <span
                    style={{
                      color: '#FF3B40',
                      fontWeight: '600',
                      fontSize: '20px',
                      position: 'absolute',
                      padding: '10px 10px',
                      cursor:'pointer'
                    }}>{allStrings.print}</span>
                  </div>
                ) ;
              }}
              content={() => this.componentRef}
            />
            <div style={{display:'none'}}>
            <ComponentToPrint  data={this.state.companies} ref={el => (this.componentRef = el)} />
            </div>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="4" sm="12" style={{paddingTop:'1rem',textAlign:'center'}}>
          <img alt=""  width= '40px' src={require('../../assets/images/icons8_group_of_companies.png')}></img>
            <span
            style={{
              color: 'mediumblue',
              fontWeight: '600',
              fontSize: '22px',
              position: 'absolute',
              padding: '5px 10px'
            }}>{this.state.companiesCount}</span>
            <p style={{
              color: "mediumblue",
              fontSize: "14px",
              marginTop: "6px",
              marginBottom: 0,
              fontWeight: "600"
            }}>{allStrings.companiesCount}</p>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="4" sm="12">
            <NavLink to="companyForm">
            <div className="addButton">
              <MDBIcon icon="plus" />
            </div>
            <span
            style={{
              float: 'right',
              color: '#43425D',
              fontWeight: '600',
              padding: '11px 2px',
              fontSize: '20px',
              cursor:'pointer',
            }}>{allStrings.addCompany}</span>
            </NavLink>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <div onClick={()=>this.getCompanies(1,false)} style={{width: '100%',textAlign: 'end',paddingRight: '18px'}}>
            <span style={{color: 'cadetblue',cursor: 'pointer'}}
            class="material-icons">undo</span>
          </div>
        </MDBRow>
      </MDBContainer>
          

        </div>
        <div className="filters" style={{marginBottom: "2rem"}}>
        <MDBContainer> 
          <MDBRow>
          <MDBCol xl="2" lg="2" md="2" sm="2" style={{paddingTop:'1rem',textAlign:'center'}}>
          <img alt=""  width= '40px' src={require('../../assets/images/day.png')}></img>
            <span
            style={{
              color: 'mediumblue',
              fontWeight: '600',
              fontSize: '22px',
              position: 'absolute',
              padding: '5px 10px'
            }}>{this.state.counts.totalDayContractCost}</span>
            <p style={{
              color: "mediumblue",
              fontSize: "14px",
              marginTop: "6px",
              marginBottom: 0,
              fontWeight: "600"
            }}>{allStrings.earnInDay}</p>
          </MDBCol>
          <MDBCol xl="2" lg="2" md="2" sm="2" style={{paddingTop:'1rem',textAlign:'center'}}>
          <img alt=""  width= '40px' src={require('../../assets/images/month.png')}></img>
            <span
            style={{
              color: 'mediumblue',
              fontWeight: '600',
              fontSize: '22px',
              position: 'absolute',
              padding: '5px 10px'
            }}>{this.state.counts.totalMonthContractCost}</span>
            <p style={{
              color: "mediumblue",
              fontSize: "14px",
              marginTop: "6px",
              marginBottom: 0,
              fontWeight: "600"
            }}>{allStrings.earnInMonth}</p>
          </MDBCol>
          <MDBCol xl="2" lg="2" md="2" sm="2" style={{paddingTop:'1rem',textAlign:'center'}}>
          <img alt=""  width= '40px' src={require('../../assets/images/year.png')}></img>
            <span
            style={{
              color: 'mediumblue',
              fontWeight: '600',
              fontSize: '22px',
              position: 'absolute',
              padding: '5px 10px'
            }}>{this.state.counts.totalYearContractCost}</span>
            <p style={{
              color: "mediumblue",
              fontSize: "14px",
              marginTop: "6px",
              marginBottom: 0,
              fontWeight: "600"
            }}>{allStrings.earnInYear}</p>
          </MDBCol>
          <MDBCol xl="2" lg="2" md="2" sm="2" style={{paddingTop:'1rem',textAlign:'center'}}>
          <img alt=""  width= '40px' src={require('../../assets/images/debt.png')}></img>
            <span
            style={{
              color: 'mediumblue',
              fontWeight: '600',
              fontSize: '22px',
              position: 'absolute',
              padding: '5px 10px'
            }}>{this.state.counts.totalDebt}</span>
            <p style={{
              color: "mediumblue",
              fontSize: "14px",
              marginTop: "6px",
              marginBottom: 0,
              fontWeight: "600"
            }}>{allStrings.companiesDebt}</p>
          </MDBCol>
          <MDBCol xl="4" lg="4" md="4" sm="12" >
            <Input 
              onKeyPress={(e)=>{
                if (e.key === 'Enter') {
                  if(hasValue(""+e.target.value)){
                    console.log("ENTER   ",e.target.value)
                    this.setState({filterType:'name',value:e.target.value})
                    this.getCompanies(1,false,"name",e.target.value)
                  }else{
                    message.warning(allStrings.noValue);
                    console.log("ENTER NO VALUE   ",e.target.value)
                  }
                }
              }}  
              placeholder={allStrings.nameFilter}
              size="large"  prefix={<SearchOutlined />} />
          </MDBCol>
         
          </MDBRow>
        </MDBContainer>
        </div>
      <MDBContainer> 
        <MDBRow>
        {this.state.loading?
          <LoadCard/>
          :
          this.state.Companies.length > 0 ?
            this.state.Companies.map(Company=>(
              
              <MDBCol xl="4" lg="6" md="12" sm="12">
                
              <div className="categoryCard" >
              <MDBContainer> 
                <MDBRow>
                  <MDBCol xl="2" lg="2" md="2" sm="2">
                    <div className="icons">
                    <Popconfirm
                        title={allStrings.areYouSureCompany}
                        onConfirm={this.OKBUTTON}
                        onCancel={this.fCANCELBUTTON}
                        okText={allStrings.ok}
                        cancelText={allStrings.cancel}
                      >
                      <p style={{
                            fontSize: '24px',
                            color: '#A4A3B0',
                            marginBottom: '8px',
                            cursor: 'pointer',
                            zIndex:'4',
                            position: 'relative'
                      }} onClick={this.selectCompanyId} id={"Company"+Company.id}>
                        <MDBIcon icon="times" onClick={this.selectCompanyId} id={"Company"+Company.id}/>
                      </p>

                    </Popconfirm>
                       
                      <p onClick={() => this.props.history.push('/companyForm',{data:Company})}  style={{
                          fontSize: '18px',
                          color: '#A4A3B0',
                          marginBottom: '8px',
                          cursor: 'pointer',
                      }}>
                      <MDBIcon icon="pen" />
                      </p>
                     
                    </div>
                  </MDBCol>
                  <MDBCol xl="6" lg="6" md="6" sm="6" className="noPadding">
                    <div className="categoryInfo" onClick={() => this.props.history.push('/companyForm',{data:Company})} >
                      <p style={{
                        fontWeight: '600',
                        color:'#43425D',
                        marginBottom: '6px',
                        fontSize: '1.2rem'
  
                      }}>{Company.fullname.length>15?Company.fullname.substring(0, 15) + "..":Company.fullname}</p>
                     
                      <p style={{
                      color: '#43425D',
                      fontSize: '16px',
                      marginBottom: '8px',
                      display:'inline-block'}}>{Company.phone} </p>
                      <p style={{color: '#43425D',fontSize: '16px',margin: 0,marginBottom: '10px'}}>{Company.anotherPhone}</p>
                     
                    </div>
                  </MDBCol>
                  <MDBCol xl="4" lg="4" md="4" sm="4">
                  
                    <div className="categoryImg" >
                      <img alt=""  style={{width: '85px',
                      height: '85px',
                      borderRadius: '100%'}} src={Company.img?Company.img:require('../../assets/images/profileicon.jpg')}></img>
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow onClick={() => this.props.history.push('/companyForm',{data:Company})} >
                  <MDBCol  xl="6" lg="6" md="6" sm="6">
                  <p style={{color: '#43425D',fontSize: '16px',margin: 0,marginBottom: '10px'}}>{this.props.isRTL?Company.city.cityName_ar:Company.city.cityName_en}</p>
                  </MDBCol>
                  <MDBCol  xl="6" lg="6" md="6" sm="6">
                  <p style={{color: '#43425D',fontSize: '16px',margin: 0,marginBottom: '10px'}}>{allStrings.city}</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow onClick={() => this.props.history.push('/companyForm',{data:Company})} >
                  <MDBCol  xl="6" lg="6" md="6" sm="6">
                  <p style={{color: '#43425D',fontSize: '16px',margin: 0,marginBottom: '10px'}}>{Company.companyOwner}</p>
                  </MDBCol>
                  <MDBCol  xl="6" lg="6" md="6" sm="6">
                  <p style={{color: '#43425D',fontSize: '16px',margin: 0,marginBottom: '10px'}}>{allStrings.companyOwner}</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow onClick={() => this.props.history.push('/companyForm',{data:Company})} >
                  <MDBCol  xl="6" lg="6" md="6" sm="6">
                  <p style={{color: '#43425D',fontSize: '16px',margin: 0,marginBottom: '10px'}}>{Company.debt}</p>
                  </MDBCol>
                  <MDBCol  xl="6" lg="6" md="6" sm="6">
                  <p style={{color: '#43425D',fontSize: '16px',margin: 0,marginBottom: '10px'}}>{allStrings.debt}</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow onClick={() => this.props.history.push('/companyForm',{data:Company})} >
                  <MDBCol  xl="6" lg="6" md="6" sm="6">
                  <p style={{color: '#43425D',fontSize: '16px',margin: 0,marginBottom: '10px'}}>{allStrings.day} {Company.remainToPay?Company.remainToPay:0 }</p>
                  </MDBCol>
                  <MDBCol  xl="6" lg="6" md="6" sm="6">
                  <p style={{color: '#43425D',fontSize: '16px',margin: 0,marginBottom: '10px'}}>{allStrings.deptPayment}</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow onClick={() => this.props.history.push('/companyForm',{data:Company})} >
                  <MDBCol  xl="6" lg="6" md="6" sm="6">
                  <p style={{color: '#43425D',fontSize: '16px',margin: 0,marginBottom: '10px'}}>{Company.contractType?Company.contractType === "RATIO"?allStrings.ratio:allStrings.cost:""}</p>
                  </MDBCol>
                  <MDBCol  xl="6" lg="6" md="6" sm="6">
                  <p style={{color: '#43425D',fontSize: '16px',margin: 0,marginBottom: '10px'}}>{allStrings.contractType}</p>
                  </MDBCol>
                </MDBRow>
                <MDBRow onClick={() => this.props.history.push('/companyForm',{data:Company})} >
                  <MDBCol  xl="6" lg="6" md="6" sm="6">
                  <p style={{color: '#43425D',fontSize: '16px',margin: 0,marginBottom: '10px'}}>{Company.contractCost?Company.contractCost:"" + Company.contractType === "RATIO"?" %":" "}</p>
                  </MDBCol>
                  <MDBCol  xl="6" lg="6" md="6" sm="6">
                  <p style={{color: '#43425D',fontSize: '16px',margin: 0,marginBottom: '10px'}}>{allStrings.contractCost}</p>
                  </MDBCol>
                </MDBRow>
              
              </MDBContainer> 
              </div>
              </MDBCol>
            ))
            :
            <div className="noData">{allStrings.noData}</div>
        }
        </MDBRow>
        <MDBRow>
          {this.state.Companies.length > 0 &&
            <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
              <Pagination
                onChange={(event,page)=>{
                console.log("page   ",page)
                if(page!==this.state.page){
                  this.getCompanies(page,false,this.state.filterType,this.state.value)
                  this.setState({page:page})
                }
                
                }} 
                defaultPage={1} page={this.state.page}  count={this.state.pages} style={{color:`blue !important`}} />
            </div>
          }
          
        </MDBRow>
      </MDBContainer> 
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage
}

export default  withRouter( connect(mapToStateProps,mapDispatchToProps) (Companies))
