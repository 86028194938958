
import React from 'react';
import allStrings from '../../assets/strings'
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import './problems.css'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import { setUser } from "../../redux/actions/AuthActions";
import 'antd/dist/antd.css';
import { MDBContainer ,MDBRow,MDBCol} from 'mdbreact';
import Skeleton from 'react-loading-skeleton';
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import moment from 'moment'
import { Redirect } from 'react-router-dom'


class Problems extends React.Component {
  constructor(props){
    super(props)
    console.log("isr   ",this.props.isRTL)
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
    }else{
      allStrings.setLanguage('en')
    } 
  }

  state ={
    loading:false,
    problems:[],
    problem:[],
    selectedProblem:null
  }

  componentDidMount(){
    this.getProblems()
  }
  getProblems = (page,refresh) =>{
    this.setState({loading:true})
    let url
    url = `${BASE_END_POINT}suggestions?page=${page}`
    
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("suggestions   ",response.data)
      this.setState({
        loading:false,
        refresh:false,
        problems:response.data.data,
        problem:response.data.data[0],
        selectedProblem:response.data.data[0].id,
        page:response.data.page,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })
  }
 


  render(){
    if (this.props.currentUser.user.type === "COMPANY") return <Redirect to='/Home' /> 
    if (this.props.currentUser.user.type === "SUB-ADMIN" && !this.props.currentUser.user.pagesPermission.includes('PROBLEMS')) return <Redirect to='/Home' /> 
  
  return (
    <div>
      <MDBContainer>
      <div className="Problem">
        <MDBRow className="mdRevers">
            <MDBCol xl="8" lg="8" md = "12" sm="12">
            {this.state.problems.length > 0 ?
              <div className="problemInfo">
                <p style={{textAlign:this.props.isRTL?'right':'left',
                fontWeight:'600',fontSize: '18px'}}>{allStrings.title}</p>
                <p style={{textAlign:this.props.isRTL?'right':'left',
                fontWeight:'400'}}>{this.state.problem.description}</p>
              </div>
              :
              <div className="problemInfo">
                <div className="noData">{allStrings.noData}</div>
              </div>
            }
            {this.state.problems.length > 0 ?
              <div className="problemUserInfo">
                  <p style={{textAlign:this.props.isRTL?'right':'left',
                fontWeight:'600',fontSize: '18px'}}>{allStrings.contactInfo}</p>
                  <p style={{textAlign:this.props.isRTL?'right':'left',
                fontWeight:'400'}}>{this.state.problem.phone!==" "?this.state.problem.phone:allStrings.noData}</p>
                <br></br>
                <br></br>
                <br></br>
                <p style={{textAlign:'left',
                fontWeight:'400'}}>{moment(this.state.problem.createdAt).calendar()}</p>
              </div>
              :
              <div className="problemInfo">
                <div className="noData">{allStrings.noData}</div>
              </div>
            }
            </MDBCol>
            <MDBCol xl="4" lg="4" md = "12" sm="12">
              <div className="problemsRight">
                <h3>{allStrings.problemsAndSuggestions}</h3>
                <div className="proplemsItems">
                {this.state.loading?
                      <div>
                        <Skeleton height={80} />
                        <Skeleton height={80} />
                        <Skeleton height={80} />
                        <Skeleton height={80} />
                        <Skeleton height={80} />
                      </div>
                    
                    :
                    this.state.problems.length > 0 ?
                    this.state.problems.map((val) => (
                  <div className="proplemItems" 
                    onClick={(e) => {
                      this.setState({selectedProblem:val.id})
                    }}
                    style={{background:this.state.selectedProblem===val.id?'#eaedf0b9':'#fff'}}>
                    <div className="problemRight">
                      <p style={{textAlign:'right',
                        fontWeight:'600',fontSize: '16px',marginBottom: '10px'}}>{allStrings.title}</p>
                      <p style={{textAlign:'right',
                        fontWeight:'400',fontSize: '14px'}}>{val.description}</p>
                    </div>
                    <div className="problemLeft">
                      <p style={{fontWeight:'400',fontSize: '14px'}}>{moment(val.createdAt).calendar()}</p>
                    </div>
                  </div>
                  )) :
                  <div className="noData">{allStrings.noData}</div>
                  }
                </div>
              </div>
            </MDBCol>
         
        </MDBRow>
        </div> 
      </MDBContainer>
    </div> 
  )
  }
}

const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,

})

const mapDispatchToProps = {
  ChangeLanguage,
  setUser,
}

export default  withRouter(
  connect(mapToStateProps,mapDispatchToProps)(Problems)
);
