/* eslint-disable eqeqeq */
import React from "react";
import "./expenses.css";
import {MDBContainer,MDBRow,MDBCol,MDBIcon} from "mdbreact";
import allStrings from "../../assets/strings";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom'
import {BASE_END_POINT} from '../../urls'
import axios from 'axios'
import {setUser} from '../../redux/actions/AuthActions'
import {hasValue} from '../../validations/validations'
import {message,DatePicker,ConfigProvider } from 'antd';
import { Redirect } from 'react-router-dom'
import {ChangeLanguage} from '../../redux/actions/LanguageAction'
import {ChangeCompany,ChangeBranch} from '../../redux/actions/CompanyAction'

import Skeleton from 'react-loading-skeleton';
import moment from 'moment'
import Pagination from '@material-ui/lab/Pagination';
import locale_ar from 'antd/lib/locale/ar_EG';
import locale_en from 'antd/lib/locale/en_GB';
import 'moment/locale/ar';
import ReactToPrint from 'react-to-print';
class ComponentToPrint extends React.Component {
  
  render() {
console.log(this.props)
    return (
      <form class="col s12">
          <div className='tabelDev'>     
            <div class="row">
              <table class="table table-borderless">
                  <thead class="thead-light">
                      <tr>
                        <th>التاريخ</th>
                        <th>المسمى</th>
                        <th>التكلفه</th>
                        
                      </tr>
                      <tr>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Cost</th>
                      </tr>
                  </thead>

                  <tbody>
                    { this.props.data.map(val=>(
                        <tr>
                          <td>{val.createdAt.substring(0, 10)}</td>
                          <td>{val.description}</td>
                          <td>{val.cost}</td>
                          
                        </tr>
                    ))}
                  </tbody>
              </table>
              </div>
              </div>          
         </form>
    );
  }
}
let fromDate
let toDate
class Expenses extends React.Component {
  state = {
    cost:'',
    description:'',
    expenses:[],
    Expenses:[],
    expensessCost:0,
    balance:0,
    loading:true,
    refresh:false,
    page:1,
    pages:1,
    from:this.props.isRTL?moment().startOf('month').format("YYYY/MM/DD"):moment().startOf('month').locale('en').format("YYYY/MM/DD"),
    to:this.props.isRTL?moment().endOf('month').format("YYYY/MM/DD"):moment().endOf('month').locale('en').format("YYYY/MM/DD"),
  };

  constructor(props){
    super(props)
    console.log("isr   ",this.props.history)
    this.getDuration()
    this.getExpenses(1,false)
    this.getExpensesWithoutPagenation()
    if(this.props.isRTL){
      allStrings.setLanguage('ar')
      moment.locale('ar');
    }else{
      allStrings.setLanguage('en')
      moment.locale('en');
    } 
    
    window.scrollTo(0,0)
  }
  getDuration = () =>{
    //first day
    let date = new Date()
    let newDate = date.toISOString().slice(0, 8)  
    let firstDay = newDate + '01';
    //get days in month
    function daysInMonth (month, year) {
        return new Date(year, month , 0).getDate();
    }

    let DaysInCurrentMonth = daysInMonth(date.getMonth() + 1,date.getFullYear()); //number of day in month
    //last day
    let lastDay= newDate + DaysInCurrentMonth;
    console.log(firstDay)
    console.log(lastDay)
    if(this.props.isRTL){
      this.setState({from:moment(firstDay).format("YYYY/MM/DD") ,to:moment(lastDay).format("YYYY/MM/DD") })
    }else{
      this.setState({from:moment(firstDay).locale('en').format("YYYY/MM/DD") ,to:moment(lastDay).locale('en').format("YYYY/MM/DD") })

    }

    fromDate = firstDay
      toDate = lastDay
    
  }
  getExpensesWithoutPagenation = () =>{
    let url
    if(this.props.currentUser.user.type =="ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN"){
      if(this.props.company == false){
        url =`${BASE_END_POINT}expenses/withoutPagenation/get?start=${fromDate}&end=${toDate}`
      }else{
        url =`${BASE_END_POINT}expenses/withoutPagenation/get?start=${fromDate}&end=${toDate}&company=${this.props.company}`
      }
    }else{
      url = `${BASE_END_POINT}expenses/withoutPagenation/get?company=${this.props.currentUser.user.id}&start=${fromDate}&end=${toDate}`
    }
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("expenses   ",response.data)
      this.setState({
        Expenses:response.data.expensess,
        expensessCost:response.data.expensessCost
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
    })

  }
  getExpenses = (page,refresh) =>{
    this.setState({loading:true})
    let url
    if(this.props.currentUser.user.type =="ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN"){
      if(this.props.company == false){
        url =`${BASE_END_POINT}expenses?page=${page}&start=${fromDate}&end=${toDate}`
      }else{
        url =`${BASE_END_POINT}expenses?page=${page}&start=${fromDate}&end=${toDate}&company=${this.props.company}`
      }
    }else{
      url = `${BASE_END_POINT}expenses?company=${this.props.currentUser.user.id}&page=${page}&start=${fromDate}&end=${toDate}`
    }
    axios.get(url,{
      headers:{
        'Authorization':`Bearer ${this.props.currentUser.token}`
      }
    })
    .then(response=>{
      console.log("orders   ",response.data)
      this.setState({
        loading:false,
        refresh:false,
        expenses:response.data.data,
        page:response.data.page,
        pages:response.data.pageCount,
      })
    })
    .catch(error=>{
      console.log("error   ",error.response)
      console.log("error2   ",error)
      this.setState({loading:false,})
    })

  }
 
  
  add = () => {
    const {description,cost} = this.state
   
    if(hasValue(description)&&hasValue(cost)){
        let l = message.loading('Wait..', 2.5)
        var data = {
          "description":description,
          "cost":parseInt(cost)
        }
        if(this.props.currentUser.user.type == "ADMIN" || this.props.currentUser.user.type == "SUB-ADMIN" ){
          if(this.props.company == false){
            data.company = this.props.currentUser.user.company[0].id
          }else{
            data.company = this.props.company
          }
        } else{
          data.company=this.props.currentUser.user.id
        }
        axios.post(`${BASE_END_POINT}expenses`,JSON.stringify(data),{
        headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.props.currentUser.token}`
        },
        })
        .then(response=>{
          l.then(() => message.success(allStrings.done, 2.5) )
          this.getExpenses(1,false)
          this.getExpensesWithoutPagenation()
          let balance = this.state.expensessCost + parseInt(cost);
          
          this.setState({description:'',cost:'',expensessCost:balance})
        })
        .catch(error=>{
            console.log(error.response)
            l.then(() => message.error('Error', 2.5))
        })
    }
  }

  componentDidMount(){
    //this.props.ChangeLanguage(false)
    this.getDuration()
    
  }
 

  changeHandler = (event) => {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };
  addSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    this.add()
  };
 
  onChangeFrom = (date,dateString) => { 
    console.log(dateString) 
    if(this.props.isRTL){
      this.setState({from:moment(dateString).format("YYYY/MM/DD")})
    }else{
      this.setState({from:moment(dateString).locale('en').format("YYYY/MM/DD") })
    }
    fromDate = moment(dateString).locale('en').format("YYYY-MM-DD")
  }
  onChangeTo = (date,dateString) => {
    if(this.props.isRTL){
      this.setState({to:moment(dateString).format("YYYY/MM/DD") })
    }else{
      this.setState({to:moment(dateString).locale('en').format("YYYY/MM/DD") })

    }
    toDate = moment(dateString).locale('en').format("YYYY-MM-DD")
    this.getExpenses(1,false)
  }
  
  render() {
    console.log(this.state.from)
    if (this.props.currentUser.user.type == "SUB-ADMIN" && !this.props.currentUser.user.pagesPermission.includes('EXPENSES')) return <Redirect to='/Home' /> 
  
    return (
      <div className="expenses">
        <MDBContainer>
          <MDBRow>
            <MDBCol  xl="4" lg="4" md="12" sm="12" style={{paddingRight:'0'}}>
            <div className="top" style={{width:'100%',textAlign:'center'}}>
              {/* <span onClick={()=> this.props.history.goBack()}>
                <MDBIcon icon="times" className="closeIcon"/>
              </span> */}
            </div>
            <div className="smallAddButton" onClick={()=> this.add()}>
              <MDBIcon icon="plus" />
            </div>
            <span
            onClick={()=> this.add()}
            style={{
              float: 'right',
              color: '#43425D',
              fontWeight: '600',
              padding: '20px 2px',
              fontSize: '20px',
              cursor:'pointer',
            }}>{allStrings.addCost}</span>
          
           
            </MDBCol>
            <MDBCol  xl="4" lg="4" md="12" sm="12">
              <MDBRow style={{marginBottom:'25px',marginTop:'15px'}}>
                <MDBCol md="9" sm="9">
                  <input name="cost" onChange={this.changeHandler} id="defaultFomRegisterNameEx16" 
                    type="number" min={1} className="form-control" placeholder={allStrings.EnterPrice} style={{direction:this.props.isRTL?'rtl':'ltr'}}
                    value={this.state.cost}/>
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFomRegisterNameEx16" className="grey-text formLabel">
                  {allStrings.price}
                  </label>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol xl="4" lg="4" md="12" sm="12">
              <MDBRow style={{marginBottom:'25px',marginTop:'15px'}}>
                <MDBCol md="9" sm="9">
                  <input name="description" onChange={this.changeHandler} type="text" id="defaultFormRegisterNameEx16" 
                    value={this.state.description} className="form-control" placeholder={allStrings.enterName} style={{direction:this.props.isRTL?'rtl':'ltr'}}/>
                </MDBCol>
                <MDBCol md="3" sm="3" style={{paddingLeft:'0px'}}>
                  <label  htmlFor="defaultFormRegisterNameEx16" className="grey-text formLabel">
                  {allStrings.theNaming}
                  </label>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol xl="8" lg="8" md="8" sm="8">
            <table class="table table-borderless">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">{allStrings.date}</th>
                    <th scope="col">{allStrings.theNaming}</th>
                    <th scope="col">{allStrings.cost}</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.loading?
                <tr style={{height: '200px'}}>
                    <th colspan="4" className="noData">
                    <Skeleton height={400}/> 
                    </th>
                </tr>
                :
                this.state.expenses.length > 0 ?
                  this.state.expenses.map(val=>(
                      <tr>
                        <td>{val.createdAt.substring(0, 10)}</td>
                        <td>{val.description}</td>
                        <td>{val.cost}</td>
                      </tr>
                  ))
                  :
                  <tr style={{height: '200px'}}>
                      <th colspan="4" className="noData">
                       <p style={{marginTop:'4rem'}}>{allStrings.noData}</p> 
                      </th>
                  </tr>
                }
                <th colspan="4">
                <MDBRow>
                  {this.state.expenses.length > 0 &&
                    <div style={{textAlign:'center',margin:'auto',marginTop:'3rem'}}>
                      <Pagination
                        onChange={(event,page)=>{
                        console.log("page   ",page)
                        if(page!=this.state.page){
                          this.getExpenses(page,false)
                          this.setState({page:page})
                        }
                        
                        }} 
                        defaultPage={1} page={this.state.page}  count={this.state.pages} style={{color:`blue !important`}} />
                    </div>
                  }
                  
                </MDBRow>
                </th>
                </tbody>
              </table>
            </MDBCol>
            <MDBCol xl="4" lg="4" md="4" sm="4">
              <p style={{fontSize: '66px',fontWeight: '700',color: '#aaa',textAlign: 'center'}}>{this.state.expensessCost}</p>
              <div style={{paddingtop: '15px'}}>
                <div style={{marginBottom:'10px',textAlign:'center'}}>
                  <span className="fromSpan" style={{float:this.props.isRTL? 'right':'left',marginRight:'25%'}}>{allStrings.from}</span>
                  <div style={{display:'inline-block',textAlign:'center',marginLeft:this.props.isRTL? '35px':"-93px"}}>
                  <ConfigProvider locale={this.props.isRTL?locale_ar:locale_en}>
                  <DatePicker  defaultValue={moment(this.state.from, 'YYYY/MM/DD')}  onChange={this.onChangeFrom}/>
                  </ConfigProvider>
                  <span className="newSpan"><img alt="" style={{marginRight:'4px'}} src={require('../../assets/images/icons8_edit_property.png')}></img>{this.state.from}</span>
                  </div>
                </div>
                <div style={{marginBottom:'10px',textAlign:'center'}}>
                  <span className="fromSpan" style={{float:this.props.isRTL? 'right':'left',marginRight:'25%'}}>{allStrings.to}</span>
                  <div style={{display:'inline-block',textAlign:'center',marginLeft: this.props.isRTL? '35px':"-93px"}}>
                  <ConfigProvider locale={this.props.isRTL?locale_ar:locale_en}>
                  <DatePicker  defaultValue={moment(this.state.to, 'YYYY/MM/DD')}  onChange={this.onChangeTo}/>
                  </ConfigProvider>
                  <span className="newSpan"><img alt="" style={{marginRight:'4px'}} src={require('../../assets/images/icons8_edit_property.png')}></img>{this.state.to}</span>
                  </div>
                </div>
              </div>
              <br></br>
              <br></br>
              <div style={{textAlign: 'center',paddingtop: '15px'}}>
                <ReactToPrint
                documentTitle={"تقرير المصروفات بتاريخ "+ moment().format("YYYY/MM/DD")}
                trigger={() => {
                  return (
                    <div>
                      <img alt="" style={{cursor:'pointer'}} src={require('../../assets/images/icons8_pdf.png')}></img>
                      <span
                      style={{
                        color: '#FF3B40',
                        fontWeight: '600',
                        fontSize: '20px',
                        position: 'absolute',
                        padding: '10px 10px',
                        cursor:'pointer'
                      }}>{allStrings.print}</span>
                    </div>
                  ) ;
                }}
                content={() => this.componentRef}
              />
              <div style={{display:'none'}}>
              <ComponentToPrint  data={this.state.Expenses} ref={el => (this.componentRef = el)} />
              </div>
              </div>
            </MDBCol>
          </MDBRow>
          
        </MDBContainer>
      </div>
    );
  }
}
const mapToStateProps = state => ({
  isRTL: state.lang.isRTL,
  currentUser: state.auth.currentUser,
  branch: state.company.branch,
  company: state.company.company,
})

const mapDispatchToProps = {
  setUser,
  ChangeLanguage,
  ChangeCompany,
  ChangeBranch,
}
  
  export default  withRouter(
    connect(mapToStateProps,mapDispatchToProps)(Expenses)
  );
  
